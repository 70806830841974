<template>
    <div class="col-11 lg:col-1 p-3  cursor-pointer" style="width: 13.2rem; " @mouseover="hover = true" @mouseleave="hover = false" :class="{ 'hovered': hover }">
        <div class="py-3 text-center border-round shadow-2" style="height: 10rem; width: 12.5rem" :class="backgroundColor">
            <span class="inline-flex justify-content-center align-items-center bg-gray-300 opacity-80 border-circle mb-3 p-3">
                <i class=" text-xl text-gray-900" :class="icon"></i>
            </span>
            <div class="text-blue-100 text-gray-800 font-medium">{{ label }}</div>
            <span :id="label.toLowerCase().replace(/ /g, '-')" class="text-xl font-medium text-gray-900">
              {{ (typeof value === 'number' ? animatedDescription :  value) }}
            </span>
        </div>
    </div>
</template>
  
<script setup>
import { ref, watchEffect } from 'vue';

const hover = ref(false);
const animatedDescription = ref(0);
let animationInterval;

const props = defineProps({
    value: {
        type: [String, Number],
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        required: true,
    },
    backgroundColor: {
        type: String,
        required: true,
    },
});
watchEffect(() => {
    clearInterval(animationInterval);

    const targetValue = parseInt(props.value);
    let currentValue = 0;

    const step = Math.ceil(targetValue / 30);

    animationInterval = setInterval(() => {
        currentValue += step;

        if (currentValue >= targetValue) {
            animatedDescription.value = targetValue;
            clearInterval(animationInterval);
        } else {
            animatedDescription.value = currentValue;
        }
    }, 60);
});
</script>
  
<style scoped>
.hovered {
    transform: scale(1.04);
    transition: 0.1s ease-in-out;
}
</style>
  
