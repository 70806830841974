<template>
  <slot name="button" :show="show" :hide="hide"></slot>
  <Dialog v-model:visible="visible" modal :style="{ width: '45rem' }">
    <template #header>
      <div class="flex justify-content-between w-full">
        <h3>Upload {{ header }}</h3>
        <p
          v-if="formState?.file?.length > 0"
          class="text-xl font-bold bg-blue-100 px-4 border-round mr-4 shadow-1"
        >
          No of Files : {{ formState && formState?.file?.length }}
        </p>
      </div>
    </template>
    <FileUpload
      ref="fileUpload"
      @select="onFileSelected"
      :accept="acceptedFilesFormate"
      :maxFileSize="1000000 * 4"
      :multiple="isMultiple"
      :chooseLabel="`Browse ${header}`"
    >
      <template #content="{ files, removeFileCallback }">
        <div v-if="files.length > 0">
          <div
            class="selected-files-grid"
            style="max-height: 40vh; overflow-y: scroll"
          >
            <div
              v-for="(file, index) of files"
              :key="file.name + file.type + file.size"
              class="card text]-sm m-0 px-2 shadow-1 border-round my-2 flex border-1 surface-border gap-2 uploaded-item-preview"
              :class="{
                'bg-red-100': missingPairs.includes(file?.name.toLowerCase()),
              }"
            >
              <div class="mt-2">
                <XlsxIcon
                  v-if="file.type === 'text/xml'"
                  width="65px"
                  height="40px"
                  fill="#428bff"
                />
                <PdfIcon
                  v-else-if="file.type === 'application/pdf'"
                  width="65px"
                  height="40px"
                  fill="#428bff"
                />
              </div>
              <div
                class="w-full text-left flex-column flex justify-content-between py-2"
              >
                <p
                  class="font-bold m-0 mt-1 cursor-pointer"
                  v-tooltip.left="file.name"
                >
                  {{ limitText(file.name, 45) }}
                </p>
                <div class="flex justify-content-between">
                  <span style="font-size: 12px" class="">{{
                    formatSize(file.size)
                  }}</span>
                  <Button
                    icon="pi pi-trash"
                    class="delete-file-button -mt-3 mb-2"
                    @click="
                      onRemoveTemplatingFile(file, removeFileCallback, index)
                    "
                    outlined
                    rounded
                    severity="danger"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div
          class="border-3 border-2 border-dotted border-round border-blue-400 p-5 process-invoice-uploader"
        >
          <div>
            <div>
              <XlsxIcon
                fill="#428bff"
                height="100"
                width="100"
                style="transform: rotate(12deg)"
              />
              <XlsIcon
                fill="#428bff"
                height="100"
                width="100"
                style="transform: rotate(-12deg)"
              />
            </div>
            <div>
              <h2
                class="text-5xl mb-0"
                style="font-weight: 900; letter-spacing: -2px"
              >
                Drag & Drop
                <span class="" style="color: #428bff">{{ header }}</span>
                to upload
              </h2>
              <p class="mt-1 overflow-hidden">
                or
                <a
                  tabindex="0"
                  @keypress.enter="openFileSelection"
                  @click="openFileSelection"
                  class="cursor-pointer browse-files"
                  ><span>browse files</span></a
                >
                from your device
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #header>
        <div class="flex justify-content-between w-full">
          <div class="w-full">
            <ProgressBar
              v-if="isLoading"
              mode="indeterminate"
              style="height: 6px"
            ></ProgressBar>
          </div>
        </div>
      </template>
    </FileUpload>
    <!-- <slot name="footer"></slot> -->
    <div class="flex justify-content-end">
      <Button
        :label="`Upload ${header}`"
        class="my-5"
        @click="onUploadFile"
        icon="pi pi-send"
        :disabled="disabledProcess"
        :loading="isLoading"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { reactive, ref, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";
import { formatSize, limitText } from "../../composables/useUtils";
import XlsxIcon from "@/components/XlsxIcon.vue";
import XlsIcon from "@/components/XlsIcon.vue";
import PdfIcon from "@/components/PdfIcon.vue";

const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);

const props = defineProps({
  header: {
    type: String,
    default: "Invoices",
  },
  acceptedFilesFormate: {
    type: String,
    default: ".xls,.xlsx",
  },
  isMultiple: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isUploadDone: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["onUploadFile"]);

const visible = ref(false);
const disabledUpload = ref(true);
const disabledProcess = ref(true);
const fileUpload = ref();
// const keyfileUploading = ref(false);
const missingPairs = ref([]);

const formState = reactive({
  email: attributes.value.email,
  user: userName.value,
  organization: organization.value,
  file: null,
});

const checkMissingPairs = (files) => {
  const filePairs = {};
  const missingPairs = [];

  files.forEach((file) => {
    const baseName = file.name.replace(/\.(pdf|xml)$/i, "");
    const extension = file.name.split(".").pop().toLowerCase();

    if (!filePairs[baseName]) {
      filePairs[baseName] = { pdf: false, xml: false };
    }
    filePairs[baseName][extension] = true;
  });

  // Return an empty array immediately if no .xml files are present
  if (!Object.values(filePairs).some((pair) => pair.xml)) {
    return [];
  }

  // Check for missing pairs
  Object.keys(filePairs).forEach((baseName) => {
    if (!filePairs[baseName].xml) {
      missingPairs.push(`${baseName.toLowerCase()}.pdf`);
    }
    if (!filePairs[baseName].pdf) {
      missingPairs.push(`${baseName.toLowerCase()}.xml`);
    }
  });

  return missingPairs;
};

const onFileSelected = (event) => {
  formState.file = event.files;
  disabledUpload.value = false;
  disabledProcess.value = false;

  if (props.isMultiple) {
    missingPairs.value = checkMissingPairs(formState.file);
  }
};

const onUploadFile = async () => {
  if (props.isMultiple) {
    missingPairs.value = checkMissingPairs(formState.file);
  }

  emits("onUploadFile", formState);
};

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
  removeFileCallback(index);
  const files = formState.file.filter((item) => {
    return item.name !== file.name;
  });
  formState.file = files;
};
const show = () => {
  visible.value = true;
};

const openFileSelection = () => {
  fileUpload.value.choose();
};

watchEffect(() => {
  if (props.isUploadDone && visible.value) {
    visible.value = false;
  }
});
</script>

<style scoped>
::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

  .details-subtable {
    padding: 1rem;
  }
}

:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}
.browse-files {
  color: #015df1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #68a2ff;
  padding: 0 3px;
  position: relative;
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: inline;
  background: transparent;
}

.browse-files:before {
  background: #dae9fc;
  display: block;
  content: "";
  position: absolute;
  top: 23px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.2s ease-in;
  height: 22px;
  z-index: 1;
}

.browse-files:focus:before {
  outline: none !important;
}

.browse-files:focus:before,
.browse-files:hover:before {
  top: 0;
}

.browse-files span {
  z-index: 2;
  display: inline;
  position: relative;
}
</style>
