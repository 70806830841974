<template>
  <div class="relative">
    <div class="flex justify-content-between align-items-center mt-2 px-4">
      <div class="flex align-items-center gap-4">
        <h2 class="text-start ml-5">Ap Check</h2>
        <ButtonGroup>
          <router-link to="/ap-check"><Button outlined class="border-noround border-round-left" label="Results" /></router-link>
          <router-link to="/ap-check/issues"><Button class="border-noround border-round-right" label="Issues" /></router-link>
        </ButtonGroup>
      </div>
      <UploadModal @uploaded="fileUploaded" />
    </div>
    <Divider style="width: 98%" class="mx-3" />
    <div class="flex w-full p-4 -mt-4">
      <div class="border-1 surface-border border-round shadow-1 px-3 py-2" style="width: 25%; z-index: 100">
        <ApCheckFilter @applyFilters="applyFilters" @resetFilters="resetFilters" />
      </div>
      <div class="kanban">
        <div v-for="i in apCheckIssuesStore.columns" :key="i.label" class="kanban-column">
          <div class="kanban-header flex justify-content-between">
            <span>{{ i.label }}</span>
            <span>{{ i.items.length }}</span>
          </div>
          <div class="kanban-body px-2">
            <div v-bind:class="{
              'h-full overflow-x-hidden px-2': true,
              'overflow-y-auto': i.items.length > 0,
              'overflow-y-hidden': i.items.length === 0
            }">
              <div v-if="loading">
                <Skeleton :key="x" class="mb-4" v-for="x in 5" width="100%" height="10rem"></Skeleton>
              </div>
              <draggable v-else v-model="i.items" v-bind="dragOptions" tag="ul" :component-data="{
                tag: 'div',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null,
              }" group="people" class="kanban-draggable" item-key="invoice_issue_id"
                @end="drag = false" @start="drag = true" @change="draggableChange(i, ...arguments)">
                <template #item="{ element }">
                  <ApCheckIssueModal @select-issue="issueModalVisible = true" :issue="element"></ApCheckIssueModal>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="apCheckIssuesStore.currentIssue" :header="renderApStatusTitle(apCheckIssuesStore.currentIssue.ap_check_status)" v-model:visible="issueModalVisible" :modal="true"
      :closeOnEscape="true" @hide="apCheckIssuesStore.removeCurrentIssueId();" :style="{ width: '80vw', height: '100vh' }"
      contentStyle="height: 100vh"
    >
      <template #header >
        <h2 class="text-red-300 my-0">
            {{ renderApStatusTitle(apCheckIssuesStore.currentIssue.ap_check_status) }}
        </h2>
      </template>
      <div class="flex gap-3 border-top-1 border-200 border-bottom-1">
        <div style="width: 100%;height: calc(100vh - 190px)" class="border-right-1 border-200 pt-3 pr-3 flex flex-column">
            <div class="overflow-y-auto">
              <CommentWithAttachments v-for="comment in formattedComments" :key="comment.date" :comment="comment" />
              <div v-if="formattedComments.length === 0" class="flex flex-column align-items-center gap-2 mt-5">
                  <i class="pi pi-comments" style="font-size: 2rem"></i>
                  <p>No comments</p>
              </div>
            </div>
            <div class="mt-auto">
                <AddComment></AddComment>
            </div>
        </div>
        <div class="pt-3" style="width: 70%">
            <IssueActions :updating-status="updatingStatus" @update-status="handleUpdateStatus"></IssueActions>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import draggable from 'vuedraggable';
import Repository from "@repositories/RepositoryFactory";
import ApCheckIssueModal from "@/components/ApCheck/Issues/ApCheckIssueModal.vue";
import { useIssuesStore } from "../store/apcheck/issues";
import ApCheckFilter from "@/components/ApCheck/ApCheckFilter.vue";
import CommentWithAttachments from "@/components/ApCheck/Issues/CommentWithAttachments.vue";
import IssueActions from "@/components/ApCheck/Issues/IssueActions.vue";
import AddComment from "@/components/ApCheck/Issues/AddComment.vue";
import { renderApStatusTitle } from "@/components/ApCheck/utils/ap-check";

const ApCheckInvoiceIssuesRepository = Repository.get("ApCheckInvoiceIssues");

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});

const apCheckIssuesStore = useIssuesStore();
const drag = ref(false);
const loading = ref(true);
const issueModalVisible = ref(false);

const issuesList = ref([]);
const issuesListUnfiltered = ref([]);

onMounted(() => {
  fetchIssues()
});

const fetchIssues = (filters = null) => {
  loading.value = true;
  ApCheckInvoiceIssuesRepository.getIssues(filters).then((res) => {
    apCheckIssuesStore.setIssues(res?.data?.data || []);
    apCheckIssuesStore.updateList();
    issuesList.value = res?.data?.data || [];
    issuesListUnfiltered.value = res?.data?.data || [];
  }).finally(() => {
    loading.value = false;
  });
}

const draggableChange = (column) => {
  let obj = {
    transition: column.status,
    requests_order: column.items.reduce((acc, cValue, index) => {
      acc[cValue.invoice_issue_id] = index;
      return acc;
    }, {}),
  };
  if (column.items.length === 0) return;
  ApCheckInvoiceIssuesRepository.transition(obj);
};

const handleUpdateStatus = (status) => {
  updatingStatus.value = true;
  apCheckIssuesStore.updateIssue({
      invoice_issue_id: apCheckIssuesStore.currentIssue.invoice_issue_id,
      status: status
  });
  apCheckIssuesStore.updateList();
  ApCheckInvoiceIssuesRepository.updateIssue({
      issueId: apCheckIssuesStore.currentIssue.invoice_issue_id,
      fields: {
          status: status
      }
  }).finally(() => {
      updatingStatus.value = false;
  });
};

const applyFilters = (filtersData) => {
  fetchIssues(filtersData);
};

const resetFilters = () => {
  fetchIssues();
};

const formattedComments = computed(() => {
  if (apCheckIssuesStore.currentIssue === null) return [];
  return Object.entries(apCheckIssuesStore.currentIssue.notes || {}).map(([key, value]) => {
      const [username, time] = key.split('@');
      return { username, time, comment: value };
  });
});

const updatingStatus = ref(false);
</script>

<style scoped>
.p-datatable {
  width: 100%;
}

.viewDetailButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.manually-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.no-close-button .p-dialog-header .p-dialog-header-icons) {
  display: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.no-data-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.p-datatable {
  width: 100% !important;
}

:deep(.p-datatable-loading-overlay) {
  background-color: rgb(255 255 255 / 79%);
}

:deep(.p-datatable-wrapper) {
  height: calc(100vh - 255px) !important;
}

:deep(.p-datatable tr .p-datatable-wrapper) {
  height: auto !important;
}

:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage)) {
  cursor: pointer;
}

:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage):hover) {
  background-color: #f2f2f2;
}

:deep(.p-paginator-first) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-last) {
  display: none;
  visibility: hidden;
}

:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}

:deep(.p-datatable-thead) {
  height: 6rem;
}

.kanban {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 100%;
  gap: 10px;
}

.kanban .kanban-column {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.kanban .kanban-column .kanban-header {
  padding: 0.9rem 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.kanban .kanban-column .kanban-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kanban .kanban-body {
  padding: 5px 0;
  height: calc(100vh - 265px);
}

:deep(.p-card-content) {
  padding: 0 !important;
}

:deep(.p-card .p-card-body) {
  padding: 0.9rem !important;
}

.kanban-draggable {
  padding: 0;
  min-height: 90%;
}

.p-datatable {
  width: 100%;
}

:deep(.p-datatable-loading-overlay) {
  background-color: rgb(255 255 255 / 79%);
}

:deep(.p-datatable-wrapper) {
  height: calc(100vh - 320px) !important;
}

:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage)) {
  cursor: pointer;
}

:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage):hover) {
  background-color: #f2f2f2;
}
</style>
