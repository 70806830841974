<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200px" height="200px"
        viewBox="0 0 512 512" version="1.1" xml:space="preserve">

        <g id="file__x2C__xlsx__x2C__xlx__x2C__excel___x2C_">

            <g id="Layer_18">

                <g>

                    <g>

                        <polygon
                            points="107.074,25.467 349.079,25.467 420.601,97.842       420.601,447.875 107.074,447.875     "
                            style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" />

                        <path
                            d="M420.601,454.736H107.074c-3.79,0-6.86-3.07-6.86-6.861V25.467c0-3.79,3.07-6.86,6.86-6.86      h242.005c1.831,0,3.593,0.734,4.877,2.037l71.526,72.375c1.267,1.285,1.982,3.015,1.982,4.822v350.033      C427.465,451.666,424.391,454.736,420.601,454.736z M113.934,441.016h299.812V100.66l-67.534-68.333H113.934V441.016z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <rect height="105.6" style="fill-rule:evenodd;clip-rule:evenodd;fill:#4C8CF9;" width="313.529"
                            x="129.982" y="177.615" />

                    </g>

                    <g>

                        <path
                            d="M194.181,258.465h-11.189v-9.957h11.189V258.465z M224.947,222.28l10.389-19.915h12.921      l-16.383,27.853l17.315,28.247h-13.452l-10.657-20.18l-10.655,20.18h-13.052l16.916-28.247l-16.517-27.853h12.919      L224.947,222.28z M266.104,249.719h24.641v8.746h-35.696v-56.1h11.056V249.719z M327.104,243.8c0-2.147-0.799-3.9-2.267-5.245      c-1.464-1.211-4.13-2.418-7.994-3.63c-6.524-1.886-11.581-4.175-14.912-6.864c-3.332-2.556-5.066-6.186-5.066-10.76      c0-4.712,2.001-8.342,5.864-11.301c3.864-2.96,8.792-4.304,14.784-4.304c6.126,0,11.055,1.615,14.918,4.845      c3.859,3.226,5.727,7.259,5.594,12.105l-0.134,0.266h-10.787c0-2.551-0.799-4.708-2.533-6.323      c-1.729-1.478-4.13-2.285-7.194-2.285c-3.061,0-5.323,0.675-6.92,2.019c-1.602,1.211-2.4,2.959-2.4,4.979      c0,1.886,0.799,3.497,2.529,4.708c1.734,1.211,4.799,2.418,9.058,3.767c6.13,1.749,10.654,4.038,13.853,6.86      c3.198,2.822,4.795,6.457,4.795,11.031c0,4.841-1.863,8.613-5.727,11.436c-3.73,2.693-8.658,4.033-14.784,4.033      c-5.993,0-11.324-1.479-15.849-4.708c-4.396-3.092-6.663-7.534-6.525-13.178l0.129-0.138h10.792c0,3.23,1.064,5.653,3.061,7.264      c1.996,1.615,4.795,2.29,8.393,2.29c3.065,0,5.327-0.542,6.924-1.886C326.306,247.572,327.104,245.957,327.104,243.8      L327.104,243.8z M366.125,222.28l10.389-19.915h13.055l-16.515,27.853l17.446,28.247h-13.454l-10.654-20.18l-10.783,20.18      h-12.922l16.78-28.247l-16.381-27.853h12.788L366.125,222.28z"
                            style="fill:#FEFEFE;" />

                    </g>

                    <g>

                        <path
                            d="M263.424,146.413c-0.027,0-0.06,0-0.092,0c-13.951-0.023-26.076-5.135-32.432-13.669      c-2.264-3.038-1.636-7.337,1.404-9.599c3.038-2.263,7.335-1.634,9.599,1.404c3.728,5.006,11.949,8.126,21.452,8.14      c0.023,0,0.046,0,0.068,0c9.719,0,18.703-3.271,22.36-8.158c2.276-3.033,6.575-3.644,9.604-1.372      c3.032,2.271,3.647,6.571,1.376,9.604C290.451,141.186,277.681,146.413,263.424,146.413z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                            d="M143.971,233.173c-0.023,0-0.046,0-0.069,0c-41.671-0.404-66.396-11.247-73.49-32.226      c-9.243-27.338,17.015-62.735,32.446-74.752c2.99-2.327,7.303-1.794,9.629,1.193c2.328,2.992,1.792,7.3-1.195,9.631      c-13.264,10.329-34.524,39.879-27.88,59.532c6.254,18.501,36.419,22.668,60.624,22.902c3.788,0.037,6.83,3.138,6.793,6.924      C150.791,230.144,147.729,233.173,143.971,233.173z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <polygon points="349.079,97.842 420.601,97.842 349.079,25.467           "
                            style="fill-rule:evenodd;clip-rule:evenodd;fill:#D4E4FF;" />

                        <path
                            d="M420.601,104.703h-71.521c-3.79,0-6.86-3.07-6.86-6.86V25.467c0-2.785,1.68-5.291,4.254-6.346      c2.574-1.056,5.529-0.455,7.483,1.523l71.526,72.375c1.946,1.968,2.515,4.91,1.45,7.465      C425.868,103.042,423.372,104.703,420.601,104.703z M355.938,90.983h48.239l-48.239-48.813V90.983z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                            d="M239.341,493.393c-0.376,0-0.757-0.027-1.143-0.092l-22.509-3.766      c-3.306-0.557-5.727-3.414-5.727-6.77v-34.891c0-3.789,3.07-6.859,6.86-6.859c3.791,0,6.86,3.07,6.86,6.859v29.082l16.783,2.809      c3.737,0.629,6.259,4.162,5.632,7.9C245.538,491.021,242.631,493.393,239.341,493.393z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                            d="M325.25,493.393c-0.376,0-0.762-0.027-1.146-0.092l-22.507-3.766      c-3.309-0.557-5.727-3.414-5.727-6.77v-34.891c0-3.789,3.069-6.859,6.859-6.859s6.86,3.07,6.86,6.859v29.082l16.78,2.809      c3.739,0.629,6.259,4.162,5.635,7.9C331.445,491.021,328.536,493.393,325.25,493.393z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                            d="M290.873,65.154c6.259-1.753,13.188,0.94,16.519,6.86      c4.13,7.13,1.73,16.143-5.332,20.313c-7.057,4.033-15.978,1.61-19.979-5.516c-3.193-5.515-2.396-12.38,1.469-16.95      c1.331,1.748,3.726,2.418,5.727,1.211C291.276,69.862,292.075,67.306,290.873,65.154L290.873,65.154z"
                            style="fill-rule:evenodd;clip-rule:evenodd;fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                            d="M228.811,65.154c6.259-1.753,13.185,0.94,16.649,6.86      c3.994,7.13,1.597,16.143-5.462,20.313c-6.924,4.033-15.982,1.61-19.979-5.516c-3.196-5.515-2.397-12.38,1.466-16.95      c1.331,1.748,3.861,2.418,5.727,1.211C229.343,69.862,230.008,67.306,228.811,65.154L228.811,65.154z"
                            style="fill-rule:evenodd;clip-rule:evenodd;fill:#4C8CF9;" />

                    </g>

                    <g>

                        <polygon
                            points="209.098,359.762 209.098,371.738 390.638,371.738       390.638,359.762 390.638,339.719 209.098,339.719     "
                            style="fill-rule:evenodd;clip-rule:evenodd;fill:#D4E4FF;" />

                    </g>

                    <g>

                        <path
                            d="M390.638,346.578H137.042c-3.791,0-6.86-3.068-6.86-6.859v-32.02c0-3.789,3.07-6.859,6.86-6.859      h253.596c3.79,0,6.854,3.07,6.854,6.859v32.02C397.492,343.51,394.428,346.578,390.638,346.578z M143.901,332.859h239.871      v-18.299H143.901V332.859z"
                            style="fill:#4C8CF9;" />

                    </g>

                    <g>

                        <path
                        d="M390.638,378.598H137.042c-3.791,0-6.86-3.07-6.86-6.859v-32.02c0-3.789,3.07-6.859,6.86-6.859      h253.596c3.79,0,6.854,3.07,6.854,6.859v32.02C397.492,375.527,394.428,378.598,390.638,378.598z M143.901,364.879h239.871      v-18.301H143.901V364.879z"
                        style="fill:#4C8CF9;" />

                </g>

                <g>

                    <path
                        d="M390.638,410.611H137.042c-3.791,0-6.86-3.068-6.86-6.859v-32.014c0-3.791,3.07-6.859,6.86-6.859      h253.596c3.79,0,6.854,3.068,6.854,6.859v32.014C397.492,407.543,394.428,410.611,390.638,410.611z M143.901,396.893h239.871      v-18.295H143.901V396.893z"
                        style="fill:#4C8CF9;" />

                </g>

                <g>

                    <path
                        d="M318.579,410.611H209.098c-3.79,0-6.86-3.068-6.86-6.859v-96.053c0-3.789,3.07-6.859,6.86-6.859      h109.481c3.79,0,6.859,3.07,6.859,6.859v96.053C325.438,407.543,322.369,410.611,318.579,410.611z M215.958,396.893h95.761      v-82.332h-95.761V396.893z"
                        style="fill:#4C8CF9;" />

                </g>

            </g>

        </g>

    </g>

    <g id="Layer_1" />

</svg></template>
