import ResultsRepository from "@repositories/ResultsRepository";
import { useApi } from "../../hooks/useApi";

export const useFormatCurrency = () => {
  const formatCurrency = (value) => {
    return (value || 0).toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
    });
  };

  return { formatCurrency };
};

export const useOpenInNewTab = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return { openInNewTab };
};

export const useGetDownloadLinks = (
  loading,
  organization,
  jobStore,
  detailPageTitle,
  detailXmlLink,
  detailPdfLink
) => {
  const getDownloadLinks = async (title, invoice) => {
    try {
      loading.value = invoice;
      const response = await ResultsRepository.downloadFiles({
        invoiceId: invoice,
      });
      jobStore.dialogVisible = true;
      detailPageTitle.value = title;
      detailXmlLink.value = response.data["xml_download_url"];
      detailPdfLink.value = response.data["pdf_download_url"];

      loading.value = "";
      // Do something with the response data
    } catch (error) {
      loading.value = "";
      throw new Error("getDownloadLinks", error);
    }
  };

  return { getDownloadLinks };
};

export const stockClass = (item) => {
  const data = parseInt(item);
  return [
    "border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm",
    {
      "bg-red-100 text-red-900": data === 0,
      "bg-blue-100 text-blue-900": data > 0 && data < 10,
      "bg-teal-100 text-teal-900": data >= 10,
    },
  ];
};

export const limitText = (text, limit) => {
  if (text.length <= limit) {
    return text;
  } else {
    const filename = text.substr(text.length - 4); // Extract the last 4 characters as the filename (.pdf)
    const shortenedText = text.substr(0, limit - 5); // Leave space for "... [filename]"
    return shortenedText + "... " + filename;
  }
};

export const formatSize = (bytes) => {
  if (bytes === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const infoToggle = (payload, data, isActive) => {
  payload.isActive = isActive;
  payload.message = data.message;
  payload.heading = data.head;
  payload.list = data.list;
  payload.footer = data.footer;
};

export const getTransactionsList = async (
  jobsStore,
  userName,
  email,
  organization,
  startOfWeek,
  endOfWeek,
  isError
) => {
  try {
    jobsStore.jobListLoading = true;
    const params = {
      user: userName.value,
      email: email.value,
      organization: organization.value,
      start_date: startOfWeek,
      end_date: endOfWeek,
    };

    const {
      data: trxData,
      error: errorRef,
      fetchItems: getTrxList,
    } = useApi("/results/user_transactions", params);

    await getTrxList();
    isError.value = errorRef.value;
    jobsStore.jobsTransactionList = trxData.value?.jobs;
    jobsStore.transactionsList = trxData.value?.jobs;
  } catch (error) {
    console.log(error);
  } finally {
    jobsStore.jobListLoading = false;
  }
};
