import moment from "moment";

export const datePresets = [
  {
    name: "Today",
    code: "today",
    getvalue: function () {
      return [moment().startOf("day").toDate(), moment().endOf("day").toDate()];
    },
  },
  {
    name: "Yesterday",
    code: "yesterday",
    getvalue: function () {
      return [
        moment().subtract(1, "day").startOf("day").toDate(),
        moment().subtract(1, "day").endOf("day").toDate(),
      ];
    },
  },
  {
    name: "This week",
    code: "this_week",
    getvalue: function () {
      return [
        moment().startOf("week").toDate(),
        moment().endOf("week").toDate(),
      ];
    },
  },
  {
    name: "Last week",
    code: "last_week",
    getvalue: function () {
      return [
        moment().subtract(1, "week").startOf("week").toDate(),
        moment().subtract(1, "week").endOf("week").toDate(),
      ];
    },
  },
  {
    name: "This month",
    code: "this_month",
    getvalue: function () {
      return [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ];
    },
  },
  {
    name: "Last month",
    code: "last_month",
    getvalue: function () {
      return [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    },
  },
  {
    name: "This year",
    code: "this_year",
    getvalue: function () {
      return [
        moment().startOf("year").toDate(),
        moment().endOf("year").toDate(),
      ];
    },
  },
];
