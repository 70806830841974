<template>
  <div class="flex align-items-center">
    <h2>Users</h2>
    <div class="ml-auto">
      <Button severity="info" size="small" @click="newUserDialog = true">Invite a User</Button>
    </div>
  </div>
  <DataTable :value="users" tableStyle="min-width: 50rem" :loading="fetchingUsers">
    <template #empty>
      <div class="flex justify-content-center align-items-center gap-2">
        <i class="pi pi-users text-3xl text-500"></i>
        <span class="text-xl font-medium text-900 mr-2">No users found</span>
      </div>
    </template>
    <Column field="username" header="Name"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="role" header="Role">
      <template #body="slotProps">
        <span v-if="slotProps.data.roles && slotProps.data.roles.length > 0">
          {{ slotProps.data.roles[0].name }}
        </span>
        <span v-else>Member</span>
      </template>
    </Column>
    <Column field="status" header="Status">
      <template #body="slotProps">
        <div class="flex gap-2 align-items-center">
          <div :style="{
            height: '10px',
            width: '10px',
            background: slotProps.data.status ? 'green' : 'red',
            borderRadius: '50%',
          }"></div>
          <label>{{ slotProps.data.status ? "Active" : "Inactive" }}</label>
        </div>
      </template>
    </Column>
    <Column field="action" header="Action">
      <template #body="slotProps">
        <div class="flex gap-2">
          <user-status :user="slotProps.data"></user-status>
          <delete-user :user="slotProps.data" @userDeleted="fetchUsers"></delete-user>
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog v-model:visible="newUserDialog" @hide="
    newUserFormData = {};
  isEditingUser = false;
  " modal header="New user">
    <form @submit.prevent="() => {
      if (isEditingUser) {
        handleUpdateUser();
      } else {
        handleAddUser();
      }
    }">
      <div style="width: 400px">
        <div class="w-5/2">
          <h4 class="mb-1">Name</h4>
          <InputText class="w-full" v-model="newUserFormData['username']" placeholder="name" required></InputText>
        </div>
        <div class="w-5/2">
          <h4 class="mb-1">Email</h4>
          <InputText class="w-full" v-model="newUserFormData['email']" type="email" required placeholder="email">
          </InputText>
        </div>
        <!-- add field for role number -->
        <div class="w-5/2">
          <h4 class="mb-1">Role</h4>
          <Dropdown class="w-full" v-model="newUserFormData['role']" :options="props.roles" optionLabel="name"
            optionValue="name" required placeholder="Role"></Dropdown>
        </div>
        <div class="w-5/2">
          <h4 class="mb-1">Phone Number</h4>
          <InputText class="w-full" v-model="newUserFormData['phone_number']" type="text" required
            placeholder="Phone Number"></InputText>
        </div>
        <div class="flex">
          <Button type="submit" :loading="updatingUser || addingUser" class="mt-4 ml-auto" size="small"
            label="Save Changes"></Button>
        </div>
      </div>
    </form>
  </Dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import Repository from "@repositories/RepositoryFactory";
import UserStatus from "./UserStatus.vue";
import DeleteUser from "./DeleteUser.vue";
const props = defineProps({
  organizationId: {
    type: Number,
    required: true,
  },
  roles: {
    type: Array,
    required: true,
  }
});

const EmisorConfigurationRepository = Repository.get("emisorConfiguration");
// const resetPassword = ref(false);

const userSession = useUserSessionStore();
const { organization } = storeToRefs(userSession);
const toast = useToast();

const newUserDialog = ref(false);
const newUserFormData = ref({});

const isEditingUser = ref(false);
// const newPassword = ref("");
// const confirmPassword = ref("");

const users = ref([]);
const fetchingUsers = ref(false);

const fetchUsers = async () => {
  fetchingUsers.value = true;
  await EmisorConfigurationRepository.getOrganizationUsers({
    org_name: organization.value,
    org_tax_id: props.organizationId,
  })
    .then((res) => {
      users.value = res.data.data;
    }).finally(() => {
      fetchingUsers.value = false;
    });
};

const updatingUser = ref(false);
const handleUpdateUser = async () => {
  updatingUser.value = true;
  await EmisorConfigurationRepository.updateOrganizationUser({
    ...newUserFormData.value,
    organization: props.organizationId,
  })
    .then(() => {
      fetchUsers();
    })
    .finally(() => {
      updatingUser.value = false;
      newUserDialog.value = false;
      newUserFormData.value = {};
      isEditingUser.value = false;
    });
};

onMounted(() => {
  if (organization.value) {
    fetchUsers();
  }
});

const addingUser = ref(false);
const handleAddUser = async () => {
  addingUser.value = true;
  await EmisorConfigurationRepository.createOrganizationUser({
    ...newUserFormData.value,
    })
    .then(() => {
      newUserDialog.value = false;
      fetchUsers();
      newUserFormData.value = {};
    })
    .catch((err) => {
      toast.add({
        severity: "info",
        summary: err,
        life: 10000,
      });
    }).finally(() => {
      addingUser.value = false;
    });
};

const isActivatedUserId = ref("");
const isActivatingUser = ref(false);

const activateUser = async (userId) => {
  isActivatingUser.value = true;
  isActivatedUserId.value = userId;
  await EmisorConfigurationRepository.activateOrganizationUser({ id: userId })
    .then((res) => {
      toast.add({
        severity: "success",
        summary: res?.data.Data,
        life: 10000,
      });
      fetchUsers();
    })
    .catch((err) => {
      toast.add({
        severity: "error",
        summary: err,
        life: 10000,
      });
    })
    .finally(() => {
      isActivatingUser.value = false;
      isActivatedUserId.value = null;
    });
};
</script>