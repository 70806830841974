<template>
    <div class="card flex justify-content-center relative">
        <Chart type="doughnut" ref="chartCanvass" :data="chartData" :options="chartOptions" class="" />
        <span class="min_value"><b>0</b></span>
        <span class="max_value"><b>{{ props.details }}</b></span>
        <div class="user_value">
            <h3>{{ userTotal }}</h3>
            <h4>User Total</h4>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const chartCanvass = ref(null);

onMounted(() => {
    chartData.value = setChartData();
});

const props = defineProps({
    chartDto: {
        required: true
    },
    details: {
        required: true
    },
    userTotal: {
        required: true
    },
});

const chartData = ref();
const chartOptions = ref({
    cutout: '60%',
    rotation: -90,
    circumference: 180,
    plugins: {
        tooltip: {
            enabled: false
        }
    }
});

const chartDetails = computed(() => {
    return [parseInt(props.chartDto), 100 - parseInt(props.chartDto)]
})


const setChartData = () => {
    const documentStyle = getComputedStyle(document.body);

    return {
        labels: ['User Total Process', 'Organization Total Process'],
        datasets: [
            {
                data: chartDetails,
                backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--gray-500'), documentStyle.getPropertyValue('--green-500')],
            }
        ]
    };
};
</script>
<style scoped>
.min_value {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.max_value {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.user_value {
    position: absolute;
    bottom: 0rem;
    right: 7rem;
}
</style>
