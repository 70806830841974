<template>
    <div>
        <Button icon="pi pi-trash" text raised rounded severity="danger" aria-label="Filter" class="ml-1 px-3"
            :disabled="props.invoice.ids.length === 0" @click="showDeleteDialog = true" />
        <Dialog v-model:visible="showDeleteDialog" header="Delete Confirmation" :closable="false"
            style="max-width:40vw;min-width: 30vw; max-height: 40vh;" modal @hide="handleCancelDelete">
            <template #header>
                <div>
                    <h2>Confirm Delete</h2>
                    <p class="text-xl">This action will delete the following invoices!</p>
                </div>
            </template>
            <ul class="list-container flex gap-2 flex-wrap">
                <li v-for="(item, index) in props.invoice.ids" class="my-2 shadow-2 border-round" :key="index"><i
                        class="pi pi-file-pdf mx-2 mt-1"></i>{{ item }}</li>
            </ul>
            <template #footer>
                <div class="flex justify-content-end gap-3 pt-5">
                    <Button label="Cancel" outlined class="p-button-secondary" @click="handleCancelDelete" />
                    <Button label="Delete" :loading="loading" class="p-button-danger" @click="handleConfirmDelete" />
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useApi } from '../../../hooks/useApi';

const props = defineProps({
    invoice: {
        type: Object,
        required: true,
    },
    invoicesData : {
        type: Object,
        required: true,
    }
});

const emits = defineEmits(['updateInvoices']);
const loading = ref(false);
const toast = useToast();
const showDeleteDialog = ref(false);

const handleCancelDelete = () => {
    showDeleteDialog.value = false;
};


const handleConfirmDelete = async () => {
    try {
        loading.value = true;
        const selectedInvoices = props.invoicesData?.filter((item) => props?.invoice?.ids.includes(item._id));
        const selectedS3Paths = selectedInvoices?.map((item) => item.s3_path + "/" + item._id);
        const params = {
            ids: props.invoice.ids,
            s3_paths: selectedS3Paths,
        };
        const { deleteItem: deleteInvoice } = useApi('/invoice');
        await deleteInvoice(params)
        toast.add({ severity: 'success', summary: 'Success', detail: 'Invoices deleted successfully',life: 3000 });
        emits('updateInvoices');
        showDeleteDialog.value = false;
    } catch (error) {
        throw new Error(error);
    } finally {
        loading.value = false;
    }
};
</script>
<style scoped>
.list-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list-container li {
    padding: 10px;
    border-radius: 5px;
}
</style>