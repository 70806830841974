<template>
  <div>
    <!-- <div class="surface-section px-4 py-4">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
        <div class="flex align-items-center">
          <i class="pi pi-check-circle text-2xl mr-3 text-500"></i>
          <span class="text-3xl font-medium text-900">Organization Report</span>
        </div>
        <Button icon="pi pi-filter " label="Show Filters" @click="visible = true" />
      </div>
    </div> -->

    <div class="flex">
      <div style="width: 33rem; max-height: 65rem; overflow: hidden;" class="px-3 py-3 border-round shadow-2 m-2">
        <FilterSidebar />
      </div>
      <div v-if="!resultsStores.isLoading && !userActivityStore.tableResponse?.length > 0" class="w-full">
        <div class="mx-5 mt-5">
          <Message v-if="!resultsStores.isLoading && !userActivityStore.initiallyLoading && userActivityStore.isError"
            severity="info">No Invoices Found
            within
            selected date range</Message>
        </div>
        <div class="flex justify-content-center align-items-center h-30rem" style="margin-top: 10rem;"
          v-if="!resultsStores.isLoading && !userActivityStore.tableResponse?.length > 0">
          <InfoModal />
        </div>
      </div>
      <div class="container gap-4 mb-5 w-full flex justify-content-center align-items-center h-30rem"
        v-if="resultsStores.isLoading">
        <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)"
          animationDuration=".5s" aria-label="Loading" />
      </div>
      <div class="flex flex-column p-3 container w-full gap-4"
        v-if="userActivityStore.tableResponse?.length > 0 && !resultsStores.isLoading">
        <div class="flex gap-4 w-full align-items-center justify-content-center ">
          <DataTable :value="userActivityStore.referencesActivityList" v-model:expandedRows="expandedRows"
            v-model:filters="filters" :dataKey="userActivityStore.emisorColumn ? 'emisor_rfc' : 'reference'" stripedRows
            removableSort :paginator="true" :rows="10" :paginatorTemplate="{
              '640px': 'PrevPageLink CurrentPageReport NextPageLink',
              '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown',
              '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
              default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown'
            }" :rowsPerPageOptions="[10, 20, 50, 100]" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :globalFilterFields="['reference', 'SubTotal', 'Impuestos', 'Total', 'num_invoices', 'emisor_rfc']">
            <template #header>
              <div class="flex card-container blue-container overflow-hidden w-full">
                <div class="flex-none flex align-items-center justify-content-center font-bold">
                  <h3 class="m-0">User Overview</h3>
                </div>
                <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                <div class="flex-none flex align-items-center justify-content-center">
                  <span v-show="userActivityStore.referencesActivityList?.length > 0 && !resultsStores.isLoading">
                    <DownloadReportModal @downloadExport="downloadReport"
                      v-if="exportReportRes.status === 1 && !exportingReport" :isVisible="exportModalVisible" />
                    <Button :label="exportingReport ? 'Exporting Zip' : 'Export Zip'" icon="pi pi-download"
                      @click="exportReport(); exportModalVisible = true" :loading="exportingReport" />
                  </span>
                  <span class="p-input-icon-left" style="margin-left: 1rem; ">
                    <div class="flex ">
                      <InputGroup>
                        <InputText placeholder="Keyword Search" style="width: 1rem;" v-model="keywordSearch"
                          @input="handleKeywordSearch" />
                        <Button icon="pi pi-search" />
                      </InputGroup>
                      <Button icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2"
                        style="margin-left: 1rem; width: 20rem;" />
                      <Button icon="pi pi-minus" label="Collapse All" @click="collapseAll" style="width: 20rem;" />
                    </div>
                  </span>
                </div>
              </div>
            </template>
            <template #empty>
              No invoices found.
            </template>
            <template #loading>
              Loading invoice data. Please wait.
            </template>
            <Column expander headerStyle="width: 3rem" />
            <Column :field="userActivityStore.emisorColumn ? 'emisor_rfc' : 'reference'"
              :header="userActivityStore.emisorColumn ? 'Emisor RFC' : 'Reference'" :sortable="true">
            </Column>
            <Column field="SubTotal" header="SubTotal" :sortable="true">
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data['SubTotal']) }}
              </template>
            </Column>
            <Column field="Impuestos" header="Taxes" :sortable="true">
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data['Impuestos']) }}
              </template>
            </Column>
            <Column field="Total" header="Total" :sortable="true">
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data['Total']) }}
              </template>
            </Column>
            <Column field="num_invoices" header="# Inv" :sortable="true">
              <template #body="slotProps">
                <div :class="stockClass(slotProps.data)">
                  {{ slotProps.data['num_invoices'] }}
                </div>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="details-subtable ml-7">
                <h4>Details for {{ userActivityStore.emisorColumn ? slotProps.data.emisor_rfc : slotProps.data.reference
                  }}</h4>
                <DataTable :value="slotProps.data.details" responsiveLayout="scroll">
                  <Column field="_id" header="ID" sortable></Column>
                  <Column v-if="!userActivityStore.emisorColumn" field="emisor_rfc" header="Em RFC" sortable></Column>
                  <Column v-else field="reference" header="Reference" :sortable="true">
                  </Column>
                  <Column field="emisor_nombre" header="Em Name" sortable></Column>
                  <Column field="receptor_rfc" header="Rec RFC" sortable></Column>
                  <Column field="receptor_nombre" header="Rec Name"></Column>
                  <Column field="tag" header="Tag">
                    <template #body="{ data }">
                      <TagsChipComponent :tagItem="data" />
                    </template>
                  </Column>
                  <Column field="moneda" header="Currency" sortable></Column>
                  <Column field="SubTotal" header="SubTotal" sortable>
                    <template #body="slotProps">
                      {{ formatCurrency(slotProps.data['SubTotal']) }}
                    </template>
                  </Column>
                  <Column field="Impuestos" header="Taxes" sortable>
                    <template #body="slotProps">
                      {{ formatCurrency(slotProps.data['Impuestos']) }}
                    </template>
                  </Column>
                  <Column field="Total" header="Total" sortable>
                    <template #body="slotProps">
                      {{ formatCurrency(slotProps.data['Total']) }}
                    </template>
                  </Column>
                  <Column header="Preview PDF" headerStyle="width: 4rem; text-align: center"
                    bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                      <Button type="button" icon="pi pi-eye"
                        :loading="pdfLoading === `${slotProps.data.s3_path}/${slotProps.data._id}`"
                        @click="handleViewButton(slotProps.data)" />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
            <template #paginatorstart>
              <Button type="button" icon="pi pi-refresh" class="p-button-text" />
            </template>
            <template #paginatorend>
              <Button type="button" icon="pi pi-cloud" class="p-button-text" />
            </template>
          </DataTable>
        </div>

        <Dialog header="Pdf Preview" :modal="true" v-model:visible="dialogVisible"
          :style="{ width: '45vw', height: '100vh' }" maximizable @unmaximize="dialogHeight = '60vh'"
          @maximize="dialogHeight = '100vh'" :contentStyle="{ height: '100vh' }" class="p-fluid">
          <div class="flex justify-content-between">
            <h4 class="text-blue-500 font-bold">
              {{ detailPageTitle }}
            </h4>
            <Button class="w-4 py-1 w-20rem" style="height: 3rem;" label="Re-assign" icon="pi pi-plus-circle"
              @click="filterModel = !filterModel" />
            <div v-if="filterModel" class="surface-200 shadow-4 w-20rem h-15rem absolute px-4"
              style="right: 2.3rem; top: 9rem; border-radius: 5px;">
              <h4 style="text-align: start;">Select a Reference</h4>
              <hr class="-mt-3">
              <Dropdown v-model="selectedSubcategory" :virtualScrollerOptions="{ itemSize: 30 }" :loading="isUserTyping"
                :options="dropdownData" editable optionLabel="name" placeholder="Select a Reference"
                class="w-full mr-1 mt-2 shadow-1" @change="onReferenceChange" />
              <div class="flex">
                <Button label="Close" @click="resetReferences" icon="pi pi-times-circle" :disabled="false"
                  severity="danger" class="mt-4 mr-2 w-full" />
                <Button label="Assign" @click="toggleConfirmModal" icon="pi pi-send" :disabled="false"
                  class="mt-4 w-full" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap border-top-1 surface-border pt-4" :style="{ height: dialogHeight }">
            <div v-if="detailPdfLink === null" class="w-full">
              <div class="bg-white w-6 mx-auto border-round py-5">
                <img src="../assets/images.png" alt="">
                <h2>Oops, something went wrong.!</h2>
                <h4> We are unable to find this file. Please report this with the details below:</h4>
                <p><b>Job ID : </b>{{ viewedInvoice.job_id }}</p>
                <p><b>Invoices ID : </b>{{ viewedInvoice._id }}</p>
                <p><b>PDF path : </b>{{ viewedInvoice.s3_path }}</p>
              </div>
            </div>
            <div v-else
              class="bg-blue-50 flex align-items-center justify-content-center py-3 px-0 md:w-full border-round">
              <embed :src="detailPdfLink" type="application/pdf" class="mt-4" width="100%" height="100%"
                style="height: 70vh;" />
            </div>
          </div>
          <template #footer>
            <div class="border-top-1 surface-border pt-3">
              <Button icon="pi pi-check" @click="dialogVisible = false" label="Close"></Button>
            </div>
          </template>
        </Dialog>
      </div>
    </div>
    <Dialog v-model:visible="exportModalVisible" modal
      :header="exportingReport ? 'Exporting report' : 'Report exported'" style="width: 450px">
      <div class="text-center" v-if="exportingReport">
        <p>
          Exporting report. Please do not navigate away from this page or close this browser window.
        </p>
        <div class="flex align-items-center">
          <ProgressSpinner style="width: 50px;height: 50px" />
        </div>
      </div>
      <div v-if="exportReportRes.status === exportStatus.READY && exportingReport === false">
        <p>
          The report has been exported. You can download it by clicking the button below.
        </p>
      </div>
      <div v-if="exportReportRes.status === exportStatus.PENDING && exportingReport === false">
        <p>
          The report is being exported. Please wait.
        </p>
      </div>
      <template #footer>
        <div class="flex justify-content-end">
          <Button @click="exportModalVisible = false" link label="Close"></Button>
          <Button @click="downloadReport" v-if="exportReportRes.status === 1 && !exportingReport" label="Download"
            icon="pi pi-download" />
        </div>
      </template>
    </Dialog>
    <Dialog v-model:visible="confirmModal" :modal="true" header="Confirm" :style="{ width: '40vw' }">
      <div class="w-full">
        <p class="mb-5 px-2">
          Are you sure to assign reference <b> {{ referencePayload.reference }} </b> to invoice
          <b>{{ referencePayload.ids.toString().replace(/,/g, ", ") }}</b>.
        </p>
        <div class="flex justify-content-end">
          <Button @click="confirmModal = false" icon="pi pi-times" label="Cancel"></Button>
          <Button @click="handleAssignClick" :loading="assignButtonLoading" icon="pi pi-check" class="ml-2"
            label="Confirm"></Button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, reactive, onMounted } from "vue";
import Repository from "@repositories/RepositoryFactory";
import { useFormatCurrency, useOpenInNewTab, useGetDownloadLinks } from "@composables/useUtils";
import { useUserSessionStore } from '@/store/userSession';
import { storeToRefs } from 'pinia';
import { serviceStore } from "@/store/serviceStore";
import { assignReference, createFiltersObject, getSearchResults, handleDropDownChange, toggleModal, toggleViewModel } from '../utils/helpers';
import { useToast } from "primevue/usetoast";
import { useApi } from "../../hooks/useApi";
import InfoModal from '../components/InfoModal.vue';
import DownloadReportModal from '.././components/DownloadReportModal.vue'
import TagsChipComponent from "../components/Jobs/TagsChipComponent.vue";
import FilterSidebar from "@/components/UserActivity/FilterSidebar.vue";
import { useUserActivityStore } from "../store/userActivity/store";

const toast = useToast();

const TypeRoute = {
  ALL: 'All',
  UNKNOWN: 'Unknown',
  MULTIPLE: 'MultipleMatches',
  MATCHES: 'Matches',
  SINGLE: 'Single',
  CLOSED: 'Closed'
};

const userActivityStore = useUserActivityStore()
const userActivityResponse = userActivityStore.userActivityResponse

const userSession = useUserSessionStore();
const resultsStores = serviceStore()
const { userName, organization } = storeToRefs(userSession);
const selectedSubcategory = ref([])
const viewedInvoice = ref()

const references = ref([
  { name: 'All', code: TypeRoute.ALL },
  { name: 'Unknown', code: TypeRoute.UNKNOWN },
  { name: 'MultipleMatches', code: TypeRoute.MULTIPLE },
  { name: 'Matches', code: TypeRoute.MATCHES },
  { name: 'Closed', code: TypeRoute.CLOSED },
]);

const emisors = ref([]);
const disableReferences = ref(true);
const disableEmisor = ref(true);
const keywordSearch = ref()
const filterModel = ref(false);
const subcategory = ref()
const selectedMatchReferences = ref()
const dialogHeight = ref("60vh")

const ResultsRepository = Repository.get("results");

const { formatCurrency } = useFormatCurrency();
const { openInNewTab } = useOpenInNewTab();
const expandedRows = ref({});
const dialogVisible = ref(false);
const detailPageTitle = ref();
const detailXmlLink = ref();
const detailPdfLink = ref();
const referenceCategories = ref([])
const confirmModal = ref(false);
const assignButtonLoading = ref(false);
const pdfLoading = ref(false);
const reportExportTimeout = ref(null)
const dropdownData = ref()
const isUserTyping = ref(false)

const referencePayload = reactive({
  reference: '',
  user: '',
  ids: [],
  s3_paths: [],
  organization: ''
})

const onReferenceChange = () => {
  if (typeof selectedSubcategory.value === 'object') {
    isUserTyping.value = false
  } else {
    handleDropDownChange(selectedSubcategory, isUserTyping, dropdownData, subcategory)
  }
  referencePayload.reference = selectedSubcategory.value.name
};

const downloadReport = () => {
  if (openInNewTab) {
    openInNewTab(exportReportRes.value.presigned_zip_url);
    exportModalVisible.value = false
  }
}


const { getDownloadLinks } =
  useGetDownloadLinks(
    pdfLoading,
    organization,
    dialogVisible,
    detailPageTitle,
    detailXmlLink,
    detailPdfLink
  );

const filterNames = ['global', 'reference', 'subtotal', 'Impuestos', 'Total', 'num_invoices', 'emisor_rfc'];
const filters = createFiltersObject(filterNames);

onBeforeMount(async () => {
  await getReferencesDropdownList();
});
onBeforeUnmount(() => {
  clearTimeout(exportReportTimeout.value)
})

const stockClass = (data) => {
  return [
    'border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm',
    {
      'bg-red-100 text-red-900': data.num_invoices === 0,
      'bg-blue-100 text-blue-900': data.num_invoices > 0 && data.num_invoices < 10,
      'bg-teal-100 text-teal-900': data.num_invoices > 10
    }
  ];
};


const resetReferences = () => {
  confirmModal.value = false
  filterModel.value = false
  dialogVisible.value = false
  selectedSubcategory.value = ''
  selectedMatchReferences.value = ''
  referencePayload.reference = ''
  filterModel.value = false
}

const handleViewButton = (data) => {
  viewedInvoice.value = data
  detailPdfLink.value = ''
  dialogVisible.value = true
  toggleViewModel(
    data,
    pdfLoading,
    referenceCategories,
    getDownloadLinks,
    referencePayload,
    userName,
    organization,
    resultsStores
  );
}

const getReferencesDropdownList = async () => {

  try {
    userActivityStore.isFiltersLoading = true;
    await resultsStores.getFilterData(organization.value, false)
    const newReferences = resultsStores.matchedReferences?.options?.map((item, index) => ({
      name: item,
      code: index
    }));

    const newEmisors = resultsStores.emirosFilterData?.options?.map((item, index) => ({
      name: item,
      code: index
    }));

    references.value = [...references.value, ...newReferences];
    emisors.value = [...newEmisors]
    disableEmisor.value = false;
    disableReferences.value = false;
    userActivityStore.isFiltersLoading = false;
  } catch (error) {
    throw new Error(error, 'Error for reference dropdown')
  } finally {
    userActivityStore.isFiltersLoading = false;
  }
};

const handleKeywordSearch = () => {
  const searchTerm = keywordSearch.value.trim().toLowerCase();

  if (searchTerm === '') {
    userActivityStore.referencesActivityList = userActivityStore.referencesListAll;
    collapseAll()
    return;
  }
  const referencesListAll = ref(null)
  userActivityStore.referencesActivityList = getSearchResults(searchTerm, referencesListAll, userActivityStore);
  expandAll()
};


const expandAll = () => {
  const expandedRowsObject = userActivityStore.referencesActivityList
    .filter(p => p[userActivityStore.emisorColumn ? "emisor_rfc" : "reference"])
    .reduce((acc, curr) => {
      acc[userActivityStore.emisorColumn ? curr.emisor_rfc : curr.reference] = true;
      return acc;
    }, {});

  expandedRows.value = expandedRowsObject;
};



const collapseAll = () => {
  expandedRows.value = null;
};


const exportStatus = {
  PENDING: 0,
  READY: 1
}
const exportingReport = ref(false);
const exportModalVisible = ref(false)
const exportReportRes = ref({
  presigned_zip_url: '',
  status: -1
})

const exportReportTimeout = ref(null)

const exportReport = async () => {
  clearTimeout(reportExportTimeout.value);
  exportingReport.value = true;
  const params = {
    organization: organization.value,
    export_zip_name: userActivityResponse.export_zip_name
  }

  const {
    data: exportReportsRes,
    error: errorRef,
    loading: loadingRef,
    fetchItems: exportReports
  } = useApi('/report/export', params);

  await exportReports();

  exportReportRes.value = exportReportsRes.value;

  if (exportReportRes.value.status === exportStatus.PENDING) {
    reportExportTimeout.value = setTimeout(() => {
      exportReport().then(() => {
        if (exportReportsRes.value?.status !== exportStatus.PENDING) {
          exportReportRes.value = {
            presigned_zip_url: '',
            status: -1
          }
          exportReportRes.value = exportReportsRes.value
          clearTimeout(reportExportTimeout.value)
          reportExportTimeout.value = null
          exportingReport.value = false
        }
      })
    }, 7000)
    userActivityStore.isError = errorRef.value;
  } else {
    clearTimeout(reportExportTimeout.value);
    reportExportTimeout.value = null;
    exportingReport.value = loadingRef.value;
    userActivityStore.isError = errorRef.value;
  }
};


const toggleConfirmModal = () => {
  toggleModal(
    selectedMatchReferences,
    referencePayload,
    confirmModal,
    toast
  );
}

const handleAssignClick = () => {
  const category = 'reference'
  assignReference(
    assignButtonLoading,
    selectedMatchReferences,
    ResultsRepository,
    toast,
    resultsStores,
    organization,
    resetReferences,
    referencePayload,
    category,
    filterModel
  );
}


onMounted(async () => {
  if (resultsStores?.referenceCategories?.options) {
    subcategory.value = Object.values(
      resultsStores.referenceCategories.options
    ).map((item) => ({ name: item }));
    dropdownData.value = subcategory.value
  } else {
    subcategory.value = [];
    dropdownData.value = []
  }
})

</script>

<style scoped>
.p-datatable {
  width: 100%
}

:deep(.p-multiselect-token-label) {
  font-size: 10px !important;
  width: 4rem !important;
  overflow: hidden !important;
}

:deep(.p-multiselect-token) {
  margin-top: 4px;
}


:deep(.p-datatable-tbody) {
  font-size: 14px !important;
}

:deep(.p-dialog-mask) {
  background-color: #808080b0 !important;
}

:deep(.p-fieldset-content) {
  padding: 0 !important;
}

.label {
  position: absolute;
  right: 7.09rem;
  top: 0.6rem;
  font-size: 12px;
  background-color: #dee2e6;
  padding: 6px 9px;
  border-radius: 50%;
}

.selectDateButton {
  position: absolute;
  top: -4rem;
  left: 8.5rem;
}
</style>