<template>
  <Button :loading="resendingInvite" size="small" v-if="user.status === false" icon="pi pi-refresh"
    @click="resendInvite" aria-label="Resend Invite" v-tooltip.top="'Resend Invite'" rounded outlined>
  </Button>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});

import Repository from "@repositories/RepositoryFactory";

const EmisorConfigurationRepository = Repository.get("emisorConfiguration");
const resendingInvite = ref(false);
const resendInvite = function () {
  if (resendingInvite.value == true) return;
  resendingInvite.value = true;
  EmisorConfigurationRepository.resendInvite({ email: props.user.email })
    .finally(() => {
      setTimeout(() => {
        resendingInvite.value = true
      }, 10000);
    });
}
</script>
