<template>
    <div>
        <div class="surface-section px-4 pt-4">
            <div
                class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
                <div class="flex align-items-center">
                    <i class="pi pi-at text-2xl mr-3 text-500"></i>
                    <span class="text-3xl font-medium text-900 mr-2">Email</span>
                </div>
                <div>
                    <Button @click="fetchUnreadEmails" :loading="fetchingUnreadEmails">
                        <i v-if="!fetchingUnreadEmails" class="pi pi-sync mr-2"></i>
                        <i v-else class="pi pi-spin mr-2 pi-spinner mr-2"></i>
                        Fetch emails
                    </Button>
                </div>
            </div>
            <div class="flex flex-wrap pt-5 ">
                <StatsCardComponent icons="pi pi-at" :description="fetchUnreadEmailsResponse?.summmary?.emails_checked"
                    heading="Emails" iconColor="bg-blue-200" />
                <StatsCardComponent icons="pi pi-hashtag"
                    :description="fetchUnreadEmailsResponse?.summmary?.total_invoices" heading="# of invoices"
                    iconColor="bg-pink-200" />
                <StatsCardComponent icons="pi pi-sort-numeric-down"
                    :description="fetchUnreadEmailsResponse?.summmary?.emails_with_invoices"
                    heading="Emails With Invoices" iconColor="bg-green-200" />
                <StatsCardComponent icons="pi pi-sort-numeric-up" :description="first_email_date"
                    heading="First email date" iconColor="bg-green-200" />
                <StatsCardComponent icons="pi pi-sort-numeric-up" :description="last_email_date"
                    heading="Last email date" iconColor="bg-green-200" />
            </div>
        </div>
        <div class="mb-3 px-4 pt-4">
            <h2 class="text-left">
                Current Unread Emails
            </h2>
            <Message v-if="fetchUnreadEmailsError" severity="info">To ensure a seamless and personalized Email
                collection setup, we invite you to reach out to our dedicated support team. Let us guide you through the
                configuration process for an optimized experience.</Message>
            <DataTable :value="email_details" v-model:selection="emailInvoicesSelectedMessages" dataKey="message_id">
                <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                <Column :sortable="true" field="subject" header="Subject"></Column>
                <Column :sortable="true" field="date" header="Date"></Column>
                <Column :sortable="true" field="from" header="From"></Column>
                <Column :sortable="true" field="num_invoices" header="Number of invoices">
                    <template #body="slotProps">
                        <Button text size="small" style="padding: 2px 5px;" @click="
                            previewEmailDialog = true;
                        previewEmailIndex = slotProps.index;
                        previewEmailShowBody = false;
                        ">
                            <span style="border-bottom: 1px dotted #909090;">{{ slotProps.data.num_invoices }}</span>
                        </Button>
                    </template>
                </Column>
                <Column header="Actions">
                    <template #body="slotProps">
                        <Button class="" @click="
                            previewEmailDialog = true;
                        previewEmailIndex = slotProps.index;
                        previewEmailShowBody = true
                            " size="small" style="white-space: nowrap;">
                            Preview Email
                        </Button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="mb-3 px-4 pt-5 md:px-6 lg:px-8">
            <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-2 mb-2">
                <h2 class="text-left">
                    Process Final Invoices
                </h2>
                <Button @click="fetchEmailInvoices" :loading="fetchingEmailInvoices || fetchingUnreadEmails"
                    :disabled="totalEmailInvoicesSelectedMessages === 0" class="ml-auto">
                    {{ fetchingEmailInvoices ? 'Fetching Email Invoices' : 'Fetch Email Invoices' }}
                </Button>
            </div>
            <div :class="{
                'invoice-uploader': true,
                empty: email_attachment_invoices.length === 0
            }">
                <template v-if="email_attachment_invoices.length > 0">
                    <div v-for="(invoiceName, index) in email_attachment_invoices" :key="index" class="invoice-name">
                        <label class="cursor-pointer" :for="'checkbox' + index">{{ invoiceName }}</label>
                        <Checkbox v-model="final_invoices_list" :inputId="'checkbox' + index" name="category"
                            :value="invoiceName" class="ml-auto" />
                    </div>
                </template>
                <div v-else>
                    <p>
                        No invoices found. Click Fetch Email invoices to fetch emails.
                    </p>
                </div>
            </div>
            <div class="flex">
                <Button :disabled="final_invoices_list.length === 0 || fetchingEmailInvoices" label="Process Batch"
                    @click="processBatch" icon="pi pi-cloud-upload" class="mt-5 ml-auto" />
            </div>
        </div>
        <Dialog header="Email Preview" v-model:visible="previewEmailDialog" style="width: 600px" modal>
            <template v-if="previewEmail">
                <div>
                    <h3>Subject: {{ previewEmail.subject }}</h3>
                    <h3>Date: {{ previewEmail.date }}</h3>
                    <h3>From: {{ previewEmail.from }}</h3>
                </div>
                <template v-if="previewEmail.invoice_names.length > 0 && previewEmailShowBody === false">
                    <div style="display: flex;flex-wrap: wrap;gap: 10px;">
                        <div v-for="(invoiceName, index) in previewEmail.invoice_names" :key="index"
                            class="invoice-name">
                            <label class="cursor-pointer" :for="'checkbox' + index">{{ invoiceName }}</label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div style="
                        height: 300px;
                        padding: 10px 0px;
                        overflow: auto;
                    " v-dompurify-html="previewEmailBody">
                    </div>
                </template>
            </template>
            <template #footer>
                <Button size="small" label="Close" @click="previewEmailDialog = false" text />
            </template>
        </Dialog>
        <Dialog :header="detailPageTitle" v-model:visible="jobSentDialogVisible" style="width: 400px" modal>
            <div v-if="processingBatch" class="">
                <ProgressSpinner style="height: 40px;width: 40px" class="flex ml-auto" />
                <h4 class="text-center">
                    Processing files, please wait...
                </h4>
            </div>
            <div v-else-if="processingBatch === false && !processingBatchResponse">
                <p>
                    Something went wrong while sending job. Please try again.
                </p>
            </div>
            <div v-else class="m-2">
                <p>
                    Job has been successfully sent.
                </p>
                <p>Job ID {{ processingBatchResponse?.job_id }}</p>
            </div>
            <template #footer>
                <Button size="small" label="Close" @click="jobSentDialogVisible = false" text />
                <template v-if="processingBatchResponse && processingBatch === false">
                    <router-link :to="{ name: 'details', params: { trx_id: processingBatchResponse.job_id } }">
                        <Button size="small" label="Show details" @click="jobSentDialogVisible = false" autofocus
                            :loading="processingBatch" />
                    </router-link>
                </template>
            </template>
        </Dialog>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";
import { computed, onMounted, ref } from "vue"
import Repository from "@repositories/RepositoryFactory";
import StatsCardComponent from "@components/StatsCardComponent.vue";
import moment from "moment"

const userSession = useUserSessionStore();
const { userName, organization, attributes } = storeToRefs(userSession);

const EmailRepository = Repository.get("email");

const email = ref(attributes.value.email);
const fetchingUnreadEmails = ref(false)
const fetchUnreadEmailsResponse = ref(null)
const fetchUnreadEmailsError = ref(null);
const fetchUnreadEmails = () => {
    fetchingUnreadEmails.value = true;
    EmailRepository.emailCheck({
        organization: organization.value,
        user: userName.value,
        target_email : email.value
    }).then((res) => {
        fetchUnreadEmailsResponse.value = res.data
        emailInvoicesSelectedMessages.value = res.data.email_details.map(email => email)
        fetchUnreadEmailsError.value = "";
    }).catch((error) => {
        if (error.response) {
            fetchUnreadEmailsError.value = "";
            setTimeout(() => {
                fetchUnreadEmailsError.value = error?.response?.data?.message;
            }, 50);
            return;
        }
        throw new Error("FetchUnReadEmails", error)
    }).finally(() => {
        fetchingUnreadEmails.value = false;
    })
}
const last_email_date = computed(() => {
    if (!fetchUnreadEmailsResponse?.value?.summmary?.last_email_date) return "..."
    return moment(fetchUnreadEmailsResponse?.value?.summmary?.last_email_date).format("MMMM DD YYYY")
})
const first_email_date = computed(() => {
    if (!fetchUnreadEmailsResponse?.value?.summmary?.first_email_date) return "..."
    return moment(fetchUnreadEmailsResponse?.value?.summmary?.first_email_date).format("MMMM DD YYYY")
})

const email_details = computed(() => {
    return fetchUnreadEmailsResponse?.value?.email_details || []
})

const final_invoices_list = ref([])
const processingBatch = ref(false)
const processingBatchResponse = ref(null)
const jobSentDialogVisible = ref(false)
const detailPageTitle = ref("");
const processBatch = () => {
    detailPageTitle.value = "Processing batch"
    jobSentDialogVisible.value = true;
    processingBatch.value = true;
    EmailRepository.emailProcessBatch({
        organization: organization.value,
        user: userName.value,
        s3_xlsx_key: false,
        job_id: fetchEmailInvoicesResponse.value?.job_id,
        final_invoices: final_invoices_list.value,
    }).then((res) => {
        setTimeout(() => {
            detailPageTitle.value = "Job sent"
            processingBatchResponse.value = res.data
            processingBatch.value = false;
        }, 5000);
    }).catch(error => {
        detailPageTitle.value = "Error sending job"
        processingBatch.value = false;
        throw new Error('processBatch' , error)
    })
}

const emailInvoicesSelectedMessages = ref(fetchUnreadEmailsResponse.value?.email_details.map(email => email))
const totalEmailInvoicesSelectedMessages = computed(() => {
    return emailInvoicesSelectedMessages.value?.length || 0
}, [emailInvoicesSelectedMessages])
const fetchingEmailInvoices = ref(false)
const fetchEmailInvoicesResponse = ref(null)
const email_attachment_invoices = computed(() => {
    return fetchEmailInvoicesResponse?.value?.email_attachment_invoices || []
})
const fetchEmailInvoices = () => {
    fetchingEmailInvoices.value = true;
    EmailRepository.emailInit({
        organization: organization.value,
        user: userName.value,
        mesage_ids: emailInvoicesSelectedMessages.value.map(email => email.message_id)
    }).then((res) => {
        final_invoices_list.value = res.data.email_attachment_invoices
        fetchEmailInvoicesResponse.value = res.data
    }).finally(() => {
        fetchingEmailInvoices.value = false;
    }).catch((error) => {
        throw new Error('fetchEmailInvoices' , error)
    })
}


const previewEmailDialog = ref(false)
const previewEmailShowBody = ref(false)
const previewEmailIndex = ref(null);
const previewEmail = computed(() => {
    return fetchUnreadEmailsResponse.value.email_details[previewEmailIndex.value] || null
})
const previewEmailBody = computed(() => {
    const string = previewEmail.value.body || "";
    const replaced = string.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return replaced;
}, [previewEmail])

onMounted(() => {
    fetchUnreadEmails()
})


</script>

<style scoped>
.invoice-uploader {
    gap: 10px;
    border: 1px solid #eee;
    padding: 40px
}

.invoice-uploader:not(.empty) {
    display: grid;
    /* 4 columsn */
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
}

.invoice-name {
    /* flex align-items-center gap-4 text-  */
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
    border: 1px solid rgb(231, 231, 231);
    padding: 7px;
    border-radius: 7px;
}


.invoice-name label {
    cursor: pointer;
    font-size: clamp(12px, calc(0.5rem + 1vw), 14px);
}
</style>