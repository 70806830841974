<template>
  <div class="flex flex-column w-full -mt-2 relative">
    <label for="invoice_id" class="text-start">
      <p>{{ inputLabel }}</p>
    </label>
    <InputText
      id="invoice_id"
      :class="{
        'p-invalid':
          !pdfOnlyStore.jobData[inputKey] ||
          pdfOnlyStore.jobData[inputKey] === null,
        'text-blue-400 cursor-pointer': pdfOnlyStore.jobData[inputKey] !== null,
        'text-gray-700': isSelected.includes(inputKey),
        'border-blue-400 border-2': pdfOnlyStore.selectedHighlight === inputKey,
      }"
      @focus="pdfOnlyStore.highlightedField = inputKey"
      class="w-full -mt-3 border-1"
      aria-describedby="username-help"
      :disabled="isDisabled"
      v-model="pdfOnlyStore.jobData[inputKey]"
      @input="handleInputChange(inputKey)"
    />
    <i
      v-if="getSelectedData"
      class="pi pi-spin pi-spinner absolute text-2xl"
      style="right: 1rem; top: 3.4rem"
    ></i>
    <i
      v-tooltip.left.top="'This field is required, and cant be empty'"
      v-if="
        !pdfOnlyStore.jobData[inputKey] ||
        pdfOnlyStore.jobData[inputKey] === null
      "
      class="pi pi-info-circle icon cursor-pointer"
    ></i>
  </div>
</template>
<script setup>
import { usePdfOnlyStore } from "../../store/pdf-only/store";

const pdfOnlyStore = usePdfOnlyStore();

defineProps({
  isSelected: {
    type: Array,
    required: true,
  },
  inputKey: {
    type: String,
    required: true,
  },
  inputLabel: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["handleInputChange"]);

const handleInputChange = (item) => {
  emits("handleInputChange", item);
};
</script>
<style scoped>
.icon {
  color: red;
  font-size: 1.3rem;
  font-weight: 100;
  position: absolute;
  right: 10px;
  top: 3.45rem;
}
</style>
