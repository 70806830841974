import Client from './Clients/AxiosClient';
import { appConfig } from "@/config";

export default {
    getMatchedReferencesByUser(params) {
        return Client.get(`/user/Matches`, params);
    },
    
    getMultipleReferencesInvoicesForUser(params) {
        return Client.get(`/user/MultipleMatches`, params);
    },

    getReferencesInvoices(params) {
        return Client.get(`/invoices/references`, params);
    },

    getDownloadLinks(data) {
        return Client.get(`/api/download/?invoice=${data.s3path}/${data.invoiceId}`);
    },

    downloadFiles(data) {
        return Client.get(`/api/download/?invoice=${data.invoiceId}`);
    },

    assignReferences(data, config) {
        return Client.put(`/invoices/reference?user=${data.user}&organization=${data.organization}`, data, config);
    },

    apiGetFiltersData(data, config) {
        return Client.get(`/report/filters?organization=${data.organization}`, data, config);
    },

    apiFilterData(payload, config) {
        return Client.post(`/report/filters`, payload, config);
    },

    apiGetUnlabeledDataInfo(data, config) {
        return Client.get(`/invoices/references/check?organization=${data.organization}`, data, config);
    }
};