<template>
  <DataTable
    class="ml-5"
    :loading="loading"
    :value="invoicesList"
    striped-rows
    :paginator="true"
    :rows="11"
    @page="onPage($event)"
    responsive-layout="scroll"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    edit-mode="row"
  >
    <template #empty>
      <p v-if="!loading" class="text-center">No APChecks found</p>
      <p v-else />
    </template>
    <Column field="invoice_number" header="Invoice Number">
      <template #body="slotProps">
        <span
          v-tooltip.top="slotProps.data.invoice_number"
          class="text-white px-2 py-2f font-semibold py-1 border-rounded"
          :class="{
            'bg-blue-400': slotProps.data.invoice_number,
          }"
        >
          {{ slotProps.data.invoice_number }}
        </span>
      </template>
    </Column>
    <Column field="customer" header="Customer">
      <template #body="slotProps">
        <span
          v-tooltip.top="slotProps.data.customer"
          class="text-white px-2 py-2f font-semibold py-1 border-rounded"
          :class="{
            'bg-yellow-400': slotProps.data.customer,
          }"
        >
          {{ slotProps.data.customer }}
        </span>
      </template>
    </Column>
    <Column field="reference" header="AP Reference"></Column>
    <Column field="reference" header="Reference">
      <template #body="slotProps">
        <span
          v-tooltip.top="slotProps.data.reference"
          class="text-white px-2 py-2f font-semibold py-1 border-rounded"
          :class="{
            'bg-indigo-400': slotProps.data.reference,
          }"
        >
          {{ slotProps.data.reference }}
        </span>
      </template>
    </Column>
    <Column field="seller" header="Seller">
      <template #body="slotProps">
        <span
          v-tooltip.top="slotProps.data.seller"
          class="text-white px-2 py-2f font-semibold py-1 border-rounded"
          :class="{
            'bg-orange-400': slotProps.data.seller,
          }"
        >
          {{ slotProps.data.seller }}
        </span>
      </template>
    </Column>
    <Column field="currency" header="Currency"></Column>
    <Column field="total" header="Total"></Column>
    <Column field="status" header="Status">
      <template #body="slotProps">
        <span
          v-tooltip.top="slotProps.data.status"
          class="text-white px-2 py-2f font-semibold py-1 border-rounded"
          :class="{
            'bg-green-400': slotProps.data.status === 'total_matched',
            'bg-red-400': slotProps.data.status === 'invoice_not_found',
            'bg-yellow-400': slotProps.data.status === 'total_matched',
            'bg-indigo-400': slotProps.data.status === 'total_mismatched' || slotProps.data.status === 'total_missmatch',
          }"
        >
          {{
            slotProps.data.status === "invoice_not_found"
              ? "Invoice Not Found"
              : slotProps.data.status === "total_matched"
              ? "Total Matched"
              : "Total Mismatched"
          }}
        </span>
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
const emit = defineEmits(["onPage"]);

defineProps({
  invoicesList: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const onPage = (event) => {
  emit("onPage", event);
};
</script>
<style scoped>
.border-rounded {
  border-radius: 10px;
}
</style>
