<template>
  <h2 class="text-left">Recently Uploaded Tags Files</h2>
  <DataTable
    :loading="props.xlsListLoading"
    :value="props.xlsxList"
    tableStyle="min-width: 50rem"
    :paginator="true"
    :rows="10"
    @page="onPage"
  >
    <Column :sortable="true" field="tag_filename" header="Filename"></Column>
    <Column field="organization" header="Organization"> </Column>
    <Column field="tags_count" header="Tags">
      <template #body="slotProps">
        <div :class="stockClass(slotProps.data['tags_count'])">
          {{ slotProps.data["tags_count"] }}
        </div>
      </template>
    </Column>
    <Column field="issuers_count" header="Issuers">
      <template #body="slotProps">
        <div :class="stockClass(slotProps.data['issuers_count'])">
          {{ slotProps.data["issuers_count"] }}
        </div>
      </template>
    </Column>
    <Column header="Actions">
      <template #body="slotProps">
        <i
          v-tooltip.top="'Preview file'"
          :class="{
            'pi-spin pi-spinner': loading === slotProps.data.tag_filename,
            'pi-eye': loading !== slotProps.data.tag_filename,
          }"
          class="pi mr-4 text-2xl cursor-pointer"
          @click="previewFile(slotProps.data.tag_filename)"
        ></i>
      </template>
    </Column>
    <template #loading>
      <div class="my-4">
        <ProgressSpinner
          v-if="props.xlsListLoading"
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Loading"
          class="mb-10"
        />
      </div>
    </template>
    <template #empty>
      <div
        class="card flex align-items-center justify-content-center"
        v-if="!props.xlsListLoading"
      >
        <Card style="width: 45em">
          <template #header>
            <div
              class="flex w-full justify-content-center"
              style="height: 20rem"
            >
              <img src="../assets/invoice.gif" alt="" class="" />
            </div>
          </template>
          <template #title>
            <p class="text-center -mt-6">Upload Tags File</p>
          </template>
          <template #subtitle>
            <p class="text-center"><b>Tags Key File .xls , .xlsx</b></p>
          </template>
          <template #content>
            <p class="m-0 text-center -mt-2">
              The tags key file servers a similar purpose as the References key
              file by inputing the rules for assigning references to invoices.
              In this case you can assign Tags to Invoices. The Tags file
              requires the following columns: Emisor, Emisor RFC, TagFilter,
              Hierarchy, Tag Name, Description Code, Tag Default; The tag filter
              will try to Match the invoices data with the rules and Hierarchy
              as configured on the Tags File, Once a match if find the process
              stops and the Tag is assigned, First we check ProductCode a.k.a
              TagFilter, Then we check Description, and as last resource we
              check for Tag Default.
            </p>
          </template>
          <template #footer>
            <div class="flex justify-content-center">
              <Button
                label="Upload Tags File"
                icon="pi pi-upload"
                @click="handleButtonClick"
              />
            </div>
          </template>
        </Card>
      </div>
    </template>
  </DataTable>
  <template>
    <div>
      <Dialog
        v-model:visible="dialogVisible"
        modal
        header="Tags Details"
        style="width: 90vw"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <DataTable
          :value="tagsDetails"
          scrollable
          scrollHeight="680px"
          :virtualScrollerOptions="{ itemSize: 80 }"
        >
          <Column
            v-for="(value, key) in tagsDetails[0]"
            :key="key"
            :field="key"
            :header="formatHeader(key)"
          >
            <template v-if="key === 'Hirerchy'" #body="slotProps">
              <div :class="stockClass(slotProps.data['Hirerchy'])">
                {{ slotProps.data["Hirerchy"] }}
              </div>
            </template>
          </Column>
        </DataTable>
      </Dialog>
    </div>
  </template>
</template>
<script setup>
import { ref } from "vue";
import { stockClass } from "@/composables/useUtils";
import { useApi } from "../../hooks/useApi";
const emit = defineEmits(["upload-tags", "on-page"]);

const tagsDetails = ref([]);
const dialogVisible = ref(false);
const loading = ref("");

const props = defineProps({
  xlsListLoading: {
    type: String,
    required: true,
  },
  xlsxList: {
    type: [],
    required: true,
  },
});

const formatHeader = (key) => {
  return key;
};

const handleButtonClick = () => {
  emit("upload-tags");
};
const onPage = () => {
  emit("on-page");
};
const previewFile = async (item) => {
  loading.value = item;
  try {
    const params = {
      tags_s3_filename: item,
    };
    const {
      data: response,
      loading: isLoading,
      fetchItems: getTagFileDetails,
    } = useApi("/tags/details", params);
    if (typeof getTagFileDetails === "function") {
      await getTagFileDetails();
      tagsDetails.value = response.value?.xlsx_data;
      dialogVisible.value = true;
      loading.value = isLoading.value ? "" : item;
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    loading.value = "";
  }
};
</script>

<style scoped>
:deep(.p-card) {
  box-shadow: none !important;
}
</style>
