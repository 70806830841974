<template>
  <div
    class="surface-100 flex flex-wrap align-items-center justify-content-center w-full"
  >
    <Toast />
    <Toast group="process_invoice_missing_files_error" />
    <div class="w-full">
      <div
        v-if="auth.route !== 'authenticated'"
        class="h-screen flex justify-content-center"
      >
        <authenticator :hide-sign-up="true">
          <template v-slot="{ user, signOut }"> </template>
        </authenticator>
      </div>
      <Layout v-else>
        <template v-slot:data>
          <div>
            <template v-if="auth.route !== 'authenticated'">
              <img alt="Vue logo" src="@assets/logo.png" />
            </template>
            <template v-if="auth.route === 'authenticated'">
              <router-view />
              <div v-if="feedbackModel">
                <FeedbackModal />
              </div>
            </template>
          </div>
        </template>
      </Layout>
    </div>
  </div>
</template>

<script>
import Layout from "./components/Layout.vue";
import { useRouter } from "vue-router";
import { useApi } from "../hooks/useApi";
import { clearDatabase } from "../db";
import FeedbackModal from "./components/App/FeedbackModal.vue";

export default {
  name: "App",
  components: {
    Layout,
  },
};
</script>

<script setup>
import { onMounted, ref, toRefs, watchEffect } from "vue";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import { Hub } from "aws-amplify";
import { serviceStore } from "@/store/serviceStore";
import { Quotes } from "./assets/quotes";
import { useToast } from "primevue/usetoast";
import AxiosClient from "./repositories/Clients/AxiosClient";

const quotesData = Quotes;

const toast = useToast();
const feedbackModel = ref(false);
const resultsStores = serviceStore();
const { user, route } = toRefs(useAuthenticator());
const auth = useAuthenticator();

const userSession = useUserSessionStore();
const { userName, attributes, organization, userGroups } =
  storeToRefs(userSession);
const vueRouter = useRouter();
const apiCallsMade = ref(false);

AxiosClient.defaults.toast = toast;

const { fetchItems: referenceWarmup } = useApi("/reference/warmup");
const { fetchItems: resultWarmup } = useApi("/results/warmup");

const getXlsxList = async () => {
  const params = {
    organization: organization.value,
  };

  const { data: xlsDataResponse, fetchItems: getXlxData } = useApi(
    "/reference/xlsx-list",
    params
  );

  await getXlxData().then(() => {
    if (xlsDataResponse.value?.data?.length > 0) {
      localStorage.setItem("isXlsUploaded", true);
    } else {
      localStorage.setItem("isXlsUploaded", false);
    }
  });
};

onMounted(() => {
  Hub.listen("auth", async (data) => {
    if (data.payload.event == "signIn" && !apiCallsMade.value) {
      if (!vueRouter.currentRoute.value.name) {
        // Push the user to the route without changing the URL
        vueRouter.push({
          name: vueRouter.currentRoute.value.name,
          replace: true,
        });
      }
      try {
        apiCallsMade.value = true;
        clearDatabase();
        await referenceWarmup();
        await resultWarmup().then(() => {
          resultsStores.getFilterData(organization.value, true);
        });
        await getXlsxList();
        await resultsStores.unlabeledDataInfo(organization.value);
        localStorage.setItem("todaysQuote", getRandomQuote());
      } catch (error) {
        console.log(error);
      }
    }
  });
});

function getRandomQuote() {
  const allQuotes = quotesData.flatMap((language) => language.quotes);
  const randomIndex =
    window.crypto.getRandomValues(new Uint32Array(1))[0] % allQuotes.length;
  const randomQuote = allQuotes[randomIndex];
  const output = `${randomQuote.quote} - ${randomQuote.author}`;
  return output;
}

watchEffect(() => {
  if (user?.value && route.value === "authenticated") {
    userName.value = user?.value.username;

    attributes.value = user?.value.attributes;
    organization.value = user?.value.attributes["custom:organization"];

    const roles =
      auth?.user?.signInUserSession?.accessToken?.payload["cognito:groups"];

    if (roles) {
      userGroups.value = Object.keys(roles).reduce((acc, key) => {
        acc.push(roles[key]);
        return acc;
      }, []);
    }
  } else {
    apiCallsMade.value = false;
  }
});

watchEffect(() => {
  if (resultsStores.responseError) {
    toast.add({
      severity: "info",
      summary: resultsStores.responseError.message,
      detail: resultsStores.responseError?.response?.data["message"],
      life: 3000,
      position: "top-left",
    });
    resultsStores.responseError = null;
  }
});

watchEffect(() => {
  if (vueRouter.currentRoute.value.name === "details") {
    feedbackModel.value = false;
  } else {
    feedbackModel.value = true;
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 11px !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 4px 5px;
}

.amplify-button[data-variation="primary"] {
  background: linear-gradient(
    to right,
    var(--amplify-colors-green-80),
    var(--amplify-colors-orange-80)
  );
}

html {
  font-size: 11px !important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 4px 5px;
}

.red-border {
  border: 1px solid red !important;
}

.red-border:focus {
  border-color: red !important;
}
.border-round-max {
  border-radius: 1rem;
}
</style>
