<template>
    <div class="flex flex-wrap relative overflow-hidden"
        style="border-radius:1rem; background:radial-gradient(100% 1126.49% at 100% 0%, rgba(106, 200, 212, 0.4) 0%, rgba(33, 33, 33, 0) 100%), #212121;">
        <div class="px-6 py-3 flex justify-content-between w-full align-items-center">
            <div>
                <div class="text-cyan-500 font-bold text-5xl mt-3 mb-5 text-start">Welcome <span class="text-white uppercase">{{
                    userName }}</span></div>
                <div class="text-white text-medium text-xl -mt-4 mb-5 text-start">{{ currentDate }}</div>
                <div class="text-white text-medium text-2xl mb-5 text-start font-900 italic" style="font-style: italic; width: 50vw;"><b>{{ quote }}</b></div>
            </div>
            <div style="z-index: 100;">
                <div class="text-orange-500 font-bold text-5xl mt-3 mb-5 absolute right-0" style="top: 5.5rem; margin-right: 4rem;">Organization: <span
                        class="text-gray-700 uppercase">{{ organization }}</span></div>
            </div>
        </div>
        <img src="../assets/cta-browser.png" alt="Image" class="static lg:absolute block mx-auto"
            style="height: 400px; top: -2rem; right: -1rem; z-index: 1;">
    </div>
</template>
<script setup>
import moment from 'moment';
import { ref } from 'vue';
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";


const userSession = useUserSessionStore();
const { organization, userName } = storeToRefs(userSession);
const currentDate = ref(moment().format('DD MMMM YYYY'))
const quote = localStorage.getItem("todaysQuote")

</script>
<style>
.text-start{
    text-align: start;
}
</style>