<template>
  <div>
    <div class="p-5 flex flex-column flex-auto">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
        <div class="flex align-items-center w-full">
          <div class="flex justify-content-between w-full mx-1">
            <div>
              <i class="pi pi-home text-2xl mr-3 text-500"></i>
              <span class="text-3xl font-medium text-900">Home</span>
            </div>
            <div>
              <i v-tooltip.top="'Refresh Stats'" :class="{ 'pi-spin pointer-event-none': isLoading }"
                class="pi pi-refresh text-2xl mr-3 cursor-pointer text-500" @click="updateResponseToDB(true)"></i>
            </div>
          </div>
          <div class="ml-auto">
            <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
              animationDuration=".5s" aria-label="Loading" />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <BannerComponent />
      </div>
      <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row  pt-2">
        <div>
          <h2 class="">Total activity for <span style="text-transform: capitalize;"> {{ userName }}</span></h2>
        </div>
      </div>
      <div class="grid gap-2 justify-content-center" v-if="isLoading">
        <div v-for="loadingColor in loadingColors" :key="loadingColor.id">
          <Skeleton class="mt-1" style="height: 10rem; width: 12.5rem;" :class="loadingColor.color"></Skeleton>
        </div>
      </div>
      <div class="grid gap-2 justify-content-center" v-else>
        <OverviewCardComponent :value="organizationStats?.user_stats?.manual_labelled_invoices" label="Manual Labeling"
          icon=" pi pi-wrench" backgroundColor="bg-gray-200" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.success_invoices" label="Successful Invoices"
          icon=" pi pi-check" backgroundColor="bg-blue-200" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.multiple_matches_invoices"
          label="Multiple-Match Invoices" icon="pi pi-file" backgroundColor="bg-yellow-300" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.unknown_invoices" label="Unknown Invoices"
          icon="pi pi-question-circle" backgroundColor="bg-pink-400" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.total_invoices" label="Total Invoices"
          icon="pi pi-map" backgroundColor="bg-orange-400" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.total_jobs" label="Total Jobs"
          icon="pi pi-briefcase" backgroundColor="bg-purple-300" />
        <OverviewCardComponent :value="organizationStats?.user_stats?.total_processed" label="Total Processed"
          icon="pi pi-sync" backgroundColor="bg-teal-400" />
      </div>
      <div class="flex mt-4">
        <div style="width: 60%;" class="mr-4 shadow-2  px-4 py-2 border-round relative">
          <div class="flex align-items-center md:justify-content-between flex-column md:flex-row  pt-2">
            <div>
              <h2 class="">Weekly Progress</h2>
            </div>
            <div class="mt-2 font-bold">
              <span>{{ chartData?.labels ? chartData?.labels[0] : '' }}</span> To
              <span>{{ chartData?.labels ? chartData?.labels[chartData?.labels?.length - 1] : '' }}</span>
            </div>
            <div>
              <Button v-tooltip.top="'Previous week'" icon="pi pi-arrow-circle-left"
                :disabled="currentIndex === chunkedData.length - 1" @click="prevChunk" severity="help" text raised rounded
                aria-label="Favorite" />
              <Button icon="pi pi-arrow-circle-right" v-tooltip.top="'Next week'" :disabled="currentIndex === 0"
                @click="previousChunk" severity="help" class="ml-2" text raised rounded aria-label="Favorite" />
            </div>
          </div>
          <div class="chart">
            <div v-if="isLoading" class="flex gap-6 justify-content-center w-full" style="transform: rotate(180deg);">
              <Skeleton height="40rem" width="5rem" class="mb-2 mt-1"></Skeleton>
              <Skeleton height="30rem" width="5rem" class="mb-2 mt-1"></Skeleton>
              <Skeleton height="20rem" width="5rem" class="mb-2 mt-1"></Skeleton>
              <Skeleton height="40rem" width="5rem" class="mb-2 mt-1"></Skeleton>
              <Skeleton height="10rem" width="5rem" class="mb-2 mt-1"></Skeleton>
            </div>
          </div>
          <div class="chart" v-if="isChartVisible && !isLoading">
            <Chart type="bar" id="myChart" :data="chartData" :options="chartOptions" style="height: 40rem;" />
          </div>
          <div>
          </div>
        </div>
        <div style="width: 40%;">
          <div class="flex justify-content-center shadow-2 w-full px-4 border-round">
            <DougnutChart :chartDto="weeklyProgressStats" :details="organizationTotal" :userTotal='userTotal'
              :loading="isLoading" />
          </div>
          <div class="px-4 py-1 shadow-2 mt-2 border-round">
            <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row  pt-2">
              <div>
                <h2 class="">Organization Total</h2>
              </div>
            </div>
            <ul class="mt-4 list-none p-0 mx-0" v-for="(item, index) in mutatedData || []" :key="index">
              <li class="flex align-items-center pb-1">
                <Skeleton shape="circle" size="2rem" class="mb-2 mr-1" v-if="isLoading"></Skeleton>
                <i v-else class="text-white border-circle p-2 mr-2" :class="[item.icon, item.bgColor]"></i>
                <Skeleton height="2rem" width="25rem" class="mb-2" v-if="isLoading"></Skeleton>
                <span v-else class="text-xl font-medium text-90">{{ item.name }}</span>
                <span class="text-600 text-xl font-medium ml-auto">{{ item.value }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useAppConfig } from "@/config";
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";
import OverviewCardComponent from "../components/OverviewCardComponent.vue";
import BannerComponent from '../components/BannerComponent.vue'
import DougnutChart from "../components/DougnutChart.vue";
import { useApi } from '../../hooks/useApi'
import { getCachedResponse, storeApiResponse } from "../../db";

const userSession = useUserSessionStore();
const { organization, userName } = storeToRefs(userSession);

// Global config constants
const appConfig = useAppConfig();

const organizationStats = ref({});
const user = ref()
const chartData = ref([]);
const weeklyProgressStats = ref([])
const chartOptions = ref();
const isChartVisible = ref(false)
const isLoading = ref(false)
const organizationTotal = ref(0)
const userTotal = ref(0)
const chunkedData = ref([])
const currentIndex = ref(0);

const chunkData = (data, chunkSize) => {
  const entries = Object.entries(data).reverse(); // Reverse the entries array
  const chunks = [];
  for (let i = 0; i < entries.length; i += chunkSize) {
    // Push the reversed chunk to maintain descending order within the chunk
    chunks.push(Object.fromEntries(entries.slice(i, i + chunkSize).reverse()));
  }
  return chunks;
};

const prevChunk = () => {
  if (currentIndex.value < chunkedData.value.length - 1) {
    currentIndex.value++;
    chartData.value = setChartData();
  }
}
const previousChunk = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
    chartData.value = setChartData();

  }
};

const currentChunk = computed(() => chunkedData.value[currentIndex.value]);

const organizationTotalInitial = [
  { "name": "Successful Invoices", "value": 0, "icon": "pi pi-check", "bgColor": "bg-blue-200" },
  { "name": "Multiple-Match Invoices", "value": 0, "icon": "pi pi-file", "bgColor": "bg-yellow-300" },
  { "name": "Unknown Invoices", "value": 0, "icon": "pi pi-question", "bgColor": "bg-pink-400" },
  { "name": "Total Invoices", "value": 0, "icon": "pi pi-map", "bgColor": "bg-orange-400" },
  { "name": "Total Jobs", "value": 0, "icon": "pi pi-briefcase", "bgColor": "bg-purple-300" },
  { "name": "Total Processed", "value": 0, "icon": "pi pi-sync", "bgColor": "bg-teal-400" }]

const mutatedData = ref(organizationTotalInitial);

const loadingColors = ref([
  { id: 1, color: 'bg-gray-200' },
  { id: 2, color: 'bg-blue-200' },
  { id: 3, color: 'bg-yellow-300' },
  { id: 4, color: 'bg-pink-400' },
  { id: 5, color: 'bg-orange-400' },
  { id: 6, color: 'bg-purple-300' },
  { id: 7, color: 'bg-teal-400' },
])

const propertyMappings = [
  { property: "success_invoices", name: "Successful Invoices", icon: "pi pi-check", bgColor: "bg-blue-200" },
  { property: "multiple_matches_invoices", name: "Multiple-Match Invoices", icon: "pi pi-file", bgColor: "bg-yellow-300" },
  { property: "unknown_invoices", name: "Unknown Invoices", icon: "pi pi-question", bgColor: "bg-pink-400" },
  { property: "total_invoices", name: "Total Invoices", icon: "pi pi-map", bgColor: "bg-orange-400" },
  { property: "total_jobs", name: "Total Jobs", icon: "pi pi-briefcase", bgColor: "bg-purple-300" },
  { property: "total_processed", name: "Total Processed", icon: "pi pi-sync", bgColor: "bg-teal-400" }
];

const setChartData = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  const randomColors = [
    documentStyle.getPropertyValue('--gray-200'),
    documentStyle.getPropertyValue('--blue-200'),
    documentStyle.getPropertyValue('--yellow-300'),
    documentStyle.getPropertyValue('--pink-400'),
    documentStyle.getPropertyValue('--orange-400'),
    documentStyle.getPropertyValue('--purple-300'),
    documentStyle.getPropertyValue('--teal-400'),
  ];
  // const chunkedData = chunkData(organizationStats.value?.user_stats?.weekly_progress || [], 5);
  const weeklyProgress = currentChunk.value || []


  return {
    labels: weeklyProgress && Object.keys(weeklyProgress) || [],
    datasets: [
      {
        label: 'Weekly progress',
        backgroundColor: randomColors.slice(0, weeklyProgress?.length),
        borderColor: randomColors.slice(0, weeklyProgress?.length),
        data: weeklyProgress && Object.values(weeklyProgress) || []
      }
    ]
  };
};

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  return {
    maintainAspectRatio: false,
    aspectRatio: 0.1,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500
          }
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      }
    }
  };
}

const calculateCoverage = (baseNumber, targetNumber) => {
  let percentage = (baseNumber / targetNumber) * 100;
  return { percentage: percentage };
}

const getCachedData = () => {
  return new Promise((resolve) => {
    getCachedResponse("/overviewData", function (cachedData) {
      if (Array.isArray(cachedData) && cachedData.length > 0) {
        resolve(cachedData[0]);
      } else {
        resolve(undefined)
      }
    });
  });
};

const addData = () => {
  mutatedData.value = propertyMappings.map(mapping => {
    const value = organizationStats.value[mapping.property];
    return {
      name: mapping.name,
      value,
      icon: mapping.icon,
      bgColor: mapping.bgColor
    };
  });

  document.title = appConfig.appName;
  user.value = organizationStats.value.last_user_job !== null && organizationStats.value.last_user_job?.user ? organizationStats.value.last_user_job?.user : ''

  chartData.value = setChartData();
  chartOptions.value = chartOptions.value ? chartOptions.value : setChartOptions();
  weeklyProgressStats.value = calculateCoverage(organizationStats.value.user_stats.total_processed, organizationStats.value.total_processed).percentage
  organizationTotal.value = organizationStats.value.total_processed
  userTotal.value = organizationStats.value.user_stats.total_processed
  isChartVisible.value = true
}

const updateResponseToDB = async (forceUpdate) => {
  try {
    isLoading.value = true
    organizationStats.value = {}
    mutatedData.value = organizationTotalInitial
    user.value = ''
    chartData.value = []
    weeklyProgressStats.value = []
    organizationTotal.value = 0
    userTotal.value = 0
    isChartVisible.value = false
    const response = await getCachedData()
    const params = {
      organization: organization.value,
      user: userName.value
    }
    const {
      data: organizationData,
      loading: loading,
      fetchItems: fetchUsers
    } = useApi('/organization/overview', params);
    if (!response || forceUpdate) {
      if (typeof fetchUsers === 'function') {
        await fetchUsers()
      }
      storeApiResponse('/overviewData', JSON.parse(JSON.stringify([organizationData.value])))
      organizationStats.value = organizationData.value || []
      isLoading.value = loading.value
      chunkedData.value = await chunkData(organizationStats.value?.user_stats?.weekly_progress || [], 7)
      addData()
    } else {
      organizationStats.value = response || []
      chunkedData.value = await chunkData(organizationStats.value?.user_stats?.weekly_progress || [], 7)
      addData()
    }

  } catch (error) {
    throw new Error(error)
  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {

  try {
    isLoading.value = true
    await updateResponseToDB()

  } catch (error) {
    console.log(error, 'Error on Mounted');
  }
});

</script>

<style scoped>
.chart {
  position: absolute;
  width: 95%;
  bottom: 0;
}
</style>
