<template>
  <div class="">
    <div>
      <div class="flex justify-content-between mb-2 w-full">
        <div>
          <h3>Filters</h3>
        </div>
        <div class="flex align-items-center gap-4">
          <p class="cursor-pointer" @click="resetFilters">Reset</p>
          <Button
            class="cursor-pointer text-white"
            @click="applyFilters"
            label="Apply
              Filters"
          />
        </div>
      </div>
      <Divider class="-mt-2" />
      <div>
        <div class="card">
          <Accordion :activeIndex="0" @click="toggleTab('date')">
            <AccordionTab header="Date Range">
              <div class="mb-2 text-start">
                <Calendar
                  v-model="jobsStore.filters.date"
                  selection-mode="range"
                  ref="calender"
                  placeholder="Select a date"
                  class="w-full"
                  :panel-style="{
                    width: '300px',
                  }"
                  :max-date="moment().toDate()"
                  @date-select="emit('change')"
                >
                  <template #footer>
                    <div class="flex flex-wrap gap-2">
                      <Button
                        v-for="(preset, index) in datePresets"
                        :key="index"
                        style="padding: 2px 7px; font-size: 11px"
                        @click="selectRange(preset)"
                      >
                        {{ preset.name }}
                      </Button>
                    </div>
                  </template>
                </Calendar>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion :activeIndex="0" @click="toggleTab('status')">
            <AccordionTab header="Status">
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.isCompleted"
                  :binary="true"
                />
                <p class="text-lg">
                  Completed: {{ summary?.complete?.length }}
                </p>
              </div>
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.inProgress"
                  :binary="true"
                />
                <p class="text-lg">
                  In Progress: {{ summary?.in_progress?.length }}
                </p>
              </div>
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.pdfParsing"
                  :binary="true"
                />
                <p class="text-lg">
                  Verification: {{ summary?.pdf_parsing?.length }}
                </p>
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion :activeIndex="0" @click="toggleTab('job_type')">
            <AccordionTab header="Job Type">
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.isBatch"
                  :binary="true"
                />
                <p class="text-lg">Batch: {{ summary?.batch?.length }}</p>
              </div>
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.isManual"
                  :binary="true"
                />
                <p class="text-lg">Manual: {{ summary?.manual?.length }}</p>
              </div>
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.isAutoJob"
                  :binary="true"
                />
                <p class="text-lg">Auto-Job: {{ summary?.auto_job?.length }}</p>
              </div>
              <div class="flex align-items-center gap-4">
                <Checkbox
                  @change="onFilterChange"
                  v-model="checked.isEmail"
                  :binary="true"
                />
                <p class="text-lg">Email: {{ summary?.email?.length }}</p>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import moment from "moment";
import { useJobStore } from "../../../store/jobsStore/store";
import { getTransactionsList } from "../../../composables/useUtils";
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";
import { datePresets } from "../../../utils/presets";

const activeIndexes = ref(["date", "status"]);
const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);
const email = ref(attributes.value.email);

const summary = ref({
  complete: [],
  in_progress: [],
  pdf_parsing: [],
});

const defaultDate = ref([
  moment().subtract(13, "days").startOf("day").format("YYYY-MM-DD"),
  moment().endOf("day").format("YYYY-MM-DD"),
]);

const props = defineProps({
  jobsData: {
    type: Object,
    default: () => {},
  },
});

const calender = ref();

const checked = ref({
  isCompleted: false,
  inProgress: false,
  pdfParsing: false,
  isBatch: false,
  isManual: false,
  isAutoJob: false,
  isEmail: false,
});

const jobsStore = useJobStore();

const activeTabs = ref({
  date: 0,
  status: 0,
  job_type: 1,
  users: 1,
});

const toggleTab = (item) => {
  activeTabs.value[item] = 0;
  if (!activeIndexes.value.includes(item)) {
    activeIndexes.value.push(item);
    if (activeIndexes.value.length > 2) {
      activeTabs.value[activeIndexes.value[0]] = 1;
      activeIndexes.value.shift();
    }
  }
};
const isError = ref();

const onFilterChange = () => {
  const filterConditions = {
    isCompleted: (job) => job.status === "Complete",
    inProgress: (job) => job.status === "In Progress",
    pdfParsing: (job) => job.status === "Verification",
    isBatch: (job) => job.ingestion_source === "BATCH",
    isManual: (job) => job.ingestion_source === "Manual",
    isAutoJob: (job) => job.ingestion_source === "Auto-Job",
    isEmail: (job) => job.ingestion_source === "Email",
  };

  const activeFilters = Object.entries(checked.value)
    .filter(([key, value]) => value)
    .map(([key]) => filterConditions[key]);

  if (activeFilters.length === 0) {
    jobsStore.jobsTransactionList = props.jobsData;
    return;
  }

  jobsStore.jobsTransactionList = props.jobsData.filter((job) =>
    activeFilters.every((condition) => condition(job))
  );
};

watchEffect(
  () => {
    if (props.jobsData) {
      const newSummary = {
        complete: [],
        in_progress: [],
        pdf_parsing: [],
        batch: [],
        manual: [],
        auto_job: [],
        email: [],
      };

      props.jobsData.forEach((item) => {
        if (item.status === "Complete") {
          newSummary.complete.push(item);
        } else if (item.status === "In Progress") {
          newSummary.in_progress.push(item);
        } else if (item.status === "Verification") {
          newSummary.pdf_parsing.push(item);
        }
        if (item.ingestion_source === "BATCH") {
          newSummary.batch.push(item);
        } else if (item.ingestion_source === "Manual") {
          newSummary.manual.push(item);
        } else if (item.ingestion_source === "Auto-Job") {
          newSummary.auto_job.push(item);
        } else if (item.ingestion_source === "Email") {
          newSummary.email.push(item);
        }
      });
      summary.value = newSummary;
    }
  },
  { flush: "sync" }
);

const fetchTransaction = async (startDate, endDate) => {
  await getTransactionsList(
    jobsStore,
    userName,
    email,
    organization,
    startDate,
    endDate,
    isError
  );
};

const applyFilters = async () => {
  jobsStore.jobListLoading = true;
  if (jobsStore.filters.date[0] === "undefined") {
    await fetchTransaction(defaultDate.value[0], defaultDate.value[1]);
  } else {
    const startDate = moment(jobsStore.filters.date[0]).format("YYYY-MM-DD");
    const endDate = moment(jobsStore.filters.date[1]).format("YYYY-MM-DD");
    await fetchTransaction(startDate, endDate);
  }

  const startDate = moment(jobsStore.filters.date[0]).format("YYYY-MM-DD");
  const endDate = moment(jobsStore.filters.date[1]).format("YYYY-MM-DD");
  await fetchTransaction(startDate, endDate);
  const timeOut = setTimeout(() => {
    jobsStore.jobListLoading = false;
    clearTimeout(timeOut);
  }, 1000);
};

const resetFilters = async () => {
  const startOfPeriod = moment()
    .subtract(13, "days")
    .startOf("day")
    .format("YYYY-MM-DD");
  const endOfPeriod = moment().endOf("day").format("YYYY-MM-DD");
  await fetchTransaction(startOfPeriod, endOfPeriod);
  jobsStore.jobsTransactionList = props.jobsData;
  for (const key in checked.value) {
    checked.value[key] = false;
  }
  jobsStore.jobListLoading = false;
};

const selectRange = (item) => {
  jobsStore.filters.date = item.getvalue();
  calender.value.overlayVisible = false;
};
</script>
