
export const getLayoutLinks = (serviceStore) =>{
    // Example usage of serviceStore to conditionally modify layoutLinks
    // This is just an illustrative example; adapt according to your actual logic
    const showBadge = serviceStore.showBadge;
    
    return [
    {
      id: 1,
      link: "/",
      text: "Home",
      icon: "pi pi-home",
      showBadge: false,
    },
    {
      id: 2,
      link: "/tags",
      text: "Tags",
      icon: "p-menuitem-icon pi pi-server pi-tags",
      showBadge: false,
    },
    {
      id: 3,
      link: "/references",
      text: "References",
      icon: "p-menuitem-icon pi pi-server pi-wrench",
      showBadge: false,
    },
    {
      id: 4,
      link: "/process",
      text: "Process Invoices",
      icon: "p-menuitem-icon pi pi-upload pi-wrench mr-2",
      showBadge: false,
    },
    {
      id: 5,
      link: "/emails",
      text: "Emails",
      icon: "p-menuitem-icon pi pi-at",
      showBadge: false,
    },
    {
      id: 5,
      link: "/ap-check",
      text: "AP Check",
      icon: "p-menuitem-icon pi pi-file-excel",
      showBadge: false,
    },
    {
      id: 6,
      link: "/org-overview",
      text: "Org-overview",
      icon: "p-menuitem-icon pi pi-chart-bar pi-thumbs-up-fill",
      showBadge: false,
    },
    {
      id: 7,
      link: "/jobs",
      text: "Jobs",
      icon: "pi pi-list",
      showBadge: false,
    },
    {
      id: 8,
      link: "/reports",
      text: "Reports",
      icon: "p-menuitem-icon pi pi-check-circle pi-thumbs-up-fill",
      showBadge: false,
    },
    {
      id: 9,
      link: "/labelling",
      text: "User Labelling",
      icon: "pi pi-question-circle",
      showBadge: !!showBadge,
    },
];
}