import { ref } from 'vue';

export const useValidateUploadFiles = (toast, fileLimit, disabledComp, formData, fileUpload) => {
    const fileCounter = ref(0);

    const onFileUpload = ({ files }) => {
        const pdfFiles = [];
        const xmlFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileType = file.type;

            if (fileType === 'application/pdf') {
                pdfFiles.push(file);
            } else if (fileType === 'text/xml' || fileType === 'application/xml') {
                xmlFiles.push(file);
            }
        }

        if (pdfFiles.length > (fileLimit.value / 2) && xmlFiles.length && xmlFiles.length > (fileLimit.value / 2)) {
            toast.add({
                severity: 'error',
                summary: 'File limit exceeded',
                detail: `You can only select up to ${fileLimit.value} PDF files and ${fileLimit.value} XML files.`,
                life: 5000,
            });

            disabledComp.value = true;
            fileUpload.value.clear(); // Reset the file input
        }  else if (pdfFiles.length > (fileLimit.value) && !xmlFiles.length) {
            toast.add({
                severity: 'error',
                summary: 'File limit exceeded',
                detail: `You can only select up to ${fileLimit.value } PDF files.`,
                life: 5000,
            });

            disabledComp.value = true;
            fileUpload.value.clear(); // Reset the file input
        } else {

            disabledComp.value = false;
            formData.files = pdfFiles.concat(xmlFiles);
            fileCounter.value = formData.files.length;

        }
    };

    const onFileRemove = ({ file }) => {
        const index = formData.files.findIndex((f) => f === file);

        if (index !== -1) {
            formData.files.splice(index, 1);
            fileCounter.value--;
        }

        if (fileCounter.value === 0) {
            disabledComp.value = true;
        }
    };

    const onFilesClear = () => {
        disabledComp.value = true;
        fileCounter.value = 0;
    };

    return { onFileUpload, onFileRemove, onFilesClear, fileCounter };
};