
<template>
    <div class="col-8 lg:col-6 xl:col-2 cursor-pointer" :class="{ 'hovered': hover }" style="width: 12.5vw;"
        @mouseover="hover = true" @mouseleave="hover = false">
        <div class="surface-card shadow-2  pt-4 border-1 border-50 border-round relative" style="height: 11rem;">
            <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center border-round" :class="iconColor"
                    style="width:2.5rem;height:2.5rem">
                    <i :class="icons" :style="{ fontSize: iconSize }"></i>
                </div>
            </div>
            <div class="mb-3 w-full mt-3">
                <div>
                    <div class="flex justify-content-center w-full">
                        <span class="block text-500 mb-3">{{ heading }}</span>
                    </div>
                    <div>
                        <div class="text-900 font-medium text-xl">{{( typeof description === 'number' ? animatedDescription : description)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ref, watchEffect } from 'vue';

const hover = ref(false);
const animatedDescription = ref(0);
let animationInterval;

const props = defineProps({
    icons: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    heading: {
        type: String,
        required: true
    },
    iconColor: {
        type: String,
        default: 'blue-500'
    },
    iconSize: {
        type: String,
        default: 'xl'
    }
});

watchEffect(() => {
    clearInterval(animationInterval);

    const targetValue = parseInt(props.description);
    let currentValue = 0;

    const step = Math.ceil(targetValue / 30);

    animationInterval = setInterval(() => {
        currentValue += step;

        if (currentValue >= targetValue) {
            animatedDescription.value = targetValue;
            clearInterval(animationInterval);
        } else {
            animatedDescription.value = currentValue;
        }
    }, 60);
});

</script>
  
    
<style scoped>
.hovered {
    width: 15.7rem;
    transform: scale(1.04);
    transition: 0.1s ease-in-out;
}
</style>
    