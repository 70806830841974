<template>
  <div class="flex justify-content-between w-full mb-3 reports-filter-container">
    <div>
      <h3>Filters</h3>
    </div>
    <div class="flex align-items-center gap-4">
      <p class="cursor-pointer" @click="resetFilters">Reset</p>
      <Button class="cursor-pointer text-white" @click="generateReport" label="Apply
              Filters" />
    </div>
  </div>
  <Divider class="-mt-2" />
  <div>
    <div class="card">
      <Accordion :activeIndex="0">
        <AccordionTab header="Date Range">
          <div class="text-start">
            <label for="dd-reference">Filter By</label>
            <Dropdown inputId="dd-reference" v-model="selectedDateType" style="width: 100%;" showClear
              :options="dateTypes" option-label="name" placeholder="Date type" />
          </div>
          <div class="text-start mt-2">
            <label>
              Date Range
            </label>
          </div>
          <div class="mb-2 text-start">
            <Calendar v-model="dates" selection-mode="range" ref="calender" placeholder="Select a date" class="w-full"
              :panel-style="{
                width: '300px',
              }" :max-date="moment().toDate()" @date-select="emit('change')">
              <template #footer>
                <div class="flex flex-wrap gap-2">
                  <Button v-for="(preset, index) in datePresets" :key="index" style="padding: 2px 7px; font-size: 11px"
                    @click="selectRange(preset)">
                    {{ preset.name }}
                  </Button>
                </div>
              </template>
            </Calendar>
          </div>
        </AccordionTab>
      </Accordion>
      <Accordion :activeIndex="0">
        <AccordionTab header="Category">
          <div class="text-start">
            <label for="dd-emissor">Select an Emisor</label>
            <MultiSelect v-model="selectedEmisor" display="chip" :options="emisors" :loading="isFiltersLoading"
              @change="handleEmisorChange" :class="{ 'opacity-50 bg-gray-200': disableEmisor }" optionLabel="name"
              class="w-full" style="height: 3.1rem;" :disabled="disableEmisor" />
            <span v-if="selectedEmisor.length > 1" class="label">+{{ selectedEmisor.length > 1 ?
              selectedEmisor.length - 1 :
              null }}</span>
          </div>
          <div class="p-float-label mt-5"
            v-tooltip.left="disableReferences && !selectedReference ? 'Reference is disabled' : 'Select Reference'"
            style="">
            <label for="dd-reference">Select a Reference</label>
            <Dropdown inputId="dd-reference" v-model="selectedReference" :loading="isFiltersLoading"
              :showClear="!!selectedReference" @change="handleReferenceChange" editable :options="references"
              option-label="name" placeholder="Select a reference" class="w-full "
              :class="{ 'opacity-50 bg-gray-200': disableReferences }" :disabled="disableReferences" />
          </div>
          <Panel header="Filter Notes" class="mt-5">
            <p>
              You can only select one filter at a time, either Reference or Emisor.
              <br>
              ➥ If "Emisor" is selected then "Reference" selector will be disabled.
              <br>
              ➥ If "Reference" is selected then "Emisor" selector will be disabled.
            </p>
          </Panel>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed, watchEffect, onMounted } from "vue";
import Repository from "@repositories/RepositoryFactory";
import { useUserSessionStore } from '@/store/userSession';
import { storeToRefs } from 'pinia';
import { serviceStore } from "@/store/serviceStore";
import { useToast } from "primevue/usetoast";
import moment from "moment"
import { useApi } from "../../../hooks/useApi";
import { useUserActivityStore } from "../../store/userActivity/store";
import { datePresets } from '../../utils/presets'

const toast = useToast();
const userActivityStore = useUserActivityStore()

const TypeRoute = {
  ALL: 'All',
  UNKNOWN: 'Unknown',
  MULTIPLE: 'MultipleMatches',
  MATCHES: 'Matches',
  SINGLE: 'Single',
  CLOSED: 'Closed'
};

const userSession = useUserSessionStore();
const resultsStores = serviceStore()
const { userName, organization } = storeToRefs(userSession);
const user = ref(userName.value);
const calender = ref()

const dateTypes = ref([
  { name: 'Invoice Date', value: 'fecha' },
  { name: 'Job Date', value: 'creation_date' },
]);

const selectedDateType = ref(dateTypes.value.find((c) => c.value === 'creation_date'));

const references = ref([
  { name: 'All', code: TypeRoute.ALL },
  { name: 'Unknown', code: TypeRoute.UNKNOWN },
  { name: 'MultipleMatches', code: TypeRoute.MULTIPLE },
  { name: 'Matches', code: TypeRoute.MATCHES },
  { name: 'Closed', code: TypeRoute.CLOSED },
]);

const emisors = ref([]);
const selectedReference = ref({ name: 'All', code: TypeRoute.ALL });
const selectedEmisor = ref('')
const disableReferences = ref(true);
const disableEmisor = ref(true);
const loading = ref(false);
const isFiltersLoading = ref(false);
const hideSelection = ref(false)
// const userActivityResponse = ref({
//   statusCode: '',
//   presigned_zip_url: ''
// });
const userActivityResponse = userActivityStore.userActivityResponse
// const dialogHeight = ref("60vh")

const ResultsRepository = Repository.get("results");

// const { formatCurrency } = useFormatCurrency();
// const { openInNewTab } = useOpenInNewTab();
// const detailPageTitle = ref();
// const detailXmlLink = ref();
// const detailPdfLink = ref();
// const referenceCategories = ref([])
// const pdfLoading = ref(false);
const dropdownData = ref()
// const isUserTyping = ref(false)
const initiallyLoading = ref(false)

const generateReport = () => {
  userActivityStore.isError = null
  getReport()
  initiallyLoading.value = false
}

const dates = ref([new Date(), new Date()]);

const selectRange = (item) => {
  dates.value = item.getvalue()
  calender.value.overlayVisible = false
}

const selectedDateTypeValue = computed(() => {
  if (!dates.value || dates.value?.length === 0) return null;
  return selectedDateType.value?.value
}, [dates, selectedDateType])

const maxDate = new Date();

const startDate = computed(() => {
  let value = dates.value ? dates.value[0] : null
  if (value) {
    return moment(value).format("YYYY-MM-DD")
  }
  return null
}, dates)

const endDate = computed(() => {
  let value = dates.value ? dates.value[1] : null
  if (value) {
    return moment(value).format("YYYY-MM-DD")
  }
  return null
}, dates)

const isInRange = (dateObj) => {
  if (!dates.value || dates.value.length < 2 || dateObj.otherMonth) return false;

  const startDate = moment(new Date(dates.value[0]));
  const endDate = moment(new Date(dates.value[1]));
  const currentDate = moment([dateObj.year, dateObj.month, dateObj.day]);

  return currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
}

onBeforeMount(async () => {
  await getReferencesDropdownList();
});

onBeforeUnmount(() => {
  clearTimeout(exportReportTimeout.value)
})


const handleReferenceChange = () => {
  if (selectedReference.value) {
    disableEmisor.value = true;
  } else {
    disableEmisor.value = false;
    disableReferences.value = false;

  }
}
const handleEmisorChange = () => {
  if (selectedEmisor.value?.length) {
    disableReferences.value = true;
  } else {
    disableEmisor.value = false;
    disableReferences.value = false;
  }
}

const getReferencesDropdownList = async () => {

  try {
    userActivityStore.isFiltersLoading = true;
    await resultsStores.getFilterData(organization.value, false)
    const newReferences = resultsStores.matchedReferences?.options?.map((item, index) => ({
      name: item,
      code: index
    }));

    const newEmisors = resultsStores.emirosFilterData?.options?.map((item, index) => ({
      name: item,
      code: index
    }));

    references.value = [...references.value, ...newReferences];
    emisors.value = [...newEmisors]
    disableEmisor.value = false;
    disableReferences.value = false;
    userActivityStore.isFiltersLoading = false;
  } catch (error) {
    throw new Error(error, 'Error for reference dropdown')
  } finally {
    userActivityStore.isFiltersLoading = false;
  }
};

const resetFilters = () => {
  disableEmisor.value = false;
  disableReferences.value = false;
  selectedReference.value = "";
  selectedEmisor.value = ''
  selectedDateType.value = dateTypes.value.find((c) => c.value === 'creation_date')
  dates.value = null
}

const validNames = [
  TypeRoute.ALL,
  TypeRoute.UNKNOWN,
  TypeRoute.MULTIPLE,
  TypeRoute.CLOSED,
  TypeRoute.MATCHES
];

const isSingleReferenceSelected = () => {
  return validNames.includes(selectedReference.value?.name || selectedReference.value);
}


const shouldShowExportButton = ref(false)

const getReport = async () => {
  shouldShowExportButton.value = true;
  toast.removeAllGroups();
  resultsStores.isLoading = false

  if (isSingleReferenceSelected() || selectedEmisor.value.length) {
    if (!selectedDateTypeValue.value && !!selectedDateType.value) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Please select a date range",
        life: 1500,
      });
      return;
    }
  }

  clearTimeout(exportReportTimeout.value)
  exportingReport.value = false
  exportModalVisible.value = false
  exportReportRes.value = {
    presigned_zip_url: '',
    status: -1
  }

  if (!selectedEmisor.value.length) userActivityStore.emisorColumn = false

  if (selectedEmisor.value.length) {
    await getEmisorReport()
    return;
  } else if (selectedReference.value.name === TypeRoute.ALL) {
    await getUserActivityOverview();
    return;
  } else if (selectedReference.value.name === TypeRoute.UNKNOWN) {
    await getUnknownReferencesForUser();
    return;
  } else if (selectedReference.value.name === TypeRoute.MULTIPLE) {
    await getMultipleReferencesInvoicesForUser();
    return;
  } else if (selectedReference.value.name === TypeRoute.CLOSED) {
    await getClosedReferencesForUser();
    return;
  } else if (selectedReference.value.name === TypeRoute.MATCHES) {
    await getMatchedReferencesForUser();
    return;
  } else {
    await getReferencesForUser();
    return;
  }
};

const getEmisorReport = async () => {
  try {
    loading.value = true;
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    let emisorList = []
    selectedEmisor.value?.map((item) => {
      emisorList.push(item.name)
    })
    const payload = {
      organization: organization.value,
      emisors: emisorList,
      start_date: startDate.value,
      date_filter_col: selectedDateTypeValue.value,
      end_date: endDate.value,
    }
    userActivityStore.emisorColumn = true
    await resultsStores.filterUserData(payload)
    if (resultsStores.filteredData.message) {
      toast.add({
        severity: "info",
        summary: "Emisor Report",
        detail: resultsStores.filteredData.message,
        life: 3000,
      });
    }
    userActivityStore.referencesActivityList = resultsStores.filteredData.results
    userActivityStore.tableResponse = resultsStores.filteredData.results
    userActivityStore.referencesListAll = resultsStores.filteredData.results
    userActivityResponse.statusCode = resultsStores.filteredData.statusCode;
    userActivityResponse.export_zip_name = resultsStores.filteredData.export_zip_name;

  } catch (error) {
    resetFilters()
  } finally {
    loading.value = false
  }
}

const getUserActivityOverview = async () => {
  try {
    resultsStores.isLoading = true;
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    const params = {
      email: userName.value,
      user: userName.value,
      organization: organization.value,
      start_date: startDate.value,
      date_filter_col: selectedDateTypeValue.value,
      end_date: endDate.value,
    };

    const {
      data: reportResponseData,
      error: errorRef,
      loading: loadingRef,
      fetchItems: getReportOverview
    } = useApi('/user/activity', params);

    await getReportOverview();

    resultsStores.isLoading = false;

    if (reportResponseData.value && reportResponseData.value.message) {
      toast.add({
        severity: "info",
        summary: "User activity report",
        detail: reportResponseData.value.message,
        life: 3000,
      });
    }

    if (reportResponseData.value) {
      userActivityResponse.statusCode = reportResponseData.value.statusCode;
      userActivityResponse.export_zip_name = reportResponseData.value.export_zip_name;
      userActivityStore.referencesActivityList = reportResponseData.value.results;
      userActivityStore.tableResponse = reportResponseData.value.results;
      userActivityStore.referencesListAll = reportResponseData.value.results;
    }

    userActivityStore.isError = errorRef.value;
  } catch (error) {
    throw new Error(error)
  } finally {
    resultsStores.isLoading = false;
  }
};

const getUnknownReferencesForUser = async () => {
  try {
    resultsStores.isLoading = true;
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    const params = {
      email: user.value,
      user: user.value,
      organization: organization.value,
      start_date: startDate.value,
      date_filter_col: selectedDateTypeValue.value,
      end_date: endDate.value,
    };

    const {
      data: unknownReferenceResponse,
      error: errorRef,
      loading: loadingRef,
      fetchItems: getUnKnownReferences
    } = useApi('/user/Unknown', params);

    await getUnKnownReferences()
    userActivityStore.isError = errorRef.value
    if (unknownReferenceResponse.value.results.length === 0) {
      toast.add({
        severity: "info",
        summary: "Unknown References Report",
        detail: "No results found",
        life: 3000,
      });
    }

    userActivityStore.isError = errorRef.value
    if (unknownReferenceResponse.value.message) {
      toast.add({
        severity: "info",
        summary: "Unknown References Report",
        detail: unknownReferenceResponse?.value?.message,
        life: 3000,
      });
    }

    userActivityResponse.statusCode = unknownReferenceResponse.value?.statusCode;
    userActivityResponse.export_zip_name = unknownReferenceResponse.value?.export_zip_name;
    userActivityStore.referencesActivityList = unknownReferenceResponse.value?.results;
    userActivityStore.tableResponse = unknownReferenceResponse.value?.results;
    userActivityStore.referencesListAll = unknownReferenceResponse.value?.results
    userActivityStore.isError = errorRef.value
  } catch (error) {
    throw new Error(error)
  } finally {
    resultsStores.isLoading = false;
  }

};

const getClosedReferencesForUser = async () => {
  try {
    userActivityStore.isError = ''
    resultsStores.isLoading = true;
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    const params = {
      email: user.value,
      user: user.value,
      organization: organization.value,
      start_date: startDate.value,
      date_filter_col: selectedDateTypeValue.value,
      end_date: endDate.value,
    };

    const {
      data: closedReferenceResponse,
      error: errorRef,
      loading: loadingRef,
      fetchItems: getUnKnownReferences
    } = useApi('/user/Closed', params);

    await getUnKnownReferences()
    userActivityStore.isError = errorRef.value
    if (closedReferenceResponse.value.results.length === 0) {
      toast.add({
        severity: "info",
        summary: "Unknown References Report",
        detail: "No results found",
        life: 3000,
      });
    }

    userActivityStore.isError = errorRef.value
    if (closedReferenceResponse.value.message) {
      toast.add({
        severity: "info",
        summary: "Unknown References Report",
        detail: closedReferenceResponse?.value?.message,
        life: 3000,
      });
    }

    userActivityResponse.statusCode = closedReferenceResponse.value?.statusCode;
    userActivityResponse.export_zip_name = closedReferenceResponse.value?.export_zip_name;
    userActivityStore.referencesActivityList = closedReferenceResponse.value?.results;
    userActivityStore.tableResponse = closedReferenceResponse.value?.results;
    userActivityStore.referencesListAll = closedReferenceResponse.value?.results
    userActivityStore.isError = errorRef.value
  } catch (error) {
    throw new Error(error)
  } finally {
    resultsStores.isLoading = false;
  }

};
const getMatchedReferencesForUser = async () => {
  try {
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    resultsStores.isLoading = true;
    const reportResponse = await ResultsRepository.getMatchedReferencesByUser({
      params: {
        email: user.value,
        user: user.value,
        organization: organization.value,
        start_date: startDate.value,
        date_filter_col: selectedDateTypeValue.value,
        end_date: endDate.value,
      }
    });
    if (reportResponse.data.message) {
      toast.add({
        severity: "info",
        summary: "Matched References Report",
        detail: reportResponse.data.message,
        life: 3000,
      });
    }

    userActivityResponse.statusCode = reportResponse.data.statusCode;
    userActivityResponse.export_zip_name = reportResponse.data.export_zip_name;
    userActivityStore.referencesActivityList = reportResponse.data.results;
    userActivityStore.tableResponse = reportResponse.data.results;
    userActivityStore.referencesListAll = reportResponse.data.results

  } catch (error) {
    throw new Error(error)
  } finally {
    resultsStores.isLoading = false;
  }
};

const getReferencesForUser = async () => {
  try {
    loading.value = true;
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''
    const payload = {
      organization: organization.value,
      reference: selectedReference.value.name
    }
    await resultsStores.filterUserData(payload)

    if (resultsStores?.filteredData?.results?.length === 0) {
      toast.add({
        severity: "info",
        summary: "Multiple References Report",
        detail: "No results found",
        life: 3000,
      });
    }

    userActivityStore.referencesActivityList = resultsStores.filteredData.results
    userActivityStore.tableResponse = resultsStores.filteredData.results
    userActivityStore.referencesListAll = resultsStores.filteredData.results
    userActivityResponse.statusCode = resultsStores.filteredData.statusCode;
    userActivityResponse.export_zip_name = resultsStores.filteredData.export_zip_name;

  } catch (error) {
    throw new Error('getReferencesForUser', error)
  } finally {
    loading.value = false;
  }
};

const getMultipleReferencesInvoicesForUser = async () => {
  try {
    resultsStores.isLoading = true;
    userActivityStore.isError = null
    userActivityStore.referencesActivityList = ''
    userActivityStore.tableResponse = ''

    const params = {
      email: userName.value,
      user: userName.value,
      organization: organization.value,
      reference: selectedReference.value.name,
      start_date: startDate.value,
      date_filter_col: selectedDateTypeValue.value,
      end_date: endDate.value
    }

    const {
      data: reportResponseData,
      error: errorRef,
      loading: loadingRef,
      fetchItems: getMultipleReferencesInvoicesForUser
    } = useApi('/user/MultipleMatches', params);

    await getMultipleReferencesInvoicesForUser()

    userActivityStore.isError = errorRef.value
    if (reportResponseMultiples.data.results.length === 0) {
      toast.add({
        severity: "info",
        summary: "Multiple References Report",
        detail: "No results found",
        life: 3000,
      });
    }

    if (reportResponseMultiples.data.message) {
      toast.add({
        severity: "info",
        summary: "Multiple References Report",
        detail: reportResponseMultiples.data.message,
        life: 3000,
      });
    }
    userActivityResponse.statusCode = reportResponseMultiples.data.statusCode;
    userActivityResponse.presigned_zip_url = reportResponseMultiples.data.presigned_zip_url;
    userActivityStore.referencesActivityList = reportResponseMultiples.data.results;
    userActivityStore.tableResponse = reportResponseMultiples.data.results;
    userActivityStore.referencesListAll = reportResponseMultiples.data.results

  } catch (error) {
    throw new Error('getMultipleReferencesInvoicesForUser', error)
  } finally {
    resultsStores.isLoading = false;
  }
};

const exportingReport = ref(false);
const exportModalVisible = ref(false)
const exportReportRes = ref({
  presigned_zip_url: '',
  status: -1
})

const exportReportTimeout = ref(null)


onMounted(async () => {
  userActivityStore.isError = null
  if (resultsStores?.referenceCategories?.options) {
    userActivityStore.subcategory = Object.values(
      resultsStores.referenceCategories.options
    ).map((item) => ({ name: item }));
    dropdownData.value = userActivityStore.subcategory
  } else {
    userActivityStore.subcategory = [];
    dropdownData.value = []
  }
  initiallyLoading.value = true
  await getReport()
})

</script>

<style scoped>
.p-datatable {
  width: 100%
}

:deep(.p-multiselect-token-label) {
  font-size: 10px !important;
  width: 4rem !important;
  overflow: hidden !important;
}

:deep(.p-multiselect-token) {
  margin-top: 4px;
}


:deep(.p-datatable-tbody) {
  font-size: 14px !important;
}

:deep(.p-dialog-mask) {
  background-color: #808080b0 !important;
}

:deep(.p-fieldset-content) {
  padding: 0 !important;
}

.label {
  position: absolute;
  right: 7.09rem;
  top: 0.6rem;
  font-size: 12px;
  background-color: #dee2e6;
  padding: 6px 9px;
  border-radius: 50%;
}

.selectDateButton {
  position: absolute;
  top: -4rem;
  left: 8.5rem;
}
</style>