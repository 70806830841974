<template>
    <i v-if="!tagItem.tag"
        v-tooltip.top="tagItem.tag === null || tagItem.tag == '' ? 'No tag match found' : 'Feature Invoice Tags is not retroactive. Tags will be processed for new invoices only.'"
        class="pi pi-question-circle cursor-pointer"></i>
    <div v-else>
        <Chip v-if="tagItem['tag'].meta !== 'failure' && tagItem['tag'].meta !== 'no_issuer_tags_found'"
            class="cursor-pointer" @click="toggleMoreInfo(tagItem['tag'])" :class="{
                'bg-green-300': tagItem['tag'].meta === 'match',
                'bg-yellow-300': tagItem['tag'].meta === 'default',
            }">
            {{ tagItem['tag'].value ? tagItem['tag'].value : 'Failure' }} <i v-if="tagItem['tag'].meta === 'default'"
                v-tooltip="'Default tags, Click here for more info'" class="pi pi-question-circle ml-2"
                style="padding-top: 2px;"></i>
        </Chip>
        <Chip v-if="tagItem['tag'].meta === 'failure' || tagItem['tag'].meta === 'no_issuer_tags_found'"
            class="cursor-pointer" v-tooltip.right="'Click here  for more info'" @click="toggleMoreInfo(tagItem['tag'])"
            :class="{
                'bg-red-300': tagItem['tag'].meta === 'failure' || tagItem['tag'].meta === 'no_issuer_tags_found'
            }">
            <span class="flex justify-content-center" style="min-width: 6rem;">
                {{ tagItem['tag'].meta === 'failure' ? 'Failure' : 'No Issuer' }} <i class="pi pi-question-circle ml-2"
                    style="padding-top:4px;"></i>
            </span>
        </Chip>
    </div>
</template>
<script setup>
defineProps(['tagItem'])
import { infoToggle } from '@/composables/useUtils'
import { serviceStore } from '../../store/serviceStore';


const store = serviceStore()

const toggleMoreInfo = (data) => {
    if (data.meta === 'failure') {
        const failureInfo = {
            head: 'Failed to Extract',
            message: 'Failed to extract tag AND we have NO default TagName setup, you can manually assign a tag for this invoices'
        }
        infoToggle(store.infoDrawer, failureInfo, true)
    } else if (data.meta === 'default') {
        const failureInfo = {
            head: 'Default Tag',
            message: `Failed to extract tag, BUT assigned ${data.value} as a Default tag`
        }
        infoToggle(store.infoDrawer, failureInfo, true)
    } else if (data.meta === 'no_issuer_tags_found') {
        const failureInfo = {
            head: 'No Issuer data found',
            message: "There's isn't any issuer data found in uploaded Tags file"
        }
        infoToggle(store.infoDrawer, failureInfo, true)
    }
}
</script>
