<template>
  <div>
    <ConfirmDialogVue
      @confirm="handleNextInvoice"
      :labelSummary="labelSummary"
      :currentIndex="currentIndex"
    />
    <div class="container h-screen mt-8 w-full" v-if="loading">
      <div class="flex">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
      <h1 class="text-center uppercase mt-4">Loading Invoice Labelling</h1>
    </div>
    <div v-else class="pb-5 -pt-7">
      <div class="flex justify-content-between px-4">
        <div class="flex align-items-center">
          <h1>Invoice Labelling</h1>
          <i
            class="pi pi-info-circle text-2xl ml-4 text-blue-500 cursor-pointer"
          ></i>
        </div>
        <div class="flex align-items-center">
          <div v-if="!pdfOnlyStore.labellingDone" class="search-container">
            <ElasticSearch />
          </div>
          <i
            class="pi pi-times-circle text-2xl cursor-pointer ml-4"
            @click="
              resultsStores.isDataAvailable
                ? (resultsStores.pdfConfirmationModal = true)
                : (resultsStores.pdfVerificationModel = false)
            "
          ></i>
        </div>
      </div>
      <div v-if="pdfOnlyStore.labellingDone">
        <div class="flex justify-content-center w-full text-center">
          <div class="bg-white mx-auto border-round mt-8 py-5">
            <div class="flex justify-content-center">
              <div class="" style="padding: 7rem 0">
                <img src="../assets/success.gif" alt="" style="width: 60rem" />
              </div>
            </div>
            <h2 class="-mt-8">
              Labeling process for this invoice has been completed
            </h2>
            <Button
              label="Go back"
              icon="pi pi-arrow-left"
              @click="emits('submitChanges')"
            />
          </div>
        </div>
      </div>
      <div v-else class="flex -mt-4">
        <Splitter class="border-2 w-full mt-6 mx-5">
          <SplitterPanel
            :size="70"
            :minSize="45"
            class="flex align-items-center justify-content-center"
          >
            <div
              class="px-5"
              style="min-width: 55vw; max-height: 85vh; overflow-y: scroll"
            >
              <div class="surface-section">
                <div
                  class="flex align-items-center md:justify-content-between flex-column md:flex-row pb-4 surface-border"
                >
                  <div
                    class="surface-section px-6 py-5 w-full flex justify-content-between align-items-center"
                  >
                    <div
                      class="flex align-items-start flex-column lg:flex-row lg:justify-content-between"
                    >
                      <div
                        class="flex align-items-start flex-column md:flex-row"
                      >
                        <div>
                          <span
                            v-tooltip.top="
                              resultsStores.labelingPageHeader.invoiceName
                            "
                            class="text-900 font-medium cursor-pointer text-3xl"
                            >{{
                              resultsStores.labelingPageHeader.invoiceName
                            }}</span
                          >
                          <i
                            class="pi pi-file-pdf text-2xl ml-4 text-yellow-500"
                          ></i>
                          <div
                            class="flex align-items-center flex-wrap text-sm"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      v-if="!pdfOnlyStore.jobData.is_new_template"
                      class="text-lg"
                    >
                      <p>
                        <b> Name : &nbsp;</b>
                        <span
                          class="bg-orange-400 shadow-1 px-4 py-1 border-round text-white"
                        >
                          {{ pdfOnlyStore.jobData.name }}</span
                        >
                      </p>
                      <p>
                        <b>Tax ID : &nbsp;</b>
                        <span
                          class="bg-orange-400 px-4 shadow-1 py-1 border-round text-white"
                        >
                          {{ pdfOnlyStore.jobData.tax_id }}</span
                        >
                      </p>
                    </div> -->
                    <div>
                      <Tag
                        v-if="pdfOnlyStore.jobData.is_new_template"
                        class="text-sm px-3 py-2"
                      >
                        New Billing Party
                        <i
                          v-tooltip.top="'Click for More Info'"
                          @click="
                            infoToggle(
                              resultsStores.infoDrawer,
                              dataFiles,
                              true
                            )
                          "
                          class="pi pi-info-circle ml-2 cursor-pointer"
                        ></i>
                      </Tag>
                    </div>
                  </div>
                </div>
                <div v-if="!pdfOnlyStore.labellingDone" class="px-3 py-3">
                  <ProgressBar
                    :value="
                      (currentIndex / labelSummary?.failure_invoices) * 100
                    "
                  >
                    {{ currentIndex }} /
                    {{ labelSummary?.failure_invoices }}
                  </ProgressBar>
                  <div>
                    <p class="w-full flex justify-content-between">
                      <b class="mr-2 bg-orange-300 py-1 px-3 border-round">0</b
                      ><b>Labelling Process</b
                      ><b class="mr-2 bg-green-300 py-1 px-3 border-round">
                        {{ labelSummary?.failure_invoices }}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="flex justify-content-center mt-4"
                style="min-height: 90vh"
                v-if="currentPdf"
              >
                <Pdfviwer
                  v-if="testRender"
                  :pdfUrl="currentPdf"
                  :coordinatesData="currentCoordinates"
                />
              </div>
              <div
                class="container flex h-screen mt-8 w-full"
                style="min-width: 50vw"
                v-if="pdfLoading || !currentPdf"
              >
                <ProgressSpinner
                  style="width: 50px; height: 50px"
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                  aria-label="Loading"
                />
              </div>
              <div
                v-if="currentPdf === undefined"
                class="container-height border-round"
              >
                <div class="bg-white w-6 mx-auto border-round mt-8 py-5">
                  <img src="../assets/images.png" alt="" />
                  <h2>Oops, something went wrong.!</h2>
                  <h4>
                    We are unable to find this file. Please report this with the
                    details below:
                  </h4>
                </div>
              </div>
            </div>
          </SplitterPanel>
          <SplitterPanel :size="30" class="flex">
            <div class="pl-4 label-containers p-5 w-full">
              <div>
                <InfoMessage
                  v-if="pdfOnlyStore.infoMessage.status !== 'none'"
                  :info-message="pdfOnlyStore.infoMessage"
                />
                <div
                  v-if="resultsStores.elasticSearchResult"
                  class="shadow-2 p-4 border-round mt-5"
                >
                  <p>
                    <b>Selected Issuer Name :</b>
                    {{ resultsStores.elasticSearchResult.name }}
                  </p>
                  <p>
                    <b>Issuer ID :</b>
                    {{ resultsStores.elasticSearchResult.issuer_id }}
                  </p>
                </div>
                <div class="form-container py-4 px-2 border-round">
                  <div class="mt-7">
                    <div class="card flex justify-content-center -mt-7">
                      <Stepper
                        orientation="vertical"
                        :activeStep="pdfOnlyStore.activeStep"
                      >
                        <StepperPanel>
                          <template #header="{ active, index }">
                            <p
                              class="border-gray-300 cursor-pointer border-1 border-circle mr-4 px-2 text-lg"
                              :class="{ 'bg-blue-600 text-white': active }"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              1
                            </p>
                            <p
                              class="text-xl italic cursor-pointer"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              <b>Vendor</b
                              ><span
                                v-if="
                                  selectedRecommendation ||
                                  pdfOnlyStore.jobData.name
                                "
                                class="ml-2 bg-orange-300 border-round px-2"
                                ><b>Name</b> :
                                {{ pdfOnlyStore.jobData.name }}</span
                              ><span
                                v-if="pdfOnlyStore.jobData.tax_id"
                                class="ml-2 bg-indigo-300 border-round px-2"
                                ><b>Tax ID</b>:{{
                                  pdfOnlyStore.jobData.tax_id
                                }}</span
                              >
                            </p>
                          </template>
                          <template #content="{ index }">
                            <div
                              class="bg-blue-50 shadow-2 my-3 p-4 border-round"
                            >
                              <div class="flex flex-column bg-blue">
                                <ValidatedInput
                                  :isSelected="isSelected"
                                  inputKey="name"
                                  inputLabel="Name"
                                  :isDisabled="
                                    !pdfOnlyStore.jobData.is_new_template
                                  "
                                  @handleInputChange="handleInputChange"
                                />
                                <ValidatedInput
                                  :isSelected="isSelected"
                                  :isDisabled="
                                    !pdfOnlyStore.jobData.is_new_template
                                  "
                                  class="mt-2"
                                  inputKey="tax_id"
                                  inputLabel="Tax ID"
                                  @handleInputChange="handleInputChange"
                                />
                              </div>
                              <div
                                class="flex pt-4 justify-content-between align-items-end"
                              >
                                <div>
                                  <span
                                    ><b
                                      v-if="
                                        pdfOnlyStore.jobData.is_new_template
                                      "
                                      class="mb-2"
                                      >Vendor Recommendations</b
                                    ></span
                                  >
                                  <Dropdown
                                    v-model="selectedRecommendation"
                                    :options="vendorRecommendations"
                                    optionLabel="name"
                                    placeholder="Select Recommendation"
                                    class="w-24rem"
                                    @change="
                                      handleRecommendation(
                                        selectedRecommendation
                                      )
                                    "
                                  />
                                </div>
                                <div>
                                  <Button
                                    label="Next"
                                    icon="pi pi-arrow-right"
                                    iconPos="right"
                                    @click="pdfOnlyStore.activeStep = index + 1"
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </StepperPanel>
                        <StepperPanel
                          v-if="
                            pdfOnlyStore.jobData.is_new_template &&
                            resultsStores.elasticSearchResult == ''
                          "
                        >
                          <template #header="{ active, index }">
                            <p
                              class="border-gray-300 cursor-pointer border-1 border-circle mr-4 px-2 text-lg"
                              :class="{ 'bg-blue-600 text-white': active }"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              2
                            </p>
                            <p
                              class="text-xl italic cursor-pointer"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              <b>Vendor Information</b>
                            </p>
                          </template>
                          <template #content="{ index }">
                            <div
                              class="bg-blue-50 shadow-2 my-3 p-4 border-round"
                            >
                              <div class="flex flex-column bg-blue">
                                <NonValidatedInputs
                                  v-for="(input, index) in noneRequiredInputs"
                                  :key="index"
                                  :isSelected="isSelected"
                                  :inputKey="input.inputKey"
                                  :inputLabel="input.inputLabel"
                                  @handleInputChange="handleInputChange"
                                />
                              </div>
                              <div class="flex pt-4 justify-content-between">
                                <Button
                                  label="Back"
                                  severity="secondary"
                                  icon="pi pi-arrow-left"
                                  @click="pdfOnlyStore.activeStep = index - 1"
                                />
                                <Button
                                  label="Next"
                                  icon="pi pi-arrow-right"
                                  iconPos="right"
                                  @click="pdfOnlyStore.activeStep = index + 1"
                                />
                              </div>
                            </div>
                          </template>
                        </StepperPanel>
                        <StepperPanel>
                          <template #header="{ active, index }">
                            <p
                              class="border-gray-300 cursor-pointer border-1 border-circle mr-4 px-2 text-lg"
                              :class="{ 'bg-blue-600 text-white': active }"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              {{
                                pdfOnlyStore.jobData.is_new_template &&
                                resultsStores.elasticSearchResult == ""
                                  ? 3
                                  : 2
                              }}
                            </p>
                            <p
                              class="text-xl italic cursor-pointer"
                              @click="pdfOnlyStore.activeStep = index"
                            >
                              <b>Invoice Details</b>
                            </p>
                          </template>
                          <template #content="{ index }">
                            <div
                              class="bg-blue-50 shadow-2 my-3 p-4 border-round"
                            >
                              <div class="flex flex-column bg-blue">
                                <ValidatedInput
                                  :isSelected="isSelected"
                                  inputKey="invoice_number"
                                  inputLabel="Invoice Number"
                                  @handleInputChange="handleInputChange"
                                />
                                <ValidatedInput
                                  :isSelected="isSelected"
                                  inputKey="total"
                                  inputLabel="Total"
                                  @handleInputChange="handleInputChange"
                                />
                                <ValidatedInput
                                  :isSelected="isSelected"
                                  inputKey="currency"
                                  inputLabel="Currency"
                                  @handleInputChange="handleInputChange"
                                />
                                <div class="flex flex-column w-full relative">
                                  <label for="invoice_date" class="text-start">
                                    <p>Invoice Date</p>
                                  </label>
                                  <Calendar
                                    v-model="pdfOnlyStore.jobData.invoice_date"
                                    showIcon
                                    :maxDate="new Date()"
                                    @click="
                                      pdfOnlyStore.highlightedField =
                                        'invoice_date'
                                    "
                                    dateFormat="dd/mm/yy"
                                    class="w-full border-1 border-round border-0 -mt-3"
                                    aria-describedby="username-help"
                                    @input="handleInputChange('date')"
                                  />
                                </div>
                              </div>
                              <div
                                v-if="
                                  pdfOnlyStore.jobData.is_new_template &&
                                  resultsStores.elasticSearchResult == ''
                                "
                                class="flex pt-4 justify-content-start"
                              >
                                <Button
                                  label="Back"
                                  severity="secondary"
                                  icon="pi pi-arrow-left"
                                  @click="pdfOnlyStore.activeStep = index - 1"
                                />
                              </div>
                            </div>
                          </template>
                        </StepperPanel>
                      </Stepper>
                    </div>
                  </div>
                </div>
                <div>
                  <Divider class="my-5" />
                  <div class="mt-2 flex justify-content-center w-full my-3">
                    <SubmitButton
                      :payload="payload"
                      :trxId="trxId"
                      @validateJobData="validateJobData"
                      :currentIndex="currentIndex"
                      :missingFields="missingFields"
                      :labelSummary="labelSummary"
                      :isLastIndex="isLastIndex"
                      :jobDetail="jobDetail"
                      @handleNext="handleNextInvoice"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, onMounted } from "vue";
import { useApi } from "../../hooks/useApi";
import { serviceStore } from "@/store/serviceStore";
import Pdfviwer from "@/components/Pdfviewer.vue";

import ElasticSearch from "@components/ElasticSearch/ElasticSearch.vue";
import { usePdfOnlyStore } from "@/store/pdf-only/store";

const trxId = ref();
const jobDetail = ref();
const isSelected = ref([]);
const currentIndex = ref(0);
const payload = ref([]);
const testRender = ref(true);
const loading = ref(false);
const labelSummary = ref(null);
import { useToast } from "primevue/usetoast";
import moment from "moment";
const vendorRecommendations = ref([]);
const missingFields = ref([]);

const currentPdf = ref(null);
const currentCoordinates = ref({});
const pdfLoading = ref(false);
const isError = ref(null);
const selectedRecommendation = ref(null);
import { infoToggle } from "@/composables/useUtils";
import SubmitButton from "./Pdf-only/SubmitButton.vue";
const resultsStores = serviceStore();
const emits = defineEmits(["submitChanges"]);
import ValidatedInput from "@/components/UserActivity/ValidatedInput.vue";
import NonValidatedInputs from "./UserActivity/NonValidatedInputs.vue";
import InfoMessage from "./Pdf-only/InfoMessage.vue";
import ConfirmDialogVue from "./Pdf-only/ConfirmDialog.vue.vue";

const toast = useToast();
const props = defineProps({
  jobId: {
    type: String,
    required: true,
  },
});

const handleRecommendation = (item) => {
  const key = item["name"];
  pdfOnlyStore.jobData.vendor_recommendations = {
    issuer_id: item["code"],
    issuer_name: key,
  };
  pdfOnlyStore.jobData.name = key;
  selectedRecommendation.value = key;
  pdfOnlyStore.activeStep = 2;
};

const noneRequiredInputs = [
  // { inputKey: "tax_id", inputLabel: "Tax ID" },
  { inputKey: "address", inputLabel: "Address" },
  { inputKey: "phone_number", inputLabel: "Phone Number" },
  { inputKey: "website", inputLabel: "Website" },
  { inputKey: "email", inputLabel: "Email" },
];

const dataFiles = {
  head: "New Billing Party",
  message:
    "A new billing party has been found, You need to fill company details",
};

const pdfOnlyStore = usePdfOnlyStore();

const handleNextInvoice = () => {
  handleNext();
};

const handleNext = () => {
  const jobDetails = Object.values(jobDetail.value);
  pdfOnlyStore.activeStep = 0;
  const currentIndexValue = currentIndex.value;
  delete pdfOnlyStore.jobData.vendor_recommendations;

  if (jobDetails.length && currentIndexValue < jobDetails.length - 1) {
    currentIndex.value++;
    const data = jobDetails[currentIndexValue + 1];
    const currentInvoiceFileName = data.id;
    const existingChangeIndex = payload.value.findIndex(
      (item) => item.id === currentInvoiceFileName
    );

    if (existingChangeIndex !== -1) {
      jobDetailsList(payload.value[existingChangeIndex]);
      getPdf(payload.value[existingChangeIndex]);
    } else {
      pdfOnlyStore.coordinatesData = "";
      pdfOnlyStore.coordinatesData = getCoordinates(data?.coordinates);
      jobDetailsList(data);
      getPdf(data);
    }
    resultsStores.labelingPageHeader.currentIndex = currentIndex.value;
    selectedRecommendation.value = null;
  }
};

const jobDetailsList = (data) => {
  pdfOnlyStore.jobData.currency = data?.currency || "";
  pdfOnlyStore.jobData.tax_id = data?.tax_id;
  pdfOnlyStore.jobData.invoice_date = data.invoice_date
    ? moment(data?.invoice_date).format("DD-MM-YY")
    : "";
  pdfOnlyStore.jobData.invoice_number = data?.invoice_number;
  pdfOnlyStore.jobData.name = data?.name || "";
  pdfOnlyStore.jobData.total = data?.total
    ? Number(parseFloat(data?.total).toFixed(2))
    : null;
  pdfOnlyStore.jobData.s3_path = data.s3_path;
  pdfOnlyStore.jobData.is_new_template = data?.is_new_template;
  pdfOnlyStore.jobData.phone_number = data?.phone_number || "";
  pdfOnlyStore.jobData.email = data?.email || "";
  pdfOnlyStore.jobData.issuer_db_id = data?.issuer_db_id || "";
  pdfOnlyStore.jobData.address = data?.address || "";
  pdfOnlyStore.jobData.website = data?.website || "";
  vendorRecommendations.value =
    data && data.recommendations
      ? Object.entries(data.recommendations).map(([name, code]) => ({
          name,
          code,
        }))
      : [];
  resultsStores.pdfLabelHeader = data?.name;
  resultsStores.labelingPageHeader.invoiceName = data?.filename
    ? data?.filename?.substring(0, 31) + "..."
    : data?.name?.substring(0, 31) + "...";
  resultsStores.labelingPageHeader.isNewCompany = data?.is_new_template;
};

const isLastIndex = ref(0); // Add this line

const getJobDetails = async () => {
  try {
    loading.value = true;
    pdfOnlyStore.payload = [];
    pdfOnlyStore.activeStep = 0;
    pdfOnlyStore.infoMessage.status = "none";
    pdfOnlyStore.coordinatesData = "";
    resultsStores.isDataAvailable = false;
    const params = {
      job_id: trxId.value,
    };
    const {
      data: response,
      loading: loadingRef,
      error: errorRef,
      fetchItems: getJobDetail,
    } = useApi("/invoices/hitl/pdf-only", params);

    await getJobDetail();
    isError.value = errorRef.value;
    loading.value = loadingRef.value;
    if (response.value?.results?.pdf_label_data?.length) {
      pdfOnlyStore.labellingDone = false;
      resultsStores.isDataAvailable = true;
      resultsStores.labelingPageHeader.summary =
        response.value?.results?.pdf_label_summary;
      labelSummary.value = response.value?.results?.pdf_label_summary || [];
      jobDetail.value = response.value.results.pdf_label_data.map((detail) => ({
        ...detail,
        edited: false,
      }));

      isLastIndex.value = jobDetail.value.length - 1;
      const data = jobDetail.value[currentIndex.value];
      pdfOnlyStore.coordinatesData = data?.coordinates
        ? getCoordinates(data?.coordinates)
        : null;
      await jobDetailsList(data);
      await getPdf(data);
    } else {
      pdfOnlyStore.labellingDone = true;
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    loading.value = false;
  }
};

const getCoordinates = (inputJson) => {
  if (inputJson) {
    const output = {};
    Object.keys(inputJson).forEach((key) => {
      if (inputJson[key].length > 0) {
        const item = inputJson[key][0];
        output[key] = {
          page_num: item.page_num,
          bbox: item.bbox,
          color:
            key === "invoice_number"
              ? "#00ffff45"
              : key === "total"
              ? "#ff00004f"
              : key === "invoice_date"
              ? "#ff9b004f"
              : "#dfdfdf9c",
          value: item.value,
        };
      }
    });
    return output;
  }
};

const getPdf = async (data) => {
  try {
    testRender.value = false;
    currentPdf.value = "";
    pdfLoading.value = true;
    const params = {
      invoice: data.s3_path,
    };
    const {
      data: downloadLinks,
      loading: loadingRef,
      error: errorRef,
      fetchItems: getDownloadLinks,
    } = useApi("/api/download/", params);
    await getDownloadLinks();
    currentPdf.value = downloadLinks.value.pdf_download_url;
    pdfLoading.value = loadingRef.value;
    isError.value = errorRef.value;
  } catch (error) {
    pdfLoading.value = false;
    throw new Error(error);
  } finally {
    pdfLoading.value = false;
    testRender.value = true;
  }
};

watchEffect(() => {
  if (typeof resultsStores.elasticSearchResult === "object") {
    pdfOnlyStore.activeStep = 0;
  }
});

const handleInputChange = (item) => {
  if (!isSelected.value.includes(item) && item !== "total") {
    isSelected.value.push(item);
  }
  if (item == "name") {
    delete pdfOnlyStore.jobData.vendor_recommendations;
  }
};

onMounted(async () => {
  trxId.value = props.jobId;
  resultsStores.elasticSearchResult = "";
  await getJobDetails();
});
</script>
<style scoped>
.input {
  height: 2.5rem;
}

.label-container {
  display: flex;
  flex-direction: column;
  width: 26vw;
}

.text-start {
  text-align: start;
}

.sticker {
  position: absolute;
  padding: 0 0.6rem;
  border-radius: 5px;
  right: 1rem;
  top: 4rem;
}

.text-start {
  text-align: start;
}
.italic {
  font-style: italic;
  /* doted border buttom with light gray color */
  border-bottom: 2px dotted lightgray;
}

.icon {
  color: red;
  font-size: 1.3rem;
  font-weight: 100;
  position: absolute;
  right: 10px;
  top: 3.45rem;
}

.form-container {
  max-height: 65vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.p-stepper {
  flex-basis: 50rem;
}

.form-container::-webkit-scrollbar {
  width: 0.5em;
}

.form-container::-webkit-scrollbar-thumb {
  background-color: rgb(148, 147, 147);
}

.form-container::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
