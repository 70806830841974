<template>
  <Sidebar
    v-model:visible="resultsStores.pdfVerificationModel"
    header="Sidebar"
    position="full"
  >
    <template #container="">
      <div class="overflow-y-scroll">
        <div class="z-1">
          <Dialog
            v-model:visible="resultsStores.pdfConfirmationModal"
            modal
            header="Warning"
            :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
          >
            <p>
              You will lose all unsaved changes if you close this page. Are you
              sure you want to proceed?
            </p>
            <div class="w-full flex justify-content-end">
              <Button
                label="Quit"
                class="mr-2 px-5"
                severity="danger"
                @click="
                  (resultsStores.pdfVerificationModel = false),
                    (resultsStores.pdfConfirmationModal = false)
                "
              />
              <Button
                label="Continue Editing"
                @click="resultsStores.pdfConfirmationModal = false"
              />
            </div>
          </Dialog>
          <VerificationComponent
            :jobId="props.currentJobId"
            @submitChanges="resultsStores.pdfVerificationModel = false"
          />
        </div>
      </div>
    </template>
  </Sidebar>
</template>
<script setup>
import VerificationComponent from "../../components/VerificationComponent.vue";

import { serviceStore } from "@/store/serviceStore";
const props = defineProps({
  currentJobId: {
    type: String,
    required: true,
  },
});

const resultsStores = serviceStore();
</script>
