<template>
  <div class="w-full flex align-items-center elastic-search-wrapper">
    <p><b>Search Issuer :&nbsp;</b></p>
    <p>
      <InputGroup>
        <AutoComplete
          style="width: 40rem;"
          placeholder="Get Issuer Directly"
          v-model="searchInputValue"
          field="name"
          @change="loading = true"
          optionLabel="name"
          :suggestions="searchResults"
          @complete="fetchResults"
          @item-select="onSelect"
          @clear="store.elasticSearchResult"
          :virtualScrollerOptions="{ itemSize: 38 }"
        >
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ slotProps.option._source.name }}</div>
            </div>
          </template>
        </AutoComplete>
        <i
          v-if="loading"
          class="pi pi-spinner pi-spin absolute text-2xl"
          style="z-index: 1000; right: 10rem; margin-top: 0.7rem"
        ></i>
        <InputGroupAddon>
          <i
            class="pi pi-info-circle text-2xl ml-2 cursor-pointer"
            v-tooltip.left="'Click for More Info'"
            @click="infoToggle(store.infoDrawer, dataFiles, true)"
          ></i>
        </InputGroupAddon>
      </InputGroup>
    </p>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect } from "vue";
import axios from "axios";
import debounce from "lodash.debounce";
import { serviceStore } from "@/store/serviceStore";
import { infoToggle } from "../../composables/useUtils";

const searchInputValue = ref("");
const searchResults = ref([]);
const store = serviceStore();
const loading = ref(false);

const url = import.meta.env.VITE_ELASTIC_SEARCH_URL;
const apiKey = import.meta.env.VITE_ELASTIC_SEARCH_API_KEY;
const dataFiles = {
  head: "Elastic Search ",
  message: "You can use this SearchBox for the following reasons",
  list: [
    "If you seen an incorrect “Tax-ID’, fix it by searching the correct one",
    "If you have seen this invoice issuer or template before, you can skip this invoice simply by searching for the issuer directly,.",
  ],
  footer: "This is an intelligent search box meant to help you save time 😀",
};

const fetchElasticSearchResults = debounce(async (query) => {
  try {
    const response = await axios.post(
      `${url}/templates-vectordb-dev/_search`,
      {
        size: 10,
        query: {
          bool: {
            must: [
              {
                multi_match: {
                  query: query,
                  type: "bool_prefix",
                  fields: ["name", "name._2gram", "name._3gram", "tax_id"],
                },
              },
              {
                term: {
                  app: "pdf-only",
                },
              },
            ],
          },
        },
        highlight: {
          fields: {
            name: { number_of_fragments: 2, type: "unified" },
            tax_id: { number_of_fragments: 1, type: "unified" },
          },
        },
        collapse: {
          field: "issuer_id",
        },
        _source: ["name", "tax_id", "issuer_id", "app"],
      },
      {
        headers: {
          Authorization: `ApiKey ${apiKey}`,
        },
      }
    );
    searchResults.value = response.data.hits.hits;
  } catch (error) {
    console.error("Error fetching search results:", error);
  } finally {
    loading.value = false;
  }
}, 600);

watch(
  searchInputValue,
  (newValue, oldValue) => {
    if (newValue === newValue) return;
    if (newValue === "") {
      searchResults.value = [];
    } else {
      fetchElasticSearchResults(newValue);
    }
  },
  { deep: true }
);

const fetchResults = (event) => {
  if (event.query.trim() === "") {
    searchResults.value = [];
  } else {
    fetchElasticSearchResults(event.query);
  }
};

watchEffect(() => {
  if (store.elasticSearchResult === "") {
    searchInputValue.value = "";
    loading.value = false;

  }
   if (typeof store.elasticSearchResult === 'object') {
    loading.value = false;
   }
});

const onSelect = (event) => {
  store.elasticSearchResult = event.value._source;
  searchInputValue.value = event.value._source.name;
};
</script>

<style>
.elastic-search-wrapper .p-autocomplete {
  width: 100%;
}

.elastic-search-wrapper .p-autocomplete-empty-message {
  text-align: center;
}
</style>
