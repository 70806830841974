<template>
  <div class="relative">
    <div class="flex justify-content-between align-items-center mt-2 px-4">
      <div class="flex align-items-center gap-4">
        <h2 class="text-start ml-5">Ap Check</h2>
        <ButtonGroup>
          <router-link to="/ap-check" ><Button   class="border-noround border-round-left"  label="Results"  /></router-link>
          <router-link to="/ap-check/issues" ><Button outlined  class="border-noround border-round-right"   label="Issues"  /></router-link>
        </ButtonGroup>
      </div>
      
      <div class="flex gap-2" >
        <Button
          label="Ap Check Status Template"
          style="height: 3.5rem"
          icon="pi pi-download"
          text
          @click="downloadApCheckStatusTemplate"
        />
        <UploadModal @uploaded="fileUploaded" />
      </div>
    </div>
    <Divider style="width: 98%" class="mx-3" />
    <div class="flex w-full p-4 -mt-4">
      <div
        class="border-1 surface-border border-round shadow-1 px-3 py-2"
        style="width: 25%; z-index: 100"
      >
        <ApCheckFilter
          @applyFilters="applyFilters"
          @resetFilters="resetFilters"
        />
      </div>
      <div
        v-if="jobsStore.jobsTransactionList && !jobsStore.jobListLoading"
        class="grid grid-cols-4 gap-3 pb-2 overflow-y-auto w-full custom-scrollbar pl-1 pr-4 pt-3"
      >
        <ApCheckTable
          @onPage="onPage"
          :invoicesList="invoicesList"
          :loading="loading"
        />
      </div>
    </div>
    <PdfLabelling :isActive="confirmationModel" :currentJobId="currentJobId" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import PdfLabelling from "../components/Jobs/PdfLabelling.vue";
import { useJobStore } from "../store/jobsStore/store";
import axiosClient from "../repositories/Clients/AxiosClient";
import ApCheckFilter from "@/components/ApCheck/ApCheckFilter.vue";
import ApCheckTable from "../components/ApCheck/ApCheckTable.vue";
import UploadModal from "../components/ApCheck/UploadModal.vue";

const jobsStore = useJobStore();
const userSession = useUserSessionStore();
const { organization } = storeToRefs(userSession);

const currentJobId = ref(null);
const confirmationModel = ref(false);
const loading = ref(false);
const invoicesList = ref([]);
const invoiceListUnMuted = ref([]);
const lastKey = ref();

onMounted(() => {
  getAPCheck();
});

const onPage = (event) => {
  if (event.page === event.pageCount - 1 && lastKey.value?.last_evaluated_key) {
    getAPCheck(lastKey.value);
  }
};

const fileUploaded = () => {
  getAPCheck();
};

const getAPCheck = async (item) => {
  lastKey.value = "";
  loading.value = true;
  const params = {
    organization: organization.value,
    page_limit: 20,
  };
  if (item?.last_evaluated_key) {
    params.last_evaluated_key = item.last_evaluated_key;
  }
  axiosClient
    .get("/apcheck/list", {
      params: params,
    })
    .then((response) => {
      invoicesList.value = response?.data?.data || [];
      invoiceListUnMuted.value = response?.data?.data || [];
      if (item?.last_evaluated_key) {
        const newResponse = response.data?.data;
        invoicesList.value = [...invoicesList.value, ...newResponse];
        invoiceListUnMuted.value = [...invoicesList.value, ...newResponse];
      } else {
        invoicesList.value = response.data?.data || [];
        invoiceListUnMuted.value = response.data?.data || [];
      }
      if (response.data) {
        lastKey.value = response.data;
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const applyFilters = (filtersData) => {
  const matchesFilter = (invoice, filters) => {
    for (const key in filters) {
      if (filters[key]) {
        if (
          !invoice[key] ||
          filters[key].toString().toLowerCase() !==
            invoice[key].toString().toLowerCase()
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const filteredInvoices = invoiceListUnMuted.value.filter((invoice) =>
    matchesFilter(invoice, filtersData)
  );
  invoicesList.value = filteredInvoices;
};

const resetFilters = () => {
  invoicesList.value = invoiceListUnMuted.value;
};


const downloadApCheckStatusTemplate = () => {
  const link = `https://junctionnet-logisticshub-apps-pub-resources.s3.amazonaws.com/ap-check/AP-Check-Status-Template.xlsx`
  window.open(link, "_blank","noreferrer");
}

</script>

<style scoped>
.p-datatable {
  width: 100%;
}

.viewDetailButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.manually-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.no-close-button .p-dialog-header .p-dialog-header-icons) {
  display: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.no-data-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.p-datatable {
  width: 100% !important;
}
:deep(.p-datatable-loading-overlay) {
  background-color: rgb(255 255 255 / 79%);
}
:deep(.p-datatable-wrapper) {
  height: calc(100vh - 255px) !important;
}
:deep(.p-datatable tr .p-datatable-wrapper) {
  height: auto !important;
}

:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage)) {
  cursor: pointer;
}
:deep(.p-datatable .p-datatable-tbody tr:not(.p-datatable-emptymessage):hover) {
  background-color: #f2f2f2;
}

:deep(.p-paginator-first) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-last) {
  display: none;
  visibility: hidden;
}
:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}
:deep(.p-datatable-thead) {
  height: 6rem;
}
</style>
