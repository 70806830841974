<template>
  <div class="relative">
    <canvas ref="pdfCanvas"></canvas>
    <div ref="textLayer" class="textLayer"></div>
    <div v-if="totalPages > 1" class="pagination">
      <!-- Navigation -->
      <Button @click="goToPreviousPage"
        icon="pi pi-chevron-left" rounded
        outlined/>
      <span class="mt-2">Page {{ currentPage }} of {{ totalPages }}</span>
      <Button
        @click="goToNextPage"
        :disabled="currentPage >= totalPages"
        icon="pi pi-chevron-right"
        rounded
        outlined
        aria-label="Filter"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, nextTick } from "vue";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { usePdfOnlyStore } from "@/store/pdf-only/store";

const pdfOnlyStore = usePdfOnlyStore();
const textLayer = ref(null);
const currentPage = ref(1);
const totalPages = ref(0);

const coordinatesData = pdfOnlyStore.coordinatesData
  ? JSON.parse(JSON.stringify(pdfOnlyStore.coordinatesData))
  : null;

let tooltipDiv = null;

const props = defineProps({
  pdfUrl: { type: String, required: true },
});

const pdfCanvas = ref(null);

function removeAllHighlights() {
  if (props.pdfUrl) {
    return new Promise((resolve) => {
      const pdfCanvasElement = pdfCanvas.value;
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = pdfCanvasElement?.width;
      offscreenCanvas.height = pdfCanvasElement?.height;
      const offscreenContext = offscreenCanvas.getContext("2d");

      // Re-render the PDF without highlights to the offscreen canvas
      pdfjsLib.getDocument(props.pdfUrl).promise.then((pdf) => {
        pdf.getPage(currentPage.value).then((page) => {
          // Get the viewport without explicitly setting the rotation
          const viewport = page.getViewport({ scale: 1.5 });
          offscreenCanvas.width = viewport.width;
          offscreenCanvas.height = viewport.height;
          const renderContext = { canvasContext: offscreenContext, viewport };
          page.render(renderContext).promise.then(() => {
            // Swap the offscreen canvas with the visible canvas
            const canvasContext = pdfCanvasElement?.getContext("2d");
            canvasContext?.clearRect(
              0,
              0,
              pdfCanvasElement?.width,
              pdfCanvasElement?.height
            );
            canvasContext?.drawImage(offscreenCanvas, 0, 0);
            resolve(); // Resolve the promise when done
          });
        });
      });
    });
  }
}

let renderTask = null;
let isRendering = false;

const renderPdf = async (page = 1) => {
  if (isRendering) {
    console.warn("A rendering task is already in progress.");
    return;
  }

  const pdfUrl = props.pdfUrl;
  if (pdfUrl) {
    isRendering = true;
    if (renderTask) {
      renderTask.cancel();
      try {
        await renderTask.promise;
      } catch (e) {
        if (e.name !== "RenderingCancelledException") {
          console.error("Error cancelling the rendering task:", e);
        }
      }
      renderTask = null;
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    totalPages.value = pdf._pdfInfo.numPages;
    const pageObj = await pdf.getPage(page);
    const viewport = pageObj.getViewport({ scale: 1.5 });
    const canvasContext = pdfCanvas.value?.getContext("2d");

    pdfCanvas.value.height = viewport.height;
    pdfCanvas.value.width = viewport.width;
    const textLayerDiv = textLayer.value;
    textLayerDiv.style.height = `${viewport.height}px`;
    textLayerDiv.style.width = `${viewport.width}px`;
    textLayerDiv.style.setProperty("--scale-factor", "1.5");

    const renderContext = {
      canvasContext,
      viewport,
    };

    renderTask = pageObj.render(renderContext);
    try {
      await renderTask.promise;
      renderTask = null;

      const textContent = await pageObj.getTextContent();
      textLayerDiv.innerHTML = "";
      pdfjsLib
        .renderTextLayer({
          textContentSource: textContent,
          container: textLayerDiv,
          viewport,
          textDivs: [],
        })
        .promise.then(() => {
          if (pdfOnlyStore.coordinatesData) {
            highlightArea(canvasContext, coordinatesData?.total, 1.5);
            highlightArea(canvasContext, coordinatesData?.invoice_number, 1.5);
            highlightArea(canvasContext, coordinatesData?.currency, 1.5);
            highlightArea(canvasContext, coordinatesData?.invoice_date, 1.5);
            textLayerDiv.addEventListener("mousemove", handleMouseMove);
            textLayerDiv.addEventListener("click", handleMouseMove);
          }
        });
    } catch (e) {
      if (e.name !== "RenderingCancelledException") {
        console.error("Rendering failed", e);
      }
    } finally {
      isRendering = false;
    }
  }
};

const goToNextPage = async () => {
  if (currentPage.value < totalPages.value && !isRendering) {
    currentPage.value++;
    pdfOnlyStore.highlightedField = "";
    pdfOnlyStore.selectedHighlight = "";
    await renderPdf(currentPage.value);
  }
};

const goToPreviousPage = async () => {
  if (currentPage.value > 1 && !isRendering) {
    currentPage.value--;
    pdfOnlyStore.highlightedField = "";
    pdfOnlyStore.selectedHighlight = "";
    await renderPdf(currentPage.value);
  }
};

onMounted(() => {
  renderPdf();
});

function highlightArea(ctx, area, scale) {
  if (area?.page_num === currentPage.value - 1) {
    const padding = 4;
    const scaledPadding = padding * scale; // Adjust padding based on the scale

    // Adjust bbox coordinates based on the scale
    const x1 = area?.bbox[0] * scale;
    const y1 = area?.bbox[1] * scale;
    const x2 = area?.bbox[2] * scale;
    const y2 = area?.bbox[3] * scale;

    ctx.fillStyle = area?.color;
    ctx.fillRect(
      x1 - scaledPadding,
      y1 - scaledPadding,
      x2 - x1 + 2 * scaledPadding,
      y2 - y1 + 2 * scaledPadding
    );
  }
}

function handleMouseMove(event) {
  const canvasRect = pdfCanvas.value?.getBoundingClientRect();
  const x = event.clientX - canvasRect.left;
  const y = event.clientY - canvasRect.top;

  if (tooltipDiv) tooltipDiv.remove();

  const highlightedArea = getHighlightedArea(x, y);
  if (highlightedArea) {
    tooltipDiv = createTooltip(
      event.clientX,
      event.clientY - 30,
      highlightedArea
    );
    document.body.appendChild(tooltipDiv);
    // Change cursor to pointer when hovering over a highlighted area
    pdfCanvas.value.style.cursor = "pointer";
  } else {
    // Reset cursor to auto when not hovering over a highlighted area
    pdfCanvas.value.style.cursor = "auto";
  }

  // Check if the event is a click event
  if (event.type === "click") {
    // alert(`${highlightedArea} has been selected.`);
    pdfOnlyStore.selectedHighlight = highlightedArea;
  }
}

function getHighlightedArea(x, y) {
  for (const [area, details] of Object.entries(coordinatesData)) {
    const scaledBbox = details.bbox.map((coord) => coord * 1.5);
    if (
      x >= scaledBbox[0] &&
      x <= scaledBbox[2] &&
      y >= scaledBbox[1] &&
      y <= scaledBbox[3]
    ) {
      return { area, details };
    }
  }
  return null;
}

async function highlightTotal(item) {
  const canvasContext = pdfCanvas.value?.getContext("2d");
  const bbox = coordinatesData[item]?.bbox;
  if (coordinatesData[item].page_num !== currentPage.value - 1) return;
  const padding = 2;
  const scale = 1.5; // Use the same scale factor as used for rendering the PDF

  // Wait for all highlights to be removed
  await removeAllHighlights();

  // Re-apply all highlights
  for (const area of Object.values(coordinatesData)) {
    highlightArea(canvasContext, area, scale);
  }

  // Apply the new border with scaling
  const scaledPadding = padding * scale;
  const x1 = bbox?.[0] * scale - scaledPadding;
  const y1 = bbox?.[1] * scale - scaledPadding;
  const x2 = bbox?.[2] * scale + scaledPadding;
  const y2 = bbox?.[3] * scale + scaledPadding;

  canvasContext.strokeStyle = "black"; // Set border color to black
  canvasContext.setLineDash([2, 2]); // Set border to dotted
  canvasContext.lineWidth = 1;
  canvasContext.strokeRect(x1, y1, x2 - x1, y2 - y1);
}

function createTooltip(left, top, text) {
  const tooltip = document.createElement("div");
  tooltip.textContent = `${
    text.area === "invoice_number"
      ? "Invoice Number"
      : text.area === "invoice_date"
      ? "Invoice Date"
      : text.area === "total"
      ? "Total"
      : text.area === "currency"
      ? "Currency"
      : ""
  }  : ${text.details.value}`;
  tooltip.style.cssText = `position: absolute; left: ${left}px; top: ${top}px; background: #f1f1f1;font-size : 20px;padding: 7px; border-radius: 5px; z-index: 9999;`;
  return tooltip;
}

watchEffect(async () => {
  if (pdfOnlyStore.highlightedField && pdfOnlyStore.coordinatesData) {
    const highlightedFieldData =
      pdfOnlyStore.coordinatesData[pdfOnlyStore.highlightedField];

    if (highlightedFieldData && highlightedFieldData.page_num !== undefined) {
      const targetPage = highlightedFieldData.page_num + 1;

      if (currentPage.value !== targetPage) {
        currentPage.value = targetPage;

        // Cancel and clear the current rendering task if it exists
        if (renderTask) {
          renderTask.cancel();
          try {
            await renderTask.promise;
          } catch (e) {
            if (e.name !== "RenderingCancelledException") {
              console.error("Error cancelling the rendering task:", e);
            }
          }
          renderTask = null;
        }

        // Wait for the next tick to ensure all updates to reactive properties are processed
        await nextTick();

        // Render the new page
        await renderPdf(currentPage.value);
      }

      if (!renderTask) {
        highlightTotal(pdfOnlyStore.highlightedField);
      }
    }
  }
});
</script>
<style>
.textLayer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: transparent;
}

.pagination {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}
</style>
