<template>
    <i v-if="reference" v-tooltip.top="'Preview file'" class="pi pi-eye mr-4 text-2xl cursor-pointer"
        @click="getXlsxFile(keyFile)"></i>
    <Button v-if="searchInvoice" label="View key file" icon="pi pi-eye" @click="getXlsxFile(keyFile)" class="mt-3"
        outlined style="height: 3rem !important" />
    <Button v-if="currentRef" label="Download Keyfile template" class="mr-4" outlined severity="warning"
        icon="pi pi-download" @click="openInNewTab(keyFileUrl)" />
    <Button v-if="currentRef" label="View Current References" icon="pi pi-eye" @click="viewCurrentReferences" />
    <Dialog @hide="cleanGetXlsxFileResponse" v-model:visible="xlsxFilePreviewDialog" modal :header="xlsxListHeader"
        :style="{ width: '60vw' }" maximizable @maximize="handleMaximize" @unmaximize="handleMinimize">
        <div class="flex justify-content-start">
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText icon="pi pi-eye" v-model="searchQuery" placeholder="Search reference" />
            </span>
        </div>
        <hr>
        <div v-if="getXlsxFileLoading" class="flex items-center justify-content-center">
            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
                animationDuration=".5s" aria-label="Loading" />
        </div>
        <div v-else class="flex justify-content-center">
            <hot-table ref="hotTableRef" :style="`width:${!isModalMaximize ? '100%' : '100vw'}`"
                :height="!isModalMaximize ? 492 : '80vh'" :filters='true' :colWidths="columnWidth"
                :colHeaders="extractedColumns" :hiddenColumns="{
        indicators: true
    }" :multiColumnSorting="true" :rowHeaders="true" :manualRowMove="true" :data="getFilteredData" search
                licenseKey="non-commercial-and-evaluation">
            </hot-table>
        </div>
    </Dialog>
</template>
<script setup>
import { computed, reactive, ref } from 'vue';
import { useApi } from '../../../hooks/useApi';
import { HotTable } from '@handsontable/vue3';
import 'handsontable/dist/handsontable.full.css';
import { useOpenInNewTab } from '../../composables/useUtils';

const { openInNewTab } = useOpenInNewTab()

const isModalMaximize = ref(false)
const hotTableRef = ref(null);
const searchQuery = ref('')
const keyFileUrl = ref(import.meta.env.VITE_KEYFILE_TEMPLATE_FILE_URL)
const getXlsxFileLoading = ref(false);
const xlsxListHeader = ref('')
const xlsxFilePreviewDialog = ref(false)
const isError = ref()
const columnWidth = ref([140, 90, 220, 150, 90, 150, 90])
const getXlsxFileResponse = reactive({
    statusCode: 200,
    xlsx_data: []
});
const extractedColumns = ref([]);

const props = defineProps(['keyFile', 'organization', 'reference', 'currentRef', 'searchInvoice'])

const cleanGetXlsxFileResponse = () => {
    getXlsxFileResponse.statusCode = 200;
    getXlsxFileResponse.xlsx_data = [];
    hotTableRef.value = null
}

const handleMaximize = () => {
    isModalMaximize.value = !isModalMaximize.value
    columnWidth.value = [200, 150, 420, 250, 150, 320, 150]
}
const handleMinimize = () => {
    isModalMaximize.value = !isModalMaximize.value
    columnWidth.value = [140, 90, 220, 150, 90, 150, 90]

}

const getFilteredData = computed(() => {
    const data = getXlsxFileResponse.xlsx_data;
    const searchValue = searchQuery.value;

    if (!searchValue) {
        if (hotTableRef.value) {
            const hotInstance = hotTableRef.value.hotInstance;
            hotInstance.loadData(data);
        }
        return data;
    }

    // Filter the data based on the search query matching the "Reference" property.
    const filteredData = data.filter((item) => item.Reference ? item.Reference.toLowerCase().includes(searchValue?.toLowerCase()) : item.reference.toLowerCase().includes(searchValue?.toLowerCase()));

    if (hotTableRef.value) {
        const hotInstance = hotTableRef.value.hotInstance;
        if (filteredData.length) {
            hotInstance.loadData(filteredData);
        } else {
            filteredData.push({
                "Reference": "",
                "HBL": '',
                "Contenedor": "No Data Found",
                "MBL": '',
                "TARJA": '',
                "PEDIMENTO": '',
                "Open/closed": "Open"
            })
            hotInstance.loadData(filteredData);
        }
    }

    return filteredData;
});

const getXlsxFile = async (reference_s3_filename) => {
    cleanGetXlsxFileResponse()
    xlsxFilePreviewDialog.value = false;
    columnWidth.value = [140, 90, 220, 150, 90, 150, 90]
    isModalMaximize.value = false
    searchQuery.value = ''
    xlsxListHeader.value = 'Xlsx File Preview'
    const params = {
        reference_s3_filename: reference_s3_filename,
        organization: props.organization.value
    }

    const {
        data: xlsDataResponse,
        error: errorRef,
        loading: loading,
        fetchItems: getXlsxFileData
    } = useApi('/reference/xlsx-file', params)

    isError.value = errorRef.value
    xlsxFilePreviewDialog.value = true;
    getXlsxFileLoading.value = true;
    if (typeof getXlsxFileData === 'function') {
        await getXlsxFileData()
    }
    const xlsxData = xlsDataResponse.value?.xlsx_data;

    if (Array.isArray(xlsxData) && xlsxData.length > 0) {
        for (const key in xlsxData[0]) {
            if (Object.prototype.hasOwnProperty.call(xlsxData[0], key)) {
                extractedColumns.value.push(key);
            }
        }
    }
    getXlsxFileResponse.statusCode = xlsDataResponse.value?.statusCode;
    getXlsxFileResponse.xlsx_data = xlsDataResponse.value?.xlsx_data;
    getXlsxFileLoading.value = loading.value;

}

const viewCurrentReferences = async () => {
    searchQuery.value = ''
    xlsxListHeader.value = 'Current References'
    const {
        data: getCurrentReferences,
        error: errorRef,
        loading: loadingRef,
        fetchItems: getReferences
    } = useApi('/reference/status')
    xlsxFilePreviewDialog.value = true;
    getXlsxFileLoading.value = true;
    errorRef.value = isError.value
    if (typeof getReferences === 'function') {
        await getReferences();
    }
    const xlsxData = getCurrentReferences.value?.reference_db_status;

    if (Array.isArray(xlsxData) && xlsxData.length > 0) {
        for (const key in xlsxData[0]) {
            if (Object.prototype.hasOwnProperty.call(xlsxData[0], key)) {
                extractedColumns.value.push(key);
            }
        }
    }

    getXlsxFileResponse.statusCode = getCurrentReferences.value?.statusCode;
    getXlsxFileResponse.xlsx_data = getCurrentReferences.value?.reference_db_status;
    getXlsxFileLoading.value = loadingRef.value;

}



</script>
