import { defineStore } from "pinia";

export const useIssuesStore = defineStore("issues", {
  state: () => ({
    issues: [],
    currentIssueId: null,
    columns: [
      {
        label: 'New',
        status: 'NEW',
        items: []
      },
      {
        label: 'Todo',
        status: 'TODO',
        items: []
      },
      {
        label: 'In Progress',
        status: 'IN_PROGRESS',
        items: []
      },
      {
        label: 'Done',
        status: 'DONE',
        items: []
      },
    ]
  }),

  getters: {
    currentIssue: (state) => {
      return state.issues.find(
        (issue) => issue.invoice_issue_id === state.currentIssueId
      );
    },
    isCurrentIssueTotalMisMatch: (state) => {
      const issue = state.issues.find(
        (issue) => issue.invoice_issue_id === state.currentIssueId
      );
      if (issue) {
        return issue.ap_check_status.includes("mismatched");
      }
      return false;
    }
  },

  actions: {
    setColumns(columns) {
      this.columns = columns;
    },
    updateList() {
      this.columns = this.columns.map((c) => {
        c.items = this.issues.filter((i) => i.status === c.status);
        return c;
      })
    },
    addComment({issueId, comment, attachments, user}) {
      const issue = this.getIssueById(issueId);
      if (!issue) return console.warn("Issue not found");
      const notes = issue.notes || {};
      notes[`${user}@${new Date().toISOString()}`] = {
        comment,
        attachments,
        user,
      };
      console.log(notes,'notes');
      this.updateIssue({ invoice_issue_id: issueId, notes });
    },
    removeCurrentIssueId() {
      this.currentIssueId = null;
    },
    setCurrentIssueId(invoice_issue_id) {
      this.currentIssueId = invoice_issue_id;
    },

    setIssues(issues) {
      this.issues = issues;
    },

    addIssue(issue) {
      this.issues.push(issue);
    },

    removeIssue(invoice_issue_id) {
      this.issues = this.issues.filter(
        (issue) => issue.invoice_issue_id !== invoice_issue_id
      );
    },

    clearIssues() {
      this.issues = [];
    },

    getIssueById(invoice_issue_id) {
      return this.issues.find(
        (issue) => issue.invoice_issue_id === invoice_issue_id
      );
    },

    addIssues(issues) {
      this.issues.push(...issues);
    },

    updateIssue(updatedIssue) {
      const index = this.issues.findIndex(
        (issue) => issue.invoice_issue_id === updatedIssue.invoice_issue_id
      );
      if (index !== -1) {
        this.issues[index] = { ...this.issues[index], ...updatedIssue };
      }
    },
  },
});
