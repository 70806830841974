<template>
  <template v-if="isAdmin">
    <slot></slot>
  </template>
  <template v-else>
    <p>Access denied. You must be an admin to view this page.</p>
  </template>
</template>

<script setup>
import { computed } from "vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";

const userSession = useUserSessionStore();
const { userGroups } = storeToRefs(userSession);

const isAdmin = computed(() => {
  return userGroups.value.includes("OrganizationAdministrators");
});
</script>
