import { reactive, toRefs } from "vue";
import client from "../src/repositories/Clients/AxiosClient"; // import the configured client


export function useApi(endpoint, params) {
  const state = reactive({
    data: null,
    error: null,
    loading: false,
  });

  const handleRequest = async (request) => {
    state.loading = true;
    state.error = null;

    try {
      const response = await request();
      state.data = response.data;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  };

  const getRequestConfig = () => (params ? { params } : {});

  const fetchItems = () =>
    handleRequest(() => client.get(endpoint, getRequestConfig()));
  const postItem = (payload) =>
    handleRequest(() => client.post(endpoint, payload, getRequestConfig()));
  const putItem = (payload) =>
    handleRequest(() => client.put(endpoint, payload, getRequestConfig()));
    const deleteItem = (body) =>
    handleRequest(() => client.delete(endpoint, { data: body }));

  return {
    ...toRefs(state),
    fetchItems,
    postItem,
    putItem,
    deleteItem,
  };
}
