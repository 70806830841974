import FilesRepository from "@repositories/FilesRepository";
import ResultsRepository from "@repositories/ResultsRepository";
import UploaderRepository from "@repositories/UploaderRepository";
import JobsRepository from "@repositories/JobsRepository";
import EmailRepository from "@repositories/EmailRepository";
import ApCheckInvoiceIssues from "@repositories/ApCheckInvoiceIssues";
import EmisorConfigurationRepository from "@repositories/EmisorConfigurationRepository";

const repositories = {
    'files': FilesRepository,
    'results': ResultsRepository,
    'uploader': UploaderRepository,
    'jobs': JobsRepository,
    'email': EmailRepository,
    "emisorConfiguration": EmisorConfigurationRepository,
    'ApCheckInvoiceIssues': ApCheckInvoiceIssues
}
export default {
    get: name => repositories[name]
};