<template>
  <div>
    <div class="surface-section px-4 pt-5 md:px-6 lg:px-8">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border"
      >
        <div class="flex align-items-center">
          <i class="pi pi-cog text-2xl mr-3 text-500"></i>
          <span class="text-3xl font-medium text-900 mr-2"
            >Emisor configuration</span
          >
        </div>
      </div>

      <div class="mb-3 form">
        <div class="form-input">
          <h4>Name</h4>
          <InputText
            placeholder="Name"
            v-model="configuration.name"
          ></InputText>
        </div>
        <div class="form-input">
          <h4>Short Name</h4>
          <InputText
            placeholder="Short Name"
            v-model="configuration.shortname"
          ></InputText>
        </div>
        <div class="form-input">
          <h4>RFC</h4>
          <InputText
            placeholder="RFC"
            v-model="configuration.tax_id"
          ></InputText>
        </div>
      </div>
      <div class="form">
        <div class="text-left domains">
          <h4>Domains</h4>
          <div>
            <InputText
              placeholder="Press Enter to add a new domain"
              class="mt-2 w-full"
              @keyup.enter="handleAddDomainToUI"
              v-model="domainsInput"
            />

            <p v-if="domains.length === 0">
              Specify domains, hit enter to add a new domain
            </p>
            <ul
              class="p-0"
              :class="domains.length !== 0 && 'h-12rem overflow-auto'"
            >
              <li
                :key="index"
                v-for="(domain, index) in domains"
                class="text-left mb-2 domain-list"
              >
                {{ domain }}
                <Button
                  size="small"
                  severity="danger"
                  icon="pi pi-trash"
                  @click="
                    domains = domains.filter((_c, _cindex) => _cindex !== index)
                  "
                ></Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style="
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          align-items: center;
          padding-top: 20px;
        "
      >
        <div class="form-input">
          <h4>Locations</h4>
          <MultiSelect
            style="width: 100% !important"
            v-model="selectedLocations"
            :options="locations"
            optionLabel="name"
            placeholder="Select Location"
            :maxSelectedLabels="3"
            class="w-full md:w-20rem"
          />
        </div>
        <div class="form-input">
          <h4>Company type</h4>
          <MultiSelect
            style="width: 100% !important"
            v-model="selectedCompanyType"
            :options="CompanyTypes"
            optionLabel="name"
            placeholder="Select Company Type"
            :maxSelectedLabels="3"
            class="w-full md:w-20rem"
          />
        </div>
      </div>
      <div style="display: grid; justify-content: end; padding: 30px 0">
        <Button :onclick="handleSubmit">Save Changes</Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useToast } from "primevue/usetoast";

const toast = useToast();

const domainsInput = ref("");
const configuration = ref({});
const domains = ref([]);
const locations = ref([
  { name: "Manzanillo", key: "A" },
  { name: "Lazaro Cardenaz", key: "M" },
  { name: "Altamira", key: "P" },
]);

const selectedLocations = ref();

const CompanyTypes = ref([
  { name: "Trucker", key: "T" },
  { name: "Rail", key: "R" },
  { name: "Customs Broker", key: "CB" },
]);
const selectedCompanyType = ref([]);

const handleAddDomainToUI = () => {
  if (validateDomain(domainsInput.value)) {
    if (domains.value.includes(domainsInput.value)) {
      toast.add({
        severity: 'error',
        summary: 'Domain already added',
        detail: 'Please enter a new domain',
        life: 3000,
      });
      return;
    }
    domains.value = [domainsInput.value, ...domains.value]
    domainsInput.value = '';
  } else {
    toast.add({
      severity: 'error',
      summary: 'Invalid domain',
      detail: 'Please enter a valid domain',
      life: 3000,
    });
  }
}

function validateDomain(url) {
  const pattern = /^(https?):\/\/([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})(\/[\w .-]*)*\/?$/;
  return pattern.test(url);
}
</script>

<style scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
}

.form-input {
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 130px 1fr;
  align-items: center;
}

.domains {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 130px 1fr;
}

.domains ul li {
  width: 100%;
  background: #eee;
  border-radius: 5px;
  display: flex;
  padding-left: 13px;
  align-items: center;
}

.domains ul li button {
  margin-left: auto;
}

</style>