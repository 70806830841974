import { defineStore } from "pinia";

export const usePdfOnlyStore = defineStore("pdf-only", {
  state: () => ({
    labellingDone: false,
    highlightedField: "",
    nextPdf: false,
    selectedHighlight: "",
    coordinatesData: {},
    jobData : {
      invoice_date: "",
      website: "",
      address: "",
      notes: "",
      s3_path: "",
      tax_id: "",
      total: "",
      invoice_number: "",
      name: "",
      currency: "",
      phone_number: "",
      is_new_template: "",
      email: "",
    },
    activeStep :0,
    payload: [],
    infoMessage: { 
      status: "none",
      message: ""
    }
  }),

  actions: {},
});
