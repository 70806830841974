import Client from './Clients/AxiosClient';

export default {
    emailInit(data, config) {
        const params = new URLSearchParams();
        params.append('organization', data.organization);
        params.append('user', data.user);
        params.append('target_email', data.target_email);
        // params.append("debug","true")
        // data.debug = true;
        return Client.post(`/email/init?${params.toString()}`, data, config);
    },
    // /email/check
    emailCheck(data, config) {
        const params = new URLSearchParams();
        params.append('organization', data.organization);
        params.append('user', data.user);
        params.append('target_email', data.target_email);
        // params.append("debug","true")
        // data.debug = true;
        return Client.get(`/email/check?${params.toString()}`, data, config);
    },
    // /email/process-batch
    emailProcessBatch(data, config) {
        const params = new URLSearchParams();
        params.append('organization', data.organization);
        params.append('user', data.user);
        params.append('job_id', data.job_id);
        params.append("s3_xlsx_key", data.s3_xlsx_key)
        return Client.post(`/email/process-batch?${params.toString()}`, data, config);
    }
}