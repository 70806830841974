<template>
  <AdminRestrictedPage>
    <div>
      <div class="surface-section px-4 pt-5 md:px-6 lg:px-8">
        <div
            class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border"
        >
          <div class="flex align-items-center">
            <i class="pi pi-cog text-2xl mr-3 text-500"></i>
            <span class="text-3xl font-medium text-900 mr-2"
            >Admin Configuration</span
            >
          </div>
          <div>
            <div style="display: grid; justify-content: end; padding: 30px 0">
              <Button
                  :loading="savingChanges || loadingEmisorConfiguration"
                  label="Save changes"
                  icon="pi-save pi"
                  :disabled="disableFields"
                  @click.prevent="handleSaveChanges">
              </Button>
            </div>
          </div>
        </div>
        <form @submit.prevent="handleSaveChanges">
          <div class="mb-3 form text-left">
            <div class="grid mt-3">
              <div class="col-12">
                <h3 class="my-0">Organization Information:</h3>
              </div>
              <div class="col-6">
                <div class="form-input flex flex-column gap-1 w-full">
                  <label class="my-2 text-sm ">Organization Name</label>
                  <InputText
                      :disabled="loadingEmisorConfiguration"
                      @change="disableFields=false"
                      class="w-full"
                      placeholder="Organization Name"
                      v-model="configuration['name']"
                  ></InputText>
                </div>
              </div>
              <div class="col-6">
                <div class="form-input flex flex-column gap-1 w-full">
                  <label class="my-2 text-sm">Tax-ID</label>
                  <InputText
                      class="w-full"
                      @change="disableFields=false"
                      placeholder="Tax-ID"
                      v-model="configuration['tax_id']"
                      :disabled="loadingEmisorConfiguration"
                  ></InputText>
                </div>
              </div>
              <div class="col-12">
                <h3 class="my-0">Preferences:</h3>
              </div>
              <div class="col-6">
                <div class="form-input flex flex-column gap-1 w-full">
                  <label class="my-2 text-sm ">Country</label>
                    <InputGroup>
                      <InputGroupAddon>
                        <i class="pi pi-globe"></i>
                      </InputGroupAddon>
                      <Dropdown
                        :disabled="loadingEmisorConfiguration"
                        @change="disableFields = false"
                        style="width: 100% !important"
                        v-model="selectedCountry"
                        :options="countries"
                        optionLabel="name"
                        placeholder="Select Country"
                        :maxSelectedLabels="3"
                        class="w-full md:w-20rem"
                    />
                  </InputGroup>
                </div>
              </div>
              <div class="col-6">
                <div class="form-input flex flex-column gap-1 w-full">
                  <label class="my-2 text-sm ">Language</label>
                  <InputGroup>
                    <InputGroupAddon>
                      <i class="pi pi-language"></i>
                    </InputGroupAddon>
                    <Dropdown
                        :disabled="loadingEmisorConfiguration"
                        @change="disableFields=false"
                        style="width: 100% !important"
                        v-model="selectedLanguage"
                        :options="languages"
                        optionLabel="name"
                        placeholder="Select Language"
                        :maxSelectedLabels="3"
                        class="w-full md:w-20rem"
                    />
                  </InputGroup>
                </div>
              </div>
              <div class="col-6">
                <div class="form-input flex flex-column gap-1 w-full">
                  <label class="my-2 text-sm ">Timezone</label>
                  <InputGroup>
                      <InputGroupAddon>
                        <i class="pi pi-clock"></i>
                      </InputGroupAddon>
                  
                      <Dropdown
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          style="width: 100% !important"
                          v-model="selectedTimezone"
                          :options="timezones"
                          optionLabel="name"
                          placeholder="Select Timezone"
                          :maxSelectedLabels="3"
                          class="w-full md:w-20rem"
                      />
                    </InputGroup>
                </div>
              </div>
              <div class="col-12" >
                <div class="col-8" >
                  <div class="form-checkboxes">
                    <div class="form-input flex align-items-center gap-2">
                      <InputSwitch
                          :binary="true"
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          v-model="configuration['global_user_labelling']"
                      ></InputSwitch>
                      <label class="my-2  ">Global user labelling</label>
                      <i
                          class="pi pi-question-circle cursor-pointer text-blue-600"
                          v-tooltip.top="{value : 'Users have the option to label all organization invoices and not just their own.' , autoHide :true}"></i>
                    </div>
                    <div class="form-input flex align-items-center gap-2">
                      <InputSwitch
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          :binary="true"
                          v-model="configuration['is_raw_keyfile']"
                      ></InputSwitch>
                      <label class="my-2 text-sm">Raw References</label>
                      <i
                          class="pi pi-question-circle cursor-pointer text-blue-600"
                          v-tooltip.top="{value : 'If you provide RAW keyfile, we can add another step of Reference cleaning for you. Ask your CS for more info.' , autoHide :true}"></i>
                    </div>
                    <div class="form-input flex align-items-center gap-2">
                      <InputSwitch
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          :binary="true"
                          v-model="configuration['pdf_only']"
                      ></InputSwitch>
                      <label class="my-2 text-sm ">PDF Only</label>
                      <i
                          class="pi pi-question-circle cursor-pointer text-blue-600"
                          v-tooltip.top="{value : 'If your organization only provides PDF invoices and not XMLs.' , autoHide :true}"></i>
                    </div>
                    <div class="form-input flex align-items-center gap-2">
                      <InputSwitch
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          :binary="true"
                          v-model="configuration['reference_upload_auto_job']"
                      ></InputSwitch>
                      <label class="my-2 text-sm ">Auto-Job</label>
                      <i
                          class="pi pi-question-circle cursor-pointer text-blue-600"
                          v-tooltip.top="{value : 'Run an Automatic job on Unknowns whenever references are updated.' , autoHide :true}">
                      </i>
                    </div>
                    <div class="form-input flex align-items-center gap-2">
                      <InputSwitch
                          :disabled="loadingEmisorConfiguration"
                          @change="disableFields=false"
                          :binary="true"
                          v-model="configuration['multi_match_auto_job']"
                      ></InputSwitch>
                      <label class="my-2 text-sm ">MultipleMatches in Auto-Job</label>
                      <i
                          class="pi pi-question-circle cursor-pointer text-blue-600"
                          v-tooltip.top="{value : 'MultipleMatches in Auto-Job' , autoHide :true}">
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <UsersList :roles="roles" :organizationId="configuration['id']"/>
      </div>
    </div>
  </AdminRestrictedPage>
</template>

<script setup>
import {onMounted, ref} from "vue";
import UsersList from "../components/EmisorConfigurationAdmin/UsersList.vue";
import {useUserSessionStore} from "@/store/userSession";
import {storeToRefs} from "pinia";
import Repository from "@repositories/RepositoryFactory";
import AdminRestrictedPage from "../components/Rbac/AdminRestrictedPage.vue";

const EmisorConfigurationRepository = Repository.get("emisorConfiguration");

const userSession = useUserSessionStore();
const {organization} = storeToRefs(userSession);

const configuration = ref({
  each_uploader_label_unknowns: false,
  is_raw_keyfile: false,
});
const countries = ref([]);
const languages = ref([]);
const selectedLanguage = ref({});
const selectedCountry = ref({});
const timezones = ref([]);
const selectedTimezone = ref({});
const hasConfiguration = ref(false);


const disableFields = ref(true);
const roles = ref([]);
const loadingEmisorConfiguration = ref(false);
const fetchEmisorConfiguration = async () => {
  loadingEmisorConfiguration.value = true;
  await EmisorConfigurationRepository.getOrganization(organization.value)
      .then((res) => {
        countries.value = res.data.data.locations;
        timezones.value = res.data.data.timezones;
        languages.value = res.data.data.languages;
        if (res.data.data.organizer_config && res.data.data.organization_info) {
          hasConfiguration.value = true;
          configuration.value = {...res.data.data.organizer_config, ...res.data.data.organization_info};
          roles.value = res.data.data.roles
          selectedCountry.value = countries.value?.find(
            (item) => item.id === configuration.value.preferred_country.id
          );
          selectedLanguage.value = languages.value?.find(
            (item) => item.id === configuration.value.preferred_language.id
          );
          selectedTimezone.value = timezones.value?.find(
            (item) => item.id === configuration.value.preferred_timezone.id
          );
        } else {
          configuration.value.name = organization.value;
          hasConfiguration.value = false;
        }
      })
      .catch((err) => {
        console.log(err)
      }).finally(() => {
        loadingEmisorConfiguration.value = false;
      })
}

onMounted(() => {
  fetchEmisorConfiguration()
});

const savingChanges = ref(false);
const handleSaveChanges = async () => {
  savingChanges.value = true;

  const configurationData = {
    app_name: "organizer",
    is_raw_keyfile: configuration.value.is_raw_keyfile,
    each_uploader_label_unknowns: configuration.value.each_uploader_label_unknowns,
    invoice_xml: true,
    pdf_only: configuration.value.pdf_only,
    reference_upload_auto_job: configuration.value.reference_upload_auto_job,
    global_user_labelling: configuration.value.global_user_labelling,
    preferred_timezone: selectedTimezone.value?.id,
    preferred_language: selectedLanguage.value?.id,
    multi_match_auto_job: configuration.value.multi_match_auto_job,
  }

  const organizationData = {
    app_name: "organizer",
    organization: organization.value,
    tax_id: configuration.value.tax_id,
    name: configuration.value.name,
  }


  if (!hasConfiguration.value) {
    try {
      const createConfigurationData = await EmisorConfigurationRepository.createConfiguration(configurationData)
    await EmisorConfigurationRepository.createOrganization({ ...organizationData, organizer_config_id: createConfigurationData.data.data.organizer_config_id, }).then(() => {
      disableFields.value = true;
      hasConfiguration.value = true;
      fetchEmisorConfiguration()
    })
    } catch (e) {
      console.error(e)
    } finally {
      savingChanges.value = false;
    }
  } else {
    try {
      await EmisorConfigurationRepository.updateOrganization({
        ...organizationData,
        ...configurationData
      })
    } catch (e) {
      console.error(e)
    } finally {
      savingChanges.value = false;
    }
  }
};
</script>


<style scoped >
  .form-checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
</style>
