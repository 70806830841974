<template>
  <div class="card flex justify-content-center">
    <div
      severity="secondary"
      class="border-bottom-1 cursor-pointer cursor-pointer md:border-bottom-none w-16rem surface-border flex-auto p-3"
    >
      <div class="flex align-items-center mb-3 justify-content-center">
        <i class="pi pi-info-circle text-blue-500 text-xl mr-2"></i>
        <span class="text-500 font-medium">Errors Invoices</span>
      </div>
      <span class="block text-900 font-medium mb-4 text-xl">
        <span
          class="text-lg bg-red-400 px-4 py-2 -mb-5 border-round text-white"
          @click="toggle"
          >Invoices : {{ summary?.error_invoices }}
        </span>
      </span>
    </div>
    <OverlayPanel
      v-show="errorInvoices?.length > 0"
      ref="op"
      style="max-height: 22rem; overflow-y: auto"
    >
      <div class="flex flex-column gap-3 w-16rem">
        <div class="border-bottom-1 border-gray-400">
          <span class="font-medium text-900 uppercase block mb-2"
            >Error Invoices List</span
          >
        </div>
        <div>
          <ul
            v-if="errorInvoices?.length > 0"
            class="list-none p-0 m-0 flex flex-column gap-3"
          >
            <li
              v-for="(item, index) in Object.values(errorInvoices)"
              :key="index"
              class="flex align-items-center gap-2 border-bottom-1 border-gray-200 py-2"
            >
              <div class="flex align-items-center gap-2">
                <i class="pi pi-file-pdf"></i>
                <div class="text-lg text-color-secondary">
                  {{ item }}
                </div>
              </div>
            </li>
          </ul>
          <span v-else>No error invoices found</span>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  summary: {
    type: Object,
    default: () => {},
  },
  errorInvoices: {
    type: Array,
    default: () => [],
  },
});

const op = ref();

const toggle = (event) => {
  op.value.toggle(event);
};
</script>
