<template>
  <Sidebar
    v-model:visible="resultsStores.infoDrawer.isActive"
    position="right"
    style="height: "
  >
    <Fieldset class="m-0">
      <template #legend>
        <legend>{{ resultsStores.infoDrawer.heading }}</legend>
      </template>
      <p class="m-0">
        {{ resultsStores.infoDrawer.message }}
      </p>
      <ul>
        <li
          class="-ml-5"
          v-for="(item, index) in resultsStores.infoDrawer.list"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
    </Fieldset>
    <footer v-if="resultsStores.infoDrawer.footer" class="bg-blue-100 border-round p-2 mt-3">
        <p class="text-center"><b>{{ resultsStores.infoDrawer.footer }}</b></p>
    </footer>
  </Sidebar>
</template>
<script setup>
import Sidebar from "primevue/sidebar";
import { serviceStore } from "@/store/serviceStore";

const resultsStores = serviceStore();
</script>
<style scoped>
:deep(.p-fieldset) {
  background-color: #6366f1 !important;
  color: #fff !important;
}
:deep(.p-fieldset-legend) {
  background-color: #6366f1 !important;
  color: #fff !important;
}
</style>
