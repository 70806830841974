<template>
  <Button type="button" icon="pi pi-eye" @click="handleView(invoiceData)" />
  <Dialog
    :modal="true"
    v-model:visible="visible"
    :style="{ width: '55vw', height: '100vh' }"
    maximizable
    @unmaximize="dialogHeight = '60vh'"
    @maximize="dialogHeight = '100vh'"
    :contentStyle="{ height: '100vh' }"
    class="p-fluid"
  >
    <template #header>
      <div class="flex justify-content-between w-full align-item-center">
        <h3 class="text-back">{{ detailPageTitle }}</h3>
        <div class="flex gap-5 mr-6 mt-2">
          <Button
            v-tooltip.top="'Previous invoice'"
            @click="handlePreviousInvoice"
            icon="pi pi-angle-left"
            severity="info"
            text
            raised
            class="p-2 px-3"
            rounded
            aria-label="Cancel"
          />
          <Button
            v-tooltip.top="'Next invoice'"
            icon="pi pi-angle-right"
            @click="handleNextInvoice"
            severity="info"
            text
            raised
            class="p-2 px-3"
            rounded
            aria-label="Cancel"
          />
        </div>
      </div>
    </template>
    <div class="flex justify-content-between align-item-center relative mt-2">
      <div class="shadow-2 p-4 border-round mb-4 w-28rem">
        <b class="text-back text-lg">Reference :</b>
        {{ invoiceData.reference }} <br />
        <b class="text-back text-lg">Invoice ID :</b> {{ invoiceData._id }}
      </div>
      <div class="flex align-items-center gap-4" style="width: 30rem">
        <ButtonGroup class="p-buttonset my-5 w-full">
          <Button
            label="Assign Tag"
            :severity="{ info: selectButtonValue === 'Assign Tag' }"
            @click="handleSelectButtonClick('Assign Tag')"
            class="mr-1"
          />
          <Button
            label="Assign Reference"
            :severity="{ info: selectButtonValue !== 'Assign Tag' }"
            @click="handleSelectButtonClick('Assign Reference')"
          />
        </ButtonGroup>
      </div>
      <div
        v-if="jobStore.assignReferenceModalData.filterModel"
        class="surface-200 shadow-4 w-28rem h-21rem absolute px-4 py-4 mt-3 reference"
      >
        <h4 style="text-align: start">Select a Reference</h4>
        <hr class="-mt-3" />
        <Dropdown
          v-model="jobStore.selectedSubcategory"
          :virtualScrollerOptions="{ itemSize: 30 }"
          :loading="isTyping"
          :options="subcategory"
          editable
          optionLabel="name"
          placeholder="Select a Reference"
          class="w-full mr-1 mt-2 shadow-1"
          @change="toggleSubCategoryChange"
        />
        <div>
          <h4 style="text-align: start">Our Recommendations</h4>
          <hr class="-mt-3" />
          <span v-if="recommendedReferences?.length">
            <span v-for="(item, index) in recommendedReferences" :key="index">
              <Chip
                @click="handleChipClick(item)"
                :class="{ 'bg-gray-400': referencePayload?.reference === item }"
                class="cursor-pointer mx-1 my-2 text-xs shadow-2"
                :label="item"
              />
            </span>
          </span>
          <span v-else> No Recommendations </span>
        </div>
        <div class="flex">
          <Button
            label="Close"
            @click="resetRefs"
            icon="pi pi-times-circle"
            :disabled="false"
            severity="danger"
            class="mt-4 mr-2 w-full"
          />
          <Button
            label="Assign"
            @click="toggleConfirmModal"
            icon="pi pi-send"
            :disabled="false"
            class="mt-4 w-full"
          />
        </div>
      </div>
      <div
        v-if="!jobStore.assignReferenceModalData.filterModel && tagsModal"
        class="surface-200 shadow-4 w-28rem min-h-22rem absolute px-4 py-4 mt-3 reference"
      >
        <h4 style="text-align: start">Select a Tag</h4>
        <hr class="-mt-3" />
        <Dropdown
          v-model="selectedTagName"
          :resetFilterOnClear="true"
          :options="tagsNameList"
          @change="toggleTagChange"
          optionLabel="name"
          placeholder="Select by Name"
          class="w-full mr-1 mt-2 shadow-1"
        />
        <div>
          <h4 style="text-align: start">Our Recommendations</h4>
          <hr class="-mt-3" />
          <span
            v-if="
              invoiceData?.tag.meta &&
              invoiceData?.tag.meta !== 'failure' &&
              invoiceData?.tag.meta !== 'no_issuer_tags_found'
            "
          >
            <Chip
              @click="selectedRecommendedTag(invoiceData)"
              :class="{
                'bg-gray-400':
                  payload &&
                  payload.tag_hitl &&
                  Object.values(payload.tag_hitl)[0] === invoiceData?.tag.value,
              }"
              class="cursor-pointer mx-1 my-2 text-xs shadow-2"
              :label="`${invoiceData?.tag.meta} : ${invoiceData?.tag.value}`"
            />
          </span>
          <span v-else> No Recommendations </span>
        </div>
        <div class="flex">
          <Button
            label="Close"
            @click="resetRefs"
            icon="pi pi-times-circle"
            :disabled="false"
            severity="danger"
            class="mt-4 mr-2 w-full"
          />
          <Button
            label="Assign"
            @click="confirmModal = true"
            icon="pi pi-send"
            :disabled="false"
            class="mt-4 w-full"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-wrap border-top-1 surface-border pt-4"
      :style="{ height: dialogHeight }"
    >
      <div
        v-if="detailPdfLink === null"
        class="w-full flex justify-content-center"
      >
        <div class="bg-white w-6 border-round py-5 text-center">
          <img src="../../assets/images.png" alt="" />
          <h2>Oops, something went wrong.!</h2>
          <h4>
            We are unable to find this file. Please report this with the details
            below:
          </h4>
          <p><b>Job ID : </b>{{ viewedInvoice.job_id }}</p>
          <p><b>Invoices ID : </b>{{ viewedInvoice._id }}</p>
          <p><b>PDF path : </b>{{ viewedInvoice.s3_path }}</p>
        </div>
      </div>
      <div
        v-else
        class="bg-blue-50 flex justify-content-center py-3 px-0 md:w-full border-round"
        style="min-height: 50vh"
      >
        <vue-pdf-embed
          width="850"
          :source="detailPdfLink"
          :textLayer="true"
          class="mt-4"
        />
      </div>
    </div>
    <template #footer>
      <div class="border-top-1 surface-border pt-3">
        <Button
          icon="pi pi-check"
          @click="visible = false"
          label="Close"
        ></Button>
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="confirmModal"
    :modal="true"
    header="Confirm"
    :style="{ width: '40vw' }"
  >
    <div class="w-full">
      <p class="mb-5 px-2">
        Are you sure to assign tag
        <b> {{ Object.values(payload.tag_hitl)[0] }} </b> to invoice
        <b>{{ invoiceData._id }}</b
        >.
      </p>
      <div class="flex justify-content-end">
        <Button
          @click="confirmModal = false"
          icon="pi pi-times"
          label="Cancel"
        ></Button>
        <Button
          @click="assignTag"
          :loading="assignButtonLoading"
          icon="pi pi-check"
          class="ml-2"
          label="Confirm"
        ></Button>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { onMounted, ref, watchEffect } from "vue";
import { useJobStore } from "@/store/jobsStore/store";
import { serviceStore } from "../../store/serviceStore";
import { useApi } from "../../../hooks/useApi";
import { useToast } from "primevue/usetoast";
import VuePdfEmbed from "vue-pdf-embed";
import { propsConfig } from "./JobsDetail/AssignReferenceModalProps";

const toast = useToast();
const visible = ref(false);
const dialogHeight = ref();
const jobStore = useJobStore();
const selectButtonValue = ref();
const tagsNameList = ref();
const tagsCodeList = ref();
const tagsModal = ref(false);
const selectedTagName = ref();
const assignButtonLoading = ref(false);
const payload = ref({});
const confirmModal = ref(false);

const resultsStores = serviceStore();

const props = defineProps(propsConfig);

const emit = defineEmits([
  "handleSubCategoryChange",
  "handleNextInvoice",
  "handleReferenceChanged",
  "resetReferences",
  "handleViewButton",
  "toggleConfirmModal",
  "handleChipClick",
  "handlePreviousInvoice",
]);

const toggleSubCategoryChange = () => {
  emit("handleSubCategoryChange");
};

const resetRefs = () => {
  emit("resetReferences");
};

const toggleConfirmModal = () => {
  emit("toggleConfirmModal");
};
const handleChipClick = (item) => {
  emit("handleChipClick", item);
};

const selectedRecommendedTag = (item) => {
  const metaKey = item.tag.meta;
  const value = item.tag.value;
  const obj = { [metaKey]: value };
  payload.value.tag_hitl = obj;
};

const handleView = (item) => {
  // selectedCode.value = ''
  if (jobStore.assignReferenceModalData.filterModel) {
    jobStore.assignReferenceModalData.filterModel = false;
  }
  emit("handleViewButton", item);
  visible.value = true;
};

watchEffect(() => {
  if (jobStore.dialogVisible === false) {
    visible.value = false;
  }
});

const handleSelectButtonClick = (item) => {
  if (item !== "Assign Tag") {
    selectButtonValue.value = item;
    jobStore.assignReferenceModalData.filterModel = true;
    tagsModal.value = false;
  } else if (item === "Assign Tag") {
    jobStore.assignReferenceModalData.filterModel = false;
    selectButtonValue.value = item;
    tagsModal.value = true;
  }
};

const toggleTagChange = () => {
  const newObj = {};
  const key = selectedTagName.value.name.split(" : ")[0];
  const value = selectedTagName.value.name.split(" : ")[1];
  newObj[key] = value;
  payload.value.tag_hitl = newObj;
};

const assignTag = async () => {
  try {
    assignButtonLoading.value = true;
    payload.value._id = props.invoiceData._id;
    const { putItem: assignTagInvoice, data: response } =
      useApi("/invoices/hitl/tag");
    await assignTagInvoice(payload.value);
    if (response.value.statusCode === 200) {
      toast.add({
        severity: "success",
        summary: "Updated Successfully",
        detail: response.value.message,
        life: 3000,
        position: "top-left",
      });
      assignButtonLoading.value = false;
      confirmModal.value = false;
      resetRefs();
      jobStore.isTagUpdated = true;
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: error.message,
      detail: error?.response?.data["message"],
      life: 3000,
      position: "top-left",
    });
  }
};

const handleNextInvoice = () => {
  emit("handleNextInvoice", props.invoiceData);
};
const handlePreviousInvoice = () => {
  emit("handlePreviousInvoice", props.invoiceData);
};

onMounted(async () => {
  await resultsStores.getFilterData(props.organization, false);
  if (resultsStores?.tags?.options) {
    tagsNameList.value = Object.values(resultsStores?.tags?.options)?.map(
      (item) => ({
        name: `${Object.keys(item)[0]} : ${Object.values(item)[0]}`,
      })
    );
    tagsCodeList.value = Object.values(resultsStores?.tags?.options)?.map(
      (item) => Object.keys(item)[0]
    );
  }
});
</script>
<style scoped>
.reference {
  right: 1rem;
  top: 5.5rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100 !important;
}
</style>
