<template>
    <div>
        <Dialog v-model:visible="downloadNotification" modal header="Download Report" :style="{ width: '30rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div>
                Your export file has been successfully downloaded
            </div>
            <template #footer>
                <Button label="Ok" icon="pi pi-check" @click="downloadNotification = false" autofocus />
            </template>
        </Dialog>
    </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue';

const downloadNotification = ref(false)
const props = defineProps(['isVisible'])

watchEffect(() => {
    if (!props.isVisible) {
        downloadNotification.value = true
    } else {
        downloadNotification.value = false

    }
})

</script>