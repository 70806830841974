<template>
  <div class="card">
    <Timeline
      :value="formattedEvents"
      align="alternate"
      class="customized-timeline"
    >
      <template #marker="slotProps">
        <span
          class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
          :style="{ backgroundColor: slotProps.item.color }"
        >
        <AI_Icon v-if="slotProps.item.icon === 'ai-icon'" style="width: 17px;"/>
        <i v-else :class="slotProps.item.icon"></i>
        </span>
      </template>
      <template #content="slotProps">
        <Card class="mt-2">
          <template #title>
            {{ slotProps.item.Reference }}
          </template>
          <template #subtitle>
            <span class="bg-blue-300 py-1 px-3 border-round text-white">
              {{ moment(slotProps.item.time).format("YYYY-MM-DD HH:mm")}}
            </span>
          </template>
          <template #content>
            <p><b>Labelled By</b>: {{ slotProps.item.LabelledBy }}</p>
            <p><b>Source</b>: {{ slotProps.item.Source }}</p>
          </template>
        </Card>
      </template>
    </Timeline>
  </div>
</template>

<script setup>
import { computed } from "vue"; 
import moment from "moment";
import AI_Icon from "../../../assets/ExternalIcons/AI_Icon.vue";

const props = defineProps({
  invoiceDetail: {
    type: Array,
    required: true,
  },
});

const getColorAndIcon = (source) => {
  switch (source) {
    case "Engine":
      return { color: "#FF9800", icon: "ai-icon" }; // Changed to AI icon
    case "UserDeletion":
      return { color: "#f44336", icon: "pi pi-trash" }; // Added UserDeletion with deletion red icon
    case "UserLabelling":
      return { color: "#4CAF50", icon: "pi pi-user" }; // Remains the same
    case "PDF_ONLY_HITL_VERIFICATION":
      return { color: "#f44336", icon: "pi pi-file-pdf" }; // Remains the same
    default:
      return { color: "#607D8B", icon: "pi pi-question-circle" }; // Default case
  }
};

const formattedEvents = computed(() => {
  const history = props.invoiceDetail?.invoice_history;

  if (!history) {
    return [];
  }

  return history
    .slice()
    .sort((a, b) => moment(b.time)?.valueOf() - moment(a.time)?.valueOf())
    .map(item => {
      const { color, icon } = getColorAndIcon(item.Source);
      return {
        ...item,
        date: item.time,
        color,
        icon,
      };
    });
});
</script>



<style scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row;

      .p-timeline-event-content {
        text-align: left;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }
  }
}
</style>
