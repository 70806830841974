import Client from './Clients/AxiosClient';
import { appConfig } from "@/config";

export default {
    uploadKeyFile(data, config) {
        return Client.put(`/reference/xlsx-key-upload?user=${data.user}&organization=${data.organization}&raw_data=${data.raw_data}&open_all=${data.open_all}`, data.file, config);
    },
    uploadTags(data, config) {
        return Client.post(`/tags/upload`, data.file, config);
    },
    getXlsxList: ({organization}) => {
        const params = new URLSearchParams()
        params.append('organization', organization)
        return Client.get(`/reference/xlsx-list?${params.toString()}`)
    },
    getXlsxFile: ({reference_s3_filename,organization}) => {
        const params = new URLSearchParams()
        params.append('reference_s3_filename', reference_s3_filename)
        params.append('organization', organization)
        return Client.get(`/reference/xlsx-file?${params.toString()}`)
    }
};