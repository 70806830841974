<template>
    <div class="p-4">
        <div
            class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border mb-4">
            <div class="flex align-items-center">
                <i class="pi pi-chart-bar text-2xl mr-3 text-500"></i>
                <span class="text-3xl font-medium text-900">Organization Overview</span>
            </div>
        </div>
        <div class="container align-content-center flex-wrap flex flex-column card-container gap-4 px-2">
            <div class="flex m-2 gap-4 w-full align-items-center justify-content-center">
                <div class="container gap-4" v-if="loading">
                    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
                        animationDuration=".5s" aria-label="Loading" />
                </div>
                <div class="w-full" v-else>
                    <DataTable :value="[]" stripedRows :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 20, 50, 100]" responsiveLayout="scroll"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                        <template #empty>
                            No Data found.
                        </template>
                        <template #header>
                            <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                <span class="text-xl text-900 font-bold">Reference Overview</span>
                            </div>
                        </template>
                        <Column field="job_id" header="User Name" :sortable="true"></Column>
                        <Column field="job_id" header="User Role" :sortable="true"></Column>
                        <Column field="job_id" header="Successful" :sortable="true"></Column>
                        <Column field="job_id" header="Multiple Match" :sortable="true"></Column>
                        <Column field="job_id" header="Unknown" :sortable="true"></Column>
                        <Column field="job_id" header="Total" :sortable="true"></Column>
                        <Column header="Actions" headerStyle="width: 4rem; text-align: center"
                            bodyStyle="text-align: center; overflow: visible">
                            <template #body="slotProps">
                                <router-link :to="{ name: 'details', params: { trx_id: slotProps.data.job_id } }">
                                    <Button type="button" label="View Details" style="width:10rem;" />
                                </router-link>
                            </template>
                        </Column>
                    </DataTable>
                    <Divider class="mt-8" />
                    <div class="h-4 w-full bg-gray-200"><span style="opacity: 0;">.</span></div>
                    <Divider class="mb-8 shadow-4" />

                    <DataTable :value="[]" stripedRows :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 20, 50, 100]" responsiveLayout="scroll"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                        <template #empty>
                            No Data found.
                        </template>
                        <template #header>
                            <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                <span class="text-xl text-900 font-bold">User Overview</span>
                            </div>
                        </template>
                        <Column field="job_id" header="User Name" :sortable="true"></Column>
                        <Column field="job_id" header="User Role" :sortable="true"></Column>
                        <Column field="job_id" header="Total Jobs" :sortable="true"></Column>
                        <Column header="Actions" headerStyle="width: 4rem; text-align: center"
                            bodyStyle="text-align: center; overflow: visible">
                            <template #body="slotProps">
                                <router-link :to="{ name: 'details', params: { trx_id: slotProps.data.job_id } }">
                                    <Button type="button" label="View Details" style="width:10rem;" />
                                </router-link>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, watchEffect } from "vue";
import { useUserSessionStore } from '@/store/userSession';
import { storeToRefs } from 'pinia';
import { useToast } from "primevue/usetoast";
import { useApi } from '../../hooks/useApi'
import { formatDateTime } from "../utils/helpers";

const toast = useToast();
const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);

const email = ref(attributes.value.email);
const transactionsList = ref([]);
const isError = ref(null)

onMounted(() => {
    // getTransactionsList();
});

const loading = ref(false);

const stockClass = (data) => {
    return [
        'border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm',
        {
            'bg-red-100 text-red-900': data === 0,
            'bg-blue-100 text-blue-900': data > 0 && data < 10,
            'bg-teal-100 text-teal-900': data >= 10
        }
    ];
};

watchEffect(() => {
    if (isError.value) {
        toast.add({
            severity: 'error',
            summary: isError.value.message,
            detail: isError.value?.response?.data["message"],
            life: 3000,
            position: 'top-left'
        });
    }
});

const getTransactionsList = async () => {
    loading.value = true
    const params = {
        user: userName.value,
        email: email.value,
        organization: organization.value
    }

    const {
        data: trxData,
        error: errorRef,
        loading: loadingRef,
        fetchItems: getTrxList
    } = useApi('/results/user_transactions', params);

    await getTrxList()
    isError.value = errorRef.value
    transactionsList.value = trxData.value?.jobs;
    loading.value = loadingRef.value
};
</script>
  
<style scoped>
.p-datatable {
    width: 100%
}
</style>
  