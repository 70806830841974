import { inject } from 'vue';  // Composable feature

const parse = (value, fallback) => {
    if (typeof value === 'undefined') {
        return fallback;
    }

    switch (typeof fallback) {
        case 'boolean' :
            return !!JSON.parse(value);
        case 'number' :
            return JSON.parse(value);
        default :
            return value;
    }
};

// Environment vars and app constants
const config = {
    appModeEnv: parse(import.meta.env.MODE),
    appName: parse(import.meta.env.VITE_TITLE),
    logLevel: parse(import.meta.env.VITE_LOG_LEVEL, ''),
}

const appConfInjectionKey = Symbol('appConfig');

export const useAppConfig = () => {
    return inject(appConfInjectionKey);
};

// Export the config object for non-Vue usage
export const appConfig = config;

export default {
    install: (app) => {
        app.provide(appConfInjectionKey, config);

        app.config.globalProperties.$appConfig = config;
    }
};