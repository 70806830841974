<template>
  <Dialog
    v-model:visible="confirmModel"
    modal
    header="Confirm"
    :style="{ width: '40rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <div class="flex align-items-center justify-content-center">
      <i
        class="pi pi-exclamation-triangle text-red-400 mr-3"
        style="font-size: 2rem"
      />
      <span v-if="pdfOnlyStore.infoMessage.status == 'error'"
        >This invoice isn't successfully labelled due to following error! <br />
        Do you want to skip and proceed to next invoice?
        <br />
        <Divider />
        <b class="text-red-400">
          Error: {{ pdfOnlyStore.infoMessage.message }}</b
        >
      </span>
    </div>
    <template #footer>
      <div class="flex align-items-center justify-content-center gap-3">
        <Button label="Cancel" icon="pi pi-times" @click="handleCancel" />
        <Button
          label="Confirm"
          icon="pi pi-check"
          @click="handleConfirm"
          autofocus
        />
      </div>
    </template>
  </Dialog>
</template>
<script setup>
import { ref, watchEffect } from "vue";
import { usePdfOnlyStore } from "@/store/pdf-only/store";
import { serviceStore } from "../../store/serviceStore";

const confirmModel = ref(false);
const pdfOnlyStore = usePdfOnlyStore();
const store = serviceStore();

const emits = defineEmits(["confirm"]);

const props = defineProps({
  currentIndex: {
    type: Number,
    required: true,
  },
  labelSummary: {
    type: Object,
    required: true,
  },
});

watchEffect(() => {
  if (pdfOnlyStore.infoMessage.status == "error") {
    confirmModel.value = true;
  }
});
const handleCancel = () => {
  confirmModel.value = false;
  pdfOnlyStore.infoMessage = {
    status: "none",
    message: "",
  };
};
const handleConfirm = () => {
  if (props.labelSummary?.failure_invoices === props.currentIndex + 1) {
    store.pdfVerificationModel = false;
  } else {
    emits("confirm");
  }
  pdfOnlyStore.infoMessage = {
    status: "none",
    message: "",
  };
  confirmModel.value = false;
};
</script>
