<template>
  <div>
    <Toast position="bottom-right" group="tl" />
    <div class="flex align-items-center" style="height: 70vh" v-if="loading">
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
    <div v-else class="flex relative lg:static mt-4 main-container relative">
      <div
        id="app-sidebar-1"
        class="surface-section hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none custom-scrollbar container-height"
      >
        <div class="flex flex-column">
          <div>
            <div
              v-if="sortedInvoices && sortedInvoices?.length"
              class="flex justify-content-end align-items-center mr-4 my-3 absolute sort-button"
            >
              <Button
                v-tooltip="'Sort Invoices by date'"
                raised
                rounded
                text
                plain
                icon="pi pi-sort-alt"
                class=""
                @click="toggleSortOrder"
              />
              <Checkbox
                v-model="selectAllChecked"
                value="selectAll"
                @change="selectAllInvoice"
                class="ml-3"
              />
              <label for="selectAll" class="ml-2"><b>Select All</b></label>
            </div>
            <ul
              v-if="sidebarLoading"
              class="list-none p-0 m-0 overflow-hidden flex align-items-center justify-content-center container-height"
            ></ul>
            <ul
              class="list-none p-0 m-0"
              v-else-if="sortedInvoices && sortedInvoices.length && !loading"
            >
              <li v-for="(item, index) in sortedInvoices" :key="item._id">
                <ul
                  class="list-none p-0 m-0 overflow-hidden"
                  :class="{
                    'surface-200':
                      states.activeTab.file_name === item.file_name,
                    'bg-blue-100': selectedInvoice.includes(item._id),
                    'invoice-selected': isSelected(item._id),
                  }"
                >
                  <li class="flex align-items-center ml-2 mb-2">
                    <a
                      v-ripple
                      style="border-color: #dad7d7"
                      @click="togglePdf(item, index)"
                      class="flex align-items-center cursor-pointer relative w-full overflow-hidden pt-3 pb-4 px-1 text-700 transition-duration-150 transition-colors p-ripple"
                    >
                      <Checkbox
                        v-model="selectedInvoice"
                        :value="item._id"
                        @change="handleInvoiceChange(index, item)"
                        class="-mb-4"
                      />
                      <i class="pi pi-file-pdf mr-1 ml-3"></i>
                      <span v-tooltip.top="item._id" class="font-medium"
                        ><b>{{ limitText(item._id, 20) }}</b></span
                      >
                      <p class="date">{{ item.creation_date }}</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul
              v-else
              class="list-none p-0 m-0 overflow-hidden flex align-items-center justify-content-center container-height"
            >
              <li>
                <a
                  v-ripple
                  style="border-color: #dad7d7"
                  class="cursor-pointer p-3 text-700 hover:surface-300 transition-duration-150 transition-colors p-ripple"
                >
                  <h3 class="font-medium">No Invoices Found</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex flex-column relative flex-auto w-full h-full">
        <div
          class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
          style="height: 95px; margin-top: -0.5rem; padding-bottom: 1rem"
        >
          <div class="" style="margin-left: -26rem">
            <SelectButton
              v-model="invoicesType"
              :options="options"
              aria-labelledby="basic"
              class="mt-4"
              @change="handleInvoiceTypeChange"
            />
            <div class="flex align-items-center mt-2 relative">
              <h3 class="mr-2">Total Invoices:</h3>
              <Chip :label="sortedInvoices.length ?? 0" class="mr-2" />
              <h3>Selected Invoices:</h3>
              <div class="absolute" style="left: 22rem; width: 60vw">
                <div class="flex">
                  <template v-if="selectedInvoice.length <= 3">
                    <Chip
                      v-for="(item, index) in selectedInvoice"
                      :key="index"
                      :label="item"
                      class="mx-2"
                    />
                  </template>
                  <template v-else>
                    <Chip
                      v-for="(item, index) in selectedInvoice.slice(0, 3)"
                      :key="index"
                      :label="item"
                      class="mx-2"
                    />
                    <Chip
                      :label="`+${selectedInvoice.length - 3}`"
                      class="mx-2 relative cursor-pointer"
                      @click.stop="showDropdown = !showDropdown"
                    />
                    <div class="relative mt-3">
                      <div
                        v-show="showDropdown"
                        class="absolute list-dropdown"
                        style="z-index: 1000"
                      >
                        <Listbox
                          :options="extraList.slice(3)"
                          optionLabel="name"
                          class="w-full bg-gray-300 md:w-14rem custom-scrollbar mt-0"
                          style="max-height: 20vh; overflow-y: auto"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-content-center">
            <h4
              class="text-800 -mt-3"
              style="word-break: break-all; width: 22rem; margin-left: 3rem"
            >
              {{ states.activeTab.file_name }}
            </h4>
          </div>
          <div style="margin-right: -1rem">
            <Button
              id="selRef"
              v-if="invoicesType === 'Closed References'"
              label="Confirm"
              @click="handleConfirm"
              :loading="assignButtonLoading"
              class="mr-2"
              icon="pi pi-check-circle"
              severity="help"
              :disabled="!isConfirm"
            />
            <div
              class="flex align-items-center gap-2"
              v-if="invoicesType !== 'Closed References'"
            >
              <Button
                v-if="subcategory?.length <= 0"
                v-tooltip.top="'Refetch references'"
                icon="pi pi-refresh"
                @click="fetchReferences"
                severity="info"
                style="width: 3.4rem"
                :loading="resultsStores.isLoading"
                class="mr-2"
                rounded
                outlined
                aria-label="User"
              />
              <Dropdown
                v-model="selectedSubcategory"
                :virtualScrollerOptions="{ itemSize: 38 }"
                :loading="isTyping || testLoading"
                :options="dropdownData"
                editable
                optionLabel="name"
                placeholder="Select a Reference"
                class="mr-1 shadow-1"
                style="width: 14vw; height: 3.5rem"
                @change="onRefChange"
              />
              <ConfirmAssignModel
                :referencePayload="referencePayload"
                :assignButtonLoading="assignButtonLoading"
                @handleAssignClick="handleAssignClick"
              />
              <DeleteInvoiceModal
                :invoice="referencePayload"
                :invoicesData="sortedInvoices"
                :selectedInvoices="selectedInvoice"
                @updateInvoices="updateInvoiceSelection"
              />
            </div>
            <div
              v-if="
                recommendedReferences &&
                recommendedReferences?.length &&
                invoicesType === 'MultipleMatches'
              "
              class="chip-wrapper"
            >
              <div class="flex overflow-x-auto">
                <span
                  v-for="(item, index) in recommendedReferences"
                  :key="index"
                  class="chip-container"
                >
                  <Chip
                    @click="handleChipClick(item)"
                    :class="{
                      'bg-gray-400': referencePayload.reference === item,
                    }"
                    class="cursor-pointer mx-1 my-2 text-xs shadow-2 py-2 flex justify-content-center"
                    style="min-width: 8rem"
                  >
                    <b>{{ item }}</b>
                  </Chip>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1 surface-ground flex flex-column flex-auto">
          <div class="flex-auto">
            <div
              class="flex align-items-center container-height"
              v-if="pdfLoading"
            >
              <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".develotestpment5s"
                aria-label="Loading"
              />
            </div>
            <div
              v-if="states?.pdfShow === null"
              class="container-height border-round"
            >
              <div class="bg-white w-6 mx-auto border-round mt-8 py-5">
                <img src="../assets/images.png" alt="" />
                <h2>Oops, something went wrong.!</h2>
                <h4>
                  We are unable to find this file. Please report this with the
                  details below:
                </h4>
                <p><b>Job ID : </b>{{ states?.activeTab.job_id }}</p>
                <p><b>Invoices ID : </b>{{ states?.activeTab._id }}</p>
                <p><b>PDF path : </b>{{ states?.activeTab.s3_path }}</p>
              </div>
            </div>
            <div
              v-if="
                !states?.pdfShow?.length &&
                !pdfLoading &&
                states?.pdfShow !== null
              "
              class="flex align-items-center justify-content-center container-height"
            >
              <h3>No Invoice selected</h3>
            </div>
            <div
              class="container-height border-round hide-scrollbar flex justify-content-center"
              v-else
            >
              <vue-pdf-embed
                width="850"
                :source="states?.pdfShow"
                :textLayer="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watchEffect, computed } from "vue";
import Repository from "@repositories/RepositoryFactory";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { serviceStore } from "@/store/serviceStore";
import { assignReference, handleDropDownChange } from "../utils/helpers";
import { useApi } from "../../hooks/useApi";
import VuePdfEmbed from "vue-pdf-embed";
import DeleteInvoiceModal from "../components/UserLabeling/DeleteInvoiceModal.vue";
import ConfirmAssignModel from "../components/UserLabeling/ConfrimAssignModel.vue";

const userSession = useUserSessionStore();
const { userName, organization } = storeToRefs(userSession);
const resultsStores = serviceStore();

const ResultsRepository = Repository.get("results");
const toast = useToast();
const options = ref([
  "Unknown (Not Found)",
  "MultipleMatches",
  "Closed References",
]);
const invoicesType = ref("Unknown (Not Found)");
const loading = ref(false);
const pdfLoading = ref(false);
const assignButtonLoading = ref(false);
const filterModel = ref(false);
const subcategory = ref();
const selectedSubcategory = ref();
const referenceCategories = ref();
const selectedMatchReferences = ref();
const disableMatchReferences = ref(true);
const recommendedReferences = ref([]);
const isError = ref(null);
const sidebarLoading = ref(false);
const selectedInvoice = ref([]);
const sortByCreationDateAsc = ref(true);
const isConfirm = ref(false);
const selectedIndex = ref(null);
const isTyping = ref(false);
const dropdownData = ref([]);
const showDropdown = ref(false);
const selectAllChecked = ref([]);
const testLoading = ref(false);

const states = reactive({
  pdfShow: "",
  activeTab: "",
  references: [],
  invoiceReferenceType: [],
  referenceInvoices: {},
  // invoices: {},
  total_references_seen: [],
  invoices: ref([]),
});

const extraList = computed(() => {
  return selectedInvoice.value.map((item) => {
    return {
      name: item,
    };
  });
});

const fetchReferences = () => {
  testLoading.value = true;
  setTimeout(async () => {
    testLoading.value = false;
    await resultsStores.getFilterData(organization.value, false);
    updateReferences();
  }, 2000);
};

const selectAllInvoice = () => {
  if (selectAllChecked.value[0] === "selectAll") {
    selectedInvoice.value = sortedInvoices.value.map((item) => item._id);
    handleInvoiceChange();
  } else {
    selectedInvoice.value = [];
  }
};

const isSelected = (itemId) => {
  return selectedInvoice.value.includes(itemId);
};

const onRefChange = () => {
  if (typeof selectedSubcategory.value === "object") {
    isTyping.value = false;
  } else {
    handleDropDownChange(
      selectedSubcategory,
      isTyping,
      dropdownData,
      subcategory
    );
  }
  referencePayload.reference = selectedSubcategory.value.name;
};

const sortedInvoices = computed(() => {
  if (states?.invoices?.length > 0) {
    return states?.invoices?.slice().sort((a, b) => {
      const dateA = new Date(a.creation_date).getTime();
      const dateB = new Date(b.creation_date).getTime();

      return sortByCreationDateAsc.value ? dateA - dateB : dateB - dateA;
    });
  } else {
    return [];
  }
});

const handleConfirm = () => {
  resultsStores.confirmAssignReferenceModal = true;
};

const handleInvoiceChange = (index, item) => {
  selectedIndex.value = index;

  if (
    invoicesType.value === "Closed References" &&
    item?.matched_references?.length
  ) {
    isConfirm.value = true;
    referencePayload.ids = item._id;
    referencePayload.reference = item.matched_references[0];
  } else if (!item?.matched_references?.length) {
    isConfirm.value = false;
  }

  const selectedS3Paths = sortedInvoices.value
    .filter((invoice) => selectedInvoice.value.includes(invoice._id))
    .map((invoice) => invoice.s3_path)
    ?.map((s3_path) => [s3_path]);

  referencePayload.s3_paths = selectedS3Paths?.flatMap((item) => item);
  referencePayload.ids = selectedInvoice.value;
  referencePayload.user = userName.value;
  referencePayload.organization = organization.value;
};

const toggleSortOrder = () => {
  sortByCreationDateAsc.value = !sortByCreationDateAsc.value;
};

const togglePdf = async (item, index) => {
  handleInvoiceSelect(item);
  states.pdfShow = "";
  states.activeTab = item;
  pdfLoading.value = true;
  selectedIndex.value = index;

  const params = {
    invoice: `${item.s3_path}/${item._id}`,
  };

  const {
    data: downloadLinks,
    error: errorRef,
    loading: loadingRef,
    fetchItems: getDownloadLinks,
  } = useApi("/api/download/", params);
  await getDownloadLinks();
  isError.value = errorRef.value;
  states.pdfShow = downloadLinks.value.pdf_download_url;
  // states.pdfShow = null
  pdfLoading.value = loadingRef.value;
};

const referencePayload = reactive({
  reference: "",
  user: "",
  ids: [],
  s3_paths: [],
  organization: "",
});

const handleChipClick = (item) => {
  if (item) {
    disableMatchReferences.value = true;
    selectedMatchReferences.value = "";
    selectedSubcategory.value = "";
    referencePayload.reference = item;
  }
};

const handleInvoiceSelect = (selectedItem) => {
  recommendedReferences.value = selectedItem.matched_references;
  referencePayload.user = userName.value;
  referencePayload.organization = organization.value;
};

const limitText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const resetReferences = () => {
  referencePayload.reference = "";
  referencePayload.user = "";
  referencePayload.ids = [];
  referencePayload.s3_paths = [];
  referencePayload.organization = "";
  selectedMatchReferences.value = "";
  resultsStores.confirmAssignReferenceModal = false;
  filterModel.value = false;
  disableMatchReferences.value = true;
  selectedSubcategory.value = "";
  recommendedReferences.value = "";
  states.pdfShow = "";
  selectAllChecked.value = false;
};

function updateInvoiceSelection(currentIndex) {
  renderInvoices(true, selectedInvoice.value);
  selectedIndex.value = currentIndex;
  const selectedItem = sortedInvoices.value[currentIndex];
  selectedInvoice.value = [];

  if (selectedItem) {
    referencePayload.ids = [selectedInvoice.value];
    handleInvoiceChange(currentIndex);
    togglePdf(selectedItem, currentIndex);
  }
}

const handleAssignClick = async () => {
  try {
    const category = "unlabelled";
    const response = await assignReference(
      assignButtonLoading,
      selectedMatchReferences,
      ResultsRepository,
      toast,
      resultsStores,
      organization,
      resetReferences,
      referencePayload,
      category
    );

    if (response.status && response.status === 200) {
      if (sortedInvoices.value.length === 0) {
        resetReferences();
        return;
      }
    } else {
      states.pdfShow = "";
      selectedInvoice.value = [];
    }
    await updateInvoiceSelection(selectedIndex.value);
    selectedInvoice.value = [];
  } catch (error) {
    selectedInvoice.value = [];
    console.error("Error in handleAssignClick:", error);
    // Handle the error appropriately
  }
};

const renderInvoices = (remove, object) => {
  try {
    sidebarLoading.value = true;
    if (remove && object) {
      if (invoicesType.value === "MultipleMatches") {
        states.referenceInvoices.multipleMatches.details =
          states.referenceInvoices.multipleMatches.details.filter(
            (inv) => !object.includes(inv._id)
          );
      } else if (invoicesType.value === "Closed References") {
        states.referenceInvoices.closed.details =
          states.referenceInvoices.closed.details.filter(
            (inv) => !object.includes(inv._id)
          );
      } else {
        states.referenceInvoices.unknowns.details =
          states.referenceInvoices.unknowns.details.filter(
            (inv) => !object.includes(inv._id)
          );
      }
    } else {
      referencePayload.ids = [];
      referencePayload.s3_paths = [];
      assignButtonLoading.value = true;
    }

    if (invoicesType.value === "MultipleMatches") {
      states.invoices = states.referenceInvoices.multipleMatches.details;
    } else if (invoicesType.value === "Closed References") {
      states.invoices = states.referenceInvoices.closed.details;
    } else {
      states.invoices = states.referenceInvoices.unknowns.details;
    }

    setTimeout(() => {
      assignButtonLoading.value = false;
    }, 200);
  } catch (error) {
    throw new Error(error);
  } finally {
    setTimeout(() => {
      sidebarLoading.value = false;
      assignButtonLoading.value = false;
    }, 800);
  }
};

const handleInvoiceTypeChange = () => {
  resetReferences();
  isConfirm.value = false;
  renderInvoices();
  selectedInvoice.value = [];
};

const getReferenceInvoices = async () => {
  try {
    await resultsStores.getReferencesInvoices(
      userName.value,
      organization.value
    );
    states.invoiceReferenceType =
      resultsStores.referencesResponses?.data?.results?.map((item) => ({
        name: item?.reference,
      }));
    if (resultsStores.referencesResponses?.data?.results.length !== 0) {
      // Initialize states
      states.referenceInvoices.multipleMatches = "";
      states.total_references_seen = [];
      states.referenceInvoices.unknowns = {};
      states.referenceInvoices.closed = {};
      resultsStores.referencesResponses?.data?.results.forEach((item) => {
        if (item?.reference === "MultipleMatches") {
          states.referenceInvoices.multipleMatches = item;
          states.total_references_seen = item?.total_references_seen;
        }
        if (item?.reference === "Unknown") {
          states.referenceInvoices.unknowns = item;
        }
        if (item?.reference === "Closed") {
          states.referenceInvoices.closed = item;
          states.total_references_seen = item?.total_references_seen;
        }
      });
      renderInvoices();
    } else {
      states.referenceInvoices = {
        multipleMatches: "",
        unknowns: {},
      };
    }
  } catch (error) {
    throw new Error(error);
  }
};

const updateReferences = () => {
  referenceCategories.value = resultsStores.referenceCategories.options;
  subcategory.value = Object?.values(
    resultsStores.referenceCategories.options
  ).map((item) => ({ name: item }));
  dropdownData.value = subcategory.value;
};

onMounted(async () => {
  try {
    loading.value = true;
    await getReferenceInvoices();
    await resultsStores.getFilterData(organization.value, false);
    updateReferences();
  } catch (error) {
    throw new Error(error, "Error");
  } finally {
    loading.value = false;
  }
});

const handleClickOutside = (event) => {
  if (!showDropdown.value) return;

  const dropdown = document.querySelector(".list-dropdown");
  if (!dropdown || !dropdown.contains(event.target)) {
    showDropdown.value = false;
  }
};

watchEffect((onInvalidate) => {
  document.addEventListener("click", handleClickOutside);
  onInvalidate(() => {
    document.removeEventListener("click", handleClickOutside);
  });
});
</script>

<style scoped>
main-container {
  height: 83.95vh !important;
}

.container-height {
  height: 80.4vh !important;
}

/* Hide the scrollbar */
.hide-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Modify the overflow property */
.hide-scrollbar {
  overflow: auto;
}

.custom-scrollbar {
  width: 280px;
  margin-top: 8rem;
  overflow-y: auto;
  /* Customize the scrollbar */
  scrollbar-width: thin;
  /* Set the width of the scrollbar */
  scrollbar-color: transparent transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 2px solid transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.radio-style {
  position: absolute;
  height: 50px;
  bottom: -2px;
  padding-top: 21px;
  width: 100%;
  z-index: 10000;
}

.date {
  font-size: 10px;
  position: absolute;
  top: 2rem;
  left: 14%;
}

.sort-button {
  top: 8.55rem;
  left: 40.5rem;
}

@keyframes invoiceSelected {
  0% {
    background-color: #f3f3f3;
    /* Initial background color */
    transform: scale(1);
  }

  50% {
    background-color: #e3e3e3;
    /* Midway color during animation */
    transform: scale(1.1);
  }

  100% {
    background-color: #3f51b5;
    /* Final background color */
    transform: scale(1);
  }
}

.invoice-selected {
  animation-name: invoiceSelected;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

.chip-wrapper {
  max-width: 35rem;
  overflow-x: auto;
  padding: 10px 0;
}

.chip-container {
  display: inline-flex;
  align-items: center;
}
</style>
