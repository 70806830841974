import Client from './Clients/AxiosClient';
import { appConfig } from "@/config";

export default {
    submitReport(data, config) {
        const params = new URLSearchParams();
        params.append('job_id', data.job_id);
        params.append('user', data.user);
        params.append('message', data.message);
        params.append('organization', data.organization);
        return Client.post(`${appConfig.apiSubmitReport}?${params.toString()}`, data, config);
    }
}