<template>
    <div class="mt-auto pt-4" style="height: 90px">
    <form @submit.prevent="addComment">
      <div
        class="flex gap-1 w-full bg-white-400 shadow-1 items-center align-items-center px-2"
        style="height: 45px; border-radius: 41px !important"
      >
        <Avatar
          label="U"
          style="
            background-color: #dee9fc;
            color: #1a2551;
            height: 30px;
            width: 30px;
            border-radius: 50%;
          "
          class="flex-shrink-0"
          shape="circle"
        ></Avatar>
        <InputText
          v-model="comment"
          class="w-full"
          placeholder="Write Comment"
          :disabled="addingComment"
          style="
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
          "
        />
        <div class="flex gap-1">
          <Button
            v-tooltip="'Select Attachments'"
            icon="pi pi-file"
            tabindex="1"
            text
            rounded
            :disabled="addingComment"
            @click="handleSelectAttachments"
          />
          <Button
            icon="pi pi-check"
            text
            rounded
            tabindex="0"
            :loading="addingComment || uploadingAttachments"
            type="submit"
          />
        </div>
      </div>
    </form>
    <p>
      <a
        href="#"
        class="hover:underline no-underline text-primary"
        @click="showAttachments = true"
      >
        Selected attachments: {{ commentAttachments.length }}.
      </a>
      <Dialog
        v-model:visible="showAttachments"
        modal
        :style="{ width: '40vw' }"
        header="Attachments"
      >
        <ul>
          <li
            v-for="(attachment, index) in commentAttachments"
            :key="index"
            class="mb-2"
          >
            <div class="flex gap-2 align-items-center">
              {{ attachment.name }}
              <Button
                v-tooltip="'Remove Attachment'"
                icon="pi pi-times"
                class="ml-auto"
                rounded
                text
                severity="danger"
                @click="
                  commentAttachments.splice(index, 1);
                  showAttachments =
                    commentAttachments.length == 0 ? false : true;
                "
              ></Button>
            </div>
          </li>
        </ul>
      </Dialog>
    </p>
    <input
      ref="fileSelectInput"
      type="file"
      multiple
      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,application/xml"
      class="hidden"
      @change="handleFileSelect"
    />
  </div>
</template>


<script setup>

import { ref, computed } from 'vue';

import {storeToRefs} from "pinia"
import { useToast } from 'primevue/usetoast';
import get from "lodash.get"
import JSZip from 'jszip';
import { useUserSessionStore } from "@/store/userSession";

const comment = ref('');

import { useIssuesStore } from "../../../store/apcheck/issues"

import Repository from "@repositories/RepositoryFactory";
const ApCheckInvoiceIssues = Repository.get("ApCheckInvoiceIssues");

const apCheckIssuesStore = useIssuesStore()
const showAttachments = ref(false);
const toast = useToast();
const userSession = useUserSessionStore();
const { userName } = storeToRefs(userSession);
const commentAttachments = ref([]);

const issue = computed(() => {
  return apCheckIssuesStore.currentIssue
})

const fileSelectInput = ref();
const handleSelectAttachments = () => {
  console.log(fileSelectInput.value)
  fileSelectInput.value.click();
};
const handleFileSelect = (e) => {
  const files = e.target.files;
  if (files.length > 5) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'You can only select up to 5 attachments',
    });
    return;
  }
  if (files.length) {
    commentAttachments.value = [...commentAttachments.value, ...files];
  }
};


const uploadingAttachments = ref(false);

const uploadAttachments = async () => {
  uploadingAttachments.value = true;
  const zipper = new JSZip();
  commentAttachments.value.forEach((file) => {
    zipper.file(file.name, file);
  });

  let content = await zipper.generateAsync({ type: 'blob' });
  ApCheckInvoiceIssues.uploadAttachments(content)
};


const addingComment = ref(false);
const addComment = async () => {
  let attachmentsUpload = {};
  if (!comment.value) return;
  if (commentAttachments.value.length > 0) {
    try {
      attachmentsUpload = await uploadAttachments();
    } catch (e) {
      console.error(e);
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error uploading attachments',
        baseZIndex: 1111,
      });
      addingComment.value = false;
      return;
    }
  }
  addingComment.value = true;
  ApCheckInvoiceIssues.addComment({
    issueId: issue.value.invoice_issue_id,
    comment: comment.value,
    attachments: get(attachmentsUpload, 'data.data', []),
  }).then(() => {
    apCheckIssuesStore.addComment({
      issueId: issue.value.invoice_issue_id,
      comment: comment.value,
      attachments: get(attachmentsUpload, 'data.data', []),
      user: userName.value
    });
  }).finally(() => {
    addingComment.value = false;
    comment.value = '';
    commentAttachments.value = [];
  });
}

</script>