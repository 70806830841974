
<template>
    <div class="surface-card border-round shadow-2 p-4 text-center" style="width: 40vw;">
        <img src="../assets/content.svg" alt="Image" class="mx-auto block mb-4">
        <div class="text-900 font-medium mb-2 text-xl">Create a Report</div>
        <p class="mt-0 mb-4 p-0 line-height-3">Click on the Show Filters button, select either Invoice or Job date, Then after selecting date range, choose either the Emisor or References you want to include in the Report.
Once all the required References or Emisors are selected Click on Get Report.</p>
    </div>
</template>
<script>
</script>