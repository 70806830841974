import { ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
import { debounce } from "lodash";
import { useApi } from "../../hooks/useApi";

export const createFiltersObject = (names) => {
  const filters = ref({});

  names.forEach((name) => {
    const filterObj = {
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    };

    if (name === "global") {
      filters.value[name] = filterObj;
    } else {
      filterObj.operator = FilterOperator.AND;
      filters.value[name] = filterObj;
    }
  });

  return filters;
};

export const getSearchResults = (
  searchTerm,
  referencesListAll,
  userActivityStore
) => {
  const fieldsToSearch = [
    "reference",
    "SubTotal",
    "Impuestos",
    "Total",
    "num_invoices",
    "emisor_rfc",
    "_id",
    "emisor_nombre",
    "receptor_rfc",
    "moneda",
    "receptor_nombre",
  ];

  if (referencesListAll.value !== null) {
    const searchResults = referencesListAll.value.filter((item) => {
      return (
        fieldsToSearch.some((field) =>
          item[field]?.toString().toLowerCase().includes(searchTerm)
        ) ||
        item.details.some((item2) =>
          fieldsToSearch.some((field) =>
            item2[field]?.toString().toLowerCase().includes(searchTerm)
          )
        )
      );
    });
    return searchResults;
  } else {
    const searchResults = userActivityStore.referencesListAll.filter((item) => {
      return (
        fieldsToSearch.some((field) =>
          item[field]?.toString().toLowerCase().includes(searchTerm)
        ) ||
        item.details.some((item2) =>
          fieldsToSearch.some((field) =>
            item2[field]?.toString().toLowerCase().includes(searchTerm)
          )
        )
      );
    });
    return searchResults;
  }
};

export const globalHeadingClass = (data) => {
  const dataNumber = parseInt(data);
  return [
    "border-round px-2 min-w-2rem min-h-2rem inline-flex font-bold justify-content-center align-items-center text-sm",
    {
      "bg-red-100 text-red-900": dataNumber === 0,
      "bg-blue-100 text-blue-900": dataNumber > 0 && dataNumber < 10,
      "bg-teal-100 text-teal-900": dataNumber > 10,
    },
  ];
};

export const filterArrayBySearchTerm = (array, searchTerm) => {
  const normalizedSearchTerm = searchTerm ? searchTerm.toLowerCase() : "";

  if (!normalizedSearchTerm) {
    return array;
  } else {
    return array.filter((item) =>
      item.toLowerCase().includes(normalizedSearchTerm)
    );
  }
};

export const assignReference = async (
  assignButtonLoading,
  selectedMatchReferences,
  ResultsRepository,
  toast,
  resultsStores,
  organization,
  resetReferences,
  referencePayload,
  category,
  jobStore
) => {
  if (referencePayload.reference) {
    try {
      assignButtonLoading.value = true;
      const response = await ResultsRepository.assignReferences(
        {
          reference: referencePayload.reference,
          user: referencePayload.user,
          ids: referencePayload.ids,
          s3_paths: referencePayload.s3_paths,
          organization: referencePayload.organization,
          category: category,
        },
        { header: { "Content-Type": "application/json" } }
      );
      toast.add({
        severity: "success",
        summary:
          response?.data?.message ||
          "Reference successfully assigned to invoice",
        life: 10000,
      });
      resetReferences();
      setTimeout(() => {
      resultsStores.isReload = true;
      }, 3000);
      assignButtonLoading.value = false;
      if (jobStore?.isTagUpdated) {
        jobStore.isTagUpdated = true;
      }
      return response;
    } catch (error) {
      resetReferences();
      assignButtonLoading.value = false;
      throw new Error("Assign reference", error);
    }
  } else {
    toast.add({
      severity: "info",
      summary: "Please select a reference before assigning",
      life: 3000,
    });
  }
};

export const handleSubCategoryChangeLogic = (
  selectedSubcategory,
  selectedMatchReferences,
  referenceCategories,
  matchedReferences,
  disableMatchReferences,
  subcategory,
  resultsStores
) => {
  if (
    (selectedSubcategory && selectedSubcategory.name) ||
    (selectedSubcategory && selectedSubcategory.value.name)
  ) {
    selectedMatchReferences = "";
    if (selectedMatchReferences.value) {
      selectedMatchReferences.value = "";
    }
    const selectedCategoryValue = selectedMatchReferences.value?.name
      ? referenceCategories.value[selectedSubcategory.value?.name]
      : referenceCategories.value[selectedSubcategory.name];
    const arr = Object.entries(selectedCategoryValue).map(([key, value]) => ({
      label: key.toUpperCase(),
      items: value.map((item) => ({ label: item })),
    }));
    const matchesIndex = arr.findIndex((obj) => obj.label === "MATCHED");
    if (matchesIndex !== -1) {
      const matchesObj = arr.splice(matchesIndex, 1)[0];
      arr.unshift(matchesObj);
    }
    matchedReferences.value = arr;
    disableMatchReferences.value = false;
  } else {
    const selectedSubcategoryValue = selectedSubcategory.value?.label
      ? selectedSubcategory.value?.label
      : selectedSubcategory.label || "";
    const filteredSubcategory = filterArrayBySearchTerm(
      Object.keys(resultsStores.referenceCategories.options),
      selectedSubcategoryValue
    );
    subcategory.value =
      filteredSubcategory?.map((item) => ({ name: item })) ||
      Object.keys(resultsStores.referenceCategories.options).map((item) => ({
        name: item,
      }));
  }
};

// modalUtils.js
export const toggleModal = (
  selectedMatchReferences,
  referencePayload,
  confirmModal,
  toast
) => {
  if (!referencePayload.reference.length) {
    toast.add({
      severity: "info",
      summary: "Please select a reference before assigning",
      life: 5000,
    });
  } else if (!referencePayload.ids.length) {
    toast.add({
      severity: "info",
      summary: "Please select an Invoice before assigning",
      life: 5000,
    });
  } else {
    confirmModal.value = true;
  }
};

export const toggleViewModel = (
  data,
  pdfLoading,
  referenceCategories,
  getDownloadLinks,
  referencePayload,
  userName,
  organization,
  resultsStores
) => {
  try {
    referenceCategories.value = resultsStores.referenceCategories.options;
    const s3Path = data["s3_path"].replace(/\/$/, "") + "/" + data._id;
    getDownloadLinks(data?._id, s3Path);
    referencePayload.ids = [data._id];
    referencePayload.s3_paths = [data.s3_path];
    referencePayload.user = userName.value;
    referencePayload.organization = organization.value;
  } catch (error) {
    throw new Error("toggleViewModel", error);
  } finally {
    // pdfLoading.value = false;
  }
};

export const formatDateTime = (dateTimeString) => {
  const dateTime = moment(dateTimeString);
  const now = moment();

  if (dateTime.isSame(now, "day")) {
    return `Today at ${dateTime.format("HH:mm")}`;
  }

  return dateTimeString;
};

const performSearch = debounce(
  (searchText, dropdownData, isTyping, subcategory) => {
    const filteredData = subcategory.value.filter((item) =>
      item.name.includes(searchText?.toUpperCase())
    );

    dropdownData.value = filteredData;
    isTyping.value = false;
  },
  600
);

export const handleDropDownChange = (
  selectedSubcategory,
  isTyping,
  dropdownData,
  subcategory
) => {
  const searchText = selectedSubcategory.value
    ? selectedSubcategory.value
    : selectedSubcategory;
  isTyping.value = true;

  if (isTyping.value) {
    performSearch(searchText, dropdownData, isTyping, subcategory);
  } else {
    performSearch.flush();
    performSearch.cancel();
    performSearch(searchText);
  }
};

// exportReports.js
export const exportReports = async ({
  reportExportTimeout,
  exportingReport,
  retryOption,
  exportPayload,
  organization,
  exportType,
  userActivityResponse,
  isError,
  reportExportResponse,
  exportStatus,
  downloadExport,
}) => {
  clearTimeout(reportExportTimeout.value);
  exportingReport.value = true;
  retryOption.value = false;
  exportPayload.value = {
    organization: organization.value,
    export_zip_name:
      exportType.value.name === "Reference"
        ? userActivityResponse.value.export_zip_name.reference
        : userActivityResponse.value.export_zip_name.issuer,
  };

  const {
    data: exportReportsResponse,
    error: errorRef,
    loading: loadingRef,
    fetchItems: fetchExportReports,
  } = useApi("/report/export", exportPayload.value);

  isError.value = errorRef.value;

  await fetchExportReports().then(() => {
    reportExportResponse.value = exportReportsResponse.value;
    if (reportExportResponse.value?.status === exportStatus.PENDING) {
      reportExportTimeout.value = setTimeout(async () => {
        await fetchExportReports();
        if (exportReportsResponse.value?.status !== exportStatus.PENDING) {
          reportExportResponse.value = exportReportsResponse.value;
          downloadExport();
          clearTimeout(reportExportTimeout.value);
          reportExportTimeout.value = null;
          exportingReport.value = false;
        }
      }, 7000);
      isError.value = errorRef.value;
    } else {
      downloadExport();
      clearTimeout(reportExportTimeout.value);
      reportExportTimeout.value = null;
      exportingReport.value = loadingRef.value;
      isError.value = errorRef.value;
    }
  });
};
