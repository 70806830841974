import Client from "./Clients/AxiosClient";

export default {
  getOrganization: (organization_name) => {
    return Client.get(`/hub/admin/organization/`, {
      params: { organization_name },
    });
  },
  createOrganization: (data) => {
    return Client.post(`/hub/admin/organization/`, data);
  },
  updateOrganization: (data) => {
    return Client.put(`/hub/admin/organization/`, data);
  },
  createConfiguration: (data) => {
    return Client.post(`/hub/admin/configuration/`, data);
  },
  updateConfiguration: (data) => {
    return Client.put(`/hub/admin/configuration/`, data);
  },
  getOrganizationUsers: (payload) => {
    return Client.get(`/hub/user/all`, { params: { ...payload } });
  },
  createOrganizationUser: (data) => {
    return Client.post(`/hub/user/create`, data);
  },
  updateOrganizationUser: (data) => {
    return Client.put("/admin/organization/user", data);
  },
  deactivateOrganizationUser: (id) => {
    return Client.post("/admin/organization/user/deactivate", id);
  },
  activateOrganizationUser: (id) => {
    return Client.post("/admin/organization/user/activate", id);
  },
  deleteOrganizationUser: (id) => {
    return Client.delete("/hub/user?user_id=" + id);
  },
  createRoleForUser: (title) => {
    return Client.post("/admin/organization/role", title);
  },
  assignRoleToUser: (data) => {
    return Client.post("/admin/organization/role", data);
  },
  resendInvite: (data) => {
    return Client.post("/hub/user/resend-invite", data);
  },
};
