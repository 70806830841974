<template>
  <div>
    <div class="flex flex-column flex-auto px-4 py-5">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 surface-border mb-4"
      >
        <div class="flex align-items-center">
          <i class="pi pi-upload text-2xl mr-3 text-500"></i>
          <span class="text-3xl font-medium text-900">Process Invoices</span>
        </div>
        <div v-if="pdfOnly && !isProduction" class="flex">
          <div class="mr-2">
            <b>Mock Data :</b>
          </div>
          <div class="flex align-items-center mr-4">
            <RadioButton
              v-model="isMock"
              inputId="ingredient1"
              name="true"
              :value="true"
            />
            <label for="ingredient1" class="ml-2">True</label>
          </div>
          <div class="flex align-items-center">
            <RadioButton
              v-model="isMock"
              inputId="ingredient2"
              name="false"
              :value="false"
            />
            <label for="ingredient2" class="ml-2">False</label>
          </div>
        </div>
      </div>
      <Steps
        class="mb-4"
        v-model:activeStep="processInvoicesStep"
        :model="uploadDialogsSteps"
        :readonly="true"
      />
      <!--  Process invoices v2 view start  -->
      <FileUpload
        ref="fileUpload"
        mode="advanced"
        :customUpload="true"
        :fileLimit="fileLimit"
        accept=".pdf,.xml"
        multiple
        @select="onFileUpload"
        @remove="onFileRemove"
        @clear="onFilesClear"
        :disabled="!disableBatch"
      >
        <template #empty>
          <div
            class="border-3 border-2 border-dotted border-blue-400 py-5 process-invoice-uploader"
          >
            <div>
              <div>
                <PdfIcon
                  fill="#428bff"
                  height="100"
                  width="100"
                  style="transform: rotate(-12deg)"
                ></PdfIcon>
                <XmlIcon
                  fill="#428bff"
                  height="100"
                  width="100"
                  style="transform: rotate(12deg)"
                ></XmlIcon>
              </div>
              <div>
                <h2
                  class="text-5xl mb-0"
                  style="font-weight: 900; letter-spacing: -2px"
                >
                  Drag & Drop
                  <span class="" style="color: #428bff">PDF</span> and
                  <span style="color: #428bff">XML</span> <br />
                  files to upload
                </h2>
                <p class="mt-1 overflow-hidden">
                  or
                  <a
                    tabindex="0"
                    @keypress.enter="openFileSelection"
                    @click="openFileSelection"
                    class="cursor-pointer browse-files"
                    ><span>browse files</span></a
                  >
                  from your device
                </p>
              </div>
            </div>
          </div>
        </template>
        <template #header><span></span></template>
        <template #content="{ files, removeFileCallback }">
          <div
            class="process-invoice-uploader relative selected border-3 border-2 border-dotted border-blue-400"
            v-if="files && files.length > 0"
          >
            <div
              v-show="isUploading"
              v-bind:class="{
                'absolute left-0 right-0 bottom-0 top-0 bg-white z-5 h-full w-full transition-delay-200 py-7': true,
              }"
              style="overflow: hidden"
            >
              <p class="m-0 -mt-5 px-3">
                &#x1F4A1; Your invoices are being uploaded and processed right
                now. Please do not navigate away from this page. <br />
                You will redirect to invoice details page in a while
              </p>
              <img
                v-if="processInvoicesStep === 1"
                src="/public/Animation.gif"
                alt="processing invoice"
                class="h-full"
                style="height: 30rem !important; width: 25rem"
              />
              <img
                v-if="processInvoicesStep !== 1"
                src="/public/processing.gif"
                alt="processing invoice"
                class="h-full"
                style="height: 30rem !important; width: 25rem"
              />
              <ProgressBar
                v-if="processInvoicesStep === 1 || processInvoicesStep === 2"
                mode="indeterminate"
                style="height: 6px"
              ></ProgressBar>
              <h3 class="-mt-3">
                {{
                  processInvoicesStep === 1
                    ? "Uploading Files"
                    : processInvoicesStep === 2
                    ? "Processing Job"
                    : ""
                }}
              </h3>
            </div>
            <div class="flex justify-content-between align-items-center h-auto">
              <h3 v-if="pdfOnly" class="text-left ml-4 mb-3">
                Files: ( {{ fileCounter }} / 200 )
              </h3>
              <h3 v-else class="text-left ml-4 mb-3">
                Files: ( {{ fileCounter }} / 2 =
                <strong> {{ Math.floor(fileCounter / 2) }} </strong> ) / 200
              </h3>
              <a
                tabindex="0"
                @keypress.enter="openFileSelection"
                @click="openFileSelection"
                class="cursor-pointer add-more"
                ><span>Add more files </span></a
              >
            </div>
            <div>
              <div class="selected-files-grid">
                <div
                  v-for="(file, index) of files"
                  :key="file.name + file.type + file.size"
                  class="card text]-sm m-0 px-2 shadow-2 flex border-1 surface-border gap-2 uploaded-item-preview"
                  :class="{ highlighted: highlightMissingFIles(file.name) }"
                >
                  <div class="">
                    <PdfIcon
                      v-if="
                        file.name.includes('.pdf') || file.name.includes('.PDF')
                      "
                      fill="#428bff"
                      height="100px"
                      width="65px"
                    ></PdfIcon>
                    <XmlIcon
                      v-else
                      fill="#428bff"
                      height="100px"
                      width="65px"
                    ></XmlIcon>
                  </div>
                  <div
                    class="w-full text-left flex-column flex justify-content-between py-2"
                  >
                    <p
                      class="font-bold m-0 mt-1"
                      style="word-break: break-all; width: 30rem"
                    >
                      {{ file.name }}
                    </p>
                    <div class="flex justify-content-between">
                      <span style="font-size: 12px" class="">{{
                        formatSize(file.size)
                      }}</span>
                      <Button
                        icon="pi pi-trash"
                        class="delete-file-button"
                        @click="
                          onRemoveTemplatingFile(
                            file,
                            removeFileCallback,
                            index
                          )
                        "
                        outlined
                        rounded
                        severity="danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-bind:class="{
              'flex justify-content-between px-2 py-2 border-top-1 border-gray-200 mx-2': true,
              'opacity-50 pointer-events-none': loading,
            }"
          >
            <Button
              :disabled="loading"
              type="button"
              label="Clear All"
              severity="danger"
              outlined
              v-if="!disabledComp"
              icon="pi pi-times"
              class="mr-4"
              @click="clearAllInvoices"
            />
            <Button
              class="compress-and-upload-files"
              v-if="files.length > 0"
              label="Compress and Upload Files"
              :loading="loading"
              @click="uploadMultiFiles"
              icon="pi pi-cloud-upload"
              :disabled="disabledComp"
            />
          </div>
        </template>
      </FileUpload>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { useAppConfig } from "@/config";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import Repository from "@repositories/RepositoryFactory";
import { useValidateUploadFiles } from "@composables/useValidations";
import XmlIcon from "@components/XmlIcon.vue";
import PdfIcon from "@components/PdfIcon.vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import JSZip from "jszip";
import retry from "promise-retry";
import { useApi } from "../../hooks/useApi";
import { formatSize } from "../composables/useUtils";
import { watch } from "vue";
import { serviceStore } from "../store/serviceStore";

const resultsStores = serviceStore();
// Global config constants
const appConfig = useAppConfig();
const fileUpload = ref(null);
const uploadDialogsSteps = ref([
  {
    label: "Select files",
  },
  {
    label: "Uploading files",
  },
  {
    label: "Processing job",
  },
  {
    label: "Job Started",
  },
]);

const openFileSelection = () => {
  fileUpload.value.choose();
};
const UploaderRepository = Repository.get("uploader");
const userSession = useUserSessionStore();
const { userName, organization, attributes } = storeToRefs(userSession);
const email = ref(attributes.value.email);
const toast = useToast();
const fileLimit = ref(200);

const disableBatch = ref(true);
const disabledJobPreview = ref(true);
const disabledComp = ref(true);
const loading = ref(false);
const isUploading = ref(false);
const missingFiles = ref([]);
const formData = reactive({
  name: "",
  files: [],
});
const pdfOnly = ref(false);
const { onFileUpload, onFileRemove, onFilesClear, fileCounter } =
  useValidateUploadFiles(toast, fileLimit, disabledComp, formData, fileUpload);

const processInvoicesStep = ref(0);
const router = useRouter();

const clearAllInvoices = () => {
  missingFiles.value = [];
  fileUpload.value.clear();
};

const isProduction = import.meta.env.MODE === "production";

const isMock = ref(isProduction ? false : true);
const uploadDialogImage = ref("/Animation.gif");

const result = reactive({
  job_id: "",
  message: "",
  s3_KEY_path: "",
  num_invoice_pairs: 0,
  files_success: [],
  unmatched_files: [],
  s3_directory: {
    bucket: "",
    path: "",
  },
});

const sortUploadFiles = async () => {
  processInvoicesStep.value = 1;

  const files = formData.files;

  let files_needed = [];
  let curr_file;
  for (let i = 0; i < files.length; i++) {
    let f = files[i];
    curr_file = f.name.toLowerCase();
    if (!curr_file.includes(".xml")) {
      pdfOnly.value = true;
    } else {
      pdfOnly.value = false;
    }

    const match_index = files_needed.indexOf(curr_file);

    if (match_index !== -1) {
      files_needed.splice(match_index, 1);
      continue;
    }

    if (curr_file.includes(".xml")) {
      files_needed.push(curr_file.replace(".xml", ".pdf"));
      continue;
    }

    if (curr_file.includes(".pdf")) {
      files_needed.push(curr_file.replace(".pdf", ".xml"));
      continue;
    }

    toast.add({
      severity: "error",
      summary: "File not Allowed...",
      detail:
        "Only PDF and XML file(s) are allowed. " +
        curr_file +
        " is not allowed.",
    });
  }

  if (files_needed.length > 0 && !pdfOnly.value) {
    if (files_needed.toString().includes(",")) {
      const files = files_needed.toString().split(",");
      missingFiles.value = files;
    } else {
      missingFiles.value = [files_needed.toString()];
    }

    const modifiedFilenames = missingFiles.value.map((filename) => {
      const dotIndex = filename.lastIndexOf(".");
      return filename.substring(0, dotIndex);
    });

    missingFiles.value = modifiedFilenames;

    toast.remove({
      group: "process_invoice_missing_files_error",
    });
    toast.add({
      group: "process_invoice_missing_files_error",
      life: 5000,
      severity: "warn",
      summary: "Missing file...",
      detail: "Please upload the missing file(s): " + files_needed.join(", "),
    });

    processInvoicesStep.value = 0;
    files_needed.length = 0;
    return;
  }

  // 7mb
  const chunkSizeLimit = 4 * 1024 * 1024;
  const chunks = {};
  let currentChunkIndex = 0;
  const getCurrentChunkFilesSize = () =>
    chunks[currentChunkIndex].map((c) => c.size).reduce((a, b) => a + b, 0);

  // loop through files array
  for (let i = 0; i < files.length; i++) {
    let file = files[i];

    if (!chunks[currentChunkIndex]) {
      chunks[currentChunkIndex] = [];
    }

    const nextChunkSizeIfFileIsAdded = getCurrentChunkFilesSize() + file.size;

    if (
      getCurrentChunkFilesSize() > chunkSizeLimit ||
      nextChunkSizeIfFileIsAdded > chunkSizeLimit
    ) {
      // write debug log here
      currentChunkIndex++;
    }
    chunks[currentChunkIndex] = [...(chunks[currentChunkIndex] || []), file];
  }

  const filesChunksArr = Object.keys(chunks).map((c) => chunks[c]);

  for (let i = 0; i < filesChunksArr.length; i++) {
    const invoicesChunk = filesChunksArr[i];

    const zipper = new JSZip();
    invoicesChunk.forEach((f) => {
      zipper.file(f.name, f);
    });

    const isLastChunk = i === filesChunksArr.length - 1;
    const isFirstChunk = i === 0;

    if (!isFirstChunk && !result.job_id) {
      toast.add({
        severity: "info",
        summary: "Something went wrong while zipping the invoices...",
        detail: "Please try again later.",
      });
      return;
    }
    await retry(() => upload(zipper, isLastChunk, isFirstChunk), {
      retries: 5,
    });
  }
};

const highlightMissingFIles = (FileName) => {
  const dotIndex = FileName.lastIndexOf(".");
  const modifiedFilename = FileName.substring(0, dotIndex);
  return missingFiles.value.some((item) =>
    modifiedFilename.toLowerCase().includes(item.toLowerCase())
  );
};

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
  removeFileCallback(index);
};

let fetchAndVerifyJobInterval = null;
let fetchAndVerifyJobIntervalCount = ref(0);
const fetchAndVerifyJob = (job_id = "") => {
  const params = {
    user: userName.value,
    email: email.value,
    job_id,
    organization: organization.value,
  };
  const { data: jobDetailResponse, fetchItems: getJobDetails } = useApi(
    "/results/transaction_status",
    params
  );

  fetchAndVerifyJobInterval = setInterval(async () => {
    if (fetchAndVerifyJobIntervalCount.value > 2) {
      clearInterval(fetchAndVerifyJobInterval);
      toast.add({
        life: 7 * 1000,
        severity: "info",
        summary: "Something went wrong while processing the job...",
        detail: "Please try again later.",
      });
      return;
    }
    await getJobDetails();
    fetchAndVerifyJobIntervalCount.value += 1;
    if (
      jobDetailResponse.value &&
      ["Complete", "In Progress", "Verification"].includes(
        jobDetailResponse.value["status"]
      )
    ) {
      clearInterval(fetchAndVerifyJobInterval);
      processInvoicesStep.value = 3;
      setTimeout(() => {
        loading.value = false;
        resultsStores.makePdfOnlyFetch = true;
        router.push(`/jobs/${job_id}`);
      }, 1500);
    }
  }, 5000);
};

const upload = async (zip, _isLastChunk = false, _isFirstChunk = true) => {
  try {
    loading.value = true;
    isUploading.value = true;
    const content = await zip.generateAsync({ type: "blob" });

    let data = {
      file: content,
      email: userName.value,
      user: userName.value,
      organization: organization.value,
      accept_files: pdfOnly.value === true ? "pdf" : "pdf,xml",
      mock: isMock.value,
    };

    if (!pdfOnly.value) {
      delete data.mock;
    }

    if (_isLastChunk) {
      data["s3_xlsx_key"] = "TRUE";
    }

    if (!_isFirstChunk) {
      data["job_id"] = result.job_id;
    }

    const response = await UploaderRepository.zipBatchUpload(data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (_isFirstChunk) {
      result.job_id = response.data.job_id;
    }
    result.message = response.data.message;
    result.s3_KEY_path = response.data.s3_KEY_path;
    result.num_invoice_pairs = response.data.num_invoice_pairs;
    result.files_success = response.data.files_success;
    result.unmatched_files = response.data.unmatched_files;
    result.s3_directory.bucket = response.data.s3_directory.bucket;
    result.s3_directory.path = response.data.s3_directory.path;

    if (_isLastChunk) {
      // result.job_id = "";
      processInvoicesStep.value = 2;
      // uploadDialogImage.value = '/processing.gif'
      disabledJobPreview.value = false;

      fetchAndVerifyJob(response.data.job_id);
    }
  } catch (error) {
    loading.value = false;
    toast.add({
      life: 7 * 1000,
      severity: "info",
      summary: "Something went wrong while zipping the invoices...",
      detail: error?.response?.data?.message || error.message,
    });
    throw new Error(error);
  } finally {
    // Reset the file input
  }
};

const uploadMultiFiles = async () => {
  await sortUploadFiles();
};

const getXlsxList = async () => {
  const isFileUploaded = localStorage.getItem("isXlsUploaded");
  disableBatch.value = JSON.parse(isFileUploaded);
  if (!disableBatch.value) {
    toast.add({
      severity: "info",
      summary:
        "To Upload and Process Invoices please first provide your Organization References",
      life: 8000,
      position: "top-left",
    });
  }
};

onBeforeUnmount(() => {
  uploadDialogImage.value = "/Animation.gif";
  clearInterval(fetchAndVerifyJobInterval);
  processInvoicesStep.value = 0;
  loading.value = false;
  fetchAndVerifyJobIntervalCount.value = 0;
});

onMounted(async () => {
  const { fetchItems: uploaderWarmup } = useApi("/api/uploader/warmup");

  uploaderWarmup();
  await getXlsxList();
  document.title = appConfig.appName;
});
watch(() => {
  if (formData.files.length) {
    const isXml = formData.files.some((item) => item.type === "text/xml");
    if (!isXml) {
      pdfOnly.value = true;
      fileLimit.value = 200;
    } else {
      pdfOnly.value = false;
      fileLimit.value = 400;
    }
  }
});
</script>

<style scoped>
:deep(.p-fileupload-content) {
  padding: 0 !important;
}

.highlighted {
  background-color: #ff50505e;
  border-radius: 3px;
  padding: 0 2px;
}

.p-fileupload > .p-fileupload-buttonbar {
  background: red !important;
}

img {
  vertical-align: middle;
  max-width: 650px !important;
  max-height: 300px !important;
  margin-bottom: 20px;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

/* ::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
} */

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

  .details-subtable {
    padding: 1rem;
  }
}

:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}

/* Adjust the width of the file list */
:deep(.p-fileupload-files) {
  width: 100%;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-file) {
  width: 100%;
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
}

:deep(.p-fileupload .p-fileupload-content) {
  border: none;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-buttonbar) {
  background: white !important;
  display: none;
}

:deep(.p-fileupload-content) {
  border-top: none !important;
  /* Customize the scrollbar */
  scrollbar-width: thin;
  /* Set the width of the scrollbar */
  scrollbar-color: transparent transparent;
}

:deep(.p-fileupload-content::-webkit-scrollbar) {
  width: 8px;
}

:deep(.p-fileupload-content::-webkit-scrollbar-thumb) {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 2px solid transparent;
}

:deep(.p-fileupload-content::-webkit-scrollbar-thumb:hover) {
  background-color: rgba(0, 0, 0, 0.6);
}

/* :deep(.p-progressbar) {
  display: none !important;
} */

:deep(.p-fileupload-file-thumbnail) {
  width: 100%;
  margin-bottom: -4rem;
}

:deep(img) {
  position: relative;
}

:deep(img[alt]:after) {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
}

.browse-files {
  color: #015df1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #68a2ff;
  padding: 0 3px;
  position: relative;
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: inline;
  background: transparent;
}

.browse-files:before {
  background: #dae9fc;
  display: block;
  content: "";
  position: absolute;
  top: 23px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.2s ease-in;
  height: 22px;
  z-index: 1;
}

.browse-files:focus:before {
  outline: none !important;
}

.browse-files:focus:before,
.browse-files:focus:before,
.browse-files:hover:before {
  top: 0;
}

.browse-files span {
  z-index: 2;
  display: inline;
  position: relative;
}

.add-more {
  color: #015df1;
  height: 24px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #68a2ff;
  padding: 0 3px;
  position: relative;
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: inline;
  background: transparent;
}

.add-more:before {
  background: #dae9fc;
  display: block;
  content: "";
  position: absolute;
  top: 29px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.2s ease-in;
  height: 22px;
  z-index: 1;
}

.add-more:focus:before,
.add-more:hover:before {
  top: 0;
}

.add-more span {
  z-index: 2;
  display: inline;
  position: relative;
}

.process-invoice-uploader:not(.selected) {
  border-radius: 50px;
  height: 503px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-invoice-uploader.selected {
  border-radius: 0;
  border: none !important;
}

.process-invoice-uploader.selected > div {
  height: 378px;
  overflow: auto;
}

.delete-file-button {
  padding: 12px 0;
  height: 20px !important;
  width: 27px !important;
}

:deep(.delete-file-button .pi) {
  font-size: 12px !important;
}

.uploaded-item-preview {
  /* width: 335px; */
  /* min-width: 320px; */
  height: 101px;
}

.selected-files-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 30px 30px;
}

@media (min-width: 768px) {
  .selected-files-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1300px) {
  .selected-files-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1800px) {
  .selected-files-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 2500px) {
  .selected-files-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
