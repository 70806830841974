import Client from "./Clients/AxiosClient";

export default {
  getIssues(filters) {
    const filtersWithoutNull = filters ?  Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key];
      }
      return acc;
    }, {}) : {};
    return Client.get("/ap-check/issues", {
      params: filtersWithoutNull,
    });
  },

  transition(obj) {
    return Client.post("/ap-check/transition", obj);
  },

  addComment({ issueId, comment, attachments }) {
    return Client.put(
      "/ap-check/issue",
      {
        notes: {
          comment: comment,
          attachments: attachments,
        },
      },
      {
        params: {
          invoice_issue_id: issueId,
        },
      }
    );
  },

  updateIssue({ issueId, fields }) {
    return Client.put("/ap-check/issue", fields, {
      params: {
        invoice_issue_id: issueId,
      },
    });
  },

  uploadAttachments(content) {
    return Client.post("/ap-check/upload", content, {});
  },
};
