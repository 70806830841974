import { createWebHistory, createRouter } from "vue-router";
import { Auth } from "aws-amplify";
import HomeView from "@views/HomeView.vue";
import ProcessInvoicesView from "@views/ProcessInvoicesView.vue";
import UserActivityView from "@views/UserActivityView.vue";
import UnknownsManagerView from "@views/UnknownsManagerView.vue";
import JobsView from "@views/JobsView.vue";
import OrgOverview from "@views/OrgOverview.vue";
import JobDetailsView from "@views/JobDetailsView.vue";
import UserSettingsView from "@views/UserSettingsView.vue";
import OrganizationReferences from "@views/OrganizationReferences.vue";
import TagsView from "@views/TagsView.vue";
import Emails from "@views/EmailView.vue";
import logger from "@utils/logger";
import EmisorConfiguration from "@views/EmisorConfiguration.vue";
import EmisorConfigurationAdmin from "@views/EmisorConfigurationAdmin.vue";
import ApCheck from "../views/ApCheck.vue";
import ApCheckInvoiceIssues from "../views/ApCheckInvoiceIssues.vue";
import WorkflowEdit from "../views/WorkflowEdit.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/process",
    name: "process",
    component: ProcessInvoicesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/references",
    name: "references",
    component: OrganizationReferences,
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: UserActivityView,
    meta: { requiresAuth: true },
  },
  {
    path: "/tags",
    name: "tags",
    component: TagsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/labelling",
    name: "unknowns",
    component: UnknownsManagerView,
    meta: { requiresAuth: true },
  },
  {
    path: "/jobs",
    name: "jobs",
    component: JobsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/jobs/:trx_id",
    name: "details",
    component: JobDetailsView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/org-overview",
    name: "org-overview",
    component: OrgOverview,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    component: UserSettingsView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/emails",
    name: "emails",
    component: Emails,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/jobs/invoice-verification/:trx_id",
    name: "invoice-verification",
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/configuration/emisor",
    name: "emisor-configuration",
    component: EmisorConfiguration,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/admin/config/emisor",
    name: "emisor-configuration-admin",
    component: EmisorConfigurationAdmin,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/ap-check",
    name: "ap-check",
    component: ApCheck,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/ap-check/issues",
    name: "ap-check-invoice-issues",
    component: ApCheckInvoiceIssues,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/workflow-edit",
    name: "workflow-edit",
    component: WorkflowEdit,
    meta: { requiresAuth: true },
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await Auth.currentAuthenticatedUser().then(logger.debug);
  const isAuthenticated = await Auth.currentAuthenticatedUser();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      // User is authenticated, allow access
      next();
    } else {
      // User is not authenticated, redirect to login page
      next("/");
    }
  } else {
    // The route does not require authentication
    next();
  }
});

export default router;
