import { appConfig } from "@/config";

const LogLevel = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
  TRACE: 5,
};

const currentLogLevel =
  appConfig.logLevel &&
  appConfig.logLevel.trim() !== "" &&
  Object.keys(LogLevel).includes(appConfig.logLevel)
    ? LogLevel[appConfig.logLevel]
    : appConfig.appModeEnv === "development"
    ? LogLevel.DEBUG // default log level in development
    : LogLevel.ERROR; // default log level elsewhere (production)

const log = (level, ...args) => {
  if (level <= currentLogLevel) {
    const logFunction = {
      [LogLevel.ERROR]: console.error,
      [LogLevel.WARN]: console.warn,
      [LogLevel.INFO]: console.info,
      [LogLevel.DEBUG]: console.debug,
      [LogLevel.TRACE]: console.trace,
    }[level];

    logFunction(...args);
  }
};

export default {
  error: (...args) => log(LogLevel.ERROR, ...args),
  warn: (...args) => log(LogLevel.WARN, ...args),
  info: (...args) => log(LogLevel.INFO, ...args),
  debug: (...args) => log(LogLevel.DEBUG, ...args),
  trace: (...args) => log(LogLevel.TRACE, ...args),
};
