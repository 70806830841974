<template>
  <div>
    <div class="flex flex-column flex-auto px-5 pt-5">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border"
      >
        <div class="flex align-items-center">
          <i class="pi pi-tags text-2xl mr-3 text-500"></i>
          <span class="text-3xl font-medium text-900">Tags </span>
        </div>
        <div class="flex align-items-center gap-2">
          <Button
            label="Download Tags template"
            outlined
            severity="warning"
            icon="pi pi-download"
            @click="openInNewTab(tagsTemplateFile)"
          />
          <Button
            label="Upload Tags File"
            icon="pi pi-upload"
            @click="tagsUploadModal = true"
          />
        </div>
      </div>
      <Dialog
        v-model:visible="tagsUploadModal"
        modal
        header="Tags File Upload"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <FileUpload
          ref="fileUpload"
          @select="onFileSelected"
          accept=".xls,.xlsx"
          :maxFileSize="1000000 * 4"
          chooseLabel="Browse Keyfile"
        >
          <template #content="{ files, removeFileCallback }">
            <div v-if="files.length > 0">
              <div class="selected-files-grid">
                <div
                  v-for="(file, index) of files"
                  :key="file.name + file.type + file.size"
                  class="card text]-sm m-0 px-2 shadow-2 flex border-1 surface-border gap-2 uploaded-item-preview"
                >
                  <div class="">
                    <XlsxIcon width="65px" height="100px" fill="#428bff" />
                  </div>
                  <div
                    class="w-full text-left flex-column flex justify-content-between py-2"
                  >
                    <p class="font-bold m-0 mt-1">
                      {{ limitText(file.name, 25) }}
                    </p>
                    <div class="flex justify-content-between">
                      <span style="font-size: 12px" class="">{{
                        formatSize(file.size)
                      }}</span>
                      <Button
                        icon="pi pi-trash"
                        class="delete-file-button"
                        @click="
                          onRemoveTemplatingFile(
                            file,
                            removeFileCallback,
                            index
                          )
                        "
                        outlined
                        rounded
                        severity="danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div
              class="border-3 border-2 border-dotted border-round border-blue-400 p-5 process-invoice-uploader"
            >
              <div>
                <div>
                  <XlsxIcon
                    fill="#428bff"
                    height="100"
                    width="100"
                    style="transform: rotate(12deg)"
                  />
                  <XlsIcon
                    fill="#428bff"
                    height="100"
                    width="100"
                    style="transform: rotate(-12deg)"
                  />
                </div>
                <div>
                  <h2
                    class="text-5xl mb-0"
                    style="font-weight: 900; letter-spacing: -2px"
                  >
                    Drag & Drop
                    <span class="" style="color: #428bff">Tags File</span>
                    files to upload
                  </h2>
                  <p class="mt-1 overflow-hidden">
                    or
                    <a
                      tabindex="0"
                      @keypress.enter="openFileSelection"
                      @click="openFileSelection"
                      class="cursor-pointer browse-files"
                      ><span>browse files</span></a
                    >
                    from your device
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template #header>
            <div class="flex justify-content-between w-full"></div>
          </template>
        </FileUpload>
        <Button
          label="Upload Tags File"
          class="my-5"
          @click="uploadTagsFile"
          icon="pi pi-send"
          :disabled="disabledProcess"
          :loading="keyfileUploading"
        />
      </Dialog>
    </div>
    <div class="px-5 -pt-5">
      <TagsTableComponent
        :xlsListLoading="xlsListLoading"
        :xlsxList="xlsxList"
        @upload-tags="tagsUploadModal = true"
        @on-page="onPage"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from "vue";
import { useAppConfig } from "@/config";
import { reactive } from "vue";
import { useToast } from "primevue/usetoast";
import Repository from "@repositories/RepositoryFactory";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import "handsontable/dist/handsontable.full.css";
import { registerAllModules } from "handsontable/registry";
import { useApi } from "../../hooks/useApi";
import { limitText, useOpenInNewTab } from "../composables/useUtils";
import { formatSize } from "../../src/composables/useUtils";
import XlsxIcon from "../../src/components/XlsxIcon.vue";
import XlsIcon from "../../src/components/XlsIcon.vue";
import TagsTableComponent from "../components/TagsTableComponent.vue";

registerAllModules();
const { openInNewTab } = useOpenInNewTab();

// Global config constants
const appConfig = useAppConfig();
const FilesRepository = Repository.get("files");
const showErrorMessage = ref(false);

const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);
const tagsTemplateFile = ref(import.meta.env.VITE_TAG_TEMPLATE_FILE_URL);
const toast = useToast();
const tagsUploadModal = ref(false);
const disabledUpload = ref(true);
const disabledProcess = ref(true);
const keyfileUploading = ref(false);
const fileUpload = ref(null);
const isError = ref(null);
const openFileSelection = () => {
  fileUpload.value.choose();
};
const badReferences = ref([]);
const onFileSelected = (event) => {
  disabledUpload.value = false;
  disabledProcess.value = false;
  formState.file = event.files[0];
};
const lastKey = ref();
const isSuccess = ref(null);
const errorMessage = ref("");

const selections = ref({});

const onPage = (event) => {
  if (event.page === event.pageCount - 1 && lastKey.value?.last_evaluated_key) {
    getTagsList(lastKey.value);
  }
};
const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
  removeFileCallback(index);
};

const formState = reactive({
  email: attributes.value.email,
  user: userName.value,
  organization: organization.value,
  file: null,
});

const xlsListLoading = ref(false);
const xlsxList = ref([]);
const getTagsList = async (item) => {
  try {
    lastKey.value = "";
    xlsListLoading.value = true;

    const params = {
      organization: organization.value,
      page_limit: 15,
    };
    if (item?.last_evaluated_key) {
      params.last_evaluated_key = item.last_evaluated_key;
    }

    const {
      data: xlsDataResponse,
      error: errorRef,
      loading: isLoading,
      fetchItems: getXlxData,
    } = useApi("/tags/list", params);

    if (typeof getXlxData === "function") {
      await getXlxData().then(() => {
        if (item?.last_evaluated_key) {
          const newResponse = xlsDataResponse.value?.data;
          xlsxList.value = [...xlsxList.value, ...newResponse];
        } else {
          xlsxList.value = xlsDataResponse.value?.data || [];
        }
        xlsListLoading.value = isLoading.value;
        isError.value = errorRef.value;
        if (xlsDataResponse.value) {
          lastKey.value = xlsDataResponse.value;
        }
      });
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    xlsListLoading.value = false;
  }
};

watchEffect(() => {
  if (isSuccess.value) {
    toast.add({
      severity: "success",
      summary: isSuccess.value.message,
      life: 3000,
      position: "top-left",
    });
  }
});

const uploadTagsFile = async () => {
  selections.value = {};
  badReferences.value = [];
  isSuccess.value = "";
  isError.value = "";
  if (!formState.file) return;
  keyfileUploading.value = true;
  try {
    await FilesRepository.uploadTags(
      {
        file: formState.file,
        user: userName.value,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (fileUpload.value) fileUpload.value.clear();
    getTagsList();
    toast.add({
      severity: "success",
      summary: "Tags File uploaded",
      life: 3000,
    });

    tagsUploadModal.value = false;
  } catch (error) {
    console.log(error);
    errorMessage.value = "";
    showErrorMessage.value = true;
    errorMessage.value = error?.response
      ? error?.response?.data["message"]
      : error.message;
    throw new Error(error);
  } finally {
    keyfileUploading.value = false;
  }
};

onMounted(async () => {
  errorMessage.value = "";
  showErrorMessage.value = false;
  document.title = appConfig.appName;
  await getTagsList();
});
</script>

<style scoped>
.p-fileupload > .p-fileupload-buttonbar {
  background: red !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

  .details-subtable {
    padding: 1rem;
  }
}

:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-first) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-last) {
  display: none;
  visibility: hidden;
}

/* Adjust the width of the file list */
:deep(.p-fileupload-files) {
  width: 100%;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-file) {
  width: 100%;
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-buttonbar) {
  background: white;
}

/* Adjust the width of the file list items */
:deep(.p-datatable-wrapper) {
  font-size: 14px;
}

.browse-files {
  color: #015df1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #68a2ff;
  padding: 0 3px;
  position: relative;
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: inline;
  background: transparent;
}

.browse-files:before {
  background: #dae9fc;
  display: block;
  content: "";
  position: absolute;
  top: 23px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.2s ease-in;
  height: 22px;
  z-index: 1;
}

.browse-files:focus:before {
  outline: none !important;
}

.browse-files:focus:before,
.browse-files:hover:before {
  top: 0;
}

.browse-files span {
  z-index: 2;
  display: inline;
  position: relative;
}

:deep(.p-datatable-loading-overlay) {
  background-color: rgb(255 255 255 / 79%);
}
</style>
