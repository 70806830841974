// props.js
export const propsConfig = {
    isTyping: {
      type: Boolean,
      required: true,
      default: false,
    },
    matchedReferences: {
      type: Array,
      required: true,
      default: [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    subcategory: {
      type: Array,
      required: true,
      default: [],
    },
    referencePayload: {
      type: Object,
      required: true,
      default: {},
    },
    detailPageTitle: {
      type: String,
      required: true,
      default: "",
    },
    disableMatchReferences: {
      type: Boolean,
      required: true,
      default: false,
    },
    detailPdfLink: {
      type: String,
      required: true,
      default: "",
    },
    recommendedReferences: {
      type: Array,
      required: true,
      default: [],
    },
    viewedInvoice: {
      type: Object,
      required: true,
      default: {},
    },
    organization: {
      type: Object,
      required: true,
      default: {},
    },
    invoiceData: {
      type: Object,
      required: true,
      default: {},
    },
    invoicesArray: {
      type: Array,
      required: true,
      default: [],
    },
  };
  