export const Quotes = [
  {
    language: "English",
    quotes: [
      {
        id: 1,
        quote: "Believe you can, and you're halfway there.",
        author: "Theodore Roosevelt",
      },
      {
        id: 2,
        quote: "The only way to do great work is to love what you do.",
        author: "Steve Jobs",
      },
      {
        id: 3,
        quote: "In the middle of every difficulty lies opportunity.",
        author: "Albert Einstein",
      },
      {
        id: 4,
        quote: "The future depends on what you do today.",
        author: "Mahatma Gandhi",
      },
      {
        id: 5,
        quote: "Don't watch the clock; do what it does. Keep going.",
        author: "Sam Levenson",
      },
      {
        id: 6,
        quote:
          "Success is not final, failure is not fatal: It is the courage to continue that counts.",
        author: "Winston Churchill",
      },
      {
        id: 7,
        quote:
          "The only person you should try to be better than is the person you were yesterday.",
        author: "Unknown",
      },
      {
        id: 8,
        quote:
          "You are never too old to set another goal or to dream a new dream.",
        author: "C.S. Lewis",
      },
      {
        id: 9,
        quote: "The journey of a thousand miles begins with one step.",
        author: "Lao Tzu",
      },
      {
        id: 10,
        quote:
          "Hardships often prepare ordinary people for an extraordinary destiny.",
        author: "C.S. Lewis",
      },
    ],
  },
  {
    language: "Mixed",
    quotes: [
      {
        id: 1,
        quote:
          "No esperes por el momento perfecto, toma el momento y hazlo perfecto.",
        author: "Pablo Picasso",
      },
      {
        id: 2,
        quote:
          "La educación es el arma más poderosa que puedes usar para cambiar el mundo.",
        author: "Nelson Mandela",
      },
      {
        id: 3,
        quote:
          "La vida es una obra de teatro que no permite ensayos... canta, ríe, baila, llora y vive intensamente cada momento de tu vida antes que el telón baje y la obra termine sin aplausos.",
        author: "Charles Chaplin",
      },
      {
        id: 4,
        quote: "No hay mal que por bien no venga.",
        author: "Refrán popular",
      },
      {
        id: 5,
        quote: "El que no vive para servir, no sirve para vivir.",
        author: "Madre Teresa de Calcuta",
      },
      {
        id: 6,
        quote: "La gratitud es la memoria del corazón.",
        author: "Antístenes",
      },
      {
        id: 7,
        quote:
          "La verdadera educación consiste en obtener lo mejor de uno mismo.",
        author: "Mahatma Gandhi",
      },
      {
        id: 8,
        quote: "Ama hasta que te duela. Si te duele es buena señal.",
        author: "Madre Teresa de Calcuta",
      },
      {
        id: 9,
        quote:
          "La perseverancia no es una carrera larga; son muchas carreras cortas, una después de la otra.",
        author: "Walter Elliot",
      },
      {
        id: 10,
        quote: "Si puedes soñarlo, puedes lograrlo.",
        author: "Walt Disney",
      },
    ],
  },
];
