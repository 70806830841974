<template>
    <Sidebar v-model:visible="jobsStore.isInfoSidebarActive" position="right" style="width:25rem ">
        <Fieldset class="m-0">
            <template #legend>
                <legend>Invoice Processing Status</legend>
            </template>
            <div>
                <ul class="p-0" style="width: 15rem;">
                    <b class="text-blue-500 border-bottom-1">Processing</b>: <li>Invoice currently being processed in Organizer</li>
                    <br>
                    <b class="text-green-500 border-bottom-1">Complete</b>: <li>Invoices successfully matched to a Reference</li><br>
                    <b class="text-red-500 border-bottom-1">Verification  <i>(Only for PDF-only jobs)</i></b>: <li>Invoices which require human verification before being passed to the Organizer</li>
                    <b class="border-bottom-1" style="color: #f9b816;">Pending</b>: <li>Invoices not matched with a Reference</li>
                    <li>Unknown, MultipleMatch, Closed</li>
                </ul>
            </div>
        </Fieldset>
    </Sidebar>
</template>

<script setup>
import Sidebar from "primevue/sidebar";
import { useJobStore } from "@/store/jobsStore/store";

const jobsStore = useJobStore();
</script>

<style scoped>
:deep(.p-fieldset) {
    background-color: #6366f1 !important;
    color: #fff !important;
}

:deep(.p-fieldset-legend) {
    background-color: #6366f1 !important;
    color: #fff !important;
}
ul {
 list-style-type: "- "
}
</style>