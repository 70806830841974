<template>
  <div class="relative">
    <div class="flex w-full flex-wrap p-4">
      <!-- Sidebar -->
      <div class="border-1 surface-border border-round shadow-1 px-3 py-2 fixed overflow-y-auto custom-scrollbar"
        style="width: 16%; z-index: 100;">
        <Sidebar :jobsData="jobsStore.transactionsList" @handleApplyFilter="handleApplyFilter" />
      </div>

      <!-- Job Cards Section -->
      <div class="gap-4 pt-8 w-full" v-if="jobsStore.jobListLoading">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
          animationDuration=".5s" aria-label="Loading" />
      </div>
      <div v-if="jobsStore.jobsTransactionList && !jobsStore.jobListLoading"
        class="grid grid-cols-4 gap-3 pb-2 overflow-y-auto custom-scrollbar pl-1 pr-4 pt-3"
        style="width: 80%; margin-left: calc(20% + 1rem);">
        <JobCard v-for="(item) in jobsStore.jobsTransactionList" :key="item.id" :jobData="item" />
      </div>
      <div v-if="!jobsStore?.jobsTransactionList?.length && !jobsStore.jobListLoading" class="w-full">
        <div class="no-data-found">
          <img src="@assets/no-data-found.gif" style="width: 10vw;" alt="">
        </div>
        <h2 style="margin-top: -13vh;">No Job Found</h2>
      </div>
    </div>
    <PdfLabelling :isActive="confirmationModel" :currentJobId="currentJobId" />
  </div>
  <InfoSidebar />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watchEffect } from "vue";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import JobCard from "@components/Jobs/JobList/JobCard.vue";
import { serviceStore } from "@/store/serviceStore";
import PdfLabelling from "../components/Jobs/PdfLabelling.vue";
import Sidebar from "../components/Jobs/JobList/Sidebar.vue";
import InfoSidebar from "../components/Jobs/JobList/InfoSidebar.vue";
import { useJobStore } from "../store/jobsStore/store";
import { getTransactionsList } from "../composables/useUtils";
import moment from "moment";

const toast = useToast();
const jobsStore = useJobStore()
const resultsStores = serviceStore();
const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);

const email = ref(attributes.value.email);
const isError = ref(null);
const currentJobId = ref(null);

onMounted(() => {
  const startOfPeriod = moment().subtract(13, 'days').startOf('day').format('YYYY-MM-DD');
  const endOfPeriod = moment().endOf('day').format('YYYY-MM-DD');
  fetchTransactionList(startOfPeriod, endOfPeriod);
  document.addEventListener("keydown", handleEscapeKey);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleEscapeKey);
});

const handleEscapeKey = (event) => {
  if (event.key === "Escape" && resultsStores.pdfVerificationModel) {
    event.preventDefault();
  }
};

watchEffect(() => {
  if (resultsStores.isReload) {
    getTransactionsList();
    resultsStores.isReload = false;
  }
});

const fetchTransactionList = (startOfWeek, endOfWeek) => {

  getTransactionsList(jobsStore, userName, email, organization, startOfWeek, endOfWeek, isError);

}


</script>

<style scoped>
.p-datatable {
  width: 100%;
}

.viewDetailButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.manually-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.no-close-button .p-dialog-header .p-dialog-header-icons) {
  display: none !important;
}

.p-confirm-dialog {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: fixed !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.no-data-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
</style>
