import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import App from "@/App.vue";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import locales from "@locales";
import configConstants from "@/config";
import router from "@router";
import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import Panel from "primevue/panel";
import RadioButton from "primevue/radiobutton";
import Menubar from "primevue/menubar";
import Dropdown from "primevue/dropdown";
import Listbox from "primevue/listbox";
import Checkbox from "primevue/checkbox";
import Fieldset from "primevue/fieldset";
import SelectButton from "primevue/selectbutton";
import MultiSelect from "primevue/multiselect";
import Message from "primevue/message";
import Calendar from "primevue/calendar";
import Chip from "primevue/chip";
import Chart from "primevue/chart";
import VueDOMPurifyHTML from "vue-dompurify-html";
import * as Sentry from "@sentry/vue";
import BadgeDirective from "primevue/badgedirective";
import Menu from "primevue/menu";
import ConfirmPopup from "primevue/confirmpopup";

import StyleClass from "primevue/styleclass";
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import Divider from "primevue/divider";
import Tag from "primevue/tag";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Steps from "primevue/steps";
import Skeleton from "primevue/skeleton";
import ProgressBar from "primevue/progressbar";
import ConfirmationService from "primevue/confirmationservice";
import Card from "primevue/card";
import Sidebar from "primevue/sidebar";
import InputSwitch from "primevue/inputswitch";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Badge from "primevue/badge";
import ConfirmDialog from 'primevue/confirmdialog';
import AutoComplete from "primevue/autocomplete";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Slider from 'primevue/slider';
import ButtonGroup from 'primevue/buttongroup';
import MeterGroup from 'primevue/metergroup';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import OverlayPanel from 'primevue/overlaypanel';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Avatar from 'primevue/avatar'

import Timeline from 'primevue/timeline';

import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@aws-amplify/ui-vue/styles.css";

const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: locales, // set locale messages
});

const pinia = createPinia();

Amplify.configure(awsconfig);
const app = createApp(App);

// Base App resources and config
// -----------------------------

app.use(configConstants);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(VueDOMPurifyHTML);
app.use(ConfirmationService);

//Sentry Integration
// -----------------------
const isProduction = import.meta.env.MODE === "production";
const isTest = import.meta.env.MODE === "test";

if (isProduction) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_URL,
    environment: isProduction ? "production" : "test",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // },
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Global use of PrimeVue components
// ---------------------------------

app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("FileUpload", FileUpload);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Button", Button);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Toast", Toast);
app.component("DataTable", DataTable);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("MeterGroup", MeterGroup);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Row", Row);
app.component("ProgressSpinner", ProgressSpinner);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Dialog", Dialog);
app.component("ButtonGroup", ButtonGroup);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Panel", Panel);
app.component("RadioButton", RadioButton);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Menubar", Menubar);
app.component("OverlayPanel", OverlayPanel);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Splitter", Splitter);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("SplitterPanel", SplitterPanel);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Slider", Slider);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Dropdown", Dropdown);
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
// eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
app.component("Listbox", Listbox);
app.component("Checkbox", Checkbox);
app.component("SelectButton", SelectButton);
app.component("MultiSelect", MultiSelect);
app.component("Textarea", Textarea);
app.component("Message", Message);
app.component("Calendar", Calendar);
app.component("Chip", Chip);
app.component("Divider", Divider);
app.component("Chart", Chart);
app.component("Tag", Tag);
app.component("TabView", TabView);
app.component("Stepper", Stepper);
app.component("StepperPanel", StepperPanel);
app.component("TabPanel", TabPanel);
app.component("Menu", Menu);
app.component("Steps", Steps);
app.component("ProgressBar", ProgressBar);
app.component("Skeleton", Skeleton);
app.component("ConfirmPopup", ConfirmPopup);
app.component("Card", Card);
app.component("Sidebar", Sidebar);
app.component("InputSwitch", InputSwitch);
app.component("InputGroup", InputGroup);
app.component("InputGroupAddon", InputGroupAddon);
app.component("Fieldset", Fieldset);
app.component("Badge", Badge);
app.component("ConfirmDialog", ConfirmDialog);
app.component("AutoComplete", AutoComplete);
app.component("AccordionTab", AccordionTab);
app.component("Accordion", Accordion);
app.component("Timeline", Timeline);
app.component("Avatar", Avatar);

// Global use of PrimeVue directives
// ---------------------------------

app.directive("styleclass", StyleClass);
app.directive("ripple", Ripple);
app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);

app.mount("#app");
