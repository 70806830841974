<template>
  <Button label="Assign" :disabled="!selectedSubcategory && !referencePayload.reference" @click="toggleConfirmModal"
    icon="pi pi-send" class="w-4" />
  <Dialog :draggable="false" v-model:visible="store.confirmAssignReferenceModal" modal header="Confirm"
    :style="{ width: '40vw', maxHeight: '50vh' }" @keydown="handleKeydown">
    <template #header>
      <div>
        <h2>Confirm</h2>
        <p class="text-lg">The following invoices will be assigned to the reference<b>{{ referencePayload.reference
            }}</b></p>
        <strong title="Total count of invoices">
          Total Invoices: <span>{{ referencePayload.ids.length.toLocaleString() }}</span>
        </strong>
      </div>
    </template>
    <div class="w-full">

      <div class="container">
        <ul class="list-container flex gap-2 flex-wrap">
          <li v-for="(item, index) in referencePayload.ids" class="my-2 shadow-2 border-round" :key="index"><i
              class="pi pi-file-pdf mx-2 mt-1"></i>{{ item }}</li>
        </ul>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end mt-5">
        <Button @click="store.confirmAssignReferenceModal = false" outlined severity="danger" icon="pi pi-times"
          label="Cancel"></Button>
        <Button @click="handleAssignClick" :loading="assignButtonLoading" icon="pi pi-check" class="ml-2"
          label="Confirm"></Button>
      </div>
    </template>
  </Dialog>
</template>
<script setup>
import { useToast } from "primevue/usetoast";
import { serviceStore } from '../../store/serviceStore';
const toast = useToast();

const store = serviceStore()

const props = defineProps({
    referencePayload: {
        type: Object,
        required: true,
    },
    assignButtonLoading: {
        type: Boolean,
        required: true,
    },
    selectedSubcategory: {
        type: Object,
        required: true,
    }
});

const emits = defineEmits(['handleAssignClick']);
const handleAssignClick = () => {
   emits('handleAssignClick');
};

const handleKeydown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    handleAssignClick();
  }
};

const toggleConfirmModal = () => {
  if (!props.referencePayload.reference.length) {
    toast.add({
      severity: "info",
      summary: "Please select a reference before assigning",
      life: 5000,
      group: 'tl'
    });
  } else if (!props.referencePayload.ids.length) {
    toast.add({
      severity: "info",
      summary: "Please select a Invoice before assigning",
      life: 5000,
      group: 'tl'
    });
  } else {
    store.confirmAssignReferenceModal = true
  }
}

</script>


<style scoped>
.list-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-container li {
  padding: 10px;
  border-radius: 5px;
}
</style>