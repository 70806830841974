<template>
  <div>
    <FileUploadModel
      @onUploadFile="onInvoicesUpload"
      :isMultiple="true"
      :isLoading="isLoading"
      :isUploadDone="isUploadDone"
      acceptedFilesFormate=".pdf,.xml"
    >
      <template #button="{ show }">
        <Button
          label="Upload Invoices"
          icon="pi pi-upload"
          outlined
          @click="show"
          rounded
          severity="info"
        />
      </template>
    </FileUploadModel>
  </div>
</template>
<script setup>
import { ref } from "vue";
import FileUploadModel from "../../Common/FileUploadModel.vue";
import JSZip from "jszip";
import { useToast } from "primevue/usetoast";
import client from "../../../repositories/Clients/AxiosClient";
import { useRoute } from "vue-router";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import { useJobStore } from "@/store/jobsStore/store";

const userSession = useUserSessionStore();
const { userName, organization } = storeToRefs(userSession);

const toast = useToast();
const jobStore = useJobStore();

const isLoading = ref(false);
const route = useRoute();
const isUploadDone = ref(false);

const onInvoicesUpload = async (formState) => {
  if (!formState.file) return;

  const files = formState.file;
  let files_needed = [];
  const seenFiles = new Set();
  let pdfOnly = true; // Initially assume only PDFs are present

  // Check for XML presence and prepare file pairs
  files.forEach((file) => {
    const fileName = file.name.toLowerCase();
    const baseName = fileName.replace(/\.(pdf|xml)$/i, "");
    const extension = fileName.split(".").pop();

    if (extension === "xml") {
      pdfOnly = false;
    }

    if (extension === "pdf" || extension === "xml") {
      const counterpart =
        extension === "pdf" ? `${baseName}.xml` : `${baseName}.pdf`;
      if (!seenFiles.has(counterpart)) {
        files_needed.push(counterpart);
      }
      seenFiles.add(fileName);
    } else {
      toast.add({
        severity: "error",
        summary: "File not Allowed",
        detail: `Only PDF and XML files are allowed. ${fileName} is not allowed.`,
      });
    }
  });

  files_needed = files_needed.filter((needed) => !seenFiles.has(needed));
  // Reset files_needed if only PDFs are present and no XML
  if (pdfOnly) {
    files_needed = [];
  }

  // Chunking files for upload
  const chunkSizeLimit = 4 * 1024 * 1024; // 4MB
  const chunks = [];
  let currentChunkSize = 0;

  files.forEach((file) => {
    if (currentChunkSize + file.size > chunkSizeLimit || !chunks.length) {
      chunks.push([]);
      currentChunkSize = 0;
    }
    chunks[chunks.length - 1].push(file);
    currentChunkSize += file.size;
  });

  // Upload each chunk
  for (let i = 0; i < chunks.length; i++) {
    if (files_needed.length === 0) {
      try {
        isLoading.value = true;
        const invoicesChunk = chunks[i];
        const zipper = new JSZip();
        invoicesChunk.forEach((f) => zipper.file(f.name, f));
        const zipBlob = await zipper.generateAsync({ type: "blob" });
        const formData = new FormData();
        formData.append("file", zipBlob, `chunk_${i}.zip`);
        formData.append("email", userName.value);
        formData.append("user", userName.value);
        formData.append("organization", organization.value);
        formData.append("accept_files", pdfOnly ? "pdf" : "pdf,xml");
        pdfOnly && formData.append("mock", "true");

        const response = await client.put(
          `/api/zip-batch-process/continue?job_id=${
            route.params.trx_id
          }&accept_files=${pdfOnly ? "pdf" : "pdf,xml"}${
            pdfOnly ? "&mock=true" : ""
          }`,
          formData,
          { headers: { "Content-Type": "application/zip" } }
        );

        if (response.status === 200 && i === chunks.length - 1) {
          isUploadDone.value = true;
          isLoading.value = false;
          toast.add({
            severity: "success",
            summary: "Upload Successful",
            detail: "Invoices uploaded successfully.",
            life: 3000,
          });
          setTimeout(() => {
            isUploadDone.value = false;
            jobStore.isTagUpdated = true;
          }, 5000);
        }
      } catch (error) {
        isLoading.value = false;
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Cannot continue upload right now",
        detail: "Please provide missing files first.",
        life: 3000,
      });
      isLoading.value = false;
    }
  }
};
</script>
