import Client from "./Clients/AxiosClient";

export default {
  emailInvoiceSorter(params) {
    return Client.get(`/api/email-process`, params);
  },
  batchUpload(data, config) {
    const params = new URLSearchParams();
    params.append("user", data.user);
    params.append("email", data.email);
    if (data.s3_xlsx_key) {
      params.append("s3_xlsx_key", data.s3_xlsx_key);
    }
    if (data.organization) {
      params.append("organization", data.organization);
    }
    if (data.job_id) {
      params.append("job_id", data.job_id);
    }
    return Client.put(
      `/api/files-batch-process?${params.toString()}`,
      data.invoicesFormData,
      config
    );
  },
  zipBatchUpload(data, config) {
    const params = new URLSearchParams();
    params.append("user", data.user);
    params.append("email", data.email);
    params.append("organization", data.organization);
    params.append("accept_files", data.accept_files);
    if (data.accept_files == "pdf") {
      params.append("mock", data.mock);
    }
    if (data.s3_xlsx_key) {
      params.append("s3_xlsx_key", data.s3_xlsx_key);
    }
    if (data.job_id) {
      params.append("job_id", data.job_id);
    }
    return Client.put(
      `/api/zip-batch-process?${params.toString()}`,
      data,
      config
    );
  },
};
