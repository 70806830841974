<template>
    <div v-if="apCheckIssuesStore.currentIssue" >
        <div class="flex mb-3" >
            <h4 class="my-0" >Change status</h4>
            <router-link class="ml-auto" to="/workflow-edit" >View Workflow</router-link>
        </div>
        <Dropdown :disabled="updatingStatus" :loading="updatingStatus" @change="updateStatus" v-model="selectedAction" :options="nextActions" optionLabel="label" optionValue="value"  placeholder="Select a status"
            class="w-full mb-4" />

        <template v-if="apCheckIssuesStore.isCurrentIssueTotalMisMatch" >
            <p v-if="apCheckIssuesStore.currentIssue.ap_check" >
                <b>Ap Check Total: </b>
                {{ apCheckIssuesStore.currentIssue.ap_check.currency }}
                {{ apCheckIssuesStore.currentIssue.ap_check.total }}
            </p>
            <p v-if="apCheckIssuesStore.currentIssue.invoice" >
                <b>Invoice Total: </b>
                {{ apCheckIssuesStore.currentIssue.invoice.currency }}
                {{ apCheckIssuesStore.currentIssue.invoice.total }}
            </p>
        </template>
        
        <p>
            <b>Reference:</b>
            {{ apCheckIssuesStore.currentIssue.reference }}
        </p>
        <p>
            <b>Invoice Number:</b>
            {{ apCheckIssuesStore.currentIssue.invoice_number }}
        </p>
        <p>
            <b>Seller:</b>
            {{ apCheckIssuesStore.currentIssue.seller }}
        </p>
        <p>
            <b>Customer:</b>
            {{ apCheckIssuesStore.currentIssue.customer }}
        </p>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue"
import { useIssuesStore } from "../../../store/apcheck/issues"

defineProps({
    updatingStatus: {
        type: Boolean,
        default: false
    }
})

const apCheckIssuesStore = useIssuesStore()
const selectedAction = ref({ name: 'NEW' });

const nextActions = ref(apCheckIssuesStore.columns.map((c) => {
    return { value: c.status, label: c.label }
}));

const emit = defineEmits(['update-status'])

const updateStatus = () => {
    if (apCheckIssuesStore.currentIssue) {
        emit('update-status', selectedAction.value)
    }
}

onMounted(( ) => {
    if (apCheckIssuesStore.currentIssue) {
        selectedAction.value = apCheckIssuesStore.currentIssue.status
    }
})

</script>