<template>
  <div class="flex flex-column w-full relative">
    <label for="total" class="text-start">
      <p>{{ inputLabel }}</p>
    </label>
    <InputText
      id="total"
      :class="{
        'text-gray-700': isSelected.includes(inputKey),
      }"
      class="w-full border-1 -mt-3"
      aria-describedby="username-help"
      v-model="pdfOnlyStore.jobData[inputKey]"
      @input="handleInputChange(inputKey)"
    />
  </div>
</template>
<script setup>
import { usePdfOnlyStore } from "../../store/pdf-only/store";

defineProps({
  isSelected: {
    type: Array,
    required: true,
  },
  inputKey: {
    type: String,
    required: true,
  },
  inputLabel: {
    type: String,
    required: true,
  },
});

const pdfOnlyStore = usePdfOnlyStore();

const emits = defineEmits(["handleInputChange"]);
const handleInputChange = (key) => {
  emits("handleInputChange", key);
};
</script>
