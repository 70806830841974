<template>
  <div>
    <div class="flex flex-column flex-auto px-5 pt-5">
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border"
      >
        <div class="flex align-items-center">
          <i class="pi pi-server text-2xl mr-3 text-500"></i>
          <span class="text-3xl font-medium text-900">References</span>
        </div>
        <div class="flex align-items-center">
          <KeyFileViewer :organization="organization" :currentRef="true" />
        </div>
      </div>
      <div class="w-full mx-10 mt-4">
        <div class="">
          <FileUpload
            ref="fileUpload"
            @select="onFileSelected"
            accept=".xls,.xlsx"
            :maxFileSize="1000000 * 4"
            :multiple="false"
            chooseLabel="Browse Keyfile"
          >
            <template #content="{ files, removeFileCallback }">
              <div v-if="files.length > 0">
                <div
                  v-for="(file, index) of files"
                  :key="file.name + file.type + file.size"
                  class="flex justify-content-center gap-5 align-items-center"
                  style="height: 152px"
                >
                  <span class="text-lg">
                    {{ file.name }}
                  </span>
                  <Button
                    icon="pi pi-trash"
                    @click="removeFileCallback(index)"
                    outlined
                    rounded
                    severity="danger"
                    size="small"
                  />
                </div>
              </div>
            </template>
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column cursor-pointer"
                @click="openFileSelection"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"
                />
                <p class="mt-4 mb-0">
                  Drag and drop or
                  <a
                    tabindex="0"
                    @keydown.enter="openFileSelection"
                    class="text-primary font-medium cursor-pointer hover:underline"
                    >Choose</a
                  >
                  your KeyFile. Only 1 KeyFile is allowed.
                </p>
              </div>
            </template>
            <template #header>
              <div class="flex justify-content-between w-full">
                <div>Upload keyfile</div>
                <div class="flex align-items-center">
                  <Checkbox v-model="openAllReferences" :binary="true" />
                  <p>
                    <b style="margin-top: 2rem" class="mx-2"
                      >Open ALL References
                      <i
                        class="pi pi-question-circle cursor-pointer text-blue-600"
                        v-tooltip.top="
                          'Ensure invoices run over ALL references'
                        "
                      ></i>
                    </b>
                  </p>
                  <Checkbox v-model="rawXlsxFile" :binary="true" />
                  <p>
                    <b style="margin-top: 2rem" class="mx-2"
                      >Raw XLSX file
                      <i
                        class="pi pi-question-circle cursor-pointer text-blue-600"
                        v-tooltip.top="
                          'If you have a raw xlsx file, Please click on checkbox before uploading.'
                        "
                      ></i>
                    </b>
                  </p>
                </div>
              </div>
            </template>
          </FileUpload>
          <Button
            label="Upload Keyfile"
            class="my-5"
            @click="uploadKeyFile"
            icon="pi pi-send"
            :disabled="disabledProcess"
            :loading="keyfileUploading"
          />
          <div>
            <transition-group name="p-message" tag="div">
              <div
                v-if="showErrorMessage"
                class="flex lg:align-items-start relative flex-column lg:flex-row p-4 bg-red-100 border-round border-1 border-red-300"
              >
                <div class="flex align-items-start">
                  <i class="pi pi-info-circle text-red-600 text-2xl mr-3"></i>
                  <div
                    class="mr-3 flex lg:align-items-start flex-column lg:flex-row"
                  >
                    <div
                      class="text-black font-bold mr-0 lg:mr-2 mb-2 lg:mb-0 line-height-3"
                    >
                      Error:
                    </div>
                    <p
                      v-if="typeof errorMessage === 'string'"
                      style="text-align: start"
                      class="m-0 p-0 text-black line-height-3"
                    >
                      {{ errorMessage }}
                    </p>
                    <div v-else class="-mt-3">
                      <ul
                        v-for="(item, index) in errorMessage"
                        :key="index"
                        style="text-align: start"
                      >
                        <li>{{ item }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a
                  @click="showErrorMessage = false"
                  class="absolute ml-auto no-underline text-blue-900 font-medium mt-3 lg:mt-0 cursor-pointer p-ripple"
                  style="right: 1rem; top: 1.5em"
                >
                  <i class="pi pi-times-circle text-black text-xl ml-2"></i>
                </a>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <ProgressSpinner
      v-if="xlsListLoading"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
      aria-label="Loading"
      class="mb-10"
    />
    <div class="p-5">
      <h2 class="text-left">Recently Uploaded Xlsx Files</h2>
      <DataTable
        :value="xlsxList"
        tableStyle="min-width: 50rem"
        :paginator="true"
        :rows="10"
        @page="onPage"
      >
        <Column :sortable="true" field="keyfile_name" header="Filename">
          <template #body="slotProps">
            <b>{{ slotProps.data.keyfile_name }}</b>
          </template>
        </Column>
        <Column field="creation_date" header="Uploaded at">
          <template #body="slotProps">
            <span
              v-if="formatDateTime(slotProps.data.created_at).includes('Today')"
            >
              <Tag severity="success" value="Today"></Tag>
              {{
                formatDateTime(slotProps.data.created_at).replace("Today ", "")
              }}
            </span>
            <span v-else>
              {{ slotProps.data.created_at }}
            </span>
          </template>
        </Column>
        <Column field="conflicts" header="Differences">
          <template #body="slotProps">
            <div :class="stockClass(slotProps.data['conflicts'])">
              {{ slotProps.data["conflicts"] }}
            </div>
          </template>
        </Column>
        <Column field="new_references" header="New References">
          <template #body="slotProps">
            <div :class="stockClass(slotProps.data['new_references'])">
              {{ slotProps.data["new_references"] }}
            </div>
          </template>
        </Column>
        <Column header="Actions">
          <template #body="slotProps">
            <KeyFileViewer
              :keyFile="slotProps.data.keyfile_name"
              :organization="organization"
              :reference="true"
            />
            <i
              v-if="conflictLoading === slotProps.data.keyfile_name"
              class="pi pi-spin pi-spinner text-2xl"
              style="font-size: 2rem"
            ></i>
            <span v-else>
              <i
                v-if="slotProps.data['conflicts'] > 0"
                v-badge.danger
                v-tooltip.top="'Show Differences'"
                class="pi pi-exclamation-circle text-2xl cursor-pointer"
                @click="getConflicts(slotProps.data.keyfile_name)"
              ></i>
              <i
                v-else
                v-tooltip.top="'Show Differences'"
                class="pi pi-exclamation-circle text-2xl cursor-pointer"
                @click="getConflicts(slotProps.data.keyfile_name)"
              ></i>
            </span>
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="conflictsPreviewModal"
        modal
        header="Differences"
        :style="{ width: '70vw' }"
        maximizable
        @maximize="scrollView = '60vh'"
        @unmaximize="scrollView = '40vh'"
      >
        <TabView>
          <TabPanel header="">
            <template #header>
              <div>
                <i
                  v-badge.danger="conflictsSummary.summary.num_conflicts"
                  class="mr-3 mb-4"
                ></i>
                <span> Differences</span>
              </div>
              <div
                v-tooltip.top="'More info'"
                class="text-blue-800 text-xs ml-3 -mb-1"
                @click="
                  infoToggle(resultsStores.infoDrawer, conflictInfo, true)
                "
              >
                <i class="pi pi-info-circle"></i>
              </div>
            </template>
            <div class="mb-5">
              <div v-if="isConflict">
                <DataTable
                  v-model:selection="selectedProduct"
                  :value="transformedData"
                  dataKey="reference"
                  scrollable
                  :scrollHeight="scrollView"
                  :virtualScrollerOptions="{ itemSize: 100 }"
                  v-model:expandedRows="expandedRows"
                  @row-toggle="onRowToggle"
                  style=""
                >
                  <template #header="">
                    <div
                      class="flex flex-wrap align-items-center justify-content-between gap-2"
                    >
                      <span class="text-xl text-900 font-bold">Summary</span>
                    </div>
                    <div class="flex justify-content-between">
                      <div>
                        <h4><b>No of Differences</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{ conflictsSummary.summary.num_conflicts }}</span
                          >
                        </div>
                      </div>
                      <div>
                        <h4 class="mx-6"><b>New References</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{ conflictsSummary.summary.new_references }}</span
                          >
                        </div>
                      </div>
                      <div>
                        <h4><b>Repeat References</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{
                              conflictsSummary.summary.repeat_references
                            }}</span
                          >
                        </div>
                      </div>
                      <div>
                        <h4 class="mx-6"><b>Missing References</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{
                              conflictsSummary.summary.num_missing_references
                            }}</span
                          >
                        </div>
                      </div>
                      <div>
                        <h4><b>Status Change References</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{
                              conflictsSummary.summary
                                .num_status_change_references
                            }}</span
                          >
                        </div>
                      </div>
                      <div>
                        <h4><b>Non-compliant References</b></h4>
                        <div class="flex justify-content-center font-medium">
                          <span
                            class="bg-purple-500 text-white px-3 py-1 border-round"
                            >{{ conflictsSummary.summary.bad_references }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <Column
                    selectionMode="multiple"
                    headerStyle="width: 3rem"
                  ></Column>
                  <Column
                    field="reference"
                    header=""
                    style="width: 1rem"
                    :expander="true"
                  ></Column>
                  <Column field="reference" header="Reference"></Column>
                  <template #expansion="slotProps">
                    <DataTable
                      :value="slotProps.data.details"
                      responsiveLayout="scroll"
                      class="ml-5"
                    >
                      <Column field="organization" header="">
                        <template #body="slotProps">
                          <span
                            class="text-white px-2 py-1 border-round"
                            :class="{
                              'bg-orange-400': !slotProps.data.organization,
                              'bg-green-400': slotProps.data.organization,
                            }"
                          >
                          </span>
                        </template>
                      </Column>
                      <Column field="Container" header="Container"></Column>
                      <Column field="HBL" header="HBL"></Column>
                      <Column field="MBL" header="MBL"></Column>
                      <Column field="Tarja" header="Tarja"></Column>
                      <Column field="Pedimento" header="Pedimento"></Column>
                      <Column field="status" header="Status"></Column>
                    </DataTable>
                  </template>
                </DataTable>
              </div>
              <div v-else>
                <SummaryHeader
                  :conflictsSummary="conflictsSummary"
                  :transformedData="transformedData"
                  message="No Differences Found"
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            :disabled="badReferences === [] || !badReferences"
            class="w-full"
          >
            <template #header>
              <div>
                <i
                  v-badge.danger="conflictsSummary.summary.bad_references"
                  class="mr-3 mb-4"
                ></i>
                <span>Non-compliant References</span>
              </div>
              <div
                v-tooltip.top="'More info'"
                class="text-blue-800 text-xs ml-3 -mb-1"
                @click="
                  infoToggle(resultsStores.infoDrawer, badReferenceInfo, true)
                "
              >
                <i class="pi pi-info-circle"></i>
              </div>
            </template>
            <div v-if="badReferences?.length">
              <DataTable
                :value="badReferences"
                responsiveLayout="scroll"
                class="w-full"
              >
                <template #header="">
                  <div
                    class="flex flex-wrap align-items-center justify-content-between gap-2"
                  >
                    <span class="text-xl text-900 font-bold">Summary</span>
                  </div>
                  <div class="flex justify-content-between">
                    <div>
                      <h4><b>No of Differences</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{ conflictsSummary.summary.num_conflicts }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <h4 class="mx-6"><b>New References</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{ conflictsSummary.summary.new_references }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <h4><b>Repeat References</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{
                            conflictsSummary.summary.repeat_references
                          }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <h4 class="mx-6"><b>Missing References</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{
                            conflictsSummary.summary.num_missing_references
                          }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <h4><b>Status Change References</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{
                            conflictsSummary.summary
                              .num_status_change_references
                          }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <h4><b>Non-compliant References</b></h4>
                      <div class="flex justify-content-center font-medium">
                        <span
                          class="bg-purple-500 text-white px-3 py-1 border-round"
                          >{{ conflictsSummary.summary.bad_references }}</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <template #empty> No Non-compliant references found. </template>
                <Column field="reference" header="Reference">
                  <template #body="slotProps">
                    <span
                      ><b>{{ slotProps.data.reference }}</b></span
                    >
                  </template>
                </Column>
                <Column field="Container" header="Container">
                  <template #body="slotProps">
                    <span
                      v-tooltip="
                        slotProps.data.Container && slotProps.data.Container
                      "
                      :class="{
                        'text-600':
                          slotProps.data.Container &&
                          slotProps.data.Container !== null,
                      }"
                    >
                      {{
                        slotProps.data.Container &&
                        slotProps.data.Container.slice(0, 15)
                      }}
                    </span>
                  </template>
                </Column>
                <Column field="HBL" header="HBL"></Column>
                <Column field="MBL" header="MBL">
                  <template #body="slotProps">
                    <span
                      v-tooltip="slotProps.data.MBL && slotProps.data.MBL"
                      :class="{
                        'text-600':
                          slotProps.data.MBL && slotProps.data.MBL !== null,
                      }"
                    >
                      {{
                        slotProps.data.MBL && slotProps.data.MBL.slice(0, 15)
                      }}
                    </span>
                  </template>
                </Column>
                <Column field="Tarja" header="Tarja"></Column>
                <Column field="Pedimento" header="Pedimento"></Column>
                <Column field="status" header="Status"></Column>
              </DataTable>
            </div>
            <div v-else>
              <SummaryHeader
                :conflictsSummary="conflictsSummary"
                :transformedData="transformedData"
                message="No Bad Reference Found"
              />
            </div>
          </TabPanel>
        </TabView>
        <div class="flex justify-content-between">
          <div>
            <Button
              v-if="!isExpended"
              @click="expandAll"
              class="px-4 mr-2"
              :disabled="
                conflictsSummary &&
                conflictsSummary.conflicts &&
                conflictsSummary.conflicts.length
                  ? false
                  : true
              "
              >Expand All</Button
            >
            <Button v-if="isExpended" @click="collapseAll" class="px-4 mr-2"
              >Collapse All</Button
            >
          </div>
          <div>
            <Button @click="conflictsPreviewModal = false" class="ml-3"
              >Close</Button
            >
            <Button
              v-if="revertConflictsLoading"
              icon="pi pi-spin pi-spinner"
              class="px-8 ml-2"
            />
            <Button
              v-else
              @click="revertConflicts"
              severity="help"
              class="ml-3"
              :disabled="
                !conflictsSummary ||
                !conflictsSummary.conflicts ||
                !conflictsSummary.conflicts.length ||
                revertStatus
              "
              >{{
                revertStatus ? "Already Reverted" : "Revert Conflict"
              }}</Button
            >
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useAppConfig } from "@/config";
import { reactive } from "vue";
import { useToast } from "primevue/usetoast";
import Repository from "@repositories/RepositoryFactory";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import "handsontable/dist/handsontable.full.css";
import { registerAllModules } from "handsontable/registry";
import { useApi } from "../../hooks/useApi";
import { formatDateTime } from "../utils/helpers";
import { serviceStore } from "@/store/serviceStore";
import SummaryHeader from "../components/SummaryHeader.vue";
import { stockClass, infoToggle } from "@/composables/useUtils";
import KeyFileViewer from "../components/References/KeyFileViewer.vue";

registerAllModules();

// Global config constants
const appConfig = useAppConfig();
const FilesRepository = Repository.get("files");
const resultsStores = serviceStore();
const showErrorMessage = ref(false);

const userSession = useUserSessionStore();
const { userName, attributes, s3UploadKey, organization } =
  storeToRefs(userSession);

const toast = useToast();
const revertStatus = ref(false);
const disabledUpload = ref(true);
const disabledProcess = ref(true);
const keyfileUploading = ref(false);
const fileUpload = ref(null);
const isError = ref(null);
const openFileSelection = () => {
  fileUpload.value.choose();
};
const conflictFile = ref("");
const badReferences = ref([]);
const selectedProduct = ref();
const onFileSelected = (event) => {
  if (event.files && event.files.length > 1) {
    fileUpload.value.remove(0);
    formState.file = event.files[0];
    disabledUpload.value = false;
    disabledProcess.value = false;
  } else {
    disabledUpload.value = false;
    disabledProcess.value = false;
    formState.file = event.files[0];
  }
};
const lastKey = ref();
const transformedData = ref([]);
const conflictLoading = ref("");
const conflictsSummary = ref();
const isSuccess = ref(null);
const expandedRows = ref(null);
const revertConflictsLoading = ref();
const onRowToggle = (e) => {
  expandedRows.value = e.data;
};
const errorMessage = ref("");
const rawXlsxFile = ref(true);
const openAllReferences = ref(true);
const isConflict = ref(true);
const isAllChecked = ref(false);
const scrollView = ref("40vh");
const isExpended = ref(false);

const selections = ref({});
const selectedRowsArray = ref([]);
const conflictInfo = {
  head: "Differences",
  message:
    "The latest key file updates information from previously loaded references",
};

const badReferenceInfo = {
  head: "Non-compliant references",
  message:
    "There are references in the latest key file that don’t comply with the expected format. Please review your key file.",
};

const onPage = (event) => {
  if (event.page === event.pageCount - 1 && lastKey.value?.last_evaluated_key) {
    getXlsxList(lastKey.value);
  }
};

const revertConflicts = async () => {
  const data = selectedProduct?.value?.map((item) => {
    if (item.details.length > 0) {
      return item.details[0];
    }
    return null;
  });

  isError.value = "";
  revertConflictsLoading.value = true;
  const payload = {
    conflicts: data,
    reference_s3_filename: conflictFile.value,
  };

  const {
    data: conflictResponse,
    error: errorRef,
    loading: loadingRef,
    postItem: revertConflicts,
  } = useApi("/reference/conflicts");

  if (typeof revertConflicts === "function") {
    if (data && data.length !== 0) {
      await revertConflicts(payload)
        .then(() => {
          if (conflictResponse.value?.statusCode === 200) {
            isSuccess.value = conflictResponse.value;
          }
          revertConflictsLoading.value = loadingRef.value;
          conflictsPreviewModal.value = false;
          isAllChecked.value = false;
        })
        .finally(() => {
          isSuccess.value = "";
          getXlsxList();
        });
      if (errorRef.value) {
        isError.value = errorRef.value;
      }
    } else {
      revertConflictsLoading.value = loadingRef.value;
      toast.add({
        severity: "info",
        summary: "Something Went Wrong",
        detail: "Please Select the differences that needs to be reverted",
        life: 3000,
        position: "top-left",
      });
    }
  }
};

const getConflicts = async (fileName) => {
  conflictLoading.value = fileName;
  conflictFile.value = fileName;
  selectedProduct.value = null;
  isError.value = "";
  selectedRowsArray.value = [];
  badReferences.value = [];
  const params = {
    reference_s3_filename: fileName,
  };
  const {
    data: conflictResponse,
    error: errorRef,
    loading: loadingRef,
    fetchItems: getConflicts,
  } = useApi("/reference/conflicts", params);

  if (typeof getConflicts === "function") {
    await getConflicts();
  }

  const testError = errorRef.value;
  errorRef.value = isError.value;
  badReferences.value = conflictResponse.value?.bad_references;
  getXlsxFileLoading.value = loadingRef.value;

  if (conflictResponse?.value) {
    if (conflictResponse?.value?.conflicts.length !== 0) {
      isConflict.value = true;
      transformedData.value = conflictResponse?.value?.conflicts?.map(
        (innerArray) => {
          let reference = innerArray[0]?.reference;
          return {
            reference: reference,
            details: innerArray,
            conflicts_number: innerArray.length > 1 ? innerArray.length : null,
          };
        }
      );
    } else {
      transformedData.value = [{}];
      isConflict.value = false;
    }
    conflictsSummary.value = conflictResponse?.value;
    conflictsPreviewModal.value = true;
    selections.value = {};
    if (conflictResponse?.value) {
      revertStatus.value = conflictResponse?.value?.revert_status;
    }
  } else {
    if (testError.message) {
      toast.add({
        severity: "info",
        summary: "Error",
        detail: testError?.response?.data?.message,
        life: 3000,
        position: "top-left",
      });
    } else {
      toast.add({
        severity: "info",
        summary: "No Differences Found",
        detail: "",
        life: 3000,
        position: "top-left",
      });
    }
  }
  conflictLoading.value = "";
};

const expandAll = () => {
  expandedRows.value = transformedData.value.filter((p) => p["reference"]);
  isExpended.value = true;
};

const collapseAll = () => {
  expandedRows.value = null;
  isExpended.value = false;
};

const s3UploadResp = reactive({
  s3_full_path: "",
  s3_url: "",
  s3_xlsx_key: "",
  s3_bucket: "",
});

const formState = reactive({
  email: attributes.value.email,
  user: userName.value,
  organization: organization.value,
  file: null,
});

const xlsListLoading = ref(false);
const xlsxList = ref([]);

const getXlsxList = async (lastEvaluatedKey) => {
  lastKey.value = "";
  xlsListLoading.value = true;

  const params = {
    organization: organization.value,
    page_limit: 15,
  };
  if (lastEvaluatedKey?.last_evaluated_key) {
    params.last_evaluated_key = lastEvaluatedKey.last_evaluated_key;
  }

  const {
    data: xlsDataResponse,
    error: errorRef,
    loading: loading,
    fetchItems: getXlxData,
  } = useApi("/reference/xlsx-list", params);

  if (typeof getXlxData === "function") {
    await getXlxData().then(() => {
      if (lastEvaluatedKey?.last_evaluated_key) {
        const newResponse = xlsDataResponse.value?.data;
        xlsxList.value = [...xlsxList.value, ...newResponse];
      } else {
        xlsxList.value = xlsDataResponse.value?.data || [];
      }
      xlsListLoading.value = loading.value;
      isError.value = errorRef.value;
      if (xlsDataResponse.value) {
        lastKey.value = xlsDataResponse.value;
      }
    });
  }
};

watchEffect(() => {
  if (isSuccess.value) {
    toast.add({
      severity: "success",
      summary: isSuccess.value.message,
      life: 3000,
      position: "top-left",
    });
  }
});
const getXlsxFileLoading = ref(false);
const conflictsPreviewModal = ref(false);
function extractKeyFileName(string) {
  if (string) {
    let parts = string?.split("/");
    let fileName = parts[2];
    return fileName;
  }
}

const uploadKeyFile = async () => {
  selections.value = {};
  badReferences.value = [];
  isSuccess.value = "";
  isError.value = "";
  if (!formState.file) return;
  keyfileUploading.value = true;
  try {
    const uploadResponse = await FilesRepository.uploadKeyFile(
      {
        file: formState.file,
        user: userName.value,
        organization: organization.value,
        raw_data: rawXlsxFile.value,
        open_all: openAllReferences.value,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          //"Access-Control-Allow-Origin": "*",
        },
      }
    );

    const keyFileResponse = uploadResponse?.data?.xlsx_file_response?.s3_key;
    localStorage.setItem("isXlsUploaded", true);
    openAllReferences.value = true;
    rawXlsxFile.value = true;
    conflictFile.value = extractKeyFileName(keyFileResponse);

    if (uploadResponse.data.conflicts.length !== 0) {
      isConflict.value = true;
      transformedData.value = uploadResponse?.data?.conflicts?.map(
        (innerArray) => {
          let reference = innerArray[0]?.reference;

          return {
            reference: reference,
            details: innerArray,
            conflicts_number: innerArray.length > 1 ? innerArray.length : null,
          };
        }
      );
      conflictsSummary.value = uploadResponse?.data;
      conflictsPreviewModal.value = true;
    } else {
      isConflict.value = false;
      transformedData.value = [{}];
      conflictsSummary.value = uploadResponse?.data;
      conflictsPreviewModal.value = true;
    }
    s3UploadResp.s3_full_path = uploadResponse.data.s3_full_path;
    s3UploadResp.s3_url = uploadResponse.data.s3_url;
    s3UploadResp.s3_xlsx_key = uploadResponse.data.s3_xlsx_key;
    s3UploadResp.s3_bucket = uploadResponse.data.s3_bucket;

    s3UploadKey.value = s3UploadResp.s3_xlsx_key;
    s3UploadKey.value = s3UploadResp.s3_xlsx_key;
    if (fileUpload.value) fileUpload.value.clear();
    getXlsxList();
    toast.add({
      severity: "success",
      summary: "Key File uploaded",
      life: 3000,
    });
    if (uploadResponse.status === 200) {
      let timeout;
      timeout = setTimeout(() => {
        resultsStores.getFilterData(organization.value, true);
        clearTimeout(timeout);
      }, 10000);
    }
  } catch (error) {
    console.log(error);
    errorMessage.value = "";
    showErrorMessage.value = true;
    errorMessage.value = error?.response
      ? error?.response?.data["message"]
      : error.message;
    throw new Error(error);
  } finally {
    keyfileUploading.value = false;
  }
};

onMounted(async () => {
  errorMessage.value = "";
  showErrorMessage.value = false;
  document.title = appConfig.appName;
  await getXlsxList();
});
</script>

<style scoped>
.p-fileupload > .p-fileupload-buttonbar {
  background: red !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

  .details-subtable {
    padding: 1rem;
  }
}

:deep(.p-fileupload-file-details) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-first) {
  display: none;
  visibility: hidden;
}

:deep(.p-paginator-last) {
  display: none;
  visibility: hidden;
}

/* Adjust the width of the file list */
:deep(.p-fileupload-files) {
  width: 100%;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-file) {
  width: 100%;
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Adjust the width of the file list items */
:deep(.p-fileupload-buttonbar) {
  background: white;
}

/* Adjust the width of the file list items */
:deep(.p-datatable-wrapper) {
}
</style>
