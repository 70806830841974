<template>
    <div>
        <div class="flex gap-3 px-4">
            <h1>Workflow</h1>
            <div class="ml-auto flex gap-3 align-items-center">
                <Button icon="pi pi-arrow-up-right-and-arrow-down-left-from-center" severity="secondary" text @click="addNewFlowModal = true" label="Add new Flow" ></Button>
                <Button icon="pi pi-plus" severity="secondary" text @click="addNewStateModal = true" label="Add new Status" ></Button>
                <Button>Save Workflow</Button>
            </div>
        </div>
        <div style="width: 100%; height: calc(100vh - 200px)" class="p-2">
            <VueFlow v-model:nodes="nodes" v-model:edges="edges" fit-view-on-init class="vue-flow-basic-example"
                :default-zoom="1.5" :min-zoom="0.2" :max-zoom="4">
                <Background pattern-color="#aaa" :gap="8" />

                <MiniMap />

                <template #node-custom="nodeProps">
                    <SpecialNode v-bind="nodeProps" />
                </template>

                <template #edge-custom="edgeProps">
                    <SpecialEdge v-bind="edgeProps" />
                </template>
            </VueFlow>
        </div>
    </div>


    <Dialog v-model:visible="addNewStateModal" header="Add new State" :style="{ width: '25rem' }" :modal="true"
        :draggable="false">
        <div class="mb-4">
            <label for="name" class="font-semibold block mb-2">State Name</label>
            <InputText id="name" class="w-full" placeholder="State Name" autocomplete="off"
                v-model="addNewStateModalData['name']" />
        </div>
        <div class="mb-4">
            <label for="stateType" class="font-semibold block mb-2">State Type</label>
            <Dropdown id="stateType" class="w-full" :options="stateTypeOptions" optionLabel="label"
                placeholder="Select a stateType" v-model="addNewStateModalData['stateType']" />
        </div>
        <div class="flex justify-end gap-2">
            <div class="ml-auto flex gap-2">
                <Button type="button" text label="Cancel" severity="secondary"
                    @click="addNewStateModal = false"></Button>
                <Button type="button" label="Save" @click="onAddNewState"></Button>
            </div>
        </div>
    </Dialog>


    <Dialog v-model:visible="addNewFlowModal" header="Add a new Flow" :style="{ width: '25rem' }" :modal="true"
        :draggable="false">

        <div class="mb-4">
            <label for="label" class="font-semibold block mb-2">Flow label</label>
            <InputText id="label" class="w-full" placeholder="Flow Label" autocomplete="off"
                v-model="addNewFlowModalData['label']" />
        </div>
        <div class="mb-4">
            <label for="source" class="font-semibold block mb-2">Source</label>
            <Dropdown id="source" class="w-full" :options="nodes" optionLabel="data.label" optionValue="id"
                placeholder="Select a source" v-model="addNewFlowModalData['source']" />
        </div>
        <div class="mb-4">
            <label for="target" class="font-semibold block mb-2">Target</label>
            <Dropdown id="target" class="w-full" :options="nodes" optionLabel="data.label" optionValue="id"
                placeholder="Select a target" v-model="addNewFlowModalData['target']" />
        </div>

        <div class="flex justify-end gap-2">
            <div class="ml-auto flex gap-2">
                <Button type="button" text label="Cancel" severity="secondary"
                    @click="addNewFlowModal = false"></Button>
                <Button type="button" label="Save" @click="onAddNewFlow"></Button>
            </div>
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';

import { ref } from 'vue'

// these components are only shown as examples of how to use a custom node or edge
// you can find many examples of how to create these custom components in the examples page of the docs
import SpecialNode from '../components/Workflow/SpecialNode.vue'
import SpecialEdge from '../components/Workflow/SpecialEdge.vue'

import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import { VueFlow, useVueFlow, type Node, type Edge } from '@vue-flow/core'

import { useToast } from "primevue/usetoast";
const toast = useToast();

const { onConnect, addEdges } = useVueFlow()

onConnect((params) => {
    addEdges([params])
})

// these are our nodes
const nodes = ref([
    // an input node, specified by using `type: 'input'`
    {
        id: 'backlog',
        position: { x: 0, y: 0 },
        data: { label: 'Backlog' },
    },
    {
        id: 'Todo',
        position: { x: 400, y: 0 },
        data: { label: 'Todo' },
    },
    {
        id: 'InProgress',
        position: { x: 800, y: 0 },
        data: { label: 'InProgress', }
    },
    {
        id: 'Review',
        position: { x: 1200, y: 0 },
        data: { label: 'Review' },
    },
    {
        id: 'Done',
        position: { x: 1500, y: 0 },
        data: { label: 'Done' },
    },
])

// these are our edges
const edges = ref([
    {
        id: 'Backlog-Todo',
        source: 'backlog',
        target: 'Todo',
        label: 'Approve',
        type: "smoothstep",
    },
    {
        id: 'Todo-InProgress',
        source: 'Todo',
        target: 'InProgress',
        label: "Start work",
        type: "smoothstep",
    },
    {
        id: 'InProgress-Review',
        source: 'InProgress',
        target: 'Review',
        label: "Submit for review",
        type: "smoothstep",
    },
    {
        id: 'Review-Done',
        source: 'Review',
        target: 'Done',
        label: "Approve",
        type: "smoothstep",
    }
])

const addNewFlowModal = ref(false)
const addNewFlowModalData = ref({
    label: '',
    source: '',
    target: ''
})
const onAddNewFlow = () => {
    const isAlreadyExists = edges.value.find(edge => edge.source === addNewFlowModalData.value['source'] && edge.target === addNewFlowModalData.value['target'])
    const isBothSame = addNewFlowModalData.value['source'] === addNewFlowModalData.value['target']
    if (isAlreadyExists || isBothSame) {
        if (isAlreadyExists) {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: "This flow already exists",
                life: 3000,
            });
        }
        if (isBothSame) {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: "Source and target can't be same",
                life: 3000,
            });
        }
        return
    }
    edges.value.push({
        id: `${addNewFlowModalData.value['source']}-${addNewFlowModalData.value['target']}`,
        source: addNewFlowModalData.value['source'],
        target: addNewFlowModalData.value['target'],
        label: addNewFlowModalData.value['label'],
        type: "smoothstep",
    })
    addNewFlowModal.value = false
    addNewFlowModalData.value = {
        label: '',
        source: '',
        target: ''
    }
}


const addNewStateModal = ref(false)
const addNewStateModalData = ref({
    name: '',
    stateType: ''
})
const stateTypeOptions = ref([
    { label: 'Backlog', value: 'Backlog' },
    { label: 'InProgress', value: 'InProgress' },
    { label: 'Todo', value: 'Todo' },
    { label: 'Done', value: 'Done' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'Review', value: 'Review' },
    { label: 'Closed', value: 'Closed' }
])
const onAddNewState = () => {
    const isStateAlready = nodes.value.find(node => node.data.label === addNewStateModalData.value['name'])
    if (isStateAlready) {
        toast.add({
            severity: "error",
            summary: "Error",
            detail: "This state already exists",
            life: 3000,
        });
        return
    }
    nodes.value.push({
        id: Math.random().toString(36).substring(7),
        position: { x: (nodes.value.length + 1) * 300, y: 0 },
        data: {
            label: addNewStateModalData.value['name']
        }
    })
    addNewStateModal.value = false
    addNewStateModalData.value = {
        name: '',
        stateType: ''
    }
}


</script>