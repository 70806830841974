<template>
  <Button
    @click="applyChanges"
    :loading="submitLoading"
    severity="help"
    class="mt-2 w-full"
    label="Submit"
  />
</template>
<script setup>
import { ref, watchEffect } from "vue";
import { useApi } from "../../../hooks/useApi";
import { serviceStore } from "@/store/serviceStore";
import { useToast } from "primevue/usetoast";
import { usePdfOnlyStore } from "@/store/pdf-only/store";
import moment from "moment";

const submitLoading = ref(false);

const store = serviceStore();
const pdfOnlyStore = usePdfOnlyStore();
const toast = useToast();
const isError = ref(null);

const props = defineProps({
  payload: {
    type: Array,
    required: true,
  },
  trxId: {
    type: String,
    required: true,
  },
  currentIndex: {
    type: Number,
    required: true,
  },
  jobDetail: {
    type: Array,
    required: true,
  },
  isLastIndex: {
    type: Boolean,
    required: true,
  },
  labelSummary: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(["handleNext"]);

const missingFields = ref([]);

const handleSubmit = async () => {
  try {
    submitLoading.value = true;
    const invoiceLabels = pdfOnlyStore.payload.map(({ id, ...rest }) => rest);
    const data = {
      job_id: props.trxId,
      invoice_labels: invoiceLabels,
    };
    const {
      data: response,
      loading: loadingRef,
      error: errorRef,
      postItem: submitInvoices,
    } = useApi("/invoices/hitl/pdf-only");
    await submitInvoices(data);
    isError.value = errorRef.value;
    if (response.value.statusCode === 200) {
      if (
        response.value &&
        Object.keys(response.value.failure_invoices).length === 0
      ) {
        if (props.isLastIndex == props.currentIndex) {
          pdfOnlyStore.activeStep = 0;
          pdfOnlyStore.labellingDone = true;
          setTimeout(() => {
            store.isReload = true;
          }, 6000);
          toast.add({
            severity: "success",
            summary: `Successfully labeled ${pdfOnlyStore.payload.length} invoices`,
            life: 4000,
          });
        }
        pdfOnlyStore.infoMessage.status = "success";
        pdfOnlyStore.infoMessage.message =
          "Invoice has been successfully labeled";
        emits("handleNext");
        setTimeout(() => {
          pdfOnlyStore.infoMessage = {
            status: "none",
            message: "",
          };
        }, 4000);
      } else {
        pdfOnlyStore.infoMessage.status = "error";
        pdfOnlyStore.infoMessage.message = `Failed to label invoice, ${
          Object.values(response.value?.failure_invoices)[0]
        } `;
      }

      submitLoading.value = loadingRef.value;

      if (props.labelSummary?.failure_invoices === props.currentIndex + 1) {
        // pdfOnlyStore.labellingDone = true;
      } else {
        // emits("handleNext");
      }
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    submitLoading.value = false;
  }
};

function generateUniqueId() {
  return Date.now().toString();
}

const applyChanges = async () => {
  const validation = validateJobData();

  if (validation.valid) {
    pdfOnlyStore.nextPdf = true;
    pdfOnlyStore.highlightedField = "";
    pdfOnlyStore.selectedHighlight = "";
    const jobDetails = Object.values(props.jobDetail);
    const appliedChanges = store.elasticSearchResult
      ? {
          ...pdfOnlyStore.jobData,
          issuer_ui_search_match: store.elasticSearchResult,
          tax_id:
            typeof store.elasticSearchResult === "object"
              ? store.elasticSearchResult.tax_id
              : pdfOnlyStore.jobData.tax_id,
          name:
            typeof store.elasticSearchResult === "object"
              ? store.elasticSearchResult.name
              : pdfOnlyStore.jobData.name,
        }
      : { ...pdfOnlyStore.jobData };
    // if (pdfOnlyStore.jobData.name && pdfOnlyStore.jobData.tax_id) {
    //   delete pdfOnlyStore.jobData.vendor_recommendations;
    // }

    if (typeof store.elasticSearchResult === "object") {
      appliedChanges.tax_id = store.elasticSearchResult.tax_id;
    }
    if (props.currentIndex + 1 != props.jobDetail?.length) {
      store.elasticSearchResult = "";
    }

    const currentInvoice = jobDetails[props.currentIndex];

    if (!currentInvoice.id) {
      currentInvoice.id = generateUniqueId();
    }

    const existingChangeIndex = pdfOnlyStore.payload.findIndex(
      (item) => item.id === currentInvoice.id
    );
    pdfOnlyStore.payload = [];
    if (existingChangeIndex !== -1) {
      // Mutate the existing object
      pdfOnlyStore.payload[existingChangeIndex] = {
        ...pdfOnlyStore.payload[existingChangeIndex],
        ...appliedChanges,
      };
    } else {
      // Push a new object with the id
      pdfOnlyStore.payload.push({
        ...appliedChanges,
        s3_path: currentInvoice.s3_path,
        id: currentInvoice.id,
        invoice_date: moment(pdfOnlyStore.jobData.date).format("DD-MM-YY"),
      });
    }
    // Additional logic
    await handleSubmit();
    // handleNext();
    if (
      props.isLastIndex !== props.currentIndex &&
      pdfOnlyStore.infoMessage.status === ""
    ) {
      // console.log("I am here 2", pdfOnlyStore.payload);
      toast.add({
        severity: "success",
        summary: `Successfully labeled current invoice ${currentInvoice.invoice_number}`,
        life: 4000,
      });
      setTimeout(() => {
        emits("handleNext");
      }, 1000);
    }
    if (props.labelSummary?.failure_invoices === pdfOnlyStore.payload?.length) {
      toast.add({
        severity: "success",
        summary: `Successfully labeled all invoices, Now please submit the changes`,
        detail: "",
        life: 3000,
      });
    }
    // isSelected.value = [];
  } else {
    if (missingFields.value.includes("name")) {
      pdfOnlyStore.activeStep = 0;
    } else if (
      missingFields.value.includes("invoice_number") ||
      missingFields.value.includes("total") ||
      missingFields.value.includes("currency")
    ) {
      pdfOnlyStore.activeStep = 2;
    }
    toast.add({
      severity: "warn",
      summary: validation.message,
      detail: "",
      life: 5000,
    });
  }
};

const validateJobData = () => {
  const requiredFields =
    typeof store.elasticSearchResult === "object"
      ? ["total", "invoice_number", "currency"]
      : !pdfOnlyStore.jobData.is_new_template && store.elasticSearchResult == ""
      ? ["tax_id", "total", "invoice_number", "currency"]
      : ["name", "total", "invoice_number", "currency"];
  missingFields.value = [];

  for (const field of requiredFields) {
    if (!pdfOnlyStore.jobData[field]) {
      missingFields.value.push(field);
    }
  }
  if (missingFields.value.length > 0) {
    return {
      valid: false,
      message: `There are required fields missing. Missing fields: ${missingFields.value.join(
        ", "
      )}`,
    };
  } else {
    return {
      valid: true,
    };
  }
};

</script>
