<template>
  <div class="flex gap-2" >
    <Avatar
    :label="comment.username[0]"
    class="mr-2 flex-shrink-0"
    style="background-color: #dee9fc; color: #1a2551; height: 30px; width: 30px"
    shape="circle"
  />
    <div class="pt-2 w-full">
      <b>{{ comment.username }}</b> -
      <span>{{ moment(comment.time).format('LLL') }}</span>
      <div>
        <p v-if="typeof(commentString) == 'object'" >
          {{ commentString.comment }}
        </p>
        <p v-else>
          {{ commentString }}
        </p>
        <div v-if="attachments.length > 0">
          <div
            v-for="(attachment, index) in attachments"
            :key="index"
            class="border-1 p-2 flex align-items-center gap-4 border-300 w-full mb-2"
          >
            <div>
              <span v-tooltip.top="attachment.split('/').pop()">{{
                getAttachmentName(attachment)
              }}</span>
            </div>
            <div class="ml-auto flex gap-2">
              <Button
                v-tooltip.left="'Download Invoice'"
                icon="pi pi-download"
                size="small"
                rounded
                :loading="downloadingAttachment === attachment"
                @click="downloadAttachment(attachment)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script setup>
  import Avatar from 'primevue/avatar';
  import moment from 'moment';
  import { defineProps, computed } from 'vue';
  import { ref } from 'vue';

  const props = defineProps({
    comment: {
      type: Object,
      required: true,
    },
  });
  
  const commentString = computed(() => {
    return props?.comment?.comment ?? '';
  });
  
  const attachments = computed(() => {
    const attachments = props?.comment?.attachments ?? [];
    return attachments;
  });
  
  const downloadingAttachment = ref('');
  const downloadedAttachments = ref({});
  const downloadAttachment = (attachment) => {
    if (downloadedAttachments.value[attachment]) {
      window.open(downloadedAttachments.value[attachment], '_blank');
      return;
    }
    downloadingAttachment.value = attachment;
  };
  
  const getAttachmentName = (attachment) => {
    if (!attachment) return '';
    attachment = attachment.split('/').pop();
    if (attachment.length > 35) {
      return `${attachment.slice(0, 35)}...${attachment.split('.').pop()}`;
    }
    return attachment.split('/').pop();
  };
  </script>