<template>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
      <div class="flex align-items-center">
        <i class="pi pi-cog text-2xl mr-3 text-500"></i>
        <span class="text-3xl font-medium text-900">User Settings</span>
      </div>
      <div class="mt-3 md:mt-0">
        <Button label="Settings" class="p-button-outlined mr-3" icon="pi pi-cog"></Button>
        <Button label="Save" icon="pi pi-save"></Button>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>