<template>
    <div 
        class="text-left mb-2 bg-white border-1 border-100 border-round-sm px-4 py-2 hover:shadow-1 transition" 
        @click="handleSelectIssue">

        <h2 class="text-red-300">
            {{ renderApStatusTitle(issue.ap_check_status) }}
        </h2>
        
        <div v-if="isTotalMismatched && issue.ap_check && issue.invoice" >
            <p class="my-0 mb-1" >
                <b>Ap Check Total: </b>{{issue.ap_check.currency}} {{ issue.ap_check.total }}
            </p>
            <p class="my-0">
                <b>Invoice Total: </b>{{issue.invoice.currency}} {{ issue.invoice.total }}
            </p>
        </div>
        <p>
            Reference: {{ issue.reference }}
        </p>
        <p>
            <b>Invoice Number:</b>
            {{ issue.invoice_number }}
        </p>
        <p>
            <b>Seller:</b>
            {{ issue.seller }}
        </p>
        <p>
            <b>Customer:</b>
            {{ issue.customer }}
        </p>
        <h3>
            {{ issue.currency }} {{ issue.total }}
        </h3>
    </div>
</template>

<script setup>
import { computed } from "vue"
import { renderApStatusTitle } from "../utils/ap-check";
import { useIssuesStore } from "../../../store/apcheck/issues"

const apCheckIssuesStore = useIssuesStore()
const emit = defineEmits(['select-issue'])

const props = defineProps({
    issue: {
        type: Object,
        required: true
    }
})

const handleSelectIssue = () => {
    apCheckIssuesStore.setCurrentIssueId(props.issue.invoice_issue_id)
    emit('select-issue')
}

const isTotalMismatched = computed(() => {
    return props.issue.ap_check_status && props.issue.ap_check_status.includes("total_mismatched")
})


</script>

<style scoped>
/* Add any additional styles if needed */
</style>