<template>
  <div class="p-5">
    <div class="surface-section py-5 md:px-6">
      <div class="card flex justify-content-center">
        <PdfLabelling :currentJobId="currentJobId" />
      </div>
      <div
        class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border"
      >
        <div class="flex align-items-center">
          <i class="pi pi-search text-2xl mr-3 text-500"></i>
          <span class="text-2xl font-medium text-900"
            >Job Details for {{ currentJobId }}</span
          >
        </div>
        <div class="flex gap-2">
          <div>
            <UploadModel />
          </div>
          <div class="p-buttonset flex align-items-center">
            <FeedbackModal
              :isActive="true"
              :trxStatus="trxStatus"
              :summary="summary"
            />
          </div>
          <ButtonGroup class="p-buttonset ml-4" outlined rounded>
            <Button
              class="ml-auto"
              @click="goToPreviousJob"
              :disabled="!pagination.prev_job_id || loading"
            >
              <i class="pi pi-caret-left mr-2 text-sm"></i>
              Prev Job
            </Button>
            <Button
              class="ml-auto"
              @click="goToNextJob"
              :disabled="!pagination.next_job_id || loading"
            >
              Next Job
              <i class="pi pi-caret-right ml-2 text-sm"></i>
            </Button>
          </ButtonGroup>
          <Button
            class="ml-4"
            icon="pi pi-refresh"
            v-tooltip.top="'Refetch job details'"
            @click="getTransactionDetails(trxId)"
            text
            raised
            rounded
            aria-label="Filter"
          />
        </div>
      </div>
    </div>
    <div
      class="surface-card shadow-2 border-round flex p-3 flex-column md:flex-row"
    >
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-4">
          <i
            v-bind:class="{
              'pi pi-sync text-blue-500  text-xl mr-2': true,
              spin: loading || (trxStatus && trxStatus === 'In Progress'),
            }"
          ></i>
          <span class="text-500 font-medium">Job Status</span>
        </div>
        <span
          v-if="trxStatus"
          id="jobStatus"
          class="block text-900 py-1 font-medium text-md border-round"
          :class="{
            'bg-red-200': trxStatus !== 'Complete',
            'bg-green-200': trxStatus === 'Complete',
          }"
          >{{ trxStatus }}
        </span>
        <span
          v-else
          id="jobStatus"
          class="block text-900 font-medium text-md border-round"
          :class="{
            'bg-red-200': trxStatus !== 'Complete',
            'bg-green-200': trxStatus === 'Complete',
          }"
          >Fetching Job Status
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-minus-circle text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Total Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.invoices_processed)" class="">
            {{
              trxStatus !== "Complete" && totalUnprocessedInvoices !== 0
                ? `${totalUnprocessedInvoices} / ${summary.invoices_processed}`
                : loading
                ? 0
                : summary.invoices_processed
            }}
          </div>
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-check-circle text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Successful Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.success_invoices)">
            {{ loading ? 0 : summary.success_invoices }}
          </div>
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-copy text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Duplicate Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.duplicate_invoices)">
            {{ loading ? 0 : summary.duplicate_invoices }}
          </div>
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-question-circle text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Unknown Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.unknown_invoices)">
            {{ summary.unknown_invoices }}
          </div>
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-list text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Multiple Matches</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.multiple_matches_invoices)">
            {{ summary.multiple_matches_invoices }}
          </div>
        </span>
      </div>
      <div
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-ban text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Closed Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.closed_invoices)">
            {{ summary.closed_invoices }}
          </div>
        </span>
      </div>
      <div
        v-if="trxStatus === 'Verification'"
        class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
      >
        <div class="flex align-items-center mb-3 justify-content-center">
          <i class="pi pi-info-circle text-blue-500 text-xl mr-2"></i>
          <span class="text-500 font-medium">Remaining Invoices</span>
        </div>
        <span class="block text-900 font-medium mb-4 text-xl">
          <div :class="globalHeadingClass(summary.remaining_invoices)">
            {{ summary.remaining_invoices }}
          </div>
        </span>
      </div>
      <div v-else>
        <div>
          <ErrorInvoicesTooltip
            :errorInvoices="summary.error_invoice_files"
            :summary="summary"
          />
        </div>
      </div>
    </div>
    <div
      class="container mt-4"
      v-if="progressBar && trxStatus === 'In Progress'"
    >
      <ProgressBar
        :value="completedStatus"
        style="height: 18px"
        class="mb-2"
      ></ProgressBar>
      <b style="font-style: italic"
        >Please hold on while our engine is organizing your invoices...</b
      >
    </div>
    <div
      class="container mt-4"
      v-else-if="loading || (trxStatus && trxStatus === 'In Progress')"
    >
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
    <div
      class="flex flex-column container align-items-center justify-content-center gap-4"
      style="margin-top: 2rem; margin-bottom: 2rem"
    >
      <div
        class="flex m-2 gap-4 w-full align-items-center justify-content-center"
      >
        <DataTable
          :loadingOverlay="false"
          :value="loading ? [] : referencesList"
          v-model:expandedRows="expandedRows"
          v-model:filters="filters"
          dataKey="reference"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          stripedRows
          removableSort
          :paginator="true"
          :rows="10"
          :paginatorTemplate="{
            '640px': 'PrevPageLink CurrentPageReport NextPageLink',
            '960px':
              'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown',
            '1300px':
              'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
            default:
              'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown',
          }"
          :rowsPerPageOptions="[10, 20, 50, 100]"
          responsiveLayout="scroll"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :globalFilterFields="[
            'reference',
            'SubTotal',
            'Impuestos',
            'Total',
            'num_invoices',
          ]"
        >
          <template #header>
            <div class="flex card-container blue-container overflow-hidden">
              <div
                class="flex-none flex align-items-center justify-content-center font-bold"
              >
                <h3 class="m-0">Sorted Invoices</h3>
              </div>
              <div
                class="flex-grow-1 flex align-items-center justify-content-center"
              ></div>
              <div
                class="flex-none flex align-items-center justify-content-center"
              >
                <div
                  class="flex-none flex align-items-center justify-content-center"
                >
                  <span v-show="true" class="flex gap-4">
                    <div class="flex align-items-center gap-2">
                      <DownloadReportModal
                        @downloadExport="downloadExport"
                        v-if="
                          reportExportResponse.status === 1 && !exportingReport
                        "
                        :isVisible="exportModalVisible"
                      />
                      <ButtonGroup class="p-buttonset">
                        <Button
                          :label="
                            exportingReport ? 'Exporting Zip' : 'Export Zip'
                          "
                          severity="help"
                          outlined
                          :disabled="
                            userActivityResponse.export_zip_name === null
                          "
                          icon="pi pi-download"
                          @click="() => handleExport()"
                          :loading="exportingReport"
                          style="border-right: none"
                          rounded
                        />
                        <Button
                          class="p-0"
                          v-tooltip.top="'Export type'"
                          outlined
                          rounded
                        >
                          <Dropdown
                            v-model="exportType"
                            :options="exportTypes"
                            @change="handleExportTypeChange"
                            optionLabel="name"
                            placeholder="Select a City"
                            class="w-full border-0"
                          />
                        </Button>
                      </ButtonGroup>
                    </div>
                  </span>
                </div>
                <span class="flex w-full ml-2">
                  <InputGroup>
                    <InputText
                      placeholder="Keyword Search"
                      v-model="keywordSearch"
                      @input="handleKeywordSearch"
                    />
                    <Button icon="pi pi-search" />
                  </InputGroup>
                  <Button
                    icon="pi pi-plus"
                    label="Expand All"
                    @click="expandAll"
                    class="mr-2"
                    style="margin-left: 1rem; width: 20rem"
                  />
                  <Button
                    icon="pi pi-minus"
                    label="Collapse All"
                    @click="collapseAll"
                    style="width: 20rem"
                  />
                </span>
              </div>
            </div>
          </template>

          <template #empty> No invoices found. </template>

          <template #loading>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column
            field="reference"
            header="Reference"
            :sortable="true"
          ></Column>
          <Column field="SubTotal" header="SubTotal" :sortable="true">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data["SubTotal"]) }}
            </template>
          </Column>
          <Column field="Impuestos" header="Taxes" :sortable="true">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data["Impuestos"]) }}
            </template>
          </Column>
          <Column field="Total" header="Total" :sortable="true">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data["Total"]) }}
            </template>
          </Column>
          <Column field="num_invoices" header="# Inv" :sortable="true">
            <template #body="slotProps">
              <div :class="globalHeadingClass(slotProps.data['num_invoices'])">
                {{ slotProps.data["num_invoices"] }}
              </div>
            </template>
          </Column>

          <template #expansion="slotProps">
            <div class="details-subtable ml-7">
              <h4>Details for {{ slotProps.data.reference }}</h4>
              <DataTable
                :value="slotProps.data.details"
                responsiveLayout="scroll"
              >
                <Column field="_id" header="ID" sortable>
                  <template #body="{ data }">
                    <span>
                      {{ data._id }}
                    </span>
                  </template>
                </Column>
                <Column field="emisor_rfc" header="Em RFC" sortable></Column>
                <Column field="emisor_nombre" header="Em Name"></Column>
                <Column field="receptor_rfc" header="Rec RFC" sortable></Column>
                <Column
                  field="receptor_nombre"
                  header="Rec Name"
                  sortable
                ></Column>
                <Column field="tag" header="Tag">
                  <template #body="{ data }">
                    <TagsChipComponent :tagItem="data" />
                  </template>
                </Column>
                <Column field="moneda" header="Currency" sortable></Column>
                <Column field="SubTotal" header="SubTotal" sortable>
                  <template #body="slotProps">
                    {{ formatCurrency(slotProps.data["SubTotal"]) }}
                  </template>
                </Column>
                <Column field="Impuestos" header="Taxes" sortable>
                  <template #body="slotProps">
                    {{ formatCurrency(slotProps.data["Impuestos"]) }}
                  </template>
                </Column>
                <Column field="Total" header="Total" sortable>
                  <template #body="slotProps">
                    {{ formatCurrency(slotProps.data["Total"]) }}
                  </template>
                </Column>
                <Column
                  header="Actions"
                  headerStyle="width: 4rem; text-align: center"
                  bodyStyle="text-align: center; overflow: visible"
                >
                  <template #body="slotProps">
                    <div class="flex gap-2">
                      <AssignReferenceModal
                        @handleViewButton="handleViewButton(slotProps.data)"
                        :invoiceData="selectedInvoice"
                        @handleSubCategoryChange="onReferenceChange"
                        :matchedReferences="matchedReferences"
                        @toggleConfirmModal="toggleConfirmModal"
                        @resetReferences="resetReferences"
                        :recommendedReferences="recommendedReferences"
                        :detailPageTitle="detailPageTitle"
                        :organization="organization"
                        :viewedInvoice="viewedInvoice"
                        :isTyping="isTyping"
                        :detailPdfLink="detailPdfLink"
                        :referencePayload="referencePayload"
                        @handleNextInvoice="handleNextInvoice"
                        :disableMatchReferences="disableMatchReferences"
                        :subcategory="subcategory"
                        :invoicesArray="invoicesArray"
                        @handleChipClick="handleChipClick"
                        @handlePreviousInvoice="handlePreviousInvoice"
                      />
                      <span
                        v-tooltip.top="
                          `Investigate or see why this has been categorized as ${slotProps.data.reference}`
                        "
                        class="cursor-pointer"
                        @click="handleInvoiceId(slotProps.data)"
                      >
                        <SearchInvoice :invoice_id="slotProps.data._id" />
                      </span>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>

          <template #paginatorstart>
            <Button type="button" icon="pi pi-refresh" class="p-button-text" />
          </template>

          <template #paginatorend>
            <Button type="button" icon="pi pi-cloud" class="p-button-text" />
          </template>
        </DataTable>
      </div>
      <Dialog
        header="Report an issue"
        v-model:visible="reportIssueDialogVisible"
        modal
        :style="{ width: '350px' }"
      >
        <Textarea
          v-model="reportIssueText"
          class="mb-3"
          placeholder="Write issue."
          autoResize
          rows="4"
          style="width: 100%"
          :disabled="reportingIssue"
        />
        <div class="flex">
          <div class="ml-auto">
            <Button class="mr-2" @click="cancelReport" link size="small"
              >Cancel Report</Button
            >
            <Button
              size="small"
              @click="submitReport"
              :loading="reportingIssue"
              :disabled="reportIssueText.length == 0"
            >
              {{ reportingIssue ? "Submitting Report" : "Submit Report" }}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        v-model:visible="exportModalVisible"
        modal
        :header="
          exportingReport && !retryOption
            ? 'Exporting report'
            : !exportingReport && retryOption
            ? 'Export Error'
            : 'Report exported'
        "
        style="width: 450px"
      >
        <template #header>
          <h4 class="">
            {{
              exportingReport && !retryOption
                ? "Exporting report"
                : !exportingReport && retryOption
                ? "Export Error"
                : "Report exported"
            }}
          </h4>
          <i
            v-if="retryOption"
            v-tooltip.top="'Reload Export'"
            @click="reloadExport"
            class="pi pi-refresh absolute reload-button"
          ></i>
        </template>
        <div class="relative">
          <div class="text-center" v-if="exportingReport && !retryOption">
            <p>
              Exporting report. Please do not navigate away from this page or
              close this browser window.
            </p>
            <div class="flex align-items-center">
              <ProgressSpinner style="width: 50px; height: 50px" />
            </div>
          </div>
          <div
            v-if="
              reportExportResponse.status === exportStatus.READY &&
              exportingReport === false &&
              !retryOption
            "
          >
            <p>
              The report has been exported. You can download it by clicking the
              button below.
            </p>
          </div>
          <div v-if="!exportingReport && retryOption">
            <p>Something went wrong with the export, Please try again.</p>
          </div>
          <div
            v-if="
              reportExportResponse.status === exportStatus.PENDING &&
              exportingReport === false &&
              !retryOption
            "
          >
            <p>The report is being exported. Please wait.</p>
          </div>
        </div>

        <template #footer>
          <div class="flex justify-content-end">
            <Button
              @click="exportModalVisible = false"
              link
              label="Close"
            ></Button>
            <Button
              v-if="reportExportResponse.status === 1 && !exportingReport"
              @click="downloadExport"
              label="Download"
              icon="pi pi-download"
            />
          </div>
        </template>
      </Dialog>
      <Dialog
        v-model:visible="confirmModal"
        :modal="true"
        header="Confirm"
        :style="{ width: '40vw' }"
      >
        <div class="w-full">
          <p class="mb-5 px-2">
            Are you sure to assign reference
            <b> {{ referencePayload.reference }} </b> to invoice
            <b>{{ referencePayload.ids.toString().replace(/,/g, ", ") }}</b
            >.
          </p>
          <div class="flex justify-content-end">
            <Button
              @click="confirmModal = false"
              icon="pi pi-times"
              label="Cancel"
            ></Button>
            <Button
              @click="handleAssignClick"
              :loading="assignButtonLoading"
              icon="pi pi-check"
              class="ml-2"
              label="Confirm"
            ></Button>
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  computed,
  watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  useFormatCurrency,
  useGetDownloadLinks,
  useOpenInNewTab,
} from "@composables/useUtils";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import Repository from "@repositories/RepositoryFactory";
import { useToast } from "primevue/usetoast";
import { serviceStore } from "@/store/serviceStore";
import {
  assignReference,
  createFiltersObject,
  getSearchResults,
  globalHeadingClass,
  exportReports,
  handleDropDownChange,
  toggleModal,
  toggleViewModel,
} from "../utils/helpers";
import { useApi } from "../../hooks/useApi";
import PdfLabelling from "../components/Jobs/PdfLabelling.vue";
import FeedbackModal from "../components/App/FeedbackModal.vue";
import SearchInvoice from "../components/Jobs/SearchInvoice.vue";
import AssignReferenceModal from "../components/Jobs/AssignReferenceModal.vue";
import DownloadReportModal from "../components/DownloadReportModal.vue";
import { useJobStore } from "@/store/jobsStore/store";
import TagsChipComponent from "../components/Jobs/TagsChipComponent.vue";
import { debounce } from "lodash";
import ErrorInvoicesTooltip from "../components/Jobs/ErrorInvoicesTooltip.vue";
import UploadModel from "../components/Jobs/JobsDetail/UploadModel.vue";

const loading = ref(false);
const ResultsRepo = Repository.get("results");
const resultsStores = serviceStore();
const { formatCurrency } = useFormatCurrency();
const userSession = useUserSessionStore();
const { userName, attributes, organization } = storeToRefs(userSession);
const email = ref(attributes.value.email);
const trxStatus = ref();
const route = useRoute();
const router = useRouter();
const trxId = ref(route.params.trx_id);
const toast = useToast();
const referencesListAll = ref([]);
const reportExportTimeout = ref(null);
const filterModel = ref(false);
const disableMatchReferences = ref(true);
const confirmModal = ref(false);
const assignButtonLoading = ref(false);
const pdfLoading = ref();
const referenceCategories = ref([]);
const subcategory = ref();
const selectedSubcategory = ref([]);
const selectedMatchReferences = ref();
const viewedInvoice = ref();
const retryOption = ref(false);
const dropdownData = ref();
const exportPayload = ref({});
const isTyping = ref(false);
const invoicesArray = ref();
const selectedInvoice = ref();

const recommendedReferences = ref([]);
const downloadAlertModal = ref(false);

const exportTypes = ref([
  { name: "Reference", code: "RF" },
  { name: "Issuer", code: "ISU" },
]);
const exportType = ref(exportTypes.value[0]);

const referencePayload = reactive({
  reference: "",
  user: "",
  ids: [],
  s3_paths: [],
  organization: "",
});
const jobStore = useJobStore();

const downloadExport = () => {
  if (openInNewTab) {
    openInNewTab(reportExportResponse.value.presigned_zip_url);
    jobStore.exportModelVisible = false;
  }
};

const handleInvoiceId = (data) => {
  resultsStores.selectedSearchInvoice = "";
  resultsStores.selectedSearchInvoice = data._id;
};

const summary = reactive({});

const currentJobId = computed(() => {
  return route.params.trx_id;
});

const { openInNewTab } = useOpenInNewTab();

const onReferenceChange = () => {
  if (typeof jobStore.selectedSubcategory === "object") {
    isTyping.value = false;
  } else {
    handleDropDownChange(
      jobStore.selectedSubcategory,
      isTyping,
      dropdownData,
      subcategory
    );
  }
  referencePayload.reference = jobStore.selectedSubcategory.name;
};

const referencesList = ref([]);
const expandedRows = ref([]);

const detailPageTitle = ref();
const detailXmlLink = ref();
const detailPdfLink = ref();
const keywordSearch = ref();
const completedStatus = ref(0);
const progressBar = ref(false);
const isError = ref();
const reportExportResponse = ref({
  presigned_zip_url: "",
  status: -1,
});

const handleChipClick = (item) => {
  if (item) {
    disableMatchReferences.value = true;
    jobStore.selectedMatchReferences = "";
    jobStore.selectedSubcategory = "";
    referencePayload.reference = item;
  }
};

const exportingReport = ref(false);
const userActivityResponse = ref({
  statusCode: "",
  presigned_zip_url: "",
});
const pagination = ref({
  prev_job_id: null,
  next_job_id: null,
});
const exportStatus = {
  PENDING: 0,
  READY: 1,
};

const handleViewButton = async (data) => {
  selectedInvoice.value = data;
  viewedInvoice.value = data;
  recommendedReferences.value = data?.matched_references;
  filterModel.value = false;
  detailPdfLink.value = "";
  await toggleViewModel(
    data,
    pdfLoading,
    referenceCategories,
    getDownloadLinks,
    referencePayload,
    userName,
    organization,
    resultsStores
  );
};

const handleNextInvoice = async (invoiceData) => {
  const id = invoiceData._id;
  const detailsArray = invoicesArray.value.flatMap((item) => item.details);
  const index = detailsArray.findIndex((item) => item._id === id);
  const nextInvoice = detailsArray[index + 1];
  if (nextInvoice) {
    detailPageTitle.value = "";
    viewedInvoice.value = "";
    recommendedReferences.value = "";
    await handleViewButton(nextInvoice);
  }
};

const handlePreviousInvoice = async (invoiceData) => {
  const id = invoiceData._id;
  const detailsArray = invoicesArray.value.flatMap((item) => item.details);
  const index = detailsArray.findIndex((item) => item._id === id);
  const previousInvoice = detailsArray[index - 1];
  if (previousInvoice) {
    detailPageTitle.value = "";
    viewedInvoice.value = "";
    recommendedReferences.value = "";
    await handleViewButton(previousInvoice);
  }
};

const toggleConfirmModal = () => {
  toggleModal(selectedMatchReferences, referencePayload, confirmModal, toast);
};

const resetReferences = () => {
  confirmModal.value = false;
  jobStore.dialogVisible = false;
  jobStore.assignReferenceModalData.filterModel = false;
  jobStore.selectedMatchReferences = "";
  selectedSubcategory.value = "";
  jobStore.selectedMatchReferences = "";
  viewedInvoice.value = "";
  recommendedReferences.value = "";
  filterModel.value = "";
  referencePayload.reference = "";
  reportExportResponse.value.presigned_zip_url = "";
  reportExportResponse.value.status = -1;
  downloadAlertModal.value = false;
};

const handleAssignClick = () => {
  const category = "reference";
  assignReference(
    assignButtonLoading,
    jobStore.selectedMatchReferences,
    ResultsRepo,
    toast,
    resultsStores,
    organization,
    resetReferences,
    referencePayload,
    category,
    jobStore
  );
};

const handleExportTypeChange = () => {
  reportExportResponse.value.status = -1;
};

const { getDownloadLinks } = useGetDownloadLinks(
  pdfLoading,
  organization,
  jobStore,
  detailPageTitle,
  detailXmlLink,
  detailPdfLink
);

const filterNames = [
  "global",
  "reference",
  "subtotal",
  "Impuestos",
  "Total",
  "num_invoices",
];
const filters = createFiltersObject(filterNames);

let polling;

const goToNextJob = () => {
  router.push({
    params: { ...route.params, trx_id: pagination.value.next_job_id },
  });
  getTransactionDetails(pagination.value.next_job_id);
  trxId.value = pagination.value.next_job_id;
};

const goToPreviousJob = () => {
  router.push({
    params: { ...route.params, trx_id: pagination.value.prev_job_id },
  });
  getTransactionDetails(pagination.value.prev_job_id);
  trxId.value = pagination.value.prev_job_id;
};

watchEffect(() => {
  if (jobStore.isTagUpdated) {
    fetchInvoiceDetails();
    jobStore.isTagUpdated = false;
  }
});

const fetchInvoiceDetails = async () => {
  const interval = setInterval(() => {
    if (completedStatus.value < 25) {
      completedStatus.value += 3;
    } else {
      clearInterval(interval);
    }
  }, 500);

  loading.value = true;
  const jobId = trxId.value;
  getTransactionDetails(jobId);
  await resultsStores.getFilterData(organization.value, false);

  polling = setInterval(() => {
    getTransactionDetails(jobId);
  }, 8000);
  if (resultsStores?.referenceCategories?.options) {
    subcategory.value = Object.values(
      resultsStores.referenceCategories.options
    ).map((item) => ({ name: item }));
    dropdownData.value = subcategory.value;
  } else {
    subcategory.value = [];
    dropdownData.value = [];
  }
};

onMounted(async () => {
  await fetchInvoiceDetails();
});

onUnmounted(() => {
  if (polling) {
    clearInterval(polling);
    loading.value = false;
  }
});

onBeforeUnmount(() => {
  if (polling) {
    clearInterval(polling);
    loading.value = false;
  }
});

const handleKeywordSearch = debounce(() => {
  const searchTerm = keywordSearch.value.trim().toLowerCase();

  if (searchTerm === "") {
    referencesList.value = referencesListAll.value;
    collapseAll();
    return;
  }

  referencesList.value = getSearchResults(searchTerm, referencesListAll, null);
  expandAll();
}, 300); // 300ms delay

const handleExport = () => {
  exportReports({
    reportExportTimeout,
    exportingReport,
    retryOption,
    exportPayload,
    organization,
    exportType,
    userActivityResponse,
    isError,
    reportExportResponse,
    exportStatus,
    downloadExport,
  });
};

const getTransactionDetails = async (jobId) => {
  try {
    loading.value = true;
    progressBar.value = true;
    const params = {
      user: userName.value,
      email: email.value,
      job_id: jobId,
      organization: organization.value,
    };
    const {
      data: jobDetailResponse,
      loading: isLoading,
      error: errorRef,
      fetchItems: getJobDetails,
    } = useApi("/results/transaction_status", params);
    if (typeof getJobDetails === "function") {
      getJobDetails().then(() => {
        if (jobDetailResponse.value) {
          jobStore.multiMatchIds = jobDetailResponse.value?.results.reduce(
            (acc, item) => {
              if (item.reference === "MultipleMatches") {
                acc.push(...item.details.map((detail) => detail._id));
              }
              return acc;
            },
            []
          );
          invoicesArray.value = jobDetailResponse.value.results;
          userActivityResponse.value.export_zip_name =
            jobDetailResponse.value.export_zip_name;

          const sumResp = jobDetailResponse.value;
          Object.assign(summary, sumResp.summary, {
            invoices_processed: sumResp.summary["invoices_processed"],
          });

          const remainingInvoices = sumResp.summary.remaining_invoices;
          const processedInvoices = sumResp.summary.invoices_processed;
          let percentage = Math.round(
            ((processedInvoices - remainingInvoices) / processedInvoices) * 100
          );

          completedStatus.value = percentage;

          if (percentage >= 100) {
            completedStatus.value = 100;
            setTimeout(() => {
              trxStatus.value = sumResp?.status;
            }, 1000);
          } else {
            setTimeout(() => {
              trxStatus.value = sumResp?.status;
            }, 800);
          }

          pagination.value = {
            next_job_id: sumResp.next_job_id,
            prev_job_id: sumResp.prev_job_id,
          };

          referencesList.value = sumResp.results;
          referencesListAll.value = sumResp.results;
          if (
            sumResp.status === "Verification" &&
            resultsStores.makePdfOnlyFetch
          ) {
            setTimeout(() => {
              toast.add({
                severity: "success",
                summary: "Success",
                detail: "Data has been fetched successfully",
                life: 3000,
                position: "top-left",
              });
              clearInterval(polling);
              resultsStores.makePdfOnlyFetch = false;
            }, 10000);
          } else if (sumResp.status === "Verification") {
            clearInterval(polling);
            resultsStores.makePdfOnlyFetch = false;
          }
          if (sumResp?.status === "Complete") {
            clearInterval(polling);
          } else {
            toast.add({
              severity: "info",
              summary: "Please wait...",
              detail: "Fetching job details",
              life: 3000,
              position: "top-left",
            });
          }
        }
        if (errorRef.value) {
          toast.add({
            severity: "warn",
            summary: "It seems there is an issue...",
            detail: "Please wait we are fetching job details again",
            life: 3000,
            position: "top-left",
          });
        }
        loading.value = isLoading.value;
      });
    }
  } catch (error) {
    progressBar.value = false;
    loading.value = false;
  }
};

watchEffect(() => {
  if (isError.value) {
    if (isError.value.message === "Network Error") {
      toast.add({
        severity: "info",
        summary: "Please wait...",
        detail: "Fetching job details",
        life: 3000,
        position: "top-left",
      });
    } else {
      toast.add({
        severity: "error",
        summary: isError.value.message,
        detail: isError.value?.response?.data["message"],
        life: 3000,
        position: "top-left",
      });
    }
  }
  if (resultsStores.isReload) {
    getTransactionDetails(trxId.value);
    resultsStores.isReload = false;
  }
});

const onRowExpand = (event) => {
  console.log(event);
};

const onRowCollapse = (event) => {
  console.log(event);
};

const expandAll = () => {
  const expandedRowsObject = referencesList.value
    .filter((p) => p["reference"])
    .reduce((acc, curr) => {
      acc[curr.reference] = true; // Use 'reference' as the key
      return acc;
    }, {});

  expandedRows.value = expandedRowsObject;
};

const totalUnprocessedInvoices = computed(() => {
  return summary.remaining_invoices
    ? summary.invoices_processed - summary.remaining_invoices
    : 0;
});

const collapseAll = () => {
  expandedRows.value = null;
};
</script>

<style scoped>
.p-datatable {
  width: 100% !important;
}

:deep(.p-datatable-tbody) {
  font-size: 14px !important;
}

:deep(.p-dropdown) {
  background: white;
  /* border: 1px solid #6366F1; */
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  height: 3rem;
}

:deep(.p-dropdown-label) {
  color: #6366f1;
  margin-top: -8px;
  font-weight: 700;
}

:deep(.p-dropdown-trigger) {
  color: #6366f1;
}

.reload-button {
  right: 4.5rem;
  color: gray;
  cursor: pointer;
  z-index: 100000;
}

/* create spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin 1.5s infinite linear;
}
</style>
