<template>
  <div class="">
    <div>
      <div class="flex justify-content-between mb-2 w-full">
        <div>
          <h3>Filters</h3>
        </div>
        <div class="flex align-items-center gap-4">
          <p class="cursor-pointer" @click="resetFilters">Reset</p>
          <Button
            class="cursor-pointer text-white"
            size="large"
            @click="applyFilters"
            :disabled="isEmpty"
            label="Apply
              Filters"
          />
        </div>
      </div>
      <Divider class="-mt-2" />
      <div>
        <div class="card">
          <Accordion :activeIndex="0">
            <AccordionTab header="Reference">
              <div class="mb-2">
                <InputText
                  type="text"
                  v-model="filterData.reference"
                  class="w-full"
                  @keyup.enter="applyFilters"
                  placeholder="Reference"
                />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion :activeIndex="0">
            <AccordionTab header="Invoice Number">
              <div class="mb-2">
                <InputText
                  type="text"
                  v-model="filterData.invoice_number"
                  class="w-full"
                  @keyup.enter="applyFilters"
                  placeholder="Invoice Number"
                />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion :activeIndex="0">
            <AccordionTab header="AP Seller">
              <div class="mb-2">
                <InputText
                  type="text"
                  v-model="filterData.seller"
                  class="w-full"
                  @keyup.enter="applyFilters"
                  placeholder="AP Seller"
                />
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive } from "vue";

const filterData = reactive({
  reference: "",
  invoice_number: "",
  seller: "",
});

const emit = defineEmits(["applyFilters", "resetFilters"]);
const isEmpty = computed(() => {
  return Object.values(filterData).every((x) => x === "");
});

const applyFilters = () => {
  emit("applyFilters", filterData);
};
const resetFilters = () => {
  emit("resetFilters");
  filterData.reference = "";
  filterData.invoice_number = "";
  filterData.seller = "";
};
</script>
<style scoped>
:deep(.p-datatable-thead) {
  height: 14rem !important;
}
</style>
