import { defineStore } from "pinia";
import Repository from "@repositories/RepositoryFactory";
import { getCachedResponse, storeApiResponse } from "../../db";

const ResultsRepository = Repository.get("results");

export const serviceStore = defineStore("resultsRepository", {
  state: () => ({
    referenceInvoice: false,
    referencesResponses: [],
    organizationStats: [],
    isLoading: false,
    matchedReferences: [],
    tags: [],
    emirosFilterData: [],
    filteredData: [],
    responseError: null,
    unlabeledDataInfo: false,
    filterResponse: [],
    referenceCategories: [],
    filtersData: null,
    pdfLabelHeader: null,
    pdfVerificationModel: false,
    pdfConfirmationModal: false,
    isDataAvailable: false,
    makePdfOnlyFetch: false,
    isReload: false,
    isDevelopment: false,
    labelingPageHeader: {
      invoiceName: "",
      summary: {},
      isNewCompany: false,
      currentIndex: 0,
      payload: 0,
    },
    infoDrawer: {
      isActive: false,
      heading: "",
      message: "",
      list: [],
      footer: "",
    },
    feedbackResponse: {},
    feedbackModal: false,
    selectedSearchInvoice: "",
    confirmAssignReferenceModal: false,
    elasticSearchResult: "",
  }),

  actions: {
    async getReferencesInvoices(userName, organization) {
      try {
        this.isLoading = true;
        const response = await ResultsRepository.getReferencesInvoices({
          params: {
            user: userName,
            organization: organization,
          },
        });

        this.referencesResponses = response;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.responseError = error;
        throw new Error("getReferenceInvoices", error);
      }
    },
    async getFilterData(organization, isMutateDB) {
      const self = this;
      try {
        this.isLoading = true;
        const getCachedData = () => {
          return new Promise((resolve) => {
            getCachedResponse("/filters", async function (cachedData) {
              if (cachedData) {
                self.filtersData = await cachedData;
              }
              resolve();
            });
          });
        };
        await getCachedData();
        const tagsResult = this.filtersData?.find(
          (item) => item.columnName === "tag_labels"
        );
        if (tagsResult) {
          this.tags = tagsResult;
        }
        if (self.filtersData === null) {
          const response = await ResultsRepository.apiGetFiltersData({
            organization: organization,
          });
          await storeApiResponse("/filters", response?.data?.filters);
        }
        if (isMutateDB === true) {
          const response = await ResultsRepository.apiGetFiltersData({
            organization: organization,
          });
          await storeApiResponse("/filters", response?.data?.filters);
        }
        this.filterResponse = this.filtersData;
        const emirosFilter = this.filtersData?.find(
          (item) => item.columnName === "emisors"
        );
        if (emirosFilter) {
          this.emirosFilterData = emirosFilter;
        }
        const matchedReferences = this.filtersData?.find(
          (item) => item.columnName === "matched_references"
        );
        if (matchedReferences) {
          this.matchedReferences = matchedReferences;
        }
        const referenceCategories = this.filtersData?.find(
          (item) => item.columnName === "all_references"
        );
        if (referenceCategories) {
          this.referenceCategories = referenceCategories;
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.responseError = error;
        throw new Error("getFilterData", error);
      }
    },
    async filterUserData(payload) {
      try {
        this.isLoading = true;
        const response = await ResultsRepository.apiFilterData(payload);
        this.filteredData = response.data;
        this.isLoading = false;
      } catch (error) {
        this.filteredData = [];
        this.isLoading = false;
        this.responseError = error;
        throw new Error("filterUserData", error);
      }
    },
    async unlabeledDataInfo(org) {
      try {
        this.isLoading = true;
        const response = await ResultsRepository.apiGetUnlabeledDataInfo({
          organization: org,
        });
        this.referenceInvoice = response.data.check;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.responseError = error;
        throw new Error("unlabeledDataInfo", error);
      }
    },
  },
});
