<template>
  <Button
    label=" Import APCheck File "
    @click="openModal = true"
    style="height: 3.5rem"
  />
  <Dialog
    v-model:visible="openModal"
    modal
    header="Import APCheck .XLS .XLSX File"
    content-class="import-content"
  >
    <form @submit.prevent="onUpload">
      <div style="width: 400px">
        <div class="w-5/2">
          <FileUpload
            ref="fileUpload"
            label="Choose File"
            max-file-size="1000000"
            :auto="false"
            :custom-upload="true"
            accept=".xls,.xlsx"
            mode="advanced"
            @select="onSelectedFiles"
            @upload="onUpload"
          >
            <template #header><span></span></template>
            <template #content="{ files, removeFileCallback }">
              <div v-if="files.length > 0" class="">
                <ProgressBar
                  :style="{
                    position: 'relative',
                    opacity: isUploading ? 1 : 0,
                  }"
                  mode="indeterminate"
                ></ProgressBar>
                <div class="py-2 mt-1">
                  <div
                    v-for="(file, index) of files"
                    :key="index"
                    class="card text-sm m-0 px-4 border-round shadow-2 flex border-1 surface-border gap-2 uploaded-item-preview"
                  >
                    <XlsIcon fill="#428bff" height="50" width="50" />
                    <div
                      class="w-full flex text-left flex-column flex justify-content-between py-2"
                    >
                      <p class="font-bold capitalize m-0 mt-1">
                        {{ limitText(file.name, 25) }}
                      </p>
                      <div class="flex justify-content-between">
                        <span style="font-size: 12px" class="">{{
                          formatSize(file.size)
                        }}</span>
                        <Button
                          v-tooltip.top="'Remove File'"
                          icon="pi pi-trash"
                          class="delete-file-button -mt-4"
                          outlined
                          rounded
                          severity="danger"
                          :disabled="isUploading"
                          @click="removeFileCallback(index)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #empty>
              <div
                class="border-3 border-2 border-dotted border-round-lg border-blue-400 py-5 process-invoice-uploader text-center"
              >
                <div>
                  <div>
                    <XlsxIcon fill="#428bff" height="100" width="100" />
                    <XlsIcon fill="#428bff" height="100" width="100" />
                  </div>
                  <div>
                    <h2
                      class="text-5xl mb-0"
                      style="font-weight: 900; letter-spacing: -0.4px"
                    >
                      Drag & Drop
                      <span class="" style="color: #428bff"
                        >APCheck .XLS .XLSX</span
                      >
                      File to upload

                      <br />
                    </h2>
                    <p class="mt-1 overflow-hidden">
                      or
                      <a
                        tabindex="0"
                        class="cursor-pointer browse-files"
                        @keypress.enter="openFileSelection"
                        @click="openFileSelection"
                        ><span>browse file</span></a
                      >
                      from your device
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </FileUpload>
        </div>
        <div class="flex">
          <Button
            type="submit"
            class="mt-4 ml-auto"
            style="width: 130px"
            size="small"
            :label="isUploading ? 'Uploading...' : 'Upload'"
            icon="pi pi-upload"
            :disabled="isUploadingDisabled"
            :loading="isUploading"
          ></Button>
        </div>
      </div>
    </form>
  </Dialog>
</template>
<script setup>
import { computed, ref } from "vue";
import { usePrimeVue } from "primevue/config";
const $primevue = usePrimeVue();
import { limitText } from "../../composables/useUtils";
import axiosClient from "../../repositories/Clients/AxiosClient";
import XlsxIcon from "../XlsxIcon.vue";
import XlsIcon from "../XlsIcon.vue";

const openModal = ref(false);
const selectedFiles = ref(null);
const isUploading = ref(false);
const totalSize = ref(0);
const fileUpload = ref(null);

const emit = defineEmits(["uploaded"]);

const onUpload = () => {
  // Assuming `event.files` contains the uploaded files
  if (selectedFiles.value && selectedFiles.value.length > 0) {
    const formData = new FormData();
    formData.append("file", selectedFiles.value[0]);

    isUploading.value = true;
    axiosClient
      .post("/apcheck/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        openModal.value = false;
        emit("uploaded");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        isUploading.value = false;
      });
  }
};
const onSelectedFiles = (event) => {
  selectedFiles.value = event.files;
  selectedFiles.value.forEach((file) => {
    totalSize.value += parseInt(formatSize(file.size));
  });
};
const openFileSelection = () => {
  fileUpload.value.choose();
};
const formatSize = (bytes) => {
  const k = 1024;
  const dm = 3;
  const sizes = $primevue.config.locale.fileSizeTypes;

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
};
const isUploadingDisabled = computed(() => {
  return isUploading.value || selectedFiles.value?.length === 0;
});
</script>
<style scoped>
.browse-files {
  color: #015df1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #68a2ff;
  padding: 0 3px;
  position: relative;
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: inline;
  background: transparent;
}

.browse-files:before {
  background: #dae9fc;
  display: block;
  content: "";
  position: absolute;
  top: 23px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.2s ease-in;
  height: 22px;
  z-index: 1;
}

.browse-files:focus:before {
  outline: none !important;
}

.browse-files:focus:before,
.browse-files:focus:before,
.browse-files:hover:before {
  top: 0;
}

.browse-files span {
  z-index: 2;
  display: inline;
  position: relative;
}

:deep(.p-fileupload-buttonbar) {
  display: none;
}
:deep(.p-fileupload-content) {
  padding: 0;
  border: 0;
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
