<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="_x34_"
        viewBox="0 0 512 512" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path style="fill:#C9A662;"
                        d="M512,0v392.858H0V50.992h258.501V23.759C258.501,10.629,269.13,0,282.26,0H512z" />
                </g>
                <g>
                    <g>
                        <rect x="27.822" y="87.242" style="fill:#342221;" width="456.355" height="191.137" />
                    </g>
                    <g>
                        <rect x="27.822" y="110.869" style="fill:#FFFFFF;" width="456.355" height="179.681" />
                    </g>
                </g>
                <g>
                    <rect x="304.511" y="154.886" style="fill:#EAB01E;" width="48.093" height="104.533" />
                </g>
                <g>
                    <rect x="361.221" y="179.156" style="fill:#C13B45;" width="48.094" height="104.533" />
                </g>
                <g>
                    <rect x="417.932" y="167.021" style="fill:#22A3B0;" width="48.094" height="104.533" />
                </g>
                <g>
                    <path style="fill:#D3B161;"
                        d="M512,234.325v191.531c0,9.24-7.433,16.673-16.604,16.673H16.604     c-4.446,0-8.475-1.737-11.463-4.655C1.945,434.887,0,430.58,0,425.856V172.843h229.74c10.976,0,20.216,7.433,22.925,17.507     c0.556,2.015,0.834,4.099,0.834,6.252v37.723H512z" />
                </g>
                <g>
                    <g>
                        <path style="fill:#D3B161;"
                            d="M87.196,314.541v78.304H62.189v-78.304H51.802v-19.047h10.387v-12.503      c0-3.589,0.577-7.086,1.73-10.485c1.153-3.399,2.917-6.445,5.292-9.139c2.375-2.693,5.387-4.874,9.044-6.543      c3.657-1.665,8.046-2.5,13.175-2.5h14.24v21.164h-9.81c-5.774,0-8.664,3.015-8.664,9.04v10.966h18.474v19.047H87.196z       M122.406,275.488v-20.394h25.59v20.394H122.406z M122.792,392.845v-97.351h25.007v97.351H122.792z" />
                        <path style="fill:#D3B161;"
                            d="M203.98,392.845c-5.136,0-9.559-0.831-13.277-2.5c-3.717-1.666-6.764-3.85-9.138-6.543      c-2.375-2.693-4.104-5.736-5.197-9.139c-1.085-3.395-1.635-6.893-1.635-10.484V255.864h25.014v106.775      c0,2.955,0.672,5.196,2.022,6.737c1.344,1.536,3.623,2.306,6.825,2.306h9.62v21.164H203.98z" />
                        <path style="fill:#D3B161;"
                            d="M257.847,350.518c0,6.543,1.858,11.9,5.576,16.065c3.718,4.169,9.172,6.252,16.357,6.252      c5.509,0,9.871-0.798,13.08-2.405c3.202-1.601,6.411-3.942,9.62-7.022l15.197,14.817c-2.565,2.565-5.129,4.841-7.694,6.828      c-2.571,1.992-5.359,3.657-8.371,5.004c-3.012,1.346-6.316,2.34-9.905,2.982c-3.596,0.638-7.632,0.959-12.123,0.959      c-5.902,0-11.635-0.77-17.218-2.306c-5.577-1.54-10.516-4.264-14.81-8.178c-4.301-3.911-7.768-9.169-10.393-15.777      c-2.632-6.605-3.942-14.973-3.942-25.105c0-8.206,1.059-15.489,3.176-21.835c2.117-6.35,5.095-11.702,8.941-16.065      c3.854-4.359,8.432-7.663,13.758-9.908c5.319-2.243,11.256-3.369,17.796-3.369c6.926,0,13.114,1.187,18.568,3.562      c5.448,2.374,10.034,5.706,13.752,10.003c3.724,4.298,6.547,9.427,8.467,15.391c1.926,5.963,2.89,12.476,2.89,19.528v10.58      H257.847z M295.941,333.398c-0.129-2.181-0.326-4.07-0.577-5.678c-0.265-1.601-0.841-3.301-1.737-5.098      c-1.412-2.948-3.494-5.418-6.249-7.405c-2.761-1.988-6.255-2.982-10.488-2.982c-4.233,0-7.727,0.994-10.482,2.982      c-2.762,1.987-4.844,4.457-6.255,7.405c-0.895,1.798-1.479,3.497-1.73,5.098c-0.258,1.608-0.455,3.497-0.577,5.678H295.941z" />
                    </g>
                    <g>
                        <path style="fill:#70663D;"
                            d="M87.196,314.541v78.304H62.189v-78.304H51.802v-19.047h10.387v-12.503      c0-3.589,0.577-7.086,1.73-10.485c1.153-3.399,2.917-6.445,5.292-9.139c2.375-2.693,5.387-4.874,9.044-6.543      c3.657-1.665,8.046-2.5,13.175-2.5h14.24v21.164h-9.81c-5.774,0-8.664,3.015-8.664,9.04v10.966h18.474v19.047H87.196z       M122.406,275.488v-20.394h25.59v20.394H122.406z M122.792,392.845v-97.351h25.007v97.351H122.792z" />
                        <path style="fill:#70663D;"
                            d="M203.98,392.845c-5.136,0-9.559-0.831-13.277-2.5c-3.717-1.666-6.764-3.85-9.138-6.543      c-2.375-2.693-4.104-5.736-5.197-9.139c-1.085-3.395-1.635-6.893-1.635-10.484V255.864h25.014v106.775      c0,2.955,0.672,5.196,2.022,6.737c1.344,1.536,3.623,2.306,6.825,2.306h9.62v21.164H203.98z" />
                        <path style="fill:#70663D;"
                            d="M257.847,350.518c0,6.543,1.858,11.9,5.576,16.065c3.718,4.169,9.172,6.252,16.357,6.252      c5.509,0,9.871-0.798,13.08-2.405c3.202-1.601,6.411-3.942,9.62-7.022l15.197,14.817c-2.565,2.565-5.129,4.841-7.694,6.828      c-2.571,1.992-5.359,3.657-8.371,5.004c-3.012,1.346-6.316,2.34-9.905,2.982c-3.596,0.638-7.632,0.959-12.123,0.959      c-5.902,0-11.635-0.77-17.218-2.306c-5.577-1.54-10.516-4.264-14.81-8.178c-4.301-3.911-7.768-9.169-10.393-15.777      c-2.632-6.605-3.942-14.973-3.942-25.105c0-8.206,1.059-15.489,3.176-21.835c2.117-6.35,5.095-11.702,8.941-16.065      c3.854-4.359,8.432-7.663,13.758-9.908c5.319-2.243,11.256-3.369,17.796-3.369c6.926,0,13.114,1.187,18.568,3.562      c5.448,2.374,10.034,5.706,13.752,10.003c3.724,4.298,6.547,9.427,8.467,15.391c1.926,5.963,2.89,12.476,2.89,19.528v10.58      H257.847z M295.941,333.398c-0.129-2.181-0.326-4.07-0.577-5.678c-0.265-1.601-0.841-3.301-1.737-5.098      c-1.412-2.948-3.494-5.418-6.249-7.405c-2.761-1.988-6.255-2.982-10.488-2.982c-4.233,0-7.727,0.994-10.482,2.982      c-2.762,1.987-4.844,4.457-6.255,7.405c-0.895,1.798-1.479,3.497-1.73,5.098c-0.258,1.608-0.455,3.497-0.577,5.678H295.941z" />
                    </g>
            </g>
        </g>
        <g style="opacity:0.05;">
            <path style="fill:#231815;"
                d="M512,0v425.856c0,9.24-7.433,16.673-16.604,16.673H16.604c-4.446,0-8.475-1.737-11.463-4.655    l45.017-45.017l102.331-102.331l12.157-12.157l82.184-82.184l5.836-5.835l79.475-79.475l23.62-23.62L443.016,0H512z" />
        </g>
    </g>
</svg></template>