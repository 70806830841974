<template>
  <div class="card flex justify-content-center">
    <Button
      type="button"
      icon="pi pi-search"
      @click="toggleSearch"
      :loading="isLoading === invoice_id"
    />
    <Sidebar v-model:visible="visible" header="" position="full">
      <template #header>
        <div class="flex justify-content-between w-full align-items-center">
          <div>
            <h1 class="ml-5">Search Invoice</h1>
          </div>
          <div
            class="flex gap-3 mr-6"
            v-if="invoiceDetail?.invoice_status === 'MultipleMatches'"
          >
            <Button
              v-tooltip.bottom="'Previous invoice'"
              icon="pi pi-angle-left"
              severity="info"
              @click="previousInvoice"
              :disabled="!previousInvoiceAvailable()"
            />
            <Button
              v-tooltip.bottom="'Next invoice'"
              icon="pi pi-angle-right"
              severity="info"
              @click="nextInvoice"
              :disabled="!nextInvoiceAvailable()"
            />
          </div>
        </div>
      </template>
      <div class="px-3" v-if="!isLoading">
        <div class="flex">
          <div style="width: 60vw" class="px-4">
            <div>
              <div
                class="shadow-2 surface-card border-round flex justify-content-between p-4 h-full my-2"
              >
                <div class="flex align-items-start">
                  <div
                    class="bg-blue-100 border-round inline-flex align-items-center justify-content-center"
                    style="width: 52px; height: 52px"
                  >
                    <FileIcon />
                  </div>
                  <div class="ml-3 flex-1">
                    <span class="block text-900 mb-1 text-xl font-medium">{{
                      invoiceDetail?.invoice_status
                    }}</span>
                    <p class="text-600 mt-0 mb-5 text-sm">
                      {{
                        invoiceDetail?.invoice_status !== "Unknown" &&
                        invoiceDetail?.invoice_status !== "MultiMatched"
                          ? "Matched Invoice"
                          : `${invoiceDetail?.invoice_status} Invoice`
                      }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    invoiceDetail?.target_references &&
                    invoiceDetail?.target_references.length
                  "
                >
                  <span><b>Target Reference : </b></span>
                  <span
                    v-for="(item, index) in invoiceDetail?.target_references"
                    :key="index"
                  >
                    <Chip
                      :label="item"
                      :class="{
                        'chip-disabled':
                          invoiceDetail?.invoice_status !== 'MultipleMatches',
                      }"
                      class="ml-2 bg-green-400 cursor-pointer hover:shadow-2 hover:bg-green-500"
                      @click="openReference(item)"
                    />
                  </span>
                </div>
              </div>
            </div>
            <Dialog
              v-model:visible="dialog"
              :style="{ width: '450px' }"
              header="Assign Reference"
              :modal="true"
              class="p-fluid"
            >
              <div class="field">
                <p>
                  Are you sure you want to assign this reference
                  <b>{{ payload.reference }}</b> to current invoice?
                </p>
              </div>
              <template #footer>
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  severity="secondary"
                  outlined
                  @click="dialog = false"
                />
                <Button
                  label="Assign"
                  icon="pi pi-check"
                  :loading="assignLoading"
                  @click="assignReference"
                />
              </template>
            </Dialog>
            <TabView>
              <TabPanel header="Invoice References">
                <div class="flex justify-content-between">
                  <h2>Key References</h2>
                  <KeyFileViewer
                    :keyFile="invoiceDetail.keyfile_s3_path"
                    :organization="organization"
                    :searchInvoice="true"
                  />
                </div>
                <Divider class="mb-4" />
                <DataTable
                  :value="invoiceDetail?.target_keyfile"
                  tableStyle="min-width: 50rem"
                  class="mb-8"
                >
                  <Column field="Reference" header="Reference"></Column>
                  <Column field="HBL" header="HBL"></Column>
                  <Column field="Contenedor" header="Contenedor"></Column>
                  <Column field="TARJA" header="TARJA"></Column>
                  <Column field="PEDIMENTO" header="PEDIMENTO"></Column>
                  <Column field="Open/closed" header="Status"></Column>
                  <template #empty>
                    <div>
                      <div class="flex justify-content-center">
                        <img
                          src="../../assets/ExternalIcons/wired-document.gif"
                          style="height: 20vh"
                          alt=""
                        />
                      </div>
                      <h3 class="text-center">
                        No references found against this invoices
                      </h3>
                    </div>
                  </template>
                </DataTable>
                <div class="flex justify-content-between">
                  <h2>Matched References</h2>
                </div>
                <Divider class="mb-4" />
                <div class="flex gap-5 flex-wrap justify-content-center">
                  <div
                    v-for="(
                      details, invoiceKey, index
                    ) in invoiceDetail.match_details"
                    :key="index"
                    class="shadow-2 bg-gray-50 border-round p-4 my-4 w-5 overflow-x-auto"
                    style="min-width: 28rem"
                  >
                    <ul class="mt-4 list-none p-0 mx-0">
                      <div class="flex justify-content-center">
                        <h2
                          class="text-center -mt-4 bg-blue-100 w-5 border-round shadow-1"
                        >
                          {{ invoiceKey }}
                        </h2>
                      </div>
                      <li
                        v-for="(detail, detailIndex) in details"
                        :key="detailIndex"
                        class="flex align-items-center justify-content-center pb-3"
                      >
                        <span
                          v-for="(value, key) in detail"
                          :key="key"
                          class="flex justify-content-center px-3 py-1 border-round flex-wrap w-full"
                        >
                          <span class="text-xl -ml-3 font-medium text-90">
                            <b class="bg-blue-400 px-3 py-1 border-round">{{
                              key
                            }}</b>
                            &nbsp; <b class="text-2xl">➡</b>
                            &nbsp;
                            <span
                              class="bg-orange-400 px-3 py-1 border-round"
                              >{{ value }}</span
                            >
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Invoice Details">
                <div>
                  <div class="shadow-2 surface-card border-round p-4 h-full">
                    <div class="grid">
                      <template
                        v-for="(
                          value, key, index
                        ) in invoiceDetail.invoice_parsed_data"
                      >
                        <div
                          class="col-12 md:col-6 xl:col-4"
                          :key="index"
                          v-if="key !== 'tag' && key !== 'matched_items'"
                        >
                          <div class="flex flex-column gap-2">
                            <label for="username"
                              ><b>{{ key }}</b></label
                            >
                            <InputText
                              id="username"
                              :disabled="true"
                              class="bg-gray-200"
                              :value="value"
                              aria-describedby="username-help"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Invoice History">
                <InvoiceHistory :invoiceDetail="invoiceDetail" />
              </TabPanel>
            </TabView>
          </div>
          <div
            class="flex justify-content-center bg-blue-50 border-round shadow-2"
            style="width: 60vw"
          >
            <vue-pdf-embed
              width="850"
              height="100"
              class="p-5"
              :source="invoiceDetail?.pdf_presigned_url"
              :textLayer="true"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="flex justify-content-center align-items-center"
        style="height: 60vh"
      >
        <ProgressSpinner />
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { ref } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import { useApi } from "../../../hooks/useApi";
import { useUserSessionStore } from "@/store/userSession";
import { storeToRefs } from "pinia";
import FileIcon from "../../assets/ExternalIcons/FileSvgIcon.vue";
import KeyFileViewer from "../References/KeyFileViewer.vue";
import InvoiceHistory from "./tabs/InvoiceHistory.vue";
import { useJobStore } from "../../store/jobsStore/store";
import Repository from "@repositories/RepositoryFactory";
import { useToast } from "primevue/usetoast";
import { serviceStore } from "@/store/serviceStore";

const ResultsRepository = Repository.get("results");
const toast = useToast();

const userSession = useUserSessionStore();
const resultsStores = serviceStore();
const { organization, userName } = storeToRefs(userSession);
const isLoading = ref(true);
const visible = ref(false);
const invoiceDetail = ref({});
const payload = ref({});
const props = defineProps({
  invoice_id: {
    type: String,
    required: true,
  },
});
const assignLoading = ref(false);

const dialog = ref(false);

const currentInvoicesId = ref(props.invoice_id);

const jobStore = useJobStore();

const fetchResults = async (itemId) => {
  try {
    if (itemId) {
      isLoading.value = itemId;
      const params = {
        organization: organization.value,
        invoice_id: itemId,
      };

      const { data: response, fetchItems: getInvoiceDetails } = useApi(
        "/results/invoice",
        params
      );
      await getInvoiceDetails();
      currentInvoicesId.value = "";
      currentInvoicesId.value = response.value?.full_json?._id;
      invoiceDetail.value = response.value;
      if (invoiceDetail.value && !visible.value) {
        visible.value = true;
      }
    }
  } catch (error) {
    isLoading.value = "";
  } finally {
    isLoading.value = "";
  }
};

const openReference = (item) => {
  payload.value = {
    reference: item,
    organization: organization.value,
    user: userName.value,
    ids: [currentInvoicesId.value],
    s3_paths: [invoiceDetail.value?.keyfile_s3_path],
    category: "reference",
  };
  dialog.value = true;
};

const toggleSearch = () => {
  fetchResults(currentInvoicesId.value);
};

const nextInvoice = () => {
  const currentJobIdIndex = jobStore.multiMatchIds.findIndex(
    (item) => item === currentInvoicesId.value
  );
  const nextJobId = jobStore.multiMatchIds[currentJobIdIndex + 1];
  if (nextJobId) {
    fetchResults(nextJobId);
  }
};

const nextInvoiceAvailable = () => {
  const currentJobIdIndex = jobStore.multiMatchIds.findIndex(
    (item) => item === currentInvoicesId.value
  );
  if (currentJobIdIndex === jobStore.multiMatchIds.length - 1) {
    return false;
  } else {
    return true;
  }
};
const previousInvoiceAvailable = () => {
  const currentJobIdIndex = jobStore.multiMatchIds.findIndex(
    (item) => item === currentInvoicesId.value
  );
  if (currentJobIdIndex === 0) {
    return false;
  } else {
    return true;
  }
};
const previousInvoice = () => {
  const currentJobIdIndex = jobStore.multiMatchIds.findIndex(
    (item) => item === currentInvoicesId.value
  );
  const previousJobId = jobStore.multiMatchIds[currentJobIdIndex - 1];
  if (previousJobId) {
    fetchResults(previousJobId);
  }
};

const assignReference = async () => {
  try {
    assignLoading.value = true;
    const response = await ResultsRepository.assignReferences(payload.value);
    if (response.status === 200) {
      toast.add({
        severity: "success",
        summary: "Reference assigned successfully",
        life: 3000,
      });
      dialog.value = false;
      visible.value = false;
      setTimeout(() => {
        resultsStores.isReload = true;
      }, 3400);
    }
  } catch (error) {
    console.log(error);
  } finally {
    assignLoading.value = false;
  }
};
</script>
<style scoped>
.chip-disabled {
  pointer-events: none;
}
</style>
