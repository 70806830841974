<template>
    <div class="card">
        <div v-if="!props.isActive" :style="{ position: 'fixed', right: '2.4rem', bottom: '2rem' }">
            <Button label="Warning" @click="toggleModel" severity="secondary" class="bg-white shadow-2 border-gray-300" text
                raised rounded><i class="pi pi-megaphone mr-3"></i>
                Report a Feedback </Button>
        </div>
        <div v-if="props.isActive">
            <ButtonGroup class="p-buttonset flex align-items-center">
                <Button @click="toggleModel" outlined rounded severity="help" class="">
                    <i class="pi pi-info-circle mr-2 text-sm"></i>
                    Report a Feedback
                </Button>
                <Button v-if="trxStatus === 'Verification'" @click="viewModal" outlined rounded>
                    <i class="pi pi-wrench mr-2 text-sm"></i>
                    Manual Verification
                </Button>
                <Badge class="-mt-5" severity="info" v-if="trxStatus === 'Verification' && summary.remaining_invoices"
                    :value="summary.remaining_invoices"></Badge>
            </ButtonGroup>
        </div>
    </div>
    <div class="card p-3">
        <Dialog v-model:visible="feedbackModel" modal header="User Feedback" :style="{ width: '40rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class="px-2">
                <div class="flex-auto">
                    <label for="Name" class="font-bold block mb-2">Name</label>
                    <InputText id="Name" v-model="feedbackResponse.name" class="w-full" />
                </div>
                <div class="flex-auto mt-4">
                    <label for="Email" class="font-bold block mb-2">Email</label>
                    <InputText id="Email" v-model="feedbackResponse.email" class="w-full" />
                </div>
                <div class="flex-auto mt-4">
                    <label for="feedback" class="font-bold block mb-2">Comments / Feedback</label>
                    <Textarea v-model="feedbackResponse.comments" rows="5" cols="40" class="w-full "
                        :class="{ 'red-border': submitError }" />
                    <label v-if="submitError" for="feedback" class="text-xs text-red-400 block mb-2">Please provide your
                        feedback before submitting.</label>
                </div>
                <div class="flex justify-content-end mt-4">
                    <Button label="Submit Feedback" @click="submitFeedback" />
                    <Button label="Cancel" @click="feedbackModel = false" severity="danger" class="ml-2" />
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { reactive, ref } from 'vue';
import { useUserSessionStore } from "@/store/userSession";
import { serviceStore } from '../../store/serviceStore';
import * as Sentry from '@sentry/vue';

const store = serviceStore()

const props = defineProps(['isActive','trxStatus','summary'])
const userSession = useUserSessionStore();
const { userName, attributes } = storeToRefs(userSession);
import { useToast } from "primevue/usetoast";

const toast = useToast();
const feedbackResponse = reactive({
    name: '',
    email: '',
    comments: ''
})
const feedbackModel = ref(false)
const submitError = ref(false)


const toggleModel = () => {
    feedbackResponse.email = attributes.value.email
    feedbackResponse.name = userName.value
    feedbackModel.value = true
}

const viewModal = () => {
  store.pdfVerificationModel = true
}

const submitFeedback = () => {
    // Using setTag or setExtra to associate the URL with the Sentry event
    Sentry.setTags("environment", "test");


    // Capturing a Sentry message event and get the event ID
    // const eventId = Sentry.captureMessage("User Feedback");

    const eventId = Sentry.lastEventId()

    if (!eventId) {
        console.error("Failed to capture Sentry event");
        return; // Exiting if no eventId is generated
    }

    submitError.value = false;
    if (!feedbackResponse.comments) {
        submitError.value = true;
        return; // Exiting if feedback details are incomplete or invalid
    }
    // Constructing user feedback object
    const userFeedback = {
        event_id: eventId,
        name: feedbackResponse.name,
        email: feedbackResponse.email,
        comments: feedbackResponse.comments,
    };

    // Sending user feedback to Sentry
    Sentry.captureUserFeedback(userFeedback);
    toast.add({
        severity: 'success',
        summary: 'Feedback Submitted',
        detail: 'Thank you for your feedback! We will get back to you shortly.',
        life: 3000,
        position: 'top-left',
    });

    // Resting feedback form and close the model
    feedbackResponse.comments = '';
    feedbackModel.value = false;
};


</script>