<template>
    <Button size="small" :loading="deleteLoading" icon="pi pi-trash" severity="danger" @click="deleteUser"
        aria-label="Delete User" v-tooltip.top="'Delete User'" rounded outlined></Button>
</template>

<script setup >
import { ref, defineProps } from "vue"
import Repository from "@repositories/RepositoryFactory";
const EmisorConfigurationRepository = Repository.get("emisorConfiguration");


const emit = defineEmits(["userDeleted"]);
const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
});

const deleteLoading = ref(false);

const deleteUser = async () => {
    deleteLoading.value = true;
    await EmisorConfigurationRepository.deleteOrganizationUser(props.user.id).then(() => {
        emit("userDeleted");
    }).finally(() => {
        deleteLoading.value = false;
    })
};
</script>