import { defineStore } from "pinia";
// import Repository from "@repositories/RepositoryFactory";
// import { getCachedResponse, storeApiResponse } from "../../db";

// const ResultsRepository = Repository.get("results");

export const useJobStore = defineStore("jobStore", {
  state: () => ({
    selectedSubcategory: [],
    selectedMatchReferences: [],
    assignReferenceModalData: {
      filterModel: false,
      dialogVisible: false,
    },
    dialogVisible: true,
    isTagUpdated: false,
    exportModelVisible: false,
    filters: {
      date: '',
    },
    jobsTransactionList: [],
    transactionsList: [],
    jobListLoading : false,
    isInfoSidebarActive : false,
    multiMatchIds: [],
  }),

  actions: {},
});
