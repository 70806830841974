<template>
  <Card class="job-card relative">
    <template #content>
      <div class="flex align-items-center justify-content-between -mt-4">
        <div class="job-type border-circle">
          <div>
            <div class="flex justify-content-start gap-1">
              <div
                class="job-source py-2 px-3"
                :class="{ 'bg-green': jobData.ingestion_source === 'Auto-Job' }"
              >
                <i class="pi pi-sync"></i>
              </div>
              <div class="job-source py-2 px-3">
                <i class="pi pi-envelope"></i>
              </div>
            </div>
            <div class="flex justify-content-start gap-1 mt-1">
              <div
                class="job-source py-2 px-3"
                :class="{ 'bg-green': jobData.ingestion_source === 'Manual' }"
              >
                <i class="pi pi-user"></i>
              </div>
              <div
                class="job-source py-2 px-3"
                :class="{ 'bg-green': jobData.ingestion_source === 'BATCH' }"
              >
                <i class="pi pi-upload"></i>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2></h2>
          <h3>
            <b>{{ jobData.job_id }}</b>
          </h3>
        </div>
        <div>
          <span v-if="jobData.job_type !== 'pdf-only'">
            <p class="font-bold text-blue-400">XML</p>
            <p class="font-bold text-orange-400">PDF</p>
          </span>
          <span v-else>
            <p class="font-bold text-orange-400">PDF</p>
          </span>
        </div>
        <div>
          <div>
            <router-link
              :to="{ name: 'details', params: { trx_id: jobData.job_id } }"
            >
              <Button
                v-tooltip="'View Job Details'"
                icon="pi pi-eye"
                class="cursor-pointer"
                severity="success"
                text
                raised
                rounded
              />
            </router-link>
          </div>
          <div>
            <div
              class="cursor-pointer mt-1"
              type="button"
              @click="toggleDropdown"
              aria-haspopup="true"
              aria-controls="overlay_menu"
            >
              <span v-if="jobData.export_zip_name['issuer']">
                <Button
                  v-if="loading"
                  icon="pi pi-spin pi-spinner"
                  style="font-size: 2rem"
                  severity="info"
                  text
                  raised
                  rounded
                />
                <Button
                  v-else
                  v-tooltip="'Export Report'"
                  icon="pi pi-download"
                  class="cursor-pointer"
                  severity="info"
                  text
                  raised
                  rounded
                />
              </span>
            </div>
            <div class="card flex justify-content-center">
              <Menu ref="menu" :model="items" id="overlay_menu" :popup="true" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-between">
        <div>
          <p class="text-2xl text-gray-400">User</p>
          <p class="-mt-3 capitalize text-xl">
            <b>{{ jobData.user }}</b>
          </p>
        </div>
        <div>
          <p class="text-2xl text-gray-400">Status</p>
          <p
            class="bg-green-100 -mt-3 text-green-500 px-3 py-1 border-round-max"
            :class="{
              'bg-blue': jobData.status === 'In Progress',
              'bg-orange': jobData.status === 'Verification',
            }"
          >
            <b>{{ jobData.status }}</b>
          </p>
        </div>
      </div>
      <div class="flex align-items-center justify-content-between -mt-3">
        <div>
          <p class="text-2xl text-gray-400">Total Invoices</p>
          <p class="-mt-3 ml-1 text-start">
            <b>{{ jobData.total_invoices }}</b>
          </p>
        </div>
        <div>
          <p class="text-2xl text-gray-400">Job Type</p>
          <p class="-mt-3">
            <b>{{ jobData.ingestion_source }}</b>
          </p>
        </div>
      </div>
      <MeterGroup :value="adjustedValues" style="width: 30rem">
        <template #meter="slotProps">
          <span
            class="cursor-pointer"
            v-tooltip.top="
              `${slotProps.value.label} : ${
                jobData.summary[slotProps?.value?.label.toLowerCase()]
              } / ${jobData.summary['total']} `
            "
            style="border-radius: 10px"
            :style="{
              background: slotProps.value.color,
              width: slotProps.size,
            }"
          />
        </template>
        <template #end>
          <div class="text-gray-400 flex justify-content-between">
            <div>{{ jobData.date_parsed }}</div>
            <div>
              <span
                class="cursor-pointer text-blue-400"
                @click="jobStore.isInfoSidebarActive = true"
                >Info <i class="pi pi-question-circle"></i
              ></span>
            </div>
          </div>
        </template>
      </MeterGroup>
    </template>
  </Card>
  <DownloadReportModal
    @downloadExport="downloadExport"
    v-if="reportExportResponse.status === 1 && !exportingReport"
    :isVisible="jobStore.exportModelVisible"
  />
</template>
<script setup>
import { ref, computed } from "vue";
import { useOpenInNewTab } from "@composables/useUtils";
import { exportReports } from "@utils/helpers";
import { useJobStore } from "@/store/jobsStore/store";
import { storeToRefs } from "pinia";
import { useUserSessionStore } from "@/store/userSession";
import DownloadReportModal from "@/components/DownloadReportModal.vue";

const userSession = useUserSessionStore();
const { organization } = storeToRefs(userSession);

const { openInNewTab } = useOpenInNewTab();
const jobStore = useJobStore();
const reportExportTimeout = ref(null);
const isError = ref(null);
const exportingReport = ref(false);
const retryOption = ref(false);
const loading = ref(false);
const exportPayload = ref({});
const exportType = ref({
  name: "",
});

const adjustedValues = computed(() => {
  const data = [
    {
      label: "Verification",
      color: "#fa4e62",
      value: props.jobData.summary.verification,
    },
    {
      label: "Pending",
      color: "#f9b816",
      value: props.jobData.summary.pending,
    },
    {
      label: "Complete",
      color: "#0caf50",
      value: props.jobData.summary.complete,
    },
    {
      label: "Processing",
      color: "#4a8be1",
      value: props.jobData.summary.processing,
    },
  ];

  // Total of summary for percentages to sum to 100
  const totalFromSummary = props.jobData.summary.total;

  return data.map((item) => {
    // Calculate percentage relative to the total
    const percentage = (item.value / totalFromSummary) * 100;

    return { ...item, value: Math.round(percentage) };
  });
});

const userActivityResponse = ref({
  statusCode: "",
  presigned_zip_url: "",
});
const reportExportResponse = ref({
  presigned_zip_url: "",
  status: -1,
});
const exportStatus = {
  PENDING: 0,
  READY: 1,
};

const props = defineProps({
  jobData: {
    type: Object,
    required: true,
  },
});

const menu = ref();

const items = ref([
  {
    label: "Reference",
    icon: "pi pi-search",
    command: async () => {
      loading.value = true;
      userActivityResponse.value = props.jobData;
      exportType.value.name = "Reference";
      await exportReports({
        reportExportTimeout,
        exportingReport,
        retryOption,
        exportPayload,
        organization,
        exportType,
        userActivityResponse,
        isError,
        reportExportResponse,
        exportStatus,
        downloadExport,
      })
        .then(() => {
          loading.value = false;
        })
        .catch((e) => {
          console.log(e, "for error");
        });
    },
  },
  {
    label: "Issuer",
    icon: "pi pi-building-columns",
    command: async () => {
      loading.value = true;
      userActivityResponse.value = props.jobData;
      exportType.value.name = "Issuer";
      await exportReports({
        reportExportTimeout,
        exportingReport,
        retryOption,
        exportPayload,
        organization,
        exportType,
        userActivityResponse,
        isError,
        reportExportResponse,
        exportStatus,
        downloadExport,
      })
        .then(() => {
          loading.value = false;
          jobStore.exportModelVisible = true;
        })
        .catch((e) => {
          console.log(e, "for error");
        });
    },
  },
]);

const downloadExport = () => {
  if (openInNewTab) {
    openInNewTab(reportExportResponse.value.presigned_zip_url);
    jobStore.exportModelVisible = false;
  }
};

const toggleDropdown = (event) => {
  menu.value.toggle(event);
};
</script>
<style scoped>
.bg-green {
  background-color: #d6eeff !important;
}

.job-card {
  border-radius: 16px;
  border: 1px solid #e7e7e7, #e7e7e7;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.job-type {
  display: flex;
  width: 64px;
  align-items: center;
  overflow: hidden;
}

.job-source {
  padding: 6px;
  background-color: #e7e7e7;
}

.card {
  position: absolute;
  right: -11rem;
  top: 4rem;
  z-index: 1000;
}

.bg-blue {
  background-color: #4ba6f7 !important;
  color: white !important;
}

.bg-orange {
  background-color: #fa4e62 !important;
  color: white !important;
}

::v-deep(.p-metergroup-labels) {
  display: none !important;
}
</style>
