<template>
    <div v-tooltip.left="isOnline ? 'You are online' : 'You are offline'">
        <div>
            <img src="@assets/avatar.png" :alt="userName" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
        </div>
        <div class="-mt-2">
            <span style="border-radius: 3rem;"
                class="inline-flex items-center px-3 text-xs font-medium px-2.5 py-0.5 dark:bg-green-900 dark:text-green-300"
                :class="{ 'bg-green-100 text-green-800': isOnline, 'bg-red-100 text-red-800': !isOnline }">
                {{ isOnline ? 'Online' : 'Offline' }}
            </span>
        </div>
    </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue';
const isOnline = ref(false)

const checkInternetAccess = async () => {
    try {
        const response = await fetch('https://httpbin.org/ip', { method: 'GET' });
        isOnline.value = response.ok;
    } catch (error) {
        isOnline.value = false;
    }
};

watchEffect(() => {
    checkInternetAccess();

    const onlineHandler = () => {
        checkInternetAccess();
    };

    const offlineHandler = () => {
        isOnline.value = false;
    };

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
        window.removeEventListener('online', onlineHandler);
        window.removeEventListener('offline', offlineHandler);
    };
});
</script>