import { defineStore } from "pinia";

export const useUserActivityStore = defineStore("userActivity", {
  state: () => ({
    referencesActivityList: [],
    tableResponse: [],
    referencesListAll: [],
    initiallyLoading: false,
    isError: null,
    isFiltersLoading: false,
    userActivityResponse: {
      statusCode: "",
      presigned_zip_url: "",
    },
    emisorColumn: false,
  }),

  actions: {},
});
