const dbName = "junctionnet-organizer";
const dbVersion = 3;
const objectStoreName = "api-responses";

const openRequest = indexedDB.open(dbName, dbVersion);

openRequest.onupgradeneeded = function (event) {
  const db = event.target.result;

  if (db.objectStoreNames.contains(objectStoreName)) {
    db.deleteObjectStore(objectStoreName);
  }

  db.createObjectStore(objectStoreName);
};
export const storeApiResponse = (url, data) => {
  const request = indexedDB.open(dbName, dbVersion);

  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    db.createObjectStore(objectStoreName);
  };

  request.onerror = function (event) {
    console.error("Error opening IndexedDB:", event.target.error);
  };

  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction([objectStoreName], "readwrite");
    const store = transaction.objectStore(objectStoreName);

    transaction.oncomplete = function () {
      // console.log("Data stored successfully.");
    };

    transaction.onerror = function (event) {
      console.error("Error storing data:", event.target.error);
    };
    if (data[0] !== null){
      store.put(data, url);
    }
  };
};

export const getCachedResponse = (url, callback) => {
  const request = indexedDB.open(dbName, dbVersion);

  request.onerror = function (event) {
    console.error("Error opening IndexedDB:", event.target.error);
  };

  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db?.transaction([objectStoreName], "readonly");
    const store = transaction.objectStore(objectStoreName);
    const getRequest = store.get(url);

    transaction.oncomplete = function () {
      // console.log("Data retrieved successfully.");
    };

    transaction.onerror = function (event) {
      console.error("Error retrieving data:", event.target.error);
    };

    getRequest.onsuccess = function (event) {
      const storedData = event.target.result;
      callback(storedData);
    };
  };
};

export const clearDatabase = () => {
  try {
    const request = indexedDB.open(dbName, dbVersion);

    request.onsuccess = function async (event) {
      const db = event.target.result;
      const transaction = db.transaction([objectStoreName], "readwrite");
      const store = transaction.objectStore(objectStoreName);

      const clearRequest = store.clear();

      clearRequest.onsuccess = function (event) {
        console.log("Database cleared successfully.");
      };

      clearRequest.onerror = function (event) {
        console.error("Error clearing database:", event.target.error);
      };
    };
  } catch (error) {
    // console.log(error, 'Error');
    throw new Error(error, "Error in Clearing Data")
  }
};
