/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_aja0w2IBB',
        userPoolWebClientId: '6667ljasgf3p7726mp4cibiaq0'
    },
    aws_project_region: 'us-east-1',
    // aws_cognito_identity_pool_id:
    //   'us-east-1:275f4420-90e8-470b-8224-2f64e3fd8657',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_aja0w2IBB',
    aws_user_pools_web_client_id: '6667ljasgf3p7726mp4cibiaq0',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
};
export default awsmobile;
