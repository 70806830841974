<template>
  <div class="card relative z-2">
    <div class="min-h-screen flex relative lg:static surface-ground">
      <div id="app-sidebar-1"
        class="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
        :style="{
          width: collapseWidth,
        }">
        <div class="flex flex-column h-full fixed mr-2" v-bind:style="{
          background: 'inherit',
          width: collapseWidth,
        }">
          <div class="flex align-items-center flex-shrink-0" style="height: 60px">
            <img src="@assets/logo.png" alt="Image" class="mx-auto" :height="sidebarCollapsed ? 50 : 50" />
          </div>
          <div class="overflow-y-auto">
            <ul class="list-none p-3 m-0 overflow-hidden">
              <li v-for="(linkItem, index) in sidebarLinks" :key="index"
                v-tooltip="sidebarCollapsed ? linkItem.text : ''">
                <router-link :to="linkItem.link" v-ripple :class="{
                  'active': route.path === linkItem.link,
                  'sidebar-link': true,
                }"
                  class="flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors p-ripple">
                  <i :class="linkItem.icon + ' mr-2'"></i>
                  <span class="font-medium" v-if="!sidebarCollapsed">
                    {{ linkItem.text }}
                    <i v-if="linkItem.showBadge" v-badge.danger class="pi p-overlay-badge mb-2 ml-1" />
                  </span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="mt-auto">
            <a tabindex="0"
              class="flex align-items-center text-center mx-3 cursor-pointer p-3 border-round text-700 sidebar-link  transition-duration-150 transition-colors p-ripple"
              @click="sidebarCollapsed = !sidebarCollapsed">
              <i :class="{
                'pi mr-2': true,
                'pi-angle-double-right': sidebarCollapsed,
                'pi-angle-double-left': !sidebarCollapsed
              }"></i>
              <span v-if="!sidebarCollapsed" class="font-medium">
                Collapse menu
              </span>
            </a>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <a tabindex="0" v-ripple :class="{
              'm-3 p-3 flex cursor-pointer border-round text-700 hover:bg-blue-100 hover:text-blue-600 transition-duration-150 transition-colors p-ripple': true,
              'text-center align-items-center': !sidebarCollapsed,
              'justify-content-center': sidebarCollapsed,
            }">
              <img src="@assets/avatar.png" :alt="userName" :class="{
                'mr-2': !sidebarCollapsed
              }" :style="{
                width: !sidebarCollapsed ? '32px' : '25px',
                height: !sidebarCollapsed ? '32px' : '25px',
              }" />
              <span class="font-medium capitalize" v-if="!sidebarCollapsed">{{ userName }}</span>
            </a>
            <div></div>
          </div>
        </div>
      </div>
      <div class="min-h-screen flex flex-column relative flex-auto">
        <div
          class="flex justify-content-between align-items-center px-5  surface-0 border-bottom-1 surface-border relative lg:static"
          style="height: 75px">
          <div class="flex">
            <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple" v-styleclass="{
              selector: '#app-sidebar-1',
              enterClass: 'hidden',
              enterActiveClass: 'fadeinleft',
              leaveToClass: 'hidden',
              leaveActiveClass: 'fadeoutleft',
              hideOnOutsideClick: true,
            }">
              <i class="pi pi-bars text-4xl"></i>
            </a>
          </div>
          <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple" v-styleclass="{
            selector: '@next',
            enterClass: 'hidden',
            enterActiveClass: 'fadein',
            leaveToClass: 'hidden',
            leaveActiveClass: 'fadeout',
            hideOnOutsideClick: true,
          }">
            <i class="pi pi-ellipsis-v text-2xl"></i>
          </a>
          <ul
            class="list-none p-0 m-0  hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
            <h4 class="capitalize" style="height: 1rem;">{{ userName }} | <i>{{ organization }}</i></h4>
            <li class="border-top-1 surface-border lg:border-top-none">
              <div>
                <div class="relative">
                  <a @click="toggleMenu" v-ripple v-styleclass="{
                    selector: '@next',
                    enterClass: 'hidden',
                    enterActiveClass: 'fadein',
                    leaveToClass: 'hidden',
                    leaveActiveClass: 'fadeout',

                    hideOnOutsideClick: true,
                  }" tabindex="0"
                    class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple">
                    <Avatar />
                  </a>
                </div>
              </div>
            </li>
            <Menu ref="menu" :model="menuItems" :popup="true" />
          </ul>
        </div>
        <div class="py-3 px-5 flex flex-column flex-auto">
          <div class="border-1 surface-border border-round surface-section flex-auto">
            <div>
              <InfoDrawer />
            </div>
            <slot name="data"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useAuthenticator } from "@aws-amplify/ui-vue";
import { useUserSessionStore } from "@/store/userSession";
import { serviceStore } from "@/store/serviceStore";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { clearDatabase } from "../../db";
import Avatar from './Avatar.vue'
import InfoDrawer from '../components/InfoDrawer.vue'
import { getLayoutLinks } from "../utils/resources";

const route = useRoute()

const resultsStores = serviceStore()
const links = getLayoutLinks(resultsStores);
const userSession = useUserSessionStore();
const { userName, organization } = storeToRefs(userSession);
const router = useRouter();
const auth = useAuthenticator();
const menu = ref();
const menuItems = computed(() => {
  let links =  [
    {
      label: 'Configuration', icon: 'pi pi-fw pi-cog mr-2', command: function () {
        router.push({ name: 'emisor-configuration-admin' })
      },
      role: "admin"
    },
    {
      label: 'Edit Workflow', icon: 'pi pi-fw pi-home mr-2', command: function () {
        router.push({ name: 'workflow-edit' })
      },
      role: "admin"
    },
    {
      label: 'Logout', icon: 'pi pi-fw pi-sign-out mr-2', command: function () {
        auth.signOut();
        clearDatabase()
      },
      role: "*"
    },
  ]
  return links;
})


const toggleMenu = (event) => {
  menu.value.toggle(event);
};
const isProduction = import.meta.env.MODE === 'production'
const sidebarCollapsed = ref(false);
const sidebarLinks = computed(() => {
 
  if (isProduction) {
    return links.filter(link => link.link !== "/org-overview");
  }
  return links
}, [resultsStores.referenceInvoice]);

const collapseWidth = computed(() => {
  return sidebarCollapsed.value ? '5rem' : '15rem'
}, [sidebarCollapsed])

onMounted(() => {
  resultsStores.isDevelopment = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test'
})


</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}

.drop-down {
  box-shadow: gray 3px 2px 5px 0.1px;
  right: 2rem;
  top: 3rem;
  margin: 0 auto !important;
  border-radius: 4px;
  padding: 0;
}

.drop-down ul {
  padding: 0;
}

.drop-down li {
  list-style: none;
}

.drop-down li span {
  text-align: center !important;
}

.sidebar-link:hover {
  color: #326fd1 !important;
  background-color: #d2e2fc !important;
}

.sidebar-link.active {
  background-color: #d2e2fc !important;
}
</style>
