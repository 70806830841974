import { ref } from "vue";
import { defineStore } from 'pinia'

export const useUserSessionStore = defineStore('userSession', () => {
    const userName = ref('');
    const attributes = ref({});
    const s3UploadKey = ref('');
    const organization = ref('');
    const userGroups = ref([]);

    return {
        userName,
        attributes,
        s3UploadKey,
        organization,
        userGroups
    };
});